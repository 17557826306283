import React from 'react';
import T from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';

import MachineDrawerTableRow from './TableRow';
import MachineDrawerTableRowData from './TableRowData';

const MachineDrawerTable = ({
  controlId,
  dataItems,
  tableRowProps: { handleOnClickDataLabel },
  tablePaginationProps: {
    handleOnChangePage,
    handleOnChangeRowsPerPage,
    page,
    rowsPerPage,
    ...tablePaginationRestProps
  },
}) => {
  const rowsPerPageOptions = [5, 10, 15];

  return (
    <>
      {dataItems.length > rowsPerPageOptions[0] && (
        <TablePagination
          component='div'
          count={dataItems.length}
          onChangePage={(_, value) => handleOnChangePage({ value })}
          onChangeRowsPerPage={(_, { props }) =>
            handleOnChangeRowsPerPage({ value: props.value })
          }
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          {...tablePaginationRestProps}
        />
      )}
      <Table padding='checkbox' size='small' aria-label='drawer'>
        <TableBody>
          {dataItems
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, index) => (
              <MachineDrawerTableRow key={index}>
                <MachineDrawerTableRowData
                  onClickDataLabel={handleOnClickDataLabel}
                  data={data}
                />
              </MachineDrawerTableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

MachineDrawerTable.propTypes = {
  controlId: T.string.isRequired,
  dataItems: T.array.isRequired,
  tableRowProps: T.shape({
    handleOnClickDataLabel: T.func.isRequired,
  }).isRequired,
  tablePaginationProps: T.shape({
    handleOnChangePage: T.func.isRequired,
    handleOnChangeRowsPerPage: T.func.isRequired,
    page: T.number.isRequired,
    rowsPerPage: T.number.isRequired,
  }).isRequired,
};

export default MachineDrawerTable;
