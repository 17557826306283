import { assign, createMachine } from 'xstate';
import isEqual from 'lodash/isEqual';
import isMatch from 'lodash/isMatch';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import { updatedDiff } from 'deep-object-diff';
import flatten, { unflatten } from 'flat';

import { MODAL_DICTIONARY } from '../Modal/constants';
import {
  MACHINES_CONTEXT,
  MACHINES_CONTEXT_LOCAL_STORAGE_UNSET,
  MACHINES_SHARED_CONTEXT,
} from './constants';
import {
  applyDiffStringToObject,
  applyDiffStringToText,
  calculateFrameDuration,
  composeFrameContext,
  convertStringToCompressedString,
  createDiffStringFromObjects,
  createDiffStringFromText,
  getMachinesContextFromLocalStorage,
} from '../../helpers';

const initialMachineContext = {
  board: {
    frames: [],
    mode: 'record',
    name: '',
    role: 'view',
  },
  boardFramesSendQueue: [],
  controllerSettings: {
    appBarHeight: 71,
    eventDelayPerCharacter: 40,
    eventDelayProgressMinimum: 1000,
  },
  editContext: cloneDeep(MACHINES_SHARED_CONTEXT),
  editControl: {
    calculatedDuration: 0,
    durationField: {
      value: 0,
    },
    hasUnsavedChanges: false,
    isAwaitingApiRequest: false,
    isCapturingBoardFrame: false,
    isEditFrameModeActive: false,
    isFrameIndexOnNewFrame: false,
  },
  editFrameIndex: 0,
  helpControl: {
    helpTopicId: 'default',
    helpTopicIndex: 0,
    isHelpActive: false,
    minimizedControlIds: new Set(),
    openControlIds: new Set(),
    openRelatedControlIds: new Set(),
    renderedControlIds: new Set(),
    showTopics: false,
  },
  machinesContext: cloneDeep(MACHINES_CONTEXT),
  modalControl: {
    openModalIds: [],
    closedModalIds: [],
  },
  playControl: {
    frameTimeElapsed: 0,
    isLoadingAssets: false,
    timeElapsed: 0,
    timeInterval: 0.1,
    timeTotal: 0,
    paused: false,
    playing: true,
    reverse: false,
    speed: 1,
    videoPlayerMuted: true,
    videoPlayerProgress: 0,
    videoPlayerTarget: 0,
    videoPlayerMode: 'idle',
  },
  sessionId: '',
  viewContext: cloneDeep(MACHINES_SHARED_CONTEXT),
  viewFrameIndex: 0,
};

const controllerMachine = createMachine(
  {
    id: 'controllerMachine',
    context: cloneDeep(initialMachineContext),
    initial: 'init',
    states: {
      init: {
        initial: 'idle',
        states: {
          idle: {
            on: {},
          },
        },
      },
      edit: {
        id: 'edit',
        initial: 'idle',
        states: {
          idle: {
            on: {
              /**
               * Clear frames send queue, if any
               */
              DEQUEUE_BOARD_FRAMES: {
                actions: assign({
                  boardFramesSendQueue: () => [],
                }),
                cond: ({ boardFramesSendQueue }) => boardFramesSendQueue.length,
              },
              CHANGE_FRAME_INDEX: {
                actions: [
                  // 'composeMachinesContext',
                  'composeEditContext',
                  'updateViewContextWithEditContext',
                  'updateEditFrameIndex',
                  'updateViewFrameIndex',
                  'updateEditControl',
                  'resetIsEditFrameModeActive',
                ],
              },
              DELETE_FRAME_CAPTURE: {
                actions: [
                  'deleteFrame',
                  // We need to reload the machines context to update the
                  // sidenoteMachine and textMachine editorState
                  'loadMachinesContext',
                  'composeEditContext',
                  'updateViewContextWithEditContext',
                  'updateEditFrameIndex',
                  'updateViewFrameIndex',
                  'updateEditControl',
                ],
                target: 'deleteFrame',
              },
              SUBMIT_FRAME_CAPTURE: [
                {
                  actions: [
                    assign({
                      editControl: ({ editControl }) => ({
                        ...editControl,
                        isCapturingBoardFrame: true,
                      }),
                    }),
                    'createFrame',
                    'updateViewContextWithEditContext',
                    'updateEditFrameIndex',
                    'updateViewFrameIndex',
                  ],
                  cond: ({ board: { frames }, editFrameIndex }) =>
                    !frames.length || editFrameIndex === frames.length,
                  target: 'createFrame',
                },
                {
                  actions: [
                    assign({
                      editControl: ({ editControl }) => ({
                        ...editControl,
                        isCapturingBoardFrame: true,
                      }),
                    }),
                    'updateFrame',
                    'updateViewContextWithEditContext',
                  ],
                  cond: ({ board: { frames }, editFrameIndex }) =>
                    editFrameIndex < frames.length,
                  target: 'updateFrame',
                },
              ],
            },
          },
          createFrame: {
            /**
             * Containers act on controllers's 'edit.createFrame' state
             */
            after: {
              10: 'idle',
            },
            exit: [
              assign({
                editControl: ({ editControl }) => ({
                  ...editControl,
                  isCapturingBoardFrame: false,
                }),
              }),
            ],
          },
          deleteFrame: {
            /**
             * Containers act on controllers's 'edit.deleteFrame' state
             */
            after: {
              10: 'idle',
            },
            exit: [
              assign({
                editControl: ({ editControl }) => ({
                  ...editControl,
                  isCapturingBoardFrame: false,
                }),
              }),
            ],
          },
          updateFrame: {
            /**
             * Containers act on controllers's 'edit.updateFrame' state
             */
            after: {
              10: 'idle',
            },
            exit: [
              assign({
                editControl: ({ editControl }) => ({
                  ...editControl,
                  isCapturingBoardFrame: false,
                  isEditFrameModeActive: false,
                }),
              }),
            ],
          },
        },
      },
      view: {
        id: 'view',
        initial: 'loadingAssets',
        states: {
          loadingAssets: {
            invoke: {
              src: 'loadAssets',
              onDone: {
                actions: [
                  assign({
                    playControl: ({ playControl }) => {
                      return {
                        ...playControl,
                        isLoadingAssets: false,
                      };
                    },
                  }),
                ],
                target: 'idle',
              },
            },
          },
          idle: {
            id: 'idle',
            always: [
              {
                cond: ({
                  board: { frames },
                  playControl: { isLoadingAssets, paused, playing },
                  viewFrameIndex,
                }) =>
                  !isLoadingAssets &&
                  !paused &&
                  playing &&
                  viewFrameIndex < frames.length,
                target: 'playFrames',
              },
            ],
            on: {
              ENQUEUE_BOARD_FRAMES: {
                actions: [
                  assign({
                    board: ({ board }, { payload: { frames } }) => ({
                      ...board,
                      frames: [...board.frames, ...frames],
                    }),
                    playControl: (
                      { board, playControl },
                      { payload: { frames } }
                    ) => ({
                      ...playControl,
                      totalTime: [...board.frames, ...frames].reduce(
                        (totalTime, frame) => totalTime + frame.duration,
                        0
                      ),
                    }),
                  }),
                ],
                target: 'enqueueFrame',
              },
              START_PLAY_FRAMES: {
                actions: [
                  assign({
                    playControl: ({ playControl }) => ({
                      ...playControl,
                      paused: false,
                      playing: true,
                      reverse: false,
                      speed: 1,
                    }),
                  }),
                  'resetHelpRenderedControlIds',
                ],
                cond: 'isLastFrameDurationNotFinished',
                target: 'playFrames',
              },
            },
          },
          enqueueFrame: {
            /**
             * Containers act on controllers's 'view.enqueueFrame' state
             */
            after: {
              10: [
                {
                  cond: ({ playControl: { playing } }) => !playing,
                  target: 'idle',
                },
                {
                  cond: ({ playControl: { playing } }) => playing,
                  target: 'playFrames',
                },
              ],
            },
          },
          playFrames: {
            initial: 'compose',
            states: {
              compose: {
                always: [
                  /**
                   * If we have not reached the last frame, compose next frame
                   * and start the timer.
                   */
                  {
                    actions: ['composeViewContext'],
                    cond: 'isViewFrameIndexNotOnLastFrame',
                    target: 'timer',
                  },
                  /**
                   * If we have reached the last frame, the timer has not ended.
                   * This would happen if the user pauses on the last frame, and
                   * then continues playing. We fast-forward
                   */
                  {
                    actions: assign({
                      playControl: ({
                        board: { frames },
                        playControl,
                        viewFrameIndex,
                      }) => ({
                        ...playControl,
                        frameTimeElapsed: frames[viewFrameIndex].duration,
                      }),
                    }),
                    cond: 'isLastFrameDurationNotFinished',
                  },
                ],
              },
              timer: {
                entry: assign({
                  viewFrameIndex: ({
                    playControl: { reverse },
                    viewFrameIndex,
                  }) => (reverse ? viewFrameIndex - 1 : viewFrameIndex + 1),
                  playControl: ({ playControl }) => ({
                    ...playControl,
                    frameTimeElapsed: 0,
                  }),
                }),
                exit: assign({
                  /**
                   * When frame timer ends and there are multiple boards,
                   * set index to next board, or restart at first board.
                   */
                  machinesContext: ({
                    board: { frames },
                    machinesContext,
                    viewFrameIndex,
                  }) => {
                    const { boards, boardsIndex } =
                      machinesContext.controllerMachine;
                    if (
                      viewFrameIndex === frames.length - 1 &&
                      boards.length > 1
                    ) {
                      machinesContext.controllerMachine.boardsIndex =
                        boardsIndex < boards.length - 1 ? boardsIndex + 1 : 0;
                    }
                    return machinesContext;
                  },
                }),
                invoke: {
                  src:
                    ({ playControl: { timeInterval } }) =>
                    (send) => {
                      const interval = setInterval(
                        () => send('INTERVAL_TICK'),
                        1000 * timeInterval
                      );

                      return () => {
                        clearInterval(interval);
                      };
                    },
                },
                always: [
                  /**
                   * For videoMachine check if the video player has reached the target progress and the frame duration
                   * has elapsed. For all other machines check if the frame duration has elapsed.
                   */
                  {
                    target: 'compose',
                    cond: ({
                      board: { frames },
                      playControl: {
                        frameTimeElapsed,
                        videoPlayerProgress,
                        videoPlayerTarget,
                        videoPlayerMode,
                      },
                      viewContext: {
                        controllerMachine: {
                          boardGrid: { activeTabMachineId },
                        },
                      },
                      viewFrameIndex,
                    }) => {
                      return (
                        activeTabMachineId === 'videoMachine' &&
                        videoPlayerMode !== 'idle' &&
                        videoPlayerProgress >= videoPlayerTarget &&
                        frameTimeElapsed >= frames[viewFrameIndex].duration
                      );
                    },
                  },
                  {
                    target: 'compose',
                    cond: ({
                      board: { frames },
                      playControl: { frameTimeElapsed },
                      viewContext: {
                        controllerMachine: {
                          boardGrid: { activeTabMachineId },
                        },
                      },
                      viewFrameIndex,
                    }) => {
                      return (
                        activeTabMachineId !== 'videoMachine' &&
                        frameTimeElapsed >= frames[viewFrameIndex].duration
                      );
                    },
                  },
                ],
                on: {
                  INTERVAL_TICK: {
                    actions: assign({
                      playControl: ({
                        playControl,
                        playControl: { frameTimeElapsed, timeInterval },
                      }) => ({
                        ...playControl,
                        frameTimeElapsed: +(
                          frameTimeElapsed +
                          1000 * timeInterval
                        ),
                      }),
                    }),
                    cond: ({ playControl: { paused } }) => !paused,
                  },
                },
              },
            },
            on: {
              ENQUEUE_BOARD_FRAMES: {
                actions: [
                  assign({
                    board: ({ board }, { payload: { frames } }) => ({
                      ...board,
                      frames: [...board.frames, ...frames],
                    }),
                    playControl: (
                      { board, playControl },
                      { payload: { frames } }
                    ) => ({
                      ...playControl,
                      totalTime: [...board.frames, ...frames].reduce(
                        (totalTime, frame) => totalTime + frame.duration,
                        0
                      ),
                    }),
                  }),
                ],
                target: 'enqueueFrame',
              },
              START_REPLAY_FRAMES: {
                actions: [
                  assign({
                    playControl: ({ playControl }) => ({
                      ...initialMachineContext.playControl,
                      playing: true,
                    }),
                  }),
                  'resetViewContext',
                  'resetHelpRenderedControlIds',
                ],
                target: 'playFrames',
              },
              STOP_PLAY_FRAMES: {
                actions: [
                  assign({
                    playControl: ({ playControl }) => ({
                      ...playControl,
                      paused: !playControl.paused,
                    }),
                  }),
                  'resetHelpRenderedControlIds',
                ],
                cond: 'isLastFrameDurationNotFinished',
                target: '#idle',
              },
            },
          },
        },
      },
    },
    on: {
      CHANGE_CONTAINER_ORIENTATION: {
        actions: assign({
          editContext: (
            { editContext },
            { payload: { containerOrientation } }
          ) => {
            editContext.controllerMachine.boardGrid.containerOrientation =
              containerOrientation;
            return editContext;
          },
        }),
      },
      CHANGE_FRAME_INDEX: {
        actions: [
          'composeViewContext',
          'updateViewFrameIndex',
          'resetIsEditFrameModeActive',
        ],
      },
      CONTROLLER_RESET: {
        actions: assign({
          ...initialMachineContext,
        }),
        target: 'init',
      },
      UPDATE_VIDEO_PLAYER_MODE: {
        actions: assign({
          playControl: ({ playControl }, { payload: { videoPlayerMode } }) => {
            return {
              ...playControl,
              videoPlayerMode,
            };
          },
        }),
      },
      CREATE_RECORDING: {
        /**
         * - Set initial board values
         * - Create initial setup frame
         * - Set index to next frame
         * - Copy edit to view context
         * - Dispatch createFrame hook
         */
        actions: assign({
          board: ({ editContext }) => {
            return {
              ...initialMachineContext.board,
              frames: [
                {
                  diffString: createDiffStringFromObjects({}, editContext),
                  duration: 0,
                  machineId: '',
                },
              ],
              mode: 'record',
              role: 'edit',
            };
          },
          editControl: ({ editControl }) => ({
            ...initialMachineContext.editControl,
            ...editControl, // TODO Do we actually need this?
            isEditFrameModeActive: true,
            isFrameIndexOnNewFrame: true,
          }),
          editFrameIndex: () => 1,
          helpControl: () => ({ ...initialMachineContext.helpControl }),
          sessionId: (_, { payload: { sessionId } }) => sessionId,
          viewContext: ({ editContext }) => cloneDeep(editContext),
        }),
        target: '#edit.createFrame',
      },
      CREATE_STREAM: {
        /**
         * - Set initial board values
         * - Create initial setup frame
         * - Add setup frame[0] to send queue
         * - Set edit frame index to 1
         * - Dispatch createFrame hook
         */
        actions: assign({
          board: ({ editContext }) => ({
            ...initialMachineContext.board,
            frames: [
              {
                diffString: createDiffStringFromObjects({}, editContext),
                duration: 0,
                machineId: '',
              },
            ],
            mode: 'stream',
            role: 'edit',
          }),
          boardFramesSendQueue: ({ editContext }) => [
            {
              diffString: createDiffStringFromObjects({}, editContext),
              duration: 0,
              machineId: '',
            },
          ],
          editControl: ({ editControl }) => ({
            ...editControl,
            isEditFrameModeActive: true,
            isFrameIndexOnNewFrame: true,
          }),
          editFrameIndex: () => 1,
          helpControl: () => ({ ...initialMachineContext.helpControl }),
          sessionId: (_, { payload: { sessionId } }) => sessionId,
          viewContext: ({ editContext }) => cloneDeep(editContext),
        }),
        target: '#edit.createFrame',
      },
      EDIT_STREAM: {
        /**
         * - Set board from payload
         * - Set board time values
         * - Set playing flag
         * - Target edit
         */
        actions: [
          assign({
            board: (_, { payload: { board } }) => ({
              ...initialMachineContext.board,
              ...board,
              mode: 'stream',
              role: 'edit',
            }),
            editControl: ({ editControl }) => ({
              ...editControl,
              isEditFrameModeActive: true,
              isFrameIndexOnNewFrame: true,
            }),
            sessionId: (_, { payload: { sessionId } }) => sessionId,
          }),
          'loadMachinesContext',
          'composeEditContext',
          assign({
            viewContext: ({ editContext }) => cloneDeep(editContext),
          }),
        ],
        target: '#edit',
      },
      EDIT_RECORDING: {
        /**
         * - Set board from payload
         * - Set initial frame
         * - Compose edit context
         * - Clone as view context
         */
        actions: [
          assign({
            board: (_, { payload: { board } }) => ({
              ...initialMachineContext.board,
              ...board,
              mode: 'record',
              role: 'edit',
            }),
            editContext: (
              _,
              {
                payload: {
                  board: { frames },
                },
              }
            ) => {
              return applyDiffStringToObject({}, frames[0].diffString);
            },
            editFrameIndex: () => 0,
            editControl: (
              { editControl },
              {
                payload: {
                  board: { frames },
                  targetFrameIndex,
                },
              }
            ) => ({
              ...editControl,
              durationField: {
                ...editControl.durationField,
                /**
                 * Set duration to value existing target frame, or 0 if new frame
                 */
                value: frames[targetFrameIndex]
                  ? frames[targetFrameIndex].duration
                  : 0,
              },
              isEditFrameModeActive: targetFrameIndex === frames.length,
              isFrameIndexOnNewFrame: targetFrameIndex === frames.length,
            }),
            sessionId: (_, { payload: { sessionId } }) => sessionId,
            playControl: ({ playControl }, { payload: { board } }) => ({
              ...playControl,
              timeTotal: board.frames.reduce(
                (totalTime, frame) => totalTime + frame.duration,
                0
              ),
            }),
          }),
          'loadMachinesContext',
          'composeEditContext',
          assign({
            viewContext: ({ editContext }) => cloneDeep(editContext),
          }),
        ],
        target: '#edit',
      },
      VIEW_STREAM: {
        /**
         * - Set board from payload
         * - Set board time values
         * - Set playing flag
         * - Target view
         */
        actions: [
          assign({
            board: (_, { payload: { board } }) => ({
              ...initialMachineContext.board,
              ...board,
              mode: 'stream',
              role: 'view',
            }),
            modalControl: (
              { modalControl },
              {
                payload: {
                  session: { autoPlay },
                },
              }
            ) => ({
              ...modalControl,
              openModalIds: autoPlay
                ? modalControl.openModalIds
                : [...modalControl.openModalIds, 'menuBoardOverview'],
            }),
            playControl: (
              { playControl },
              {
                payload: {
                  board,
                  session: { autoPlay },
                },
              }
            ) => ({
              ...playControl,
              isLoadingAssets: true,
              paused: !autoPlay,
              playing: true,
              timeTotal: board.frames.reduce(
                (totalTime, frame) => totalTime + frame.duration,
                0
              ),
            }),
          }),
          'resetViewContext',
        ],
        target: '#view',
      },
      VIEW_PRESET: {
        /**
         * - Set board from payload
         * - Reset board context/controls
         * - Target view
         */
        actions: [
          assign({
            board: (_, { payload: { board } }) => ({
              ...initialMachineContext.board,
              ...board,
            }),
            editContext: initialMachineContext.editContext,
            editFrameIndex: initialMachineContext.editFrameIndex,
            viewContext: (
              _,
              {
                payload: {
                  board: { frames },
                },
              }
            ) => applyDiffStringToObject({}, frames[0].diffString),
            viewFrameIndex: 0,
            playControl: ({ playControl }, { payload: { board } }) => ({
              ...playControl,
              isLoadingAssets: true,
              playing: true,
              timeTotal: board.frames.reduce(
                (totalTime, frame) => totalTime + frame.duration,
                0
              ),
            }),
          }),
        ],
        target: '#view',
      },
      VIEW_RECORDING: {
        /**
         * - Set board from payload
         * - Compose setup frame[0]
         * - Set board time values
         * - Target view
         */
        actions: [
          assign({
            board: (_, { payload: { board } }) => ({
              ...initialMachineContext.board,
              ...board,
            }),
            editContext: initialMachineContext.editContext,
            editFrameIndex: initialMachineContext.editFrameIndex,
            viewContext: (
              _,
              {
                payload: {
                  board: { frames },
                },
              }
            ) => {
              return applyDiffStringToObject({}, frames[0].diffString);
            },
            viewFrameIndex: () => 0,
            modalControl: (
              { modalControl },
              {
                payload: {
                  session: { autoPlay },
                },
              }
            ) => ({
              ...modalControl,
              openModalIds: autoPlay
                ? modalControl.openModalIds
                : [...modalControl.openModalIds, 'menuBoardOverview'],
            }),
            playControl: (
              { playControl },
              { payload: { board, session } }
            ) => ({
              ...playControl,
              isLoadingAssets: true,
              playing: session.autoPlay,
              timeTotal: board.frames.reduce(
                (totalTime, frame) => totalTime + frame.duration,
                0
              ),
            }),
          }),
          'composeViewContext',
          'updateViewFrameIndex',
        ],
        target: '#view',
      },
      CONTROLLER_PROP_VALUES: {
        actions: ['setPropValues'],
      },
      MODAL_CLOSE: {
        actions: [
          assign({
            playControl: (
              { modalControl, playControl },
              { payload: { modalId } }
            ) => {
              return modalControl.openModalIds.includes(modalId)
                ? {
                    ...playControl,
                    paused: MODAL_DICTIONARY[modalId].playControl.unpauseOnClose
                      ? false
                      : playControl.paused,
                  }
                : { ...playControl };
            },
            modalControl: ({ modalControl }, { payload: { modalId } }) => {
              const { closedModalIds, openModalIds } = modalControl;
              if (modalControl.openModalIds.includes(modalId)) {
                const closeModalId = openModalIds.pop();
                closedModalIds.push(closeModalId);
              }
              return {
                ...modalControl,
                closedModalIds: [...closedModalIds],
                openModalIds: [...openModalIds],
              };
            },
          }),
          'resetHelpRenderedControlIds',
        ],
      },
      MODAL_OPEN: {
        actions: [
          assign({
            modalControl: ({ modalControl }, { payload: { modalId } }) => {
              if (!modalControl.openModalIds.includes(modalId)) {
                return {
                  ...modalControl,
                  openModalIds: [...modalControl.openModalIds, modalId],
                };
              }
              return modalControl;
            },
            playControl: ({ playControl }, { payload: { modalId } }) => ({
              ...playControl,
              paused: MODAL_DICTIONARY[modalId].playControl.pauseOnOpen
                ? true
                : playControl.paused,
            }),
          }),
          'resetHelpRenderedControlIds',
        ],
      },
      MODAL_SWAP: {
        actions: [
          assign({
            modalControl: ({ modalControl }, { payload: { modalId } }) => {
              if (!modalControl.openModalIds.includes(modalId)) {
                const { closedModalIds, openModalIds } = modalControl;
                const closeModalId = openModalIds.pop();
                closedModalIds.push(closeModalId);
                return {
                  ...modalControl,
                  closedModalIds: [...closedModalIds],
                  openModalIds: [...modalControl.openModalIds, modalId],
                };
              }
              return modalControl;
            },
          }),
          'resetHelpRenderedControlIds',
        ],
      },
      UNDO_CHANGES: {
        actions: [
          assign({
            editContext: ({ editContext, viewContext }) => {
              if (!isEqual(editContext, viewContext)) {
                return cloneDeep(viewContext);
              }
              return editContext;
            },
            editControl: ({
              // board: { frames },
              editControl,
              // editContext,
              // editFrameIndex,
              // viewContext,
            }) => {
              return {
                ...editControl,
                calculatedDuration:
                  initialMachineContext.editControl.calculatedDuration,
              };
            },
            // machinesContext: ({
            //   editContext,
            //   viewContext,
            //   machinesContext,
            // }) => {
            //   if (!isEqual(editContext, viewContext)) {
            //     return {
            //       ...machinesContext,
            //       // sidenoteMachine: {
            //       //   ...machinesContext.sidenoteMachine,
            //       //   sidenoteField: {
            //       //     ...machinesContext.sidenoteMachine.sidenoteField,
            //       //     editorState: convertRawToEditorState(
            //       //       viewContext.sidenoteMachine.sidenoteField.rawEditorState
            //       //     ),
            //       //   },
            //       // },
            //       // textMachine: {
            //       //   ...machinesContext.textMachine,
            //       //   textField: {
            //       //     ...machinesContext.textMachine.textField,
            //       //     editorState: convertRawToEditorState(
            //       //       viewContext.textMachine.textField.rawEditorState
            //       //     ),
            //       //   },
            //       // },
            //     };
            //   }
            //   return machinesContext;
            // },
          }),
        ],
      },
      UPDATE_EDIT_CONTEXT: {
        actions: ['updateEditContext'],
      },
      UPDATE_MACHINES_CONTEXT: {
        actions: ['updateMachinesContext', 'saveMachinesContext'],
      },
    },
  },
  {
    actions: {
      // NEW
      createFrame: assign({
        /**
         * - Diffs view and edit context
         * - Adds frame item to board
         * - Adds frame item to send queue
         */
        board: ({
          board,
          editControl: { calculatedDuration, durationField },
          editContext,
          viewContext,
        }) => {
          const diffString = createDiffStringFromObjects(
            viewContext,
            editContext
          );
          board.frames = [
            ...board.frames,
            {
              diffString,
              duration: durationField.value || calculatedDuration,
            },
          ];
          return board;
        },
        boardFramesSendQueue: ({
          boardFramesSendQueue,
          editControl: { calculatedDuration, durationField },
          editContext,
          viewContext,
        }) => {
          const diffString = createDiffStringFromObjects(
            viewContext,
            editContext
          );
          boardFramesSendQueue = [
            ...boardFramesSendQueue,
            {
              diffString,
              duration: durationField.value || calculatedDuration,
            },
          ];
          return boardFramesSendQueue;
        },
      }),
      deleteFrame: assign({
        /**
         * - Build frames array with context
         * - Remove frame from array
         * - Build new frames array with context diffs
         */
        board: ({ board, editFrameIndex }) => {
          const { frames } = board;
          let context = {};
          const framesContext = frames.map(({ duration, diffString }) => {
            context = applyDiffStringToObject(context, diffString, false);
            return {
              context,
              duration,
            };
          });
          framesContext.splice(editFrameIndex, 1);
          let prevContext = {};
          const updatedFrames = framesContext.map(
            ({ context, duration }, index) => {
              const diffString = createDiffStringFromObjects(
                prevContext,
                context
              );
              prevContext = context;
              return { diffString, duration };
            }
          );
          board.frames = updatedFrames;
          return board;
        },
        /**
         *
         */
        editContext: initialMachineContext.editContext,
        editFrameIndex: initialMachineContext.editFrameIndex,
      }),
      updateEditFrameIndex: assign({
        editFrameIndex: ({ board: { frames } }, { payload }) => {
          const { targetFrameIndex = frames.length } = payload || {};
          return targetFrameIndex;
        },
        editControl: ({ board: { frames }, editControl }, { payload }) => {
          const { targetFrameIndex = frames.length } = payload || {};
          return {
            ...editControl,
            isFrameIndexOnNewFrame: targetFrameIndex === frames.length,
          };
        },
      }),
      updateFrame: assign({
        board: ({
          board,
          editControl: { calculatedDuration, durationField },
          editContext,
          editFrameIndex,
          viewContext,
          viewFrameIndex,
        }) => {
          const clonedFrames = cloneDeep(board.frames);
          let clonedEditContext = cloneDeep(editContext);
          let clonedViewContext = cloneDeep(viewContext);
          let frameIndex = editFrameIndex;

          /**
           * Update the current frame's diff string
           */
          let previousViewContext = composeFrameContext({
            currentContext: clonedViewContext,
            currentFrameIndex: frameIndex,
            frames: board.frames,
            targetFrameIndex: frameIndex - 1,
          });
          let updatedDiffString = createDiffStringFromObjects(
            previousViewContext,
            clonedEditContext
          );
          clonedFrames[frameIndex] = {
            ...clonedFrames[frameIndex],
            diffString: updatedDiffString,
            duration: durationField.value || calculatedDuration,
          };

          /**
           * For any of the updated values that is overwritten by
           * a successive diffString, the patch may fail. This as
           * the diff's description no longer matches the updated
           * object key value. To update the diffStrings, we will
           * compare and update on the context key/value level.
           */

          /**
           * Set an object with the updated key/value pairs. Flatten
           * the object to handle nested keys more easily.
           */
          const updatedContext = updatedDiff(
            previousViewContext,
            clonedEditContext
          );
          let flatUpdatedContext = flatten(updatedContext);

          while (frameIndex < board.frames.length - 1) {
            /**
             * Create a flat viewContext of the current frame,
             * and flat viewContext of the previous frame.
             */
            frameIndex++;
            const { diffString } = board.frames[frameIndex];
            clonedViewContext = applyDiffStringToObject(
              clonedViewContext,
              diffString,
              false
            );
            const flatClonedViewContext = flatten(clonedViewContext);
            previousViewContext = composeFrameContext({
              currentContext: clonedViewContext,
              currentFrameIndex: frameIndex,
              frames: board.frames,
              targetFrameIndex: frameIndex - 1,
            });
            const flatPreviousViewContext = flatten(previousViewContext);

            Object.keys(flatUpdatedContext).forEach((key) => {
              /**
               * Check for each individual updated key if it's value matches
               * the value in the view context
               */
              if (!isMatch(flatClonedViewContext, flatUpdatedContext[key])) {
                const isPartialValueUpdate = [
                  'textMachine',
                  'sidenoteMachine',
                ].some((machine) => key.includes(machine));
                if (isPartialValueUpdate) {
                  /**
                   * If no match, create a diff that describes the change in value
                   * between current and previous context
                   */
                  const previousContextValue = flatPreviousViewContext[key];
                  const currentContextValue = flatClonedViewContext[key];
                  const valueDiffString = createDiffStringFromText(
                    previousContextValue,
                    currentContextValue
                  );
                  if (valueDiffString) {
                    /**
                     * Apply the diff to the updated context value. This may not
                     * work for 100%, but diff patch will do it's best effort.
                     * Then store the updated key/value in updated context object.
                     */
                    const updatedContextValue = flatUpdatedContext[key];
                    const newContextValue = applyDiffStringToText(
                      updatedContextValue,
                      valueDiffString
                    );
                    flatUpdatedContext[key] = newContextValue;
                  }
                } else {
                  flatUpdatedContext[key] = flatClonedViewContext[key];
                }
              }
            });
            /**
             * Merge the current viewContext with the updated context.
             */
            const mergedViewContext = merge(
              clonedViewContext,
              unflatten(flatUpdatedContext)
            );
            /**
             * Set frame's diffString comparing the editContext and updated
             * viewContext, and update the editContext with the diff.
             */
            clonedFrames[frameIndex].diffString = createDiffStringFromObjects(
              clonedEditContext,
              mergedViewContext
            );
            clonedEditContext = applyDiffStringToObject(
              clonedEditContext,
              clonedFrames[frameIndex].diffString,
              false
            );
          }
          board.frames = clonedFrames;
          return board;
        },
      }),
      updateViewContextWithEditContext: assign({
        viewContext: ({ editContext }) => cloneDeep(editContext),
      }),
      composeEditContext: assign({
        editContext: (
          { board: { frames }, editContext, editFrameIndex },
          { payload }
        ) => {
          const { targetFrameIndex = frames.length - 1 } = payload || {};
          const updatedEditContext = composeFrameContext({
            currentContext: editContext,
            currentFrameIndex: editFrameIndex,
            frames,
            targetFrameIndex,
          });
          return updatedEditContext;
        },
        editFrameIndex: (
          { board: { frames }, editContext, editFrameIndex },
          { payload }
        ) => {
          const { targetFrameIndex = frames.length } = payload || {};
          return targetFrameIndex;
        },
        // machinesContext: (
        //   { board: { frames }, editContext, editFrameIndex, machinesContext },
        //   { payload }
        // ) => {
        //   const { targetFrameIndex = frames.length - 1 } = payload || {};
        //   const {
        //     sidenoteMachine: { sidenoteField },
        //     textMachine: { textField },
        //   } = composeFrameContext({
        //     currentContext: editContext,
        //     currentFrameIndex: editFrameIndex,
        //     frames,
        //     targetFrameIndex,
        //   });

        //   return {
        //     ...machinesContext,
        //     sidenoteMachine: {
        //       ...machinesContext.sidenoteMachine,
        //       sidenoteField: {
        //         ...machinesContext.sidenoteMachine.sidenoteField,
        //         editorState: convertRawToEditorState(
        //           sidenoteField.rawEditorState
        //         ),
        //       },
        //     },
        //     textMachine: {
        //       ...machinesContext.textMachine,
        //       textField: {
        //         ...machinesContext.textMachine.textField,
        //         editorState: convertRawToEditorState(textField.rawEditorState),
        //       },
        //     },
        //   };
        // },
      }),
      composeViewContext: assign({
        viewContext: (
          {
            board: { frames },
            playControl: { paused, reverse },
            viewContext,
            viewFrameIndex,
          },
          { payload }
        ) => {
          const {
            targetFrameIndex = reverse
              ? viewFrameIndex - 1
              : viewFrameIndex + 1,
          } = payload || {};
          const updatedViewContext = composeFrameContext({
            currentContext: viewContext,
            currentFrameIndex: viewFrameIndex,
            frames,
            targetFrameIndex,
          });
          return updatedViewContext;
        },
        playControl: ({ board: { frames }, playControl, viewFrameIndex }) => {
          return {
            ...playControl,
            timeElapsed: frames
              .slice(0, viewFrameIndex + 1)
              .reduce((timeElapsed, frame) => timeElapsed + frame.duration, 0),
          };
        },
      }),
      // composeMachinesContext: assign({
      //   /**
      //    * Machines like TextMachine and SidenoteMachine store an instance value
      //    * in `machinesContext` when in edit mode, and at the same time store a
      //    * converted value in `editContext`. This is done because the instance
      //    * value needs to be converted for it to be transmitted and compared
      //    * to the `viewContext` value, but the conversion causes issues when
      //    * done while editing.
      //    *
      //    * When the frame index changes, we check if the composed instance value
      //    * and current instance value are equal. If no, the current instance
      //    * value is updated.
      //    *
      //    * Because the current `editContext` is required for the comparison, this
      //    * action must be done BEFORE `composeEditContext`.
      //    */
      //   machinesContext: (
      //     { board: { frames }, editContext, editFrameIndex, machinesContext },
      //     { payload }
      //   ) => {
      //     const {
      //       sidenoteMachine: { sidenoteField },
      //       textMachine: { textField },
      //     } = machinesContext;
      //     const { targetFrameIndex = frames.length } = payload || {};
      //     const composedContext = composeFrameContext({
      //       currentContext: editContext,
      //       currentFrameIndex: editFrameIndex,
      //       frames,
      //       targetFrameIndex,
      //     });
      //     const {
      //       sidenoteMachine: { sidenoteField: composedRawSidenoteField },
      //       textMachine: { textField: composedRawTextField },
      //     } = composedContext;
      //     const composedSidenoteFieldValueEditorState = convertRawToEditorState(
      //       composedRawSidenoteField.rawEditorState
      //     );
      //     const composedTextFieldValueEditorState = convertRawToEditorState(
      //       composedRawTextField.rawEditorState
      //     );
      //     switch (true) {
      //       case !isEqual(
      //         composedSidenoteFieldValueEditorState,
      //         sidenoteField.editorState
      //       ):
      //         machinesContext.sidenoteMachine.sidenoteField.editorState =
      //           composedSidenoteFieldValueEditorState;
      //         break;
      //       case !isEqual(
      //         composedTextFieldValueEditorState,
      //         textField.editorState
      //       ):
      //         machinesContext.textMachine.textField.editorState =
      //           composedTextFieldValueEditorState;
      //         break;
      //       default:
      //         break;
      //     }
      //     return { ...machinesContext };
      //   },
      // }),
      loadMachinesContext: assign({
        machinesContext: ({ machinesContext }, { payload: { sessionId } }) => {
          /**
           * Some machine context key/value pairs are not saved to local storage
           * and have been excluded from the save diff comparison. To rebuild
           * the machines context, we delete these keys from initial context,
           * apply the diff string, and then merge the initial context with the
           * saved context.
           */
          const diffMachinesContext = getMachinesContextFromLocalStorage({
            sessionId,
          });
          if (diffMachinesContext) {
            const unsetInitialMachineContext = cloneDeep(
              initialMachineContext.machinesContext
            );
            MACHINES_CONTEXT_LOCAL_STORAGE_UNSET.forEach((path) => {
              unset(unsetInitialMachineContext, path);
            });
            const machinesContext = applyDiffStringToObject(
              unsetInitialMachineContext,
              diffMachinesContext
            );
            const fullMachineContext = cloneDeep(
              initialMachineContext.machinesContext
            );
            const mergedMachinesContext = merge(
              fullMachineContext,
              machinesContext
            );
            return mergedMachinesContext;
          }
          return machinesContext;
        },
      }),
      resetViewContext: assign({
        viewContext: ({ board: { frames } }) => {
          return applyDiffStringToObject({}, frames[0].diffString);
        },
        viewFrameIndex: () => 0,
      }),
      resetHelpRenderedControlIds: assign({
        helpControl: ({ helpControl }) => ({
          ...helpControl,
          renderedControlIds: new Set(),
        }),
      }),
      saveMachinesContext: (
        { machinesContext, sessionId },
        { payload: { machineId, property, values } }
      ) => {
        /**
         * Some machine context values are not saved to local storage.
         * Check if the property is in the list of values to not save.
         * If not, save the updated machines context to local storage and
         * exclude the values that should not be saved from the diff string.
         */
        for (const value in values) {
          if (
            MACHINES_CONTEXT_LOCAL_STORAGE_UNSET.includes(
              `${machineId}.${property}.${value}`
            )
          ) {
            return;
          }
        }
        const clonedMachinesContext = cloneDeep(machinesContext);
        const clonedInitialMachineContext = cloneDeep(
          initialMachineContext.machinesContext
        );
        MACHINES_CONTEXT_LOCAL_STORAGE_UNSET.forEach((path) => {
          unset(clonedMachinesContext, path);
          unset(clonedInitialMachineContext, path);
        });
        const diffMachinesContext = createDiffStringFromObjects(
          clonedInitialMachineContext,
          clonedMachinesContext
        );
        if (diffMachinesContext) {
          const compressedString =
            convertStringToCompressedString(diffMachinesContext);
          localStorage.setItem(
            `MachinesContext:${sessionId}`,
            compressedString
          );
        }
      },
      updateEditControl: assign({
        editControl: ({ board: { frames }, editControl, editFrameIndex }) => ({
          ...editControl,
          durationField: {
            value: frames[editFrameIndex]
              ? frames[editFrameIndex].duration
              : initialMachineContext.editControl.durationField.value,
          },
        }),
      }),
      updateEditContext: assign({
        editContext: (
          { editContext },
          { payload: { machineId, property, values } }
        ) => {
          editContext[machineId][property] = {
            ...editContext[machineId][property],
            ...values,
          };
          return editContext;
        },
        editControl: ({
          board: { frames },
          editControl,
          editContext,
          editFrameIndex,
          machinesContext,
          viewContext,
        }) => {
          /**
           * When adding a new frame, the calculated duration is based
           * on the changes between the last added frame (viewContext)
           * and the changes in editContext.
           * When editing an existing frame, the viewContext is set to
           * the editContext and used to determine if any editing changes
           * have been made to editContext. For the updated duration time
           * compose the previous frame (based on viewContext), and use
           * that for the calculation.
           */
          let prevContext;
          if (editFrameIndex === frames.length) {
            prevContext = viewContext;
          } else {
            prevContext = composeFrameContext({
              currentContext: viewContext,
              currentFrameIndex: editFrameIndex,
              frames,
              targetFrameIndex: editFrameIndex - 1,
            });
          }
          /**
           * Set the unsaved changes flag to true if:
           * - editContext differs from the viewContext, and
           * - the board has an active panel or background set
           */
          const hasUnsavedChanges =
            !isEqual(editContext, viewContext) &&
            (!!editContext.controllerMachine.boardGrid.activeTabMachineId ||
              !!editContext.controllerMachine.boardGrid.backgroundMachineId ||
              !!editContext.controllerMachine.boardGrid.displayMainSection);
          return {
            ...editControl,
            calculatedDuration: calculateFrameDuration({
              currentContext: editContext,
              machinesContext,
              updatedContext: prevContext,
            }),
            hasUnsavedChanges,
          };
        },
      }),
      updateViewFrameIndex: assign({
        viewFrameIndex: ({ board: { frames } }, { payload }) => {
          const { targetFrameIndex = frames.length } = payload || {};
          return targetFrameIndex;
        },
      }),
      resetIsEditFrameModeActive: assign({
        editControl: ({ editControl }) => ({
          ...editControl,
          isEditFrameModeActive: false,
        }),
      }),
      setPropValues: assign((context, event) => {
        const { property, values } = event.payload;
        context[property] = {
          ...context[property],
          ...values,
        };
        return context;
      }),
      updateMachinesContext: assign({
        machinesContext: (
          { machinesContext },
          { payload: { machineId, property, values } }
        ) => {
          machinesContext[machineId][property] = {
            ...machinesContext[machineId][property],
            ...values,
          };
          return machinesContext;
        },
      }),
    },
    delays: {
      FRAME_DURATION: ({
        board: { frames },
        playControl: { speed },
        viewFrameIndex,
      }) => {
        const { duration } = frames[viewFrameIndex] || {};
        return duration * speed;
      },
    },
    guards: {
      // isViewFrameIndexOnLastFrame: ({ board: { frames }, viewFrameIndex }) =>
      //   viewFrameIndex === frames.length - 1,
      isLastFrameDurationNotFinished: ({
        board: { frames },
        playControl: { frameTimeElapsed },
        viewFrameIndex,
      }) =>
        viewFrameIndex < frames.length - 1 ||
        frameTimeElapsed < frames[viewFrameIndex].duration,
      isViewFrameIndexNotOnLastFrame: ({ board: { frames }, viewFrameIndex }) =>
        viewFrameIndex < frames.length - 1,
    },
    services: {
      /**
       * Cache assets (e.g. images) before starting the board
       */
      loadAssets: ({ board: { frames } }) => {
        const assets = new Set();
        const promises = [];
        let context = {};
        frames.forEach(({ diffString }) => {
          context = applyDiffStringToObject(context, diffString);
          const {
            imageMachine: {
              imageField: { backgroundUrl, url },
            },
          } = context;
          if (backgroundUrl) assets.add(backgroundUrl);
          if (url) assets.add(url);
        });
        assets.forEach((src) => {
          const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
              resolve(img);
            };
            img.onerror = img.onabort = function () {
              reject(src);
            };
            img.src = src;
          });
          promises.push(promise);
        });
        return Promise.all(promises);
      },
    },
  }
);

export default controllerMachine;
