import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import CancelRounded from '@material-ui/icons/CancelRounded';
import IconButton from '@material-ui/core/IconButton';

import { BOARD_MACHINES } from '../constants';
import { calculateBoardGridProps } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import HelpPanel from '../../Help/Panels';
import { IMAGE_SHARED_CONTEXT } from '../../Image/constants';

const PanelTabs = ({ gridTabsSectionSize, handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const {
    editContext,
    editControl: { isEditFrameModeActive },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;
  const {
    activeTabMachineId,
    containerDirection,
    containerDirectionReverse,
    itemBreakpoint,
  } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  const { itemTabSectionXs } = calculateBoardGridProps({
    containerDirection,
    containerDirectionReverse,
    itemBreakpoint,
  });

  return (
    <Box height='100%' position='relative' width='100%'>
      {BOARD_MACHINES.map(({ Component, isDisabled, isTabbed, value }) =>
        isTabbed && !isDisabled ? (
          <Component
            key={value}
            gridTabsSectionSize={gridTabsSectionSize}
            renderBackgroundComponent={false}
            isTabSectionHidden={!itemTabSectionXs}
          />
        ) : null
      )}
      {inEditMode &&
        !!activeTabMachineId &&
        activeTabMachineId === activeMachineId &&
        isEditFrameModeActive && (
          <Box position='absolute' top='0px' right='0px'>
            <Help
              controlId={`${activeMachineId}BoardSectionVisibilityControl`}
              placement='left'
            >
              <IconButton
                onClick={() => {
                  if (activeMachineId === 'imageMachine') {
                    console.log('activeMachineId', activeMachineId);
                    controllerSend({
                      type: 'UPDATE_EDIT_CONTEXT',
                      payload: {
                        machineId: 'imageMachine',
                        property: 'imageField',
                        values: {
                          credit: IMAGE_SHARED_CONTEXT.imageField.credit,
                          url: IMAGE_SHARED_CONTEXT.imageField.url,
                        },
                      },
                    });
                  }
                  handleActiveMachineChange({
                    editValues: { activeTabMachineId: '' },
                    machineValues: { activeMachineId: '' },
                  });
                }}
                style={{
                  color: theme.palette.text.backgroundContrastText,
                  paddingRight: containerDirection === 'column' ? 12 : 24,
                  paddingTop: containerDirection === 'column' ? 12 : 24,
                  zIndex: 1000,
                }}
              >
                <CancelRounded />
              </IconButton>
            </Help>
          </Box>
        )}
      {inEditMode && !activeTabMachineId && !activeMachineId && (
        <HelpPanel panelId='mainPanel' panelProperties={gridTabsSectionSize} />
      )}
    </Box>
  );
};

export default PanelTabs;
