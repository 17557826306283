import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import PublicOutlined from '@material-ui/icons/PublicOutlined';
import MapOutlined from '@material-ui/icons/MapOutlined';
import EmojiTransportationRounded from '@material-ui/icons/EmojiTransportationRounded';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarTypeControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      mapMachine: { mapField: viewMapField },
    },
  } = controllerState.context;

  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  return (
    <Help
      controlId='mapMachineToolbarMapTypeControl'
      helpLabelValue={mapField.mapTypeId}
      isDisabled={!hasLocation}
      placement='top'
    >
      <IconButton
        color={
          mapField.mapTypeId !== viewMapField.mapTypeId || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={!hasLocation}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              autoCapture: false,
              machineId: 'mapMachine',
              property: 'mapField',
              values: {
                mapTypeId:
                  mapField.mapTypeId === 'satellite'
                    ? 'roadmap'
                    : mapField.mapTypeId === 'roadmap'
                    ? 'streetview'
                    : 'satellite',
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        {['satellite', 'hybrid'].includes(mapField.mapTypeId) ? (
          <PublicOutlined />
        ) : ['streetview'].includes(mapField.mapTypeId) ? (
          <EmojiTransportationRounded />
        ) : (
          <MapOutlined />
        )}
      </IconButton>
    </Help>
  );
};

export default ToolbarTypeControl;
