import React, { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StayPrimaryLandscapeTwoTone from '@material-ui/icons/StayPrimaryLandscapeTwoTone';
import StayPrimaryPortraitTwoTone from '@material-ui/icons/StayPrimaryPortraitTwoTone';

import { useWindowProperties } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';
import { Typography } from '@material-ui/core';

const DeviceOrientationMenu = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const { deviceOrientation } = useWindowProperties();

  const {
    viewContext: {
      controllerMachine: {
        boardGrid: { containerOrientation },
      },
    },
  } = controllerState.context;

  const OrientationIcon =
    containerOrientation === 'landscape'
      ? StayPrimaryLandscapeTwoTone
      : StayPrimaryPortraitTwoTone;

  useEffect(() => {
    if (deviceOrientation === containerOrientation) {
      controllerSend({
        type: 'MODAL_CLOSE',
        payload: {
          modalId: 'menuFrameOrientation',
        },
      });
    }
  }, [containerOrientation, controllerSend, deviceOrientation]);

  return (
    <Card>
      <CardHeader
        subheader={`For best viewing experience, hold your mobile device for this frame in ${containerOrientation} orientation.`}
        title='Rotate Device '
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent ml={2} mr={2}>
        <Box m={2} my={2} textAlign='center'>
          <OrientationIcon style={{ height: '5rem', width: '5rem' }} />
          <Typography variant='h6'>{`${containerOrientation}`}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DeviceOrientationMenu;
