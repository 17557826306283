import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { GlobalStateContext } from '../../../globalState';
import FrameToolsControl from '../../Controller/Controls/FrameTools';
import Help from '../../Help';
import BoardLink from '../../Controller/Controls/BoardLink';

const BoardStreamMenu = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  // eslint-disable-next-line no-unused-vars
  const [_, controllerSend] = useActor(controllerService);
  const [sessionState, sessionSend] = useActor(sessionService);
  const navigate = useNavigate();

  const {
    session: { name: sessionName, userName: sessionUserName },
  } = sessionState.context;

  return (
    <>
      <Card>
        <CardHeader
          title={sessionName}
          subheader={`Created by ${sessionUserName || 'anonymous'}`}
        />
        <CardContent>
          <FrameToolsControl />
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Help
                controlId='controllerMachineMenuBoardStreamCancelControl'
                placement='top'
              >
                <Button
                  aria-label='stop streaming'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    sessionSend({
                      type: 'SESSION_RESET',
                    });
                    controllerSend({
                      type: 'CONTROLLER_RESET',
                    });
                    navigate(`/`);
                  }}
                  size='small'
                  variant='contained'
                >
                  Stop streaming
                </Button>
              </Help>
              <Typography variant='body2' style={{ padding: 8 }}>
                To continue streaming later, open the <i>Start</i> menu and on
                the <i>Continue</i> tab select the board title.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BoardLink
                buttonLabel='Copy Invite Link'
                disabled={false}
                fullWidth
                variant='contained'
              />
              <Typography
                variant='body2'
                color='primary'
                style={{ padding: 8 }}
              >
                Click the button to copy the link to your board to the
                clipboard, and share it with others to view the board.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default BoardStreamMenu;
