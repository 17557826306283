import controlDictionary from '../Controls';

/**
 * Guide: Edit Menus
 */

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'sidenoteMachineSidebarToolSelectControl',
  'chessMachineSidebarToolSelectControl',
  'codeMachineSidebarToolSelectControl',
  'imageMachineSidebarToolSelectControl',
  'mapMachineSidebarToolSelectControl',
  'textMachineSidebarToolSelectControl',
  'videoMachineSidebarToolSelectControl',
];

const helpTopic = {
  topicDictionary: controlDictionary,
  topicLabel: 'Edit Menus',
  topicSteps,
  topicType: 'guide',
};

export default helpTopic;
