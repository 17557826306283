import React, { useContext } from 'react';
import T from 'prop-types';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';

import controlDictionary from '../Controls';
import { formatHelpLabelWithValue } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';
import { tooltipStyles } from './styles';

const useStyles = makeStyles((theme) => tooltipStyles(theme));

const StandardTooltip = ({
  children,
  controlId,
  helpLabelValue,
  isDisabled,
  isHidden,
  ...restProps
}) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();
  const {
    helpControl: { isHelpActive, openControlIds },
  } = controllerState.context;

  const { helpLabel, helpText } = controlDictionary[controlId]
    ? controlDictionary[controlId]
    : {};

  const label = helpLabelValue
    ? formatHelpLabelWithValue({
        helpLabel,
        value: helpLabelValue,
      })
    : helpLabel;

  const renderChildrenInTooltip =
    // If help is not active, only show the tooltip for the help menu control.
    (isHelpActive || controlId === 'controllerMachineHelpMenuControl') &&
    // If the control is disabled, don't show the tooltip.
    !isDisabled &&
    // Make sure we have a label to show.
    !!label;

  return renderChildrenInTooltip ? (
    <Tooltip
      arrow
      /**
       * The number of milliseconds a user must touch the element before
       * showing the tooltip. (default: 700)
       * */
      enterTouchDelay={250}
      interactive
      title={
        isHelpActive || !helpText.length || isHidden ? (
          /**
           * TODO: Find a better way to hide the tooltip label.
           * For toolbar controls that open a Popper with input, we want to
           * temporary hide the help  Tooltip label. Can't really find a good way
           * to do this, so for now we'll just replace the label with a space.
           * The shortened tooltip now hides behind the Popper.
           * */
          isHidden ? (
            ' '
          ) : (
            label
          )
        ) : (
          <>
            <Box display='flex' alignItems='center'>
              {openControlIds.has(controlId) ? (
                <RemoveCircle
                  className={classes.standardTooltipIcon}
                  fontSize='small'
                  onClick={() =>
                    controllerSend({
                      type: 'CONTROLLER_PROP_VALUES',
                      payload: {
                        property: 'helpControl',
                        values: {
                          openControlIds: new Set([]),
                        },
                      },
                    })
                  }
                />
              ) : (
                <AddCircle
                  className={classes.standardTooltipIcon}
                  fontSize='small'
                  onClick={() =>
                    controllerSend({
                      type: 'CONTROLLER_PROP_VALUES',
                      payload: {
                        property: 'helpControl',
                        values: {
                          openControlIds: new Set([controlId]),
                        },
                      },
                    })
                  }
                />
              )}
              {label}
            </Box>
            {openControlIds.has(controlId) &&
              helpText.map((text, index) => (
                <p key={index} color='inherit'>
                  {text}
                </p>
              ))}
          </>
        )
      }
      {...restProps}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

StandardTooltip.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default StandardTooltip;
