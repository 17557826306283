import controlDictionary from '../Controls';

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'sidenoteMachineToolbarDrawerCollapseControl',
  'sidenoteMachineToolbarSwapContentControl',
  'sidenoteMachineToolbarHorizontalAlignControl',
  'sidenoteMachineToolbarVerticalAlignControl',
  'sidenoteMachineToolbarReversedTypeControl',
  'sidenoteMachineToolbarFontSizeControl',
];

const helpTopic = {
  topicDictionary: {
    ...controlDictionary,
    controllerMachineHelpMenuControl: {
      helpLabel: 'Intro',
      helpText: [
        'Step through this guide to learn about the controls in the Sidenote Toolbar.',
      ],
      relatedControlIds: [],
    },
  },
  topicLabel: 'Sidenote Toolbar',
  topicSteps,
  topicType: 'guide',
};

export default helpTopic;
