import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import { GlobalStateContext } from '../../globalState';
import ChessView from './View';
import ChessEdit from './Edit';

const Chess = ({ gridTabsSectionSize, isTabSectionHidden }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const {
    editControl: { isEditFrameModeActive },
  } = controllerState.context;

  const {
    controllerMachine: {
      boardGrid: { activeTabMachineId },
    },
  } =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit')
      ? controllerState.context.editContext
      : controllerState.context.viewContext;

  if (activeTabMachineId === 'chessMachine' && !isTabSectionHidden) {
    if (
      isEditFrameModeActive &&
      (sessionState.matches('record.edit') ||
        sessionState.matches('stream.edit'))
    ) {
      return <ChessEdit gridTabsSectionSize={gridTabsSectionSize} />;
    }
    if (
      !isEditFrameModeActive ||
      sessionState.matches('record.view') ||
      sessionState.matches('stream.view')
    ) {
      return <ChessView gridTabsSectionSize={gridTabsSectionSize} />;
    }
  }
  return null;
};

export default Chess;
