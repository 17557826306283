import controlDictionary from '../Controls';

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'sidenoteMachineSidebarToolSelectControl',
  'chessMachineSidebarToolSelectControl',
  'codeMachineSidebarToolSelectControl',
  'imageMachineSidebarToolSelectControl',
  'mapMachineSidebarToolSelectControl',
  'textMachineSidebarToolSelectControl',
  'videoMachineSidebarToolSelectControl',
];

const helpTopic = {
  topicDictionary: controlDictionary,
  topicLabel: 'Set background map',
  topicSteps,
  topicType: 'tutorial',
};

export default helpTopic;
