import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';

import { boardStyles } from '../styles';
import { GlobalStateContext } from '../../../../globalState';
import DeviceOrientationControl from '../../../Controller/Controls/DeviceOrientation';
import ControlsTabs from '../ControlsTabs';
import ControlsMain from '../ControlsMain';

const useStyles = makeStyles((theme) => boardStyles(theme));

const SidebarLandscapeRight = ({ handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const { editContext, viewContext } = controllerState.context;
  const { containerDirectionReverse } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  return !containerDirectionReverse ? (
    !inEditMode ? (
      <Box
        id='sidebarLandscapeRightCenterView'
        display='flex'
        flexDirection='column'
      >
        <DeviceOrientationControl />
      </Box>
    ) : (
      <Box
        id='sidebarLandscapeRightCenterEdit'
        className={classes.gridItemSidebar}
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='center'
      >
        <ControlsTabs
          handleActiveMachineChange={handleActiveMachineChange}
          isLeftSidebar={false}
        />
      </Box>
    )
  ) : !inEditMode ? (
    <Box
      id='sidebarLandscapeRightCenterReverseView'
      display='flex'
      flexDirection='column'
    >
      <DeviceOrientationControl />
    </Box>
  ) : (
    <Box
      id='sidebarLandscapeRightCenterReverseEdit'
      className={classes.gridItemSidebar}
      display='flex'
      flex={1}
      flexDirection='column'
      justifyContent='center'
    >
      <ControlsMain
        handleActiveMachineChange={handleActiveMachineChange}
        isLeftSidebar={false}
      />
    </Box>
  );
};

export default SidebarLandscapeRight;
