import FormatAlignCenterRounded from '@material-ui/icons/FormatAlignCenterRounded';
import FormatAlignJustifyRounded from '@material-ui/icons/FormatAlignJustifyRounded';
import FormatAlignLeftRounded from '@material-ui/icons/FormatAlignLeftRounded';
import FormatAlignRightRounded from '@material-ui/icons/FormatAlignRightRounded';
import HorizontalSplitRounded from '@material-ui/icons/HorizontalSplitRounded';
import VerticalSplitRounded from '@material-ui/icons/VerticalSplitRounded';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';

export const SIDENOTE_ICON_COLUMN = HorizontalSplitRounded;
export const SIDENOTE_ICON_ROW = VerticalSplitRounded;

export const SIDENOTE_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
  },
  toolbarDrawer: {
    chatGptAsk: '',
    drawerTab: 'editor',
    isDrawerCollapsed: true,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const SIDENOTE_SHARED_CONTEXT = {
  sidenoteField: {
    act: 'Storyteller',
    backgroundOpacity: 0.75,
    editorState: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
    fontFamily: 'Roboto Slab',
    fontSizeMax: 40,
    fontSizeMin: 10,
    horizontalAlign: 'center',
    language: 'English',
    verticalAlign: 'center',
  },
};

// export const SIDENOTE_OPENAI_OPTIONS = {
//   maxTokens: 400,
// };

export const SIDENOTE_HORIZONTAL_ALIGN_OPTIONS = [
  { value: 'left', Icon: FormatAlignLeftRounded },
  { value: 'center', Icon: FormatAlignCenterRounded },
  { value: 'justify', Icon: FormatAlignJustifyRounded },
  { value: 'right', Icon: FormatAlignRightRounded },
];

export const SIDENOTE_VERTICAL_ALIGN_OPTIONS = [
  { label: 'top', value: 'flex-start', Icon: VerticalAlignTopOutlined },
  { label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  { label: 'bottom', value: 'flex-end', Icon: VerticalAlignBottomOutlined },
];
