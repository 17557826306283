import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { isEqual } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TimerOffOutlined from '@material-ui/icons/TimerOffOutlined';
import TimerOutlined from '@material-ui/icons/TimerOutlined';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const FrameDurationControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const classes = useStyles();

  const {
    board: { frames },
    editContext,
    editControl: { calculatedDuration, durationField, isEditFrameModeActive },
    viewContext,
    editFrameIndex,
  } = controllerState.context;

  const hasContextChanged =
    !isEqual(viewContext, editContext) ||
    (frames[editFrameIndex] &&
      !isEqual(durationField.value, frames[editFrameIndex].duration));

  const durationLabelValue = (
    (durationField.value || calculatedDuration) / 1000
  ).toFixed(1);

  const handleSliderChange = (event, value) => {
    event.preventDefault();
    controllerSend({
      type: 'CONTROLLER_PROP_VALUES',
      payload: {
        property: 'editControl',
        values: {
          durationField: {
            value: value * 1000,
          },
        },
      },
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='controllerMachineFrameDurationControl'
          isHidden={Boolean(anchorEl)}
          placement='top'
          helpLabelValue={`${durationLabelValue} sec.`}
        >
          <Badge
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            badgeContent={`${durationLabelValue}s`}
            color={
              durationField.value && calculatedDuration
                ? 'secondary'
                : 'primary'
            }
            invisible={
              Boolean(anchorEl) ||
              (!hasContextChanged && !isEditFrameModeActive)
            }
          >
            <IconButton
              aria-label='swap content'
              color={
                durationField.value && calculatedDuration
                  ? 'secondary'
                  : 'primary'
              }
              onClick={(event) =>
                anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
              }
              size='small'
            >
              {!durationField.value ? <TimerOutlined /> : <TimerOffOutlined />}
            </IconButton>
          </Badge>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='controllerMachineFrameDurationControl'
          modifiers={{
            offset: {
              enabled: true,
              offset: '0, 8',
            },
          }}
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Box
            display='flex'
            flexDirection='column'
            style={{
              borderRadius: 4,
              backgroundColor: theme.palette.default[theme.palette.type],
            }}
          >
            <Slider
              aria-labelledby='input-slider'
              className={classes.machineToolbarSlider}
              max={Math.max(30, calculatedDuration / 1000)}
              min={0}
              onChange={handleSliderChange}
              orientation='vertical'
              step={0.1}
              style={{
                height: 120,
                marginBottom: theme.spacing(0.5),
                marginTop: theme.spacing(0.5),
              }}
              value={+durationLabelValue}
              valueLabelDisplay='on'
              valueLabelFormat={(value) => `${value}s`}
            />
            <IconButton
              aria-label='toggle automatic duration'
              disabled={!durationField.value}
              onClick={() => {
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'editControl',
                    values: {
                      durationField: {
                        value: 0,
                      },
                    },
                  },
                });
              }}
              edge='end'
              size='small'
              variant='text'
            >
              <TimerOutlined />
            </IconButton>
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default FrameDurationControl;
