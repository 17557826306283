import _ from 'lodash';
import { compress, decompress } from 'lz-string';

export const convertHexToRGBWithAlpha = ({ hex = '#CCCCC', alpha = 0 }) => {
  /**
   * https://stackoverflow.com/a/39077686
   */ const rgbValues = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
    .join(', ');
  return `rgba(${rgbValues}, ${alpha})`;
};

export const convertHexToHexWithAlpha = ({ hex, alpha = 0 }) => {
  const hexAlpha = Math.round(alpha * 255).toString(16);
  return `${hex}${hexAlpha}`;
};

export const convertMillisecondsToTime = (milliseconds) => {
  const duration = Number.isInteger(milliseconds) ? milliseconds : 0;
  let seconds = Math.round(duration % 60),
    minutes = Math.floor((duration / 60) % 60),
    hours = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours === '00'
    ? minutes + ':' + seconds
    : hours + ':' + minutes + ':' + seconds;
};

export const convertObjectKeysToDottedPath = (obj, path = []) =>
  !_.isObject(obj)
    ? { [path.join('.')]: obj }
    : _.reduce(
        obj,
        (cum, next, key) =>
          _.merge(cum, convertObjectKeysToDottedPath(next, [...path, key])),
        {}
      );

export const convertCompressedStringToObject = ({ compressedString }) => {
  return JSON.parse(decompress(compressedString)) || {};
};

export const convertCompressedStringToString = ({ compressedString }) => {
  return decompress(compressedString);
};

export const convertObjectToCompressedString = (object) => {
  return compress(JSON.stringify(object));
};

export const convertStringToCompressedString = (string) => {
  return compress(string);
};
