import React, { useContext, useRef } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import TextField from '@material-ui/core/TextField';

import { MAP_LIBRARIES } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import { drawerStyles } from './styles';
import Table from './Table';

const useStyles = makeStyles((theme) => drawerStyles(theme));

const DrawerInput = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();
  const refSearchBox = useRef();

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
        toolbarDrawer: { rowDataItems },
      },
    },
  } = controllerState.context;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`,
    libraries: MAP_LIBRARIES,
  });

  const onLoadSearchBox = (ref) => (refSearchBox.current = ref);

  const onPlaceChanged = () => {
    const place = refSearchBox.current.getPlace();

    if (place.geometry && place.geometry.location) {
      const { formatted_address, geometry, name, place_id } = place;
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();

      const isUnmarkedPlace = !mapField.markers.some(
        ({ placeId }) => place_id === placeId
      );

      rowDataItems.unshift({
        lat,
        lng,
        placeId: place_id,
        value: formatted_address || name,
      });

      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'mapMachine',
          property: 'mapField',
          values: {
            center: { lat, lng },
            selectedPlace: isUnmarkedPlace
              ? {
                  location: { lat, lng },
                  placeId: place_id,
                  title: formatted_address || name,
                }
              : {},
          },
        },
      });
      controllerSend({
        type: 'UPDATE_MACHINES_CONTEXT',
        payload: {
          machineId: 'mapMachine',
          property: 'toolbarDrawer',
          values: {
            isDrawerCollapsed: false,
            rowDataItems,
          },
        },
      });
      if (autoCapture) {
        controllerSend({
          type: 'SUBMIT_FRAME_CAPTURE',
        });
      }
    }
  };

  return (
    <>
      <Table />
      {isLoaded ? (
        <Autocomplete
          className='pac-autocomplete'
          onLoad={onLoadSearchBox}
          onPlaceChanged={onPlaceChanged}
          options={{
            fields: [
              'formatted_address',
              'geometry.location',
              'name',
              'place_id',
            ],
          }}
        >
          <TextField
            className={classes.drawerInput}
            fullWidth
            id='map-position-field'
            onFocus={(event) => {
              event.target.select();
            }}
            placeholder='City, place, or location '
            size='small'
            variant='outlined'
          />
        </Autocomplete>
      ) : (
        <TextField
          className={classes.drawerInput}
          disabled
          fullWidth
          id='map-position-field'
          placeholder='Loading Google Maps... '
          size='small'
          variant='outlined'
        />
      )}
    </>
  );
};

export default DrawerInput;
