import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import PaletteRounded from '@material-ui/icons/PaletteRounded';

import { GlobalStateContext } from '../../../globalState';

const FrameThemeControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { theme },
      },
    },
    viewContext: {
      controllerMachine: {
        boardGrid: { theme: viewTheme },
      },
    },
  } = controllerState.context;

  return (
    <IconButton
      aria-label='frame theme'
      color={theme !== viewTheme ? 'secondary' : 'primary'}
      onClick={() => {
        controllerSend({
          type: 'MODAL_OPEN',
          payload: {
            modalId: 'menuBoardTheme',
          },
        });
      }}
      size='small'
    >
      <PaletteRounded />
    </IconButton>
  );
};

export default FrameThemeControl;
