import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import PauseCircleFilledOutlined from '@material-ui/icons/PauseCircleFilledOutlined';
import PlayCircleFilledOutlined from '@material-ui/icons/PlayCircleFilledOutlined';
import ReplayOutlined from '@material-ui/icons/ReplayOutlined';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const FramesPlayControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    viewFrameIndex,
  } = controllerState.context;

  const inPlayMode = controllerState.matches('view.playFrames');

  return (
    <>
      {!controllerState.can('STOP_PLAY_FRAMES') && frames.length > 0 && (
        <Help
          controlId='controllerMachineFramesPlayControl'
          helpLabelValue={
            inPlayMode ? 'Ready' : !viewFrameIndex ? 'Ready' : 'Paused'
          }
          isDisabled={frames.length <= 2}
          placement='top'
        >
          <Button
            color='primary'
            disabled={frames.length <= 2}
            onClick={() =>
              controllerState.can('START_REPLAY_FRAMES')
                ? controllerSend({ type: 'START_REPLAY_FRAMES' })
                : controllerSend({ type: 'START_PLAY_FRAMES' })
            }
            size='small'
            style={{ marginRight: 4 }}
            variant='contained'
          >
            {controllerState.can('START_REPLAY_FRAMES') ? (
              showControlLabels ? (
                'REPLAY'
              ) : (
                <ReplayOutlined />
              )
            ) : showControlLabels ? (
              'PLAY'
            ) : (
              <PlayCircleFilledOutlined />
            )}
          </Button>
        </Help>
      )}
      {controllerState.can('STOP_PLAY_FRAMES') && (
        <Help
          controlId='controllerMachineFramesPlayControl'
          helpLabelValue='Playing'
          placement='top'
        >
          <Button
            color='primary'
            onClick={() => controllerSend({ type: 'STOP_PLAY_FRAMES' })}
            size='small'
            variant='contained'
          >
            {showControlLabels ? 'PAUSE' : <PauseCircleFilledOutlined />}
          </Button>
        </Help>
      )}
    </>
  );
};

export default FramesPlayControl;
