import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

// import Bot from './Bot';
import Editor from './Editor';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const SidenoteDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();
  const {
    machinesContext: {
      sidenoteMachine: {
        toolbarDrawer: { drawerTab, isDrawerCollapsed },
      },
    },
  } = controllerState.context;

  return (
    <Collapse in={isDrawerCollapsed} unmountOnExit>
      <Grid container>
        <Grid item xs={12} className={classes.machineDrawerContainer}>
          {/* {drawerTab === 'bot' && <Bot />} */}
          {drawerTab === 'editor' && <Editor />}
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default SidenoteDrawer;
