import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import CancelRounded from '@material-ui/icons/CancelRounded';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import HomeRounded from '@material-ui/icons/HomeRounded';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { GlobalStateContext } from '../../globalState';
import { globalStyles } from '../../themes/globalStyles';
import { MODAL_DICTIONARY } from './constants';
import Help from '../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const Modal = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  // eslint-disable-next-line no-unused-vars
  const [_, sessionSend] = useActor(sessionService);
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    modalControl: { openModalIds },
  } = controllerState.context;

  const {
    Component: ModalContent,
    playControl: { unpauseOnClose },
    showHomeButton,
    title,
  } = openModalIds.length
    ? MODAL_DICTIONARY[openModalIds[openModalIds.length - 1]]
    : MODAL_DICTIONARY.menuMainStart;

  return openModalIds.length ? (
    <Dialog
      closeAfterTransition
      disableEnforceFocus
      open={!!openModalIds.length}
      onClose={() => {
        if (unpauseOnClose) {
          controllerSend({
            type: 'CONTROLLER_PROP_VALUES',
            payload: {
              property: 'playControl',
              values: { paused: false, playing: true },
            },
          });
        }
        controllerSend({
          type: 'MODAL_CLOSE',
          payload: {
            modalId: openModalIds[openModalIds.length - 1],
          },
        });
      }}
      fullWidth={true}
      maxWidth='sm'
    >
      <Paper className={classes.modalPaper}>
        {showHomeButton && (
          <Box position='absolute' top='4px' left='8px'>
            <Help controlId='controllerMachineModalHomeControl' placement='top'>
              <IconButton
                onClick={() => {
                  sessionSend({
                    type: 'SESSION_RESET',
                  });
                  controllerSend({
                    type: 'CONTROLLER_RESET',
                  });
                  navigate(`/`);
                }}
              >
                <HomeRounded color='primary' />
              </IconButton>
            </Help>
          </Box>
        )}
        <Box position='absolute' top='4px' right='8px'>
          <Help controlId='controllerMachineModalCloseControl' placement='top'>
            <IconButton
              onClick={() =>
                controllerSend({
                  type: 'MODAL_CLOSE',
                  payload: {
                    modalId: openModalIds[openModalIds.length - 1],
                  },
                })
              }
            >
              <CancelRounded />
            </IconButton>
          </Help>
        </Box>
        <CardContent>
          <Typography
            style={
              showHomeButton
                ? {
                    marginLeft: 48,
                    marginRight: 48,
                  }
                : {}
            }
            variant='h5'
          >
            {title}
          </Typography>
        </CardContent>
        <ModalContent />
      </Paper>
    </Dialog>
  ) : null;
};

export default Modal;
