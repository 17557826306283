import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';

import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarTiltControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      mapMachine: { mapField: viewMapField },
    },
  } = controllerState.context;

  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  return mapField.mapTypeId === 'streetview' ? (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='mapMachineToolbarMapTiltControl'
          helpLabelValue={mapField.pov.pitch}
          isDisabled={!hasLocation}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='map pitch'
            color={
              viewMapField.pov.pitch !== mapField.pov.pitch || autoCapture
                ? 'secondary'
                : 'primary'
            }
            disabled={!hasLocation}
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
          >
            <UnfoldMore />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='mapMachineToolbarMapTiltControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Box>
            <Slider
              className={classes.machineToolbarSlider}
              color={
                viewMapField.pov.pitch !== mapField.pov.pitch || autoCapture
                  ? 'secondary'
                  : 'primary'
              }
              value={mapField.pov.pitch}
              orientation='vertical'
              onChange={(event, value) => {
                controllerSend({
                  type: 'UPDATE_EDIT_CONTEXT',
                  payload: {
                    autoCapture: false,
                    machineId: 'mapMachine',
                    property: 'mapField',
                    values: {
                      pov: {
                        ...mapField.pov,
                        pitch: value,
                      },
                    },
                  },
                });
                if (autoCapture) {
                  controllerSend({
                    type: 'SUBMIT_FRAME_CAPTURE',
                  });
                }
              }}
              min={-90}
              max={90}
              step={1}
              style={{ height: 120 }}
              valueLabelDisplay='on'
              valueLabelFormat={(value) => `${Math.round(value)}°`}
            />
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  ) : null;
};

export default ToolbarTiltControl;
