import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { composeImageCredit, composeMapCredit } from '../../../helpers';
import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => controlStyles(theme));

const CreditsControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');

  const {
    viewContext: {
      controllerMachine: {
        boardGrid: { activeTabMachineId, backgroundMachineId },
      },
      imageMachine: {
        imageField: { backgroundCredit, backgroundUrl, credit, url },
      },
      mapMachine: { mapField },
    },
  } = controllerState.context;

  const hasBackgroundImage =
    !!backgroundUrl && backgroundMachineId === 'imageMachine';
  const hasForegroundImage =
    !!url && activeTabMachineId === 'imageMachine' && backgroundUrl !== url;
  const hasBackgroundMap =
    !isEmpty(mapField) && backgroundMachineId === 'mapMachine';
  const hasForegroundMap =
    !isEmpty(mapField) && activeTabMachineId === 'mapMachine';
  const shouldDisplayCredits =
    !inEditMode &&
    (hasBackgroundImage ||
      hasForegroundImage ||
      hasBackgroundMap ||
      hasForegroundMap);

  return shouldDisplayCredits ? (
    <Box margin='auto'>
      <Paper className={classes.creditsPaper} elevation={0}>
        {hasForegroundImage && (
          <Typography noWrap variant='caption'>
            {composeImageCredit({
              credit,
              element: 'Foreground',
              url,
            })}
          </Typography>
        )}
        {(hasBackgroundMap || hasForegroundMap) && (
          <Typography noWrap variant='caption'>
            {composeMapCredit({
              element: hasBackgroundMap ? 'Background' : 'Foreground',
              mapField,
            })}
          </Typography>
        )}
        {hasBackgroundImage && (
          <Typography noWrap variant='caption'>
            {composeImageCredit({
              credit: backgroundCredit,
              element: 'Background',
              url: backgroundUrl,
            })}
          </Typography>
        )}
      </Paper>
    </Box>
  ) : null;
};

export default CreditsControl;
