import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import isEqual from 'lodash/isEqual';

import { GlobalStateContext } from '../../../globalState';
import { controlStyles } from './styles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => controlStyles(theme));

const PanelsSlider = (props) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    editContext,
    editContext: {
      controllerMachine: {
        boardGrid: { containerDirection, itemBreakpoint },
      },
    },
    editControl: { isEditFrameModeActive },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
        helpControl: { helpPanelsClosed },
      },
    },
    modalControl: { openModalIds },
    viewContext,
    viewContext: {
      controllerMachine: { boardGrid: viewBoardGrid },
    },
  } = controllerState.context;

  const onChangeGridSlider = (event, values) => {
    // See https://www.uriports.com/blog/easy-fix-for-intervention-ignored-attempt-to-cancel-a-touchmove-event-with-cancelable-false/
    if (event.cancelable) event.preventDefault();
    const newValue =
      containerDirection === 'column'
        ? values.map((value, index) => 12 - values[3 - index])
        : values;
    if (itemBreakpoint !== newValue) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardGrid',
          values: { itemBreakpoint: newValue },
        },
      });
    }
  };

  const doRenderComponent =
    // If one or both help panel(s) are open
    helpPanelsClosed.length < 2 ||
    // If in edit mode, and an active machine or unsaved changes
    (isEditFrameModeActive &&
      (!!activeMachineId || !isEqual(editContext, viewContext)));

  return doRenderComponent ? (
    <Help
      controlId='controllerMachinePanelSliderControl'
      isDisabled={!!openModalIds.length}
      placement={containerDirection === 'row' ? 'top' : 'right-start'}
    >
      <Slider
        className={classes.panelSlider}
        color={
          isEqual(viewBoardGrid.itemBreakpoint, itemBreakpoint)
            ? 'primary'
            : 'secondary'
        }
        disabled={!!openModalIds.length}
        max={12}
        marks
        min={0}
        value={itemBreakpoint.map((value, index) => {
          if (containerDirection === 'column') {
            return 12 - itemBreakpoint[3 - index];
          }
          return value;
        })}
        onChange={onChangeGridSlider}
        step={1}
        track={false}
        {...props}
      />
    </Help>
  ) : null;
};

export default PanelsSlider;
