import React from 'react';

import ToolbarHorizontalAlign from './HorizontalAlign';
import ToolbarFontSize from './FontSize';
import ToolbarFontFamily from './FontFamily';
import ToolbarVerticalAlign from './VerticalAlign';
import ToolbarReversedType from './ReversedType';

const SidenoteToolbar = () => {
  return (
    <>
      <ToolbarHorizontalAlign />
      <ToolbarVerticalAlign />
      <ToolbarReversedType />
      <ToolbarFontSize />
      <ToolbarFontFamily />
    </>
  );
};

export default SidenoteToolbar;
