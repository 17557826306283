const topicSteps = [
  'controllerMachineHelpMenuControl',
  'imageMachineSidebarToolSelectControl',
  'imageMachineToolbarDrawerCollapseControl',
  'imageMachineToolbarDrawerTabsControl',
  'imageMachineToolbarBackgroundControl',
];

const topicDictionary = {
  controllerMachineHelpMenuControl: {
    helpLabel: 'Intro',
    helpText: [
      'The purpose of this tutorial is explaining the task of setting an existing image to the background.',
    ],
    relatedControlIds: [],
  },
  imageMachineSidebarToolSelectControl: {
    helpLabel: 'Select Image Tool',
    helpText: [
      'To set a background image, select the Image Tool in the left sidebar. The Image Toolbar will appear at the bottom of screen.',
    ],
    relatedControlIds: [],
  },
  imageMachineToolbarDrawerCollapseControl: {
    helpLabel: 'Open Image Drawer',
    helpText: [
      'To set a background image, select the Image Tool in the left sidebar. The Image Toolbar will appear at the bottom of screen.',
    ],
    relatedControlIds: [],
  },
  imageMachineToolbarDrawerTabsControl: {
    helpLabel: 'Select Image',
    helpText: [
      'To set a background image, select the Image Tool in the left sidebar. The Image Toolbar will appear at the bottom of screen.',
    ],
    relatedControlIds: [],
  },
  imageMachineToolbarBackgroundControl: {
    helpLabel: 'Set background',
    helpText: [
      'To set a background image, select the Image Tool in the left sidebar. The Image Toolbar will appear at the bottom of screen.',
    ],
    relatedControlIds: [],
  },
};

const helpTopic = {
  topicDictionary,
  topicLabel: 'Set background image',
  topicSteps,
  topicType: 'tutorial',
};

export default helpTopic;
