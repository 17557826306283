export const imageMachineToolbarBackgroundControl = {
  helpLabel: 'Active Panel',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineAddImageBackgroundControl = {
  helpLabel: 'Send to',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineImageFieldComponent = {
  helpLabel: 'Image Panel',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarDrawerTabsControl = {
  helpLabel: 'Image Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarCarouselControl = {
  helpLabel: 'Carousel',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarCarouselDeleteControl = {
  helpLabel: 'Delete Image',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarCarouselNextControl = {
  helpLabel: 'Next Image',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarCarouselPreviousControl = {
  helpLabel: 'Previous Image',
  helpText: [],
  relatedControlIds: [],
};

export const imageMachineToolbarFitControl = {
  helpLabel: 'Aspect Ratio',
  helpText: ['Slide to change the sidenote text font size.'],
  relatedControlIds: [],
};

export const imageMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Image Drawer',
  helpText: [
    'This button will open and close the Image Drawer. When open, the drawer shows two tabs.',
    'The EDIT Tab is used to write or edit the sidenote in a textfield, and apply font styling.',
    'The BOT Tab is used to ask artificial intelligence to write a sidenote for you in the textfield',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const imageMachineToolbarHorizontalPositionsControl = {
  helpLabel: 'Horizontal Position',
  helpText: [
    'Click to align text left, center, right, or justify in the image section.',
  ],
  relatedControlIds: [],
};

export const imageMachineToolbarVerticalPositionsControl = {
  helpLabel: 'Vertical Position',
  helpText: [
    'Click to align text top, center, or bottom in the image section.',
  ],
  relatedControlIds: [],
};

export const imageMachineSidebarToolSelectControl = {
  helpLabel: 'Image Tool',
  helpText: [
    'Add gifs and images from our selection, or from the web. Set size, placement, and more.',
  ],
  relatedControlIds: ['imageMachineToolbarDrawerCollapseControl'],
};

export const imageMachineBoardSectionVisibilityControl = {
  helpLabel: 'Image Visibility',
  helpText: ['Hide image and close the Image Tool.'],
  relatedControlIds: [],
};
