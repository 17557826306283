import AppsOutlined from '@material-ui/icons/AppsOutlined';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';

export const CHESS_ICON = AppsOutlined;

export const CHESS_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
    sidebarTab: false,
  },
  positionField: {
    value: '',
  },
  toolbarDrawer: {
    drawerTab: 'position',
    isDrawerCollapsed: false,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const CHESS_SHARED_CONTEXT = {
  chessField: {
    boardOrientation: 'white',
    horizontalAlign: 'center',
    value: 'start',
    verticalAlign: 'center',
  },
};

export const CHESS_HORIZONTAL_ALIGN_OPTIONS = [
  { label: 'left', value: 'flex-start', Icon: VerticalAlignTopOutlined },
  { label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  { label: 'right', value: 'flex-end', Icon: VerticalAlignBottomOutlined },
];

export const CHESS_VERTICAL_ALIGN_OPTIONS = [
  { label: 'top', value: 'flex-start', Icon: VerticalAlignTopOutlined },
  { label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  { label: 'bottom', value: 'flex-end', Icon: VerticalAlignBottomOutlined },
];
