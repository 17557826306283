import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';

import { BOARD_MACHINES } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ControlsTabs = ({ handleActiveMachineChange, isLeftSidebar }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    editControl: { isEditFrameModeActive, isFrameIndexOnNewFrame },
    helpControl: { isHelpActive },
    modalControl: { openModalIds },
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  return (
    isEditFrameModeActive &&
    BOARD_MACHINES.filter(
      ({ isDisabled, isTabbed }) => isTabbed && !isDisabled
    ).map(({ Icon, label, value }) => {
      const { sidebarTab } = machinesContext[value].boardSettings;
      return sidebarTab || isHelpActive ? (
        <Help
          key={`help${value}SidebarToolSelectControl`}
          controlId={`${value}SidebarToolSelectControl`}
          isDisabled={!!openModalIds.length || !sidebarTab}
          placement={isLeftSidebar ? 'right' : 'left'}
        >
          <Button
            key={value}
            color={
              value === activeMachineId
                ? isFrameIndexOnNewFrame
                  ? 'primary'
                  : 'secondary'
                : 'default'
            }
            disabled={!!openModalIds.length || !sidebarTab}
            onClick={() =>
              handleActiveMachineChange({
                editValues: {
                  activeTabMachineId: value,
                },
                machineValues: {
                  activeMachineId: value === activeMachineId ? '' : value,
                },
              })
            }
            size='small'
            variant='outlined'
          >
            {showControlLabels ? (
              label
            ) : (
              <Icon color={!!openModalIds.length ? 'secondary' : 'inherit'} />
            )}
          </Button>
        </Help>
      ) : null;
    })
  );
};

export default ControlsTabs;
