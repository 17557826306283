import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';

import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const HorizontalPositionSlider = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      imageMachine: { imageField },
    },
    machinesContext: {
      imageMachine: {
        boardSettings: { autoCapture },
        imageCollection: { collectionItems },
        imageField: { isBackgroundSelected },
      },
    },
    viewContext: {
      imageMachine: { imageField: viewImageField },
    },
  } = controllerState.context;

  const objectHorizontalPosition = isBackgroundSelected
    ? imageField.backgroundObjectHorizontalPosition
    : imageField.objectHorizontalPosition;
  const viewObjectHorizontalPosition = isBackgroundSelected
    ? viewImageField.backgroundObjectHorizontalPosition
    : viewImageField.objectHorizontalPosition;

  const valueLabelIconDictionary = {
    0: { Icon: VerticalAlignTopOutlined, label: 'left' },
    100: { Icon: VerticalAlignBottomOutlined, label: 'right' },
  };

  const ToolbarIcon = valueLabelIconDictionary[objectHorizontalPosition]
    ? valueLabelIconDictionary[objectHorizontalPosition].Icon
    : VerticalAlignCenterOutlined;
  const helpLabelValue = valueLabelIconDictionary[objectHorizontalPosition]
    ? valueLabelIconDictionary[objectHorizontalPosition].label
    : `${objectHorizontalPosition - 50}%`;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='imageMachineToolbarHorizontalPositionsControl'
          helpLabelValue={helpLabelValue}
          isDisabled={!collectionItems.length}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            disabled={!collectionItems.length}
            aria-label='horizontal position'
            color={
              objectHorizontalPosition !== viewObjectHorizontalPosition ||
              autoCapture
                ? 'secondary'
                : 'primary'
            }
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
            style={{ transform: 'rotate(-90deg)' }}
          >
            <ToolbarIcon />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='imageMachineToolbarHorizontalPositionsControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Slider
            className={classes.machineToolbarSlider}
            color={
              objectHorizontalPosition !== viewObjectHorizontalPosition ||
              autoCapture
                ? 'secondary'
                : 'primary'
            }
            onChange={(event, value) => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  autoCapture: false,
                  machineId: 'imageMachine',
                  property: 'imageField',
                  values: isBackgroundSelected
                    ? {
                        backgroundObjectHorizontalPosition: value,
                      }
                    : {
                        objectHorizontalPosition: value,
                      },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
            min={0}
            max={100}
            step={1}
            style={{ width: 120 }}
            track={false}
            value={objectHorizontalPosition}
            valueLabelDisplay='on'
            valueLabelFormat={() => {
              const { Icon } =
                valueLabelIconDictionary[objectHorizontalPosition] || {};
              return Icon ? (
                <Icon style={{ transform: 'rotate(-90deg)' }} />
              ) : (
                `${objectHorizontalPosition - 50}%`
              );
            }}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default HorizontalPositionSlider;
