import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useActor } from '@xstate/react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import UndoRounded from '@material-ui/icons/UndoRounded';

import { GlobalStateContext } from '../../../globalState';
import { controlStyles } from './styles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => controlStyles(theme));

const FrameResetControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    editContext,
    editControl: { isEditFrameModeActive },
    viewContext,
  } = controllerState.context;
  const hasUnsavedChanges = !isEqual(editContext, viewContext);

  return isEditFrameModeActive && hasUnsavedChanges ? (
    <Help
      controlId='controllerMachineFrameResetControl'
      isDisabled={!hasUnsavedChanges}
      placement='top'
    >
      <Button
        aria-label='undo changes'
        className={classes.frameUndoButton}
        color='secondary'
        disabled={!hasUnsavedChanges}
        onClick={() => {
          controllerSend({
            type: 'UNDO_CHANGES',
          });
        }}
        size='small'
        style={{ marginRight: 4 }}
        variant='contained'
      >
        {showControlLabels ? 'RESET FRAME' : <UndoRounded />}
      </Button>
    </Help>
  ) : null;
};

export default FrameResetControl;
