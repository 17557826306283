import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';

import { MachineDrawerTable } from '../../../components/MachineDrawer';
import { GlobalStateContext } from '../../../globalState';

const ChessDrawerTable = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      chessMachine: {
        toolbarDrawer: { isDrawerCollapsed, rowDataItems, page, rowsPerPage },
      },
    },
  } = controllerState.context;

  return (
    <Collapse in={isDrawerCollapsed} unmountOnExit>
      <Paper
        elevation={0}
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          textAlign: 'center',
        }}
      >
        <MachineDrawerTable
          controlId='chessMachineToolbarDrawerTableControl'
          dataItems={rowDataItems}
          tableRowProps={{
            handleOnClickDataLabel: ({ value }) => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  machineId: 'chessMachine',
                  property: 'chessField',
                  values: {
                    value,
                  },
                },
              });
              controllerSend({
                type: 'UPDATE_MACHINES_CONTEXT',
                payload: {
                  machineId: 'chessMachine',
                  property: 'toolbarDrawer',
                  values: {
                    isDrawerCollapsed: false,
                  },
                },
              });
            },
          }}
          tablePaginationProps={{
            handleOnChangePage: ({ value }) =>
              controllerSend({
                type: 'UPDATE_MACHINES_CONTEXT',
                payload: {
                  machineId: 'chessMachine',
                  property: 'toolbarDrawer',
                  values: {
                    page: value,
                  },
                },
              }),
            handleOnChangeRowsPerPage: ({ value }) =>
              controllerSend({
                type: 'UPDATE_MACHINES_CONTEXT',
                payload: {
                  machineId: 'chessMachine',
                  property: 'toolbarDrawer',
                  values: {
                    rowsPerPage: value,
                  },
                },
              }),
            page,
            rowsPerPage,
          }}
        />
      </Paper>
    </Collapse>
  );
};

export default ChessDrawerTable;
