import * as sidenoteMachine from './sidenoteMachine';
import * as chessMachine from './chessMachine';
import * as codeMachine from './codeMachine';
import * as controllerMachine from './controllerMachine';
import * as imageMachine from './imageMachine';
import * as mapMachine from './mapMachine';
import * as textMachine from './textMachine';
import * as videoMachine from './videoMachine';

const controlDictionary = {
  ...sidenoteMachine,
  ...chessMachine,
  ...codeMachine,
  ...controllerMachine,
  ...imageMachine,
  ...mapMachine,
  ...textMachine,
  ...videoMachine,
};

export default controlDictionary;
