import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { BOARD_MACHINES } from '../../Board/constants';
import { GlobalStateContext } from '../../../globalState';
import { controlStyles } from './styles';

const useStyles = makeStyles((theme) => controlStyles(theme));

const FrameToolsControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  return (
    <>
      <FormControl fullWidth>
        <FormLabel>Board Tools</FormLabel>
        <FormGroup row style={{ justifyContent: 'space-between' }}>
          {BOARD_MACHINES.map(
            ({ ariaLabel, Icon, isDisabled, isTabbed, label, value }) => {
              if (!isTabbed || isDisabled) return null;
              const { sidebarTab } =
                controllerState.context.machinesContext[value].boardSettings;
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sidebarTab}
                      checkedIcon={<Icon color='secondary' />}
                      className={classes.frameControlCheckbox}
                      icon={<Icon />}
                      onChange={({ target: { value, checked } }) =>
                        controllerSend({
                          type: 'UPDATE_MACHINES_CONTEXT',
                          payload: {
                            machineId: value,
                            property: 'boardSettings',
                            values: { sidebarTab: checked },
                          },
                        })
                      }
                      size='small'
                      value={value}
                    />
                  }
                  key={value}
                  label={label}
                  labelPlacement='end'
                  style={{ flex: 1 }}
                />
              );
            }
          )}
        </FormGroup>
      </FormControl>
    </>
  );
};

export default FrameToolsControl;
