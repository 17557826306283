import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';

import { CHESS_VERTICAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarVerticalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      chessMachine: { chessField },
    },
    machinesContext: {
      chessMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      chessMachine: { chessField: viewChessField },
    },
  } = controllerState.context;

  const verticalAlignOptionIndex = CHESS_VERTICAL_ALIGN_OPTIONS.findIndex(
    ({ value }) => value === chessField.verticalAlign
  );
  const { label: helpLabelValue, Icon: ImageObjectAlignIcon } =
    CHESS_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex];

  return (
    <Help
      controlId='chessMachineToolbarVerticalAlignControl'
      helpLabelValue={helpLabelValue}
      placement='top'
    >
      <IconButton
        color={
          viewChessField.verticalAlign !== chessField.verticalAlign ||
          autoCapture
            ? 'secondary'
            : 'primary'
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'chessMachine',
              property: 'chessField',
              values: {
                verticalAlign:
                  verticalAlignOptionIndex ===
                  CHESS_VERTICAL_ALIGN_OPTIONS.length - 1
                    ? CHESS_VERTICAL_ALIGN_OPTIONS[0].value
                    : CHESS_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex + 1]
                        .value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarVerticalAlign;
