import SidenoteToolbar from '../Sidenote/Toolbar';
// import ImageDrawerBar from '../Image/DrawerBar';
import SidenoteDrawer from '../Sidenote/Drawer';
import SidenoteDrawerTabs from '../Sidenote/DrawerTabs';
import ChessDrawer from '../Chess/Drawer';
import ChessDrawerTabs from '../Chess/DrawerTabs';
import ChessToolbar from '../Chess/Toolbar';
import CodeDrawer from '../Code/Drawer';
import CodeDrawerTabs from '../Code/DrawerTabs';
import CodeToolbar from '../Code/Toolbar';
import ImageDrawer from '../Image/Drawer';
import ImageDrawerTabs from '../Image/DrawerTabs';
import ImageToolbar from '../Image/Toolbar';
import MapDrawer from '../Map/Drawer';
import MapDrawerTabs from '../Map/DrawerTabs';
import MapToolbar from '../Map/Toolbar';
import TextDrawer from '../Text/Drawer';
import TextDrawerTabs from '../Text/DrawerTabs';
import TextToolbar from '../Text/Toolbar';
import VideoDrawer from '../Video/Drawer';
import VideoDrawerTabs from '../Video/DrawerTabs';
import VideoToolbar from '../Video/Toolbar';

import {
  SIDENOTE_ICON_ROW,
  SIDENOTE_MACHINE_CONTEXT,
  SIDENOTE_SHARED_CONTEXT,
} from '../Sidenote/constants';
import {
  CHESS_ICON,
  CHESS_MACHINE_CONTEXT,
  CHESS_SHARED_CONTEXT,
} from '../Chess/constants';
import {
  CODE_ICON,
  CODE_MACHINE_CONTEXT,
  CODE_SHARED_CONTEXT,
} from '../Code/constants';
import {
  IMAGE_ICON,
  IMAGE_MACHINE_CONTEXT,
  IMAGE_SHARED_CONTEXT,
} from '../Image/constants';
import {
  MAP_ICON,
  MAP_MACHINE_CONTEXT,
  MAP_SHARED_CONTEXT,
} from '../Map/constants';
import {
  TEXT_ICON,
  TEXT_MACHINE_CONTEXT,
  TEXT_SHARED_CONTEXT,
} from '../Text/constants';
import { USER_SHARED_CONTEXT } from '../User/constants';
import {
  VIDEO_ICON,
  VIDEO_MACHINE_CONTEXT,
  VIDEO_SHARED_CONTEXT,
} from '../Video/constants';

export const CONTROLLER_MACHINE_CONTEXT = {
  boardGrid: {
    activeMachineId: '',
  },
  boards: ['home', 'section-tutorials', 'how-to-play-queens-gambit'],
  boardsIndex: 0,
  boardTheme: {
    type: 'dark',
  },
  helpControl: {
    helpPanelsClosed: [],
  },
};

export const CONTROLLER_SHARED_CONTEXT = {
  boardGrid: {
    activeTabMachineId: '',
    backgroundImageUrl: '',
    backgroundMachineId: '',
    containerDirection: 'column',
    containerDirectionReverse: false,
    containerOrientation: 'landscape',
    displayMainSection: true,
    itemBreakpoint: [1, 4, 5, 11],
  },
  boardTheme: {
    backgroundDefault: '#FFFFFF', // White
    backgroundPaper: '#E0E0E0', // Soft Grey
    textMainPanel: '#212121', // Dark Grey
    textSidePanel: '#424242', // Medium Dark Grey
  },
  toolbarTheme: {
    defaultContrastText: '#000000', // Black
    defaultMain: '#BDBDBD', // Light Grey
    primaryContrastText: '#000000', // Black
    primaryMain: '#9E9E9E', // Grey
    secondaryContrastText: '#FFFFFF', // White
    secondaryMain: '#FF5252', // Bright Red
  },
};

export const MACHINES_CONTEXT = {
  sidenoteMachine: { ...SIDENOTE_MACHINE_CONTEXT },
  chessMachine: { ...CHESS_MACHINE_CONTEXT },
  codeMachine: { ...CODE_MACHINE_CONTEXT },
  controllerMachine: { ...CONTROLLER_MACHINE_CONTEXT },
  imageMachine: { ...IMAGE_MACHINE_CONTEXT },
  mapMachine: { ...MAP_MACHINE_CONTEXT },
  messageMachine: {},
  textMachine: { ...TEXT_MACHINE_CONTEXT },
  userMachine: {},
  videoMachine: { ...VIDEO_MACHINE_CONTEXT },
};

/**
 * Machines context is stored in local storage so that it
 * can be persisted across sessions. However, some machines
 * have context that should not be persisted, for example
 * the editorState of the sidenoteField and textField. Here
 * we set the paths of the values to exclude, which is used
 * in saveMachinesContext and loadMachinesContext actions in
 * controllerMachine.
 */
export const MACHINES_CONTEXT_LOCAL_STORAGE_UNSET = [
  'sidenoteMachine.sidenoteField.editorState',
  'textMachine.textField.editorState',
];

export const MACHINES_SHARED_CONTEXT = {
  sidenoteMachine: { ...SIDENOTE_SHARED_CONTEXT },
  chessMachine: { ...CHESS_SHARED_CONTEXT },
  codeMachine: { ...CODE_SHARED_CONTEXT },
  controllerMachine: { ...CONTROLLER_SHARED_CONTEXT },
  imageMachine: { ...IMAGE_SHARED_CONTEXT },
  mapMachine: { ...MAP_SHARED_CONTEXT },
  textMachine: { ...TEXT_SHARED_CONTEXT },
  userMachine: { ...USER_SHARED_CONTEXT },
  videoMachine: { ...VIDEO_SHARED_CONTEXT },
};

export const MACHINES_TOOLBAR_DICTIONARY = {
  sidenoteMachine: {
    Drawer: SidenoteDrawer,
    DrawerTabs: SidenoteDrawerTabs,
    Icon: SIDENOTE_ICON_ROW,
    label: 'Sidenote',
    Toolbar: SidenoteToolbar,
  },
  chessMachine: {
    Drawer: ChessDrawer,
    DrawerTabs: ChessDrawerTabs,
    Icon: CHESS_ICON,
    label: 'Chess',
    Toolbar: ChessToolbar,
  },
  codeMachine: {
    Drawer: CodeDrawer,
    DrawerTabs: CodeDrawerTabs,
    Icon: CODE_ICON,
    label: 'Code',
    Toolbar: CodeToolbar,
  },
  controllerMachine: {},
  imageMachine: {
    Drawer: ImageDrawer,
    DrawerTabs: ImageDrawerTabs,
    Icon: IMAGE_ICON,
    label: 'Image',
    Toolbar: ImageToolbar,
  },
  mapMachine: {
    Drawer: MapDrawer,
    DrawerTabs: MapDrawerTabs,
    Icon: MAP_ICON,
    label: 'Map',
    Toolbar: MapToolbar,
  },
  messageMachine: {},
  textMachine: {
    Drawer: TextDrawer,
    DrawerTabs: TextDrawerTabs,
    Icon: TEXT_ICON,
    label: 'Text',
    Toolbar: TextToolbar,
  },
  userMachine: {},
  videoMachine: {
    Drawer: VideoDrawer,
    DrawerTabs: VideoDrawerTabs,
    Icon: VIDEO_ICON,
    label: 'Video',
    Toolbar: VideoToolbar,
  },
};
