import React, { useEffect, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import InfiniteScroll from 'react-infinite-scroll-component';

const UnsplashGrid = ({ gridColumns, gridKeys, handleImageAdd }) => {
  const [fetchResult, setFetchResult] = useState([]);
  const [searchPage, setSearchPage] = useState(1);

  const fetchRequest = async () => {
    try {
      const data = await fetch(
        `https://api.unsplash.com/search/photos?page=${searchPage}&query=${
          !gridKeys.searchQuery ? 'trending' : gridKeys.searchQuery
        }&client_id=${process.env.REACT_APP_UNSPLASH_API_ACCESS_KEY}`
      );
      const dataJ = await data.json();
      const result = dataJ.results;
      setFetchResult((current) => [...current, ...result]);
      setSearchPage((current) => current + 1);
    } catch {
      console.log('unsplash.com fetch request failed');
    }
  };

  // See https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
  const downloadRequest = async ({ download_location }) => {
    try {
      await fetch(
        `${download_location}&client_id=${process.env.REACT_APP_UNSPLASH_API_ACCESS_KEY}`
      );
    } catch {
      console.log('unsplash.com download request failed');
    }
  };

  const handleImageClick = ({
    alt_description,
    description,
    download_location,
    regular,
    user: {
      links: { html },
      name,
    },
  }) => {
    const credit = {
      imageType: 'unsplash',
      userName: name,
      userUrl: html,
    };
    downloadRequest({ download_location });
    handleImageAdd({
      alt: alt_description,
      credit,
      title: description,
      url: regular,
    });
  };

  useEffect(() => {
    setSearchPage(1);
    setFetchResult([]);
    fetchRequest();
  }, [gridKeys]);

  return (
    <InfiniteScroll
      dataLength={fetchResult.length}
      next={fetchRequest}
      hasMore={true} // Replace with a condition based on your data source
      loader={<p>Loading...</p>}
      endMessage={<p>No more data to load.</p>}
      scrollableTarget='scrollablePaper'
    >
      <Masonry columnsCount={gridColumns} gutter='8px'>
        {fetchResult.map(
          ({
            alt_description,
            description,
            links: { download_location },
            slug,
            urls: { regular, thumb },
            user,
          }) => {
            return (
              <img
                key={slug}
                // className='col-3 img-fluid img-thumbnail'
                onClick={() =>
                  handleImageClick({
                    alt_description,
                    description,
                    download_location,
                    regular,
                    user,
                  })
                }
                src={thumb}
                alt={alt_description}
              />
            );
          }
        )}
      </Masonry>
    </InfiniteScroll>
  );
};

export default UnsplashGrid;
