import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { GlobalStateContext } from '../../../globalState';

const ImageDrawerTabs = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      imageMachine: {
        toolbarDrawer: { drawerTab, searchQuery },
      },
    },
  } = controllerState.context;

  const handleOnClickType = (value) => {
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values: {
          gridKeys: { imageType: drawerTab, searchQuery },
          drawerTab: value,
        },
      },
    });
  };

  return (
    <ButtonGroup
      aria-label='small outlined button group'
      onChange={(_, value) => handleOnClickType(value)}
      value={drawerTab}
      variant='text'
    >
      <Button
        color={drawerTab === 'img' ? 'primary' : 'default'}
        onClick={() => handleOnClickType('img')}
        value='img'
      >
        Image
      </Button>
      <Button
        color={drawerTab === 'gif' ? 'primary' : 'default'}
        onClick={() => handleOnClickType('gif')}
        value='gif'
      >
        Gif
      </Button>
      <Button
        color={drawerTab === 'tag' ? 'primary' : 'default'}
        onClick={() => handleOnClickType('tag')}
        value='tag'
      >
        Tag
      </Button>
      <Button
        color={drawerTab === 'url' ? 'primary' : 'default'}
        onClick={() => handleOnClickType('url')}
        value='url'
      >
        url
      </Button>
    </ButtonGroup>
  );
};

export default ImageDrawerTabs;
