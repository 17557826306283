import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormatItalicRounded from '@material-ui/icons/FormatItalicRounded';
import Tooltip from '@material-ui/core/Tooltip';

import { GlobalStateContext } from '../../../globalState';
import { GOOGLE_FONTS_LIST } from '../../../themes/constants';
import Help from '../../Help';

const ToolbarFontFamily = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    editContext: {
      textMachine: {
        textField: { editorState, fontFamily },
      },
    },
    viewContext: {
      textMachine: {
        textField: { fontFamily: viewFontFamily },
      },
    },
  } = controllerState.context;

  const textHTML = slateToHtml(editorState);
  const isTextHTMLEmpty = !textHTML || textHTML === '<p></p>';

  const handleOnChange = (fontFamily) => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'textMachine',
        property: 'textField',
        values: { fontFamily },
      },
    });
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='textMachineToolbarFontFamilyControl'
          helpLabelValue={fontFamily}
          isDisabled={isTextHTMLEmpty}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='frame theme'
            color={fontFamily !== viewFontFamily ? 'secondary' : 'primary'}
            disabled={isTextHTMLEmpty}
            onClick={(event) => {
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
            }}
            size='small'
          >
            <FormatItalicRounded />
          </IconButton>
        </Help>
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          value={viewFontFamily}
        >
          {GOOGLE_FONTS_LIST.map(({ font: fontFamily }) => (
            <Tooltip
              key={fontFamily}
              arrow
              title={fontFamily}
              placement='right'
            >
              <MenuItem
                onClick={() => handleOnChange(fontFamily)}
                style={{
                  border: '1px solid',
                }}
                value={fontFamily}
              >
                <div
                  style={{
                    fontFamily,
                    width: '100%',
                  }}
                >
                  Font
                </div>
              </MenuItem>
            </Tooltip>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default ToolbarFontFamily;
