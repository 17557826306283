import { useState, useEffect } from 'react';

function calculatedBoardDimensions({ height, deviceOrientation, width }) {
  const calculatedPortraitWidth = Math.floor(height * (9 / 16));
  const boardPortraitWidth =
    calculatedPortraitWidth > width ? width : calculatedPortraitWidth;
  const boardPortraitHeight = Math.floor(boardPortraitWidth * (16 / 9));

  const calculatedLandscapeHeight = Math.floor(width * (9 / 16));
  const boardLandscapeHeight =
    calculatedLandscapeHeight > height ? height : calculatedLandscapeHeight;
  const boardLandscapeWidth = Math.floor(boardLandscapeHeight * (16 / 9));
  return {
    portrait: {
      width: boardPortraitWidth,
      height: boardPortraitHeight,
    },
    landscape: {
      width: boardLandscapeWidth,
      height: boardLandscapeHeight,
    },
  };
}

function getWindowProperties() {
  const { innerWidth: width, innerHeight: height } = window;
  const deviceOrientation = height > width ? 'portrait' : 'landscape';
  return {
    ...calculatedBoardDimensions({ height, deviceOrientation, width }),
    deviceOrientation,
    height,
    inAutoOrientationMode: true,
    width,
  };
}

export const useWindowProperties = () => {
  const [windowProperties, setWindowProperties] = useState(
    getWindowProperties()
  );

  useEffect(() => {
    function handleResize() {
      setWindowProperties(getWindowProperties());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowProperties;
};
