import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DeleteForever from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ViewCarouselRounded from '@material-ui/icons/ViewCarouselRounded';
import Slider from '@material-ui/core/Slider';
import InsertPhotoRounded from '@material-ui/icons/InsertPhotoRounded';
import PhotoSizeSelectLargeRounded from '@material-ui/icons/PhotoSizeSelectLargeRounded';
import Badge from '@material-ui/core/Badge';

import Help from '../../Help';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import { IMAGE_SHARED_CONTEXT } from '../constants';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarCarouselControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      imageMachine: { imageField },
    },
    machinesContext: {
      imageMachine: {
        boardSettings: { autoCapture },
        imageCollection: { collectionIndex, collectionItems },
      },
    },
  } = controllerState.context;

  const collectionItemUrl = collectionItems[collectionIndex]
    ? collectionItems[collectionIndex].url
    : '';

  const isCollectionItemUrlSetToBackground =
    !!imageField.backgroundUrl &&
    collectionItemUrl === imageField.backgroundUrl;
  const isCollectionItemUrlSetToForeground =
    !!imageField.url && collectionItemUrl === imageField.url;

  const handleImageUrlsDelete = (index) => {
    if (!collectionItems[index]) return;
    collectionItems.splice(index, 1);
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex:
            index > collectionItems.length - 1
              ? collectionItems.length - 1
              : index,
          collectionItems,
        },
      },
    });
  };

  const handleCarouselIndexChange = ({ collectionIndex }) => {
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex,
        },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values: { isDrawerCollapsed: false },
      },
    });
  };

  const handleSetBackgroundImage = () => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: {
          backgroundMachineId: isCollectionItemUrlSetToBackground
            ? ''
            : 'imageMachine',
        },
      },
    });

    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: isCollectionItemUrlSetToBackground
          ? {
              backgroundCredit:
                IMAGE_SHARED_CONTEXT.imageField.backgroundCredit,
              backgroundUrl: IMAGE_SHARED_CONTEXT.imageField.backgroundUrl,
            }
          : {
              backgroundCredit: collectionItems[collectionIndex].credit,
              backgroundUrl: collectionItemUrl,
            },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          isBackgroundSelected: isCollectionItemUrlSetToBackground
            ? false
            : true,
        },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  const handleSetForegroundImage = () => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: {
          activeTabMachineId: 'imageMachine',
        },
      },
    });
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: isCollectionItemUrlSetToForeground
          ? {
              credit: IMAGE_SHARED_CONTEXT.imageField.credit,
              url: IMAGE_SHARED_CONTEXT.imageField.url,
            }
          : {
              credit: collectionItems[collectionIndex].credit,
              url: collectionItemUrl,
            },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          isBackgroundSelected: false,
        },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='imageMachineToolbarCarouselControl'
          isDisabled={!collectionItems.length}
          isHidden={Boolean(anchorEl)}
          helpLabelValue={
            collectionItems.length
              ? `${collectionItems.length} image${
                  collectionItems.length === 1 ? '' : 's'
                }`
              : 'no images'
          }
          placement='top'
        >
          <Badge
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            badgeContent={`${collectionItems.length}`}
            color='primary'
            invisible={Boolean(anchorEl)}
          >
            <IconButton
              aria-label='swap content'
              // className={classes.machineToolbarIconButton}
              color='primary'
              disabled={!collectionItems.length}
              onClick={(event) =>
                anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
              }
              size='small'
            >
              <ViewCarouselRounded />
            </IconButton>
          </Badge>
        </Help>
        <Popper
          className={classes.machineToolbarPopper}
          open={Boolean(anchorEl)}
          placement='top'
          anchorEl={anchorEl}
          style={{ zIndex: 2000 }}
        >
          <Box
            id='imageToolbarCarousel'
            display='flex'
            flexDirection='column'
            position='relative'
            p={1}
          >
            <Box
              id='imageToolbarCarouselActions'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <IconButton
                className={
                  isCollectionItemUrlSetToBackground
                    ? classes.machineToolbarPopperIconButtonActive
                    : classes.machineToolbarPopperIconButton
                }
                disabled={collectionItems.length < 1}
                onClick={handleSetBackgroundImage}
                size='small'
              >
                <InsertPhotoRounded />
              </IconButton>
              <IconButton
                className={
                  isCollectionItemUrlSetToForeground
                    ? classes.machineToolbarPopperIconButtonActive
                    : classes.machineToolbarPopperIconButton
                }
                disabled={collectionItems.length < 1}
                onClick={handleSetForegroundImage}
                size='small'
              >
                <PhotoSizeSelectLargeRounded />
              </IconButton>
              <IconButton
                className={classes.machineToolbarPopperIconButton}
                color='secondary'
                onClick={() => handleImageUrlsDelete(collectionIndex)}
                size='small'
              >
                <DeleteForever />
              </IconButton>
            </Box>
            <Box
              id='imageToolbarCarouselImage'
              component='img'
              height={170}
              width={170}
              // alt='Contemplative Reptile'
              // width='140'
              src={
                collectionItems[collectionIndex]
                  ? collectionItems[collectionIndex].url
                  : ''
              }
              // title='Contemplative Reptile'
              sx={{
                objectFit: 'contain',
                objectPosition: 'center',
                margin: '4px 0',
              }}
            />
            <Box
              id='imageToolbarCarouselNavigation'
              alignItems='center'
              display='flex'
              flexDirection='row'
            >
              <IconButton
                className={classes.machineToolbarPopperIconButton}
                disabled={collectionItems.length <= 1}
                onClick={() => {
                  const newIndex =
                    collectionIndex === 0
                      ? collectionItems.length - 1
                      : collectionIndex - 1;
                  handleCarouselIndexChange({ collectionIndex: newIndex });
                }}
                size='small'
              >
                <NavigateBefore />
              </IconButton>
              <Slider
                onChange={(event, collectionIndex) =>
                  handleCarouselIndexChange({ collectionIndex })
                }
                min={0}
                marks
                max={collectionItems.length - 1}
                step={1}
                track={false}
                value={collectionIndex}
                valueLabelDisplay='auto'
                valueLabelFormat={() => `${collectionIndex + 1}`}
              />
              <IconButton
                className={classes.machineToolbarPopperIconButton}
                disabled={collectionItems.length <= 1}
                onClick={() => {
                  const newIndex =
                    collectionIndex === collectionItems.length - 1
                      ? 0
                      : collectionIndex + 1;
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'imageMachine',
                      property: 'imageCollection',
                      values: {
                        collectionIndex: newIndex,
                      },
                    },
                  });
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'imageMachine',
                      property: 'toolbarDrawer',
                      values: { isDrawerCollapsed: false },
                    },
                  });
                }}
                size='small'
              >
                <NavigateNext />
              </IconButton>
            </Box>
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ToolbarCarouselControl;
