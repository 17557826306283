import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import FlipToBackRounded from '@material-ui/icons/FlipToBackRounded';
import FlipToFrontRounded from '@material-ui/icons/FlipToFrontRounded';
import IconButton from '@material-ui/core/IconButton';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import { IMAGE_SHARED_CONTEXT } from '../../Image/constants';

const ToolbarBackgroundControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { backgroundMachineId },
      },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      controllerMachine: {
        boardGrid: { backgroundMachineId: stageBackgroundMachineId },
      },
    },
  } = controllerState.context;

  return (
    <Help
      controlId='mapMachineToolbarBackgroundControl'
      placement='top'
      helpLabelValue={
        backgroundMachineId !== 'mapMachine' ? 'Main' : 'Background'
      }
    >
      <IconButton
        color={
          ((stageBackgroundMachineId === 'mapMachine' ||
            backgroundMachineId === 'mapMachine') &&
            stageBackgroundMachineId !== backgroundMachineId) ||
          autoCapture
            ? 'secondary'
            : 'primary'
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: {
                backgroundMachineId:
                  backgroundMachineId === 'mapMachine' ? '' : 'mapMachine',
              },
            },
          });
          /**
           * TODO: We are clearing the imageField background values here
           * or else the image will not be displayed in the carousel
           * as it is still considered a background image. However, seems
           * somewhat hacky to do this in the Map machine.
           */
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'imageMachine',
              property: 'imageField',
              values: {
                backgroundCredit:
                  IMAGE_SHARED_CONTEXT.imageField.backgroundCredit,
                backgroundUrl: IMAGE_SHARED_CONTEXT.imageField.backgroundUrl,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        {backgroundMachineId !== 'mapMachine' ? (
          <FlipToBackRounded />
        ) : (
          <FlipToFrontRounded />
        )}
      </IconButton>
    </Help>
  );
};

export default ToolbarBackgroundControl;
