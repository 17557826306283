import React, { useContext, useMemo } from 'react';
import { useActor } from '@xstate/react';
import { createEditor } from 'slate';
import { withReact } from 'slate-react';
import { useTheme } from '@material-ui/core/styles';

import { GlobalStateContext } from '../../../globalState';
import SlateEditor from '../../../components/SlateEditor';

const TextDrawerEditor = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const editor = useMemo(() => withReact(createEditor()), []);
  const theme = useTheme();

  const {
    machinesContext: {
      textMachine: {
        boardSettings: { autoCapture },
      },
    },
    editContext: {
      textMachine: {
        textField: { editorState, fontFamily, horizontalAlign: textAlign },
      },
    },
  } = controllerState.context;

  const handleChangeTextField = (editorState) => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'textMachine',
        property: 'textField',
        values: { editorState },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  const handleFocusTextField = () =>
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: { activeMachineId: 'textMachine' },
      },
    });

  return (
    <SlateEditor
      editableProps={{
        style: {
          backgroundColor: theme.palette.background.mainPanelContrast,
          color: theme.palette.text.mainPanel,
          fontFamily,
          fontSize: '1.2rem',
          textAlign,
        },
      }}
      editor={editor}
      editorState={editorState}
      initialValue={editorState}
      onChange={handleChangeTextField}
      onFocus={handleFocusTextField}
    />
  );
};

export default TextDrawerEditor;
