import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import HighlightOffRounded from '@material-ui/icons/HighlightOffRounded';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import { calculateBoardGridProps } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';
import Sidenote from '../../Sidenote';
import Help from '../../Help';

const PanelMain = ({ gridMainSectionSize, handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const {
    editContext,
    editControl: { isEditFrameModeActive },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;
  const {
    containerDirection,
    containerDirectionReverse,
    displayMainSection,
    itemBreakpoint,
  } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  const { itemMainSectionXs } = calculateBoardGridProps({
    containerDirection,
    containerDirectionReverse,
    itemBreakpoint,
  });

  return (
    <Box
      component={Paper}
      elevation={0}
      height='100%'
      position='relative'
      style={{
        backgroundColor: 'transparent',
      }}
      width='100%'
    >
      <Sidenote
        displayMainSection={displayMainSection}
        gridMainSectionSize={gridMainSectionSize}
        isMainSectionHidden={!itemMainSectionXs}
      />
      {inEditMode &&
        activeMachineId === 'sidenoteMachine' &&
        isEditFrameModeActive && (
          <Box position='absolute' top='0px' right='0px'>
            <Help
              controlId={`${activeMachineId}BoardSectionVisibilityControl`}
              placement='left'
            >
              <IconButton
                onClick={() =>
                  handleActiveMachineChange({
                    editValues: { displayMainSection: false },
                    machineValues: { activeMachineId: '' },
                  })
                }
                style={{
                  color: theme.palette.text.primary,
                  paddingRight: containerDirection === 'column' ? 12 : 24,
                  paddingTop: containerDirection === 'column' ? 12 : 24,
                }}
              >
                <HighlightOffRounded />
              </IconButton>
            </Help>
          </Box>
        )}
    </Box>
  );
};

export default PanelMain;
