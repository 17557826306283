import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinkRounded from '@material-ui/icons/LinkRounded';
import Button from '@material-ui/core/Button';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const BoardLinkControl = ({ buttonLabel, ...restProps }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [sessionState] = useActor(sessionService);
  const [controllerState] = useActor(controllerService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    modalControl: { openModalIds },
  } = controllerState.context;
  const {
    session: { id: sessionId },
  } = sessionState.context;

  const handleBoardLinkControlOnClick = () => {
    const sessionUrl = `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ''
    }/board/${sessionId}`;

    navigator.clipboard.writeText(sessionUrl);
  };

  return sessionState.matches('stream') ? (
    <Help
      controlId='controllerMachineBoardLinkControl'
      isDisabled={!!openModalIds.length || !frames.length}
      placement='top'
    >
      <Button
        color='primary'
        disabled={!!openModalIds.length || !frames.length}
        onClick={handleBoardLinkControlOnClick}
        size='small'
        variant='contained'
        {...restProps}
      >
        {showControlLabels ? (
          buttonLabel || 'INVITE'
        ) : (
          <LinkRounded aria-label='Copy invite link to clipboard' />
        )}
      </Button>
    </Help>
  ) : null;
};

export default BoardLinkControl;
