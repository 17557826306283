import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import StayPrimaryLandscapeTwoTone from '@material-ui/icons/StayPrimaryLandscapeTwoTone';
import StayPrimaryPortraitTwoTone from '@material-ui/icons/StayPrimaryPortraitTwoTone';

import { useWindowProperties } from '../../../helpers';
import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => controlStyles(theme));

const DeviceOrientationControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const { deviceOrientation } = useWindowProperties();
  const classes = useStyles();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');

  const {
    board: { frames },
  } = controllerState.context;
  const {
    controllerMachine: {
      boardGrid: { containerOrientation },
    },
  } = inEditMode
    ? controllerState.context.editContext
    : controllerState.context.viewContext;

  return isMobile &&
    !inEditMode &&
    deviceOrientation !== containerOrientation &&
    // Hide when responsive default background image is displayed
    frames.length > 1 ? (
    <Button
      className={classes.deviceOrientationControl}
      color='default'
      onClick={() =>
        controllerSend({
          type: 'MODAL_OPEN',
          payload: { modalId: 'menuFrameOrientation' },
        })
      }
      variant='contained'
    >
      <Icon className={classes.deviceOrientationIcon} color='secondary'>
        {deviceOrientation === 'landscape' ? (
          <StayPrimaryLandscapeTwoTone
            className={classes.deviceOrientationIconAnimate}
          />
        ) : (
          <StayPrimaryPortraitTwoTone
            className={classes.deviceOrientationIconAnimate}
          />
        )}
      </Icon>
    </Button>
  ) : null;
};

export default DeviceOrientationControl;
