import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import PlayCircleFilledWhiteRounded from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import EditTwoTone from '@material-ui/icons/EditTwoTone';
import AddToPhotosRounded from '@material-ui/icons/AddToPhotosRounded';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const BoardViewControl = () => {
  const { controllerService, sessionService, userService } =
    useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const [userState] = useActor(userService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    modalControl: { openModalIds },
    viewFrameIndex,
  } = controllerState.context;
  const {
    session,
    session: { id: sessionId },
  } = sessionState.context;
  const { user } = userState.context;

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const isViewingOwnRecording =
    !inEditMode && session.mode === 'record' && user.role === 'edit';
  const isViewingOwnStream =
    !inEditMode && session.mode === 'stream' && user.role === 'edit';

  const handleOnClickBoardViewControl = () => {
    const sessionUrl = `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ''
    }/board/${sessionId}${
      // Not in viewing mode; add /view to switch to viewing mode
      !isViewingOwnRecording && !isViewingOwnStream ? '/view' : ''
      // Viewing recording; navigate to current frame in edit mode
    }${isViewingOwnRecording ? `?frame=${viewFrameIndex}` : ''}`;

    window.open(sessionUrl, '_self', 'rel=noopener noreferrer');
  };

  return frames.length > 1 &&
    sessionId &&
    (inEditMode || isViewingOwnRecording || isViewingOwnStream) ? (
    <Help
      controlId='controllerMachineBoardViewControl'
      helpLabelValue={inEditMode ? session.mode : 'View '}
      isDisabled={!!openModalIds.length || frames.length <= 1}
      placement='right'
    >
      <Button
        // color='primary'
        disabled={!!openModalIds.length || frames.length <= 1}
        onClick={handleOnClickBoardViewControl}
        size='small'
        variant='contained'
      >
        {isViewingOwnRecording || isViewingOwnStream ? (
          isViewingOwnRecording ? (
            showControlLabels ? (
              'RECORD'
            ) : (
              <EditTwoTone aria-label='Continue recording' />
            )
          ) : showControlLabels ? (
            'STREAM'
          ) : (
            <AddToPhotosRounded aria-label='Continue streaming' />
          )
        ) : showControlLabels ? (
          'PLAY'
        ) : (
          <PlayCircleFilledWhiteRounded aria-label='View board' />
        )}
      </Button>
    </Help>
  ) : null;
};

export default BoardViewControl;
