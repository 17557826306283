import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import VolumeOffOutlined from '@material-ui/icons/VolumeOffOutlined';
import VolumeMuteOutlined from '@material-ui/icons/VolumeMuteOutlined';

import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => controlStyles(theme));

const AudioMute = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    controllerSettings: { appBarHeight },
    playControl: { videoPlayerMode, videoPlayerMuted },
  } = controllerState.context;

  return videoPlayerMode === 'play' ? (
    <Button
      className={classes.audioMuteButton}
      color={videoPlayerMuted ? 'secondary' : 'primary'}
      onClick={() =>
        controllerSend({
          type: 'CONTROLLER_PROP_VALUES',
          payload: {
            property: 'playControl',
            values: { videoPlayerMuted: !videoPlayerMuted },
          },
        })
      }
      style={{ height: appBarHeight }}
      variant={videoPlayerMuted ? 'contained' : 'text'}
    >
      {videoPlayerMuted ? <VolumeOffOutlined /> : <VolumeMuteOutlined />}
    </Button>
  ) : null;
};

export default AudioMute;
