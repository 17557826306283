export const editorStyles = (theme) => ({
  textEditor: {
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',
    margin: 0,
    overflow: 'auto',
    padding: theme.spacing(1),
    '&:focus': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
});
