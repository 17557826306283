import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { GlobalStateContext } from '../../../globalState';

const MapDrawerTabs = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      mapMachine: {
        toolbarDrawer: { drawerTab },
      },
    },
  } = controllerState.context;

  const handleOnClickType = (value) => {
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'mapMachine',
        property: 'toolbarDrawer',
        values: {
          drawerTab: value,
          isDrawerCollapsed: true,
        },
      },
    });
  };

  return (
    <ButtonGroup
      aria-label='search location'
      onChange={(_, value) => handleOnClickType(value)}
      value={drawerTab}
      variant='text'
    >
      <Button
        color='inherit'
        onClick={() => handleOnClickType('search')}
        tabIndex={-1}
        value='search'
      >
        Map
      </Button>
    </ButtonGroup>
  );
};

export default MapDrawerTabs;
