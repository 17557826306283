import React from 'react';
import T from 'prop-types';

import TableCell from '@material-ui/core/TableCell';

const MachineDrawerTableRowData = ({ data, onClickDataLabel }) => {
  return (
    <>
      <TableCell
        key={data.placeId}
        onClick={() => onClickDataLabel(data)}
        style={{ cursor: 'pointer' }}
      >
        {data.label || data.value}
      </TableCell>
      {data.actions &&
        data.actions.map(({ Component, key, onClick }) => (
          <TableCell key={key} onClick={() => onClick(data)}>
            {Component}
          </TableCell>
        ))}
    </>
  );
};

MachineDrawerTableRowData.propTypes = {
  data: T.shape({
    value: T.string.isRequired,
  }).isRequired,
};

export default MachineDrawerTableRowData;
