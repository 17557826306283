const session = {
  autoPlay: true,
  mode: 'record',
  role: 'view',
  name: 'Launching Smurfboard',
  status: 'closed',
  userName: '',
};

export default session;
