import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import ExploreRounded from '@material-ui/icons/ExploreRounded';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';

import { globalStyles } from '../../../themes/globalStyles';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarDirectionControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      mapMachine: { mapField: viewMapField },
    },
  } = controllerState.context;

  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  return mapField.mapTypeId === 'streetview' ? (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='mapMachineToolbarMapDirectionControl'
          helpLabelValue={mapField.pov.heading}
          isDisabled={!hasLocation}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='map heading'
            color={
              viewMapField.pov.heading !== mapField.pov.heading || autoCapture
                ? 'secondary'
                : 'primary'
            }
            disabled={!hasLocation}
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
          >
            <ExploreRounded />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='mapMachineToolbarMapDirectionControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Slider
            className={classes.machineToolbarSlider}
            color={
              viewMapField.pov.heading !== mapField.pov.heading || autoCapture
                ? 'secondary'
                : 'primary'
            }
            value={mapField.pov.heading}
            onChange={(event, value) => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  autoCapture: false,
                  machineId: 'mapMachine',
                  property: 'mapField',
                  values: {
                    pov: {
                      ...mapField.pov,
                      heading: value,
                    },
                  },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
            min={0}
            max={360}
            step={1}
            style={{ width: 120 }}
            valueLabelDisplay='on'
            valueLabelFormat={(value) => `${Math.round(value)}°`}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  ) : null;
};

export default ToolbarDirectionControl;
