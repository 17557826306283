import React from 'react';
import T from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

const MachineDrawerTableRow = ({ children }) => {
  return <Box component={TableRow}>{children}</Box>;
};

MachineDrawerTableRow.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default MachineDrawerTableRow;
