import React, { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LiveHelpRounded from '@material-ui/icons/LiveHelpRounded';
import Button from '@material-ui/core/Button';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import helpTopicsByRole from '../../Help/Topics';

const HelpMenuControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { mode, role },
    helpControl: { helpTopicId, isHelpActive },
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    modalControl: { openModalIds },
  } = controllerState.context;

  const { topicDictionary, topicSteps } =
    helpTopicsByRole[role][mode][helpTopicId];
  const controlId = topicSteps[0];
  const { relatedControlIds } = topicDictionary[controlId];
  const { toolbarDrawer } = activeMachineId
    ? machinesContext[activeMachineId]
    : {};

  /**
   * Clears the rendered control id's on certain events (e.g. change
   * active machine, open/close drawer, etc) in order for the Help
   * component to rebuild it.
   */
  useEffect(() => {
    if (isHelpActive) {
      controllerSend({
        type: 'CONTROLLER_PROP_VALUES',
        payload: {
          property: 'helpControl',
          values: {
            renderedControlIds: new Set(),
          },
        },
      });
    }
  }, [isHelpActive, activeMachineId, controllerSend, controlId, toolbarDrawer]);

  return (
    <Help
      controlId='controllerMachineHelpMenuControl'
      helpLabelValue={isHelpActive ? 'On' : 'Off'}
      isDisabled={!!openModalIds.length}
      placement='right'
    >
      <Button
        disabled={!!openModalIds.length}
        onClick={() => {
          /**
           * Clear on close, set on open
           */
          const openControlIds = isHelpActive
            ? new Set()
            : new Set([controlId]);
          const openRelatedControlIds = isHelpActive
            ? new Set()
            : new Set(relatedControlIds);
          const minimizedControlIds = isHelpActive
            ? new Set()
            : new Set([
                'controllerMachineHelpMenuControl',
                ...relatedControlIds,
              ]);
          controllerSend({
            type: 'CONTROLLER_PROP_VALUES',
            payload: {
              property: 'helpControl',
              values: {
                helpTopicIndex: 0,
                helpTopicId: 'default',
                isHelpActive: !isHelpActive,
                openControlIds,
                openRelatedControlIds,
                minimizedControlIds,
                renderedControlIds: new Set(),
                showTopics: true,
              },
            },
          });
          /**
           * Pause frames playback on open
           */
          if (!isHelpActive && controllerState.can('STOP_PLAY_FRAMES')) {
            controllerSend({ type: 'STOP_PLAY_FRAMES' });
          }
        }}
        size='small'
        variant='contained'
      >
        {showControlLabels ? 'HELP' : <LiveHelpRounded />}
      </Button>
    </Help>
  );
};

export default HelpMenuControl;
