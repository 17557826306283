import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import EditRounded from '@material-ui/icons/EditRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const FrameEditControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editContext,
    editControl: {
      durationField,
      isEditFrameModeActive,
      isFrameIndexOnNewFrame,
    },
    editFrameIndex,
    viewContext,
  } = controllerState.context;
  const {
    session: { mode },
  } = sessionState.context;

  const hasContextChanged =
    !isEqual(viewContext, editContext) ||
    (frames[editFrameIndex] &&
      !isEqual(durationField.value, frames[editFrameIndex].duration));

  return (
    mode === 'record' &&
    !hasContextChanged &&
    !isFrameIndexOnNewFrame && (
      <Help controlId='controllerMachineFrameEditControl' placement='top'>
        <Button
          color='primary'
          disabled={sessionState.matches('stream')}
          onClick={() =>
            controllerSend({
              type: 'CONTROLLER_PROP_VALUES',
              payload: {
                property: 'editControl',
                values: { isEditFrameModeActive: !isEditFrameModeActive },
              },
            })
          }
          size='small'
          style={{ marginRight: 4 }}
          variant='contained'
        >
          {showControlLabels ? (
            isEditFrameModeActive ? (
              'STOP EDITING'
            ) : (
              `EDIT FRAME: ${editFrameIndex}`
            )
          ) : isEditFrameModeActive ? (
            <CancelRounded />
          ) : (
            <EditRounded />
          )}
        </Button>
      </Help>
    )
  );
};

export default FrameEditControl;
