import React, { useContext, useRef } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Box from '@material-ui/core/Box';

import { globalStyles } from '../../themes/globalStyles';
import { videoStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import Help from '../Help';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...videoStyles(theme),
}));

const VideoEdit = ({ gridTabsSectionSize, renderBackgroundComponent }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const videoFieldRef = useRef();
  const classes = useStyles();

  const {
    editContext: {
      controllerMachine: {
        boardGrid: {
          containerDirectionReverse,
          containerDirection,
          flexDirection,
        },
      },
      videoMachine: {
        videoField: { muted, playing, url, verticalAlign },
      },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
        helpControl: { helpPanelsClosed },
      },
    },
    playControl: { videoPlayerMode, videoPlayerProgress },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'videoMachine';

  const handleVideoPlayerProgress = ({ playedSeconds }) => {
    if (videoPlayerMode !== 'idle') {
      controllerSend({
        type: 'CONTROLLER_PROP_VALUES',
        payload: {
          property: 'playControl',
          values: { videoPlayerProgress: playedSeconds },
        },
      });
    }
    if (videoPlayerMode !== 'idle' && playing) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'videoMachine',
          property: 'videoField',
          values: { played: playedSeconds },
        },
      });
    }
  };

  const handleVideoPlayingMode = ({ playing }) => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'videoMachine',
        property: 'videoField',
        values: { playing, played: videoPlayerProgress },
      },
    });
  };

  return !isActiveMachine && !renderBackgroundComponent ? (
    <HelpPanel
      isHidden={!!activeMachineId}
      panelId='mainPanel'
      panelProperties={gridTabsSectionSize}
    />
  ) : !!url || helpPanelsClosed.includes('videoToolbar') ? (
    <Help
      controlId='videoMachineVideoFieldComponent'
      placement={
        containerDirection === 'row'
          ? !containerDirectionReverse
            ? 'left'
            : 'right'
          : !containerDirectionReverse
          ? 'top'
          : 'bottom'
      }
    >
      <Box
        className={
          isActiveMachine
            ? isFrameIndexOnNewFrame
              ? classes.activeMachineContainer
              : classes.activeMachineContainerEdit
            : classes.inactiveMachineContainer
        }
        display='flex'
        flexDirection={flexDirection}
        style={{
          height: '100%',
          justifyContent: verticalAlign,
        }}
      >
        {/* <Fade in={videoPlayerMode !== 'idle'}> */}
        <div className={classes.reactPlayerWrapper}>
          <ReactPlayer
            className={classes.reactPlayer}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                },
              },
            }}
            controls={true}
            // controls={
            //   activeMachineId === 'videoMachine' &&
            //   backgroundMachineId !== 'videoMachine'
            // }
            height='100%'
            id='videoField'
            muted={muted}
            onPause={() => handleVideoPlayingMode({ playing: false })}
            onPlay={() => handleVideoPlayingMode({ playing: true })}
            onProgress={handleVideoPlayerProgress}
            onReady={() => {
              controllerSend({
                type: 'CONTROLLER_PROP_VALUES',
                payload: {
                  property: 'playControl',
                  values: { videoPlayerMode: 'ready' },
                },
              });
            }}
            playing={playing}
            progressInterval={250}
            ref={videoFieldRef}
            style={
              /**
               * Use inline styling for Player as theme styling is buggy
               * when switching themes.
               */
              {
                position: 'absolute',
                top: 0,
                left: 0,
              }
            }
            url={url}
            width='100%'
          />
        </div>
        {/* </Fade> */}
      </Box>
    </Help>
  ) : null;
};

export default VideoEdit;
