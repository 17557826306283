import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StreetViewPanorama,
} from '@react-google-maps/api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Box from '@material-ui/core/Box';

import { globalStyles } from '../../themes/globalStyles';
import { MAP_LIBRARIES } from './constants';
import { GlobalStateContext } from '../../globalState';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
}));

const MapEdit = ({ gridTabsSectionSize, renderBackgroundComponent }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [googleMap, setGoogleMap] = useState(null);
  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    // See https://github.com/JustFly1984/react-google-maps-api/issues/3334#issuecomment-2002696176
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`,
    libraries: MAP_LIBRARIES,
  });

  const {
    editContext: {
      mapMachine: { mapField },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'mapMachine';
  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  const onChangeMap = () => {
    // TODO Find better fix to catch empty map object error
    if (isEmpty(googleMap)) return;
    const {
      center,
      mapTypeId,
      streetView: { pov },
      zoom,
    } = googleMap;
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: { activeMachineId: 'mapMachine' },
      },
    });
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'mapMachine',
        property: 'mapField',
        values: {
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
          mapTypeId,
          markers: mapField.markers,
          pov: {
            heading: pov.heading,
            pitch: pov.pitch,
            zoom: pov.zoom,
          },
          zoom,
        },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  const onStreetViewPositionChanged = () => {
    if (isEmpty(googleMap)) return;
    const { center } = googleMap;
    const { position } = googleMap.streetView;
    const streetLat = position.lat();
    const streetLng = position.lng();
    const mapLat = center.lat();
    const mapLng = center.lng();
    if (!isEqual(streetLat, mapLat) || !isEqual(streetLng, mapLng)) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'mapMachine',
          property: 'mapField',
          values: {
            center: {
              lat: streetLat,
              lng: streetLng,
            },
          },
        },
      });
      if (autoCapture) {
        controllerSend({
          type: 'SUBMIT_FRAME_CAPTURE',
        });
      }
    }
  };

  const onStreetViewPovChanged = () => {
    if (isEmpty(googleMap)) return;
    const {
      pov: { heading, pitch },
    } = googleMap.streetView;
    if (
      !isEqual(heading, mapField.pov.heading) ||
      !isEqual(pitch, mapField.pov.pitch)
    ) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'mapMachine',
          property: 'mapField',
          values: {
            pov: {
              ...mapField.pov,
              heading,
              pitch,
            },
          },
        },
      });
      if (autoCapture) {
        controllerSend({
          type: 'SUBMIT_FRAME_CAPTURE',
        });
      }
    }
  };

  const onStreetViewZoomChanged = () => {
    if (isEmpty(googleMap)) return;
    const { zoom } = googleMap.streetView;
    if (!isEqual(zoom, mapField.pov.zoom)) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'mapMachine',
          property: 'mapField',
          values: {
            pov: {
              ...mapField.pov,
              zoom,
            },
          },
        },
      });
      if (autoCapture) {
        controllerSend({
          type: 'SUBMIT_FRAME_CAPTURE',
        });
      }
    }
  };

  return hasLocation && isLoaded ? (
    <>
      {/*
       * Map field
       * package: https://react-google-maps-api-docs.netlify.app/
       * https://tomchentw.github.io/react-google-maps/#standalonesearchbox
       */}
      {/* <Help controlId='mapMachineMapFieldComponent' placement='top'> */}
      <GoogleMap
        center={mapField.center}
        mapContainerClassName={
          isActiveMachine
            ? renderBackgroundComponent
              ? isFrameIndexOnNewFrame
                ? classes.activeMachineBackgroundContainer
                : classes.activeMachineBackgroundContainerEdit
              : isFrameIndexOnNewFrame
              ? classes.activeMachineContainer
              : classes.activeMachineContainerEdit
            : classes.inactiveMachineContainer
        }
        mapContainerStyle={{
          height: '100%',
          width: '100%',
          zIndex: renderBackgroundComponent && isActiveMachine ? 1 : 0,
        }}
        mapTypeId={mapField.mapTypeId}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: { activeMachineId: 'mapMachine' },
            },
          });
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'mapMachine',
              property: 'toolbarDrawer',
              values: { drawerTab: 'search', isDrawerCollapsed: true },
            },
          });
        }}
        onDragEnd={onChangeMap}
        onMapTypeIdChanged={onChangeMap}
        onZoomChanged={onChangeMap}
        onLoad={(map) => {
          map.setZoom(mapField.zoom);
          map.setMapTypeId(mapField.mapTypeId);
          map.streetView.setPov(mapField.pov);
          setGoogleMap(map);
        }}
        options={{
          backgroundColor: 'transparent',
          fullscreenControl: false,
          mapTypeControl: false,
          rotateControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
        zoom={mapField.zoom}
      >
        <StreetViewPanorama
          id='street-view'
          position={mapField.center}
          pov={mapField.pov}
          motionTracking={false}
          motionTrackingControl={false}
          onPositionChanged={onStreetViewPositionChanged}
          onPovChanged={onStreetViewPovChanged}
          onZoomChanged={onStreetViewZoomChanged}
          options={{
            addressControl: false,
            enableCloseButton: false,
            fullscreenControl: false,
            linksControl: false,
            panControl: false,
            showRoadLabels: false,
            visible: mapField.mapTypeId === 'streetview',
            zoomControl: false,
          }}
          style={{
            zIndex: renderBackgroundComponent && isActiveMachine ? 1 : 0,
          }}
          setZoom={mapField.pov.zoom}
        />
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {!!mapField.selectedPlace.location.lat &&
            !!mapField.selectedPlace.location.lan && (
              <Marker
                key={mapField.selectedPlace.placeId}
                position={mapField.selectedPlace.location}
                title={mapField.selectedPlace.title}
              />
            )}
          {mapField.markers &&
            mapField.markers.map(
              ({ location, markerLabel, placeId, value }) => {
                return (
                  <Marker
                    key={placeId}
                    label={markerLabel}
                    position={location}
                    title={value}
                  />
                );
              }
            )}
        </>
      </GoogleMap>
      {/* </Help> */}
    </>
  ) : !renderBackgroundComponent && !isActiveMachine ? (
    <HelpPanel
      isHidden={!!activeMachineId}
      panelId='mainPanel'
      panelProperties={gridTabsSectionSize}
    />
  ) : (
    <Box
      height='100%'
      width='100%'
      className={
        isActiveMachine
          ? renderBackgroundComponent
            ? isFrameIndexOnNewFrame
              ? classes.activeMachineBackgroundContainer
              : classes.activeMachineBackgroundContainerEdit
            : isFrameIndexOnNewFrame
            ? classes.activeMachineContainer
            : classes.activeMachineContainerEdit
          : classes.inactiveMachineContainer
      }
    ></Box>
  );
};

export default MapEdit;
