import React, { useContext } from 'react';
import { isDesktop } from 'react-device-detect';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import StayPrimaryLandscapeTwoTone from '@material-ui/icons/StayPrimaryLandscapeTwoTone';
import StayPrimaryPortraitTwoTone from '@material-ui/icons/StayPrimaryPortraitTwoTone';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const FrameOrientationControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { containerOrientation },
      },
    },
    viewContext: {
      controllerMachine: {
        boardGrid: { containerOrientation: viewContainerOrientation },
      },
    },
  } = controllerState.context;

  /**
   * Only display manual frame orientation control on desktop device. For mobile
   * we have a useEffect in `src/containers/Board/index.js` that will automatically
   * set the containerOrientation to the mobile device orientation.
   */
  return isDesktop ? (
    <Help
      controlId='controllerMachineFrameOrientationControl'
      helpLabelValue={
        containerOrientation === 'landscape' ? 'Landscape' : 'Portrait'
      }
      placement='top'
    >
      <IconButton
        color={
          containerOrientation === viewContainerOrientation
            ? 'primary'
            : 'secondary'
        }
        size='small'
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: {
                containerOrientation:
                  containerOrientation === 'landscape'
                    ? 'portrait'
                    : 'landscape',
              },
            },
          });
        }}
      >
        {containerOrientation === 'landscape' ? (
          <StayPrimaryLandscapeTwoTone />
        ) : (
          <StayPrimaryPortraitTwoTone />
        )}
      </IconButton>
    </Help>
  ) : null;
};

export default FrameOrientationControl;
