import OndemandVideoOutlined from '@material-ui/icons/OndemandVideoOutlined';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';

export const VIDEO_ICON = OndemandVideoOutlined;

export const VIDEO_MACHINE_CONTEXT = {
  boardSettings: {
    sidebarTab: true,
  },
  urlField: {
    value: '',
  },
  toolbarDrawer: {
    drawerTab: 'url',
    isDrawerCollapsed: true,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const VIDEO_SHARED_CONTEXT = {
  videoField: {
    horizontalAlign: 'center',
    muted: false,
    played: 0,
    playing: false,
    url: '',
    verticalAlign: 'center',
  },
};

export const VIDEO_VERTICAL_ALIGN_OPTIONS = [
  { label: 'top', value: 'flex-start', Icon: VerticalAlignTopOutlined },
  { label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  { label: 'bottom', value: 'flex-end', Icon: VerticalAlignBottomOutlined },
];
