import React, { useContext, useEffect, useRef } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import extend from 'lodash/extend';

import { VIDEO_SHARED_CONTEXT } from './constants';
import { GlobalStateContext } from '../../globalState';
import { calculateVideoPlayerTarget } from '../../helpers';
import { videoStyles } from './styles';

const useStyles = makeStyles((theme) => videoStyles(theme));

const VideoView = () => {
  const videoFieldRef = useRef();
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    board: { frames },
    playControl: {
      paused: framesPaused,
      playing: framesPlaying,
      videoPlayerMode,
      videoPlayerMuted,
    },
    viewContext,
    viewContext: {
      videoMachine: { videoField: machineVideoField },
      controllerMachine: {
        boardGrid: { flexDirection },
      },
    },
    viewFrameIndex,
  } = controllerState.context;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const videoField = extend(VIDEO_SHARED_CONTEXT.videoField, machineVideoField);

  const { played, playing, url, verticalAlign } = videoField;

  /**
   * Move to playhead to initial position.
   */
  useEffect(() => {
    if (['ready', 'pause'].includes(videoPlayerMode) && played > 0) {
      videoFieldRef.current.seekTo(played);
    }
  }, [videoPlayerMode, played]);

  useEffect(
    () => () => {
      controllerSend({
        type: 'CONTROLLER_PROP_VALUES',
        payload: {
          property: 'playControl',
          values: {
            videoPlayerMode: 'idle',
            videoPlayerProgress: 0,
            videoPlayerTarget: 0,
          },
        },
      });
    },
    [controllerSend]
  );

  return (
    <Fade in={videoPlayerMode !== 'idle'}>
      <Box
        display='flex'
        flexDirection={flexDirection}
        style={{
          height: '100%',
          justifyContent: verticalAlign,
        }}
      >
        <div className={classes.reactPlayerWrapper}>
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                },
              },
            }}
            controls={false}
            height='100%'
            id='videoField'
            onReady={() =>
              controllerSend({
                type: 'CONTROLLER_PROP_VALUES',
                payload: {
                  property: 'playControl',
                  values: { videoPlayerMode: 'ready' },
                },
              })
            }
            onPause={() =>
              controllerSend({
                type: 'CONTROLLER_PROP_VALUES',
                payload: {
                  property: 'playControl',
                  values: { videoPlayerMode: 'pause' },
                },
              })
            }
            onProgress={({ playedSeconds }) => {
              if (['ready', 'play'].includes(videoPlayerMode)) {
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'playControl',
                    values: { videoPlayerProgress: playedSeconds },
                  },
                });
              }
            }}
            onPlay={() => {
              const videoPlayerTarget = calculateVideoPlayerTarget({
                currentContext: viewContext,
                currentFrameIndex: viewFrameIndex,
                frames,
              });
              controllerSend({
                type: 'CONTROLLER_PROP_VALUES',
                payload: {
                  property: 'playControl',
                  values: {
                    videoPlayerMode: 'play',
                    videoPlayerTarget,
                  },
                },
              });
            }}
            ref={videoFieldRef}
            muted={videoPlayerMuted}
            style={{
              /**
               * Use inline styling for Player as styling disappears
               * when switching themes.
               */
              position: 'absolute',
              top: 0,
              left: 0,
            }}
            playing={playing && framesPlaying && !framesPaused}
            progressInterval={250}
            url={url}
            width='100%'
          />
        </div>
      </Box>
    </Fade>
  );
};

export default VideoView;
