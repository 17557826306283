import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { GlobalStateContext } from '../../../globalState';

const UrlGrid = ({ handleImageAdd, imageUrl, isValidImageUrl }) => {
  const { controllerService } = useContext(GlobalStateContext);
  // eslint-disable-next-line no-unused-vars
  const [_, controllerSend] = useActor(controllerService);
  const theme = useTheme();

  const handleOnClick = () => {
    const alt = ``;
    const credit = {
      imageType: 'url',
      userName: '',
      userUrl: '',
    };
    handleImageAdd({ alt, credit, url: imageUrl });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values: { imageUrl: '', isValidImageUrl: false },
      },
    });
  };

  return (
    <>
      {isValidImageUrl ? (
        <img
          alt='from the web'
          src={imageUrl}
          onClick={handleOnClick}
          style={{
            maxHeight: '100%',
            maxWidth: `100%`,
          }}
        />
      ) : (
        <Box p={2}>
          <Typography
            variant='body1'
            paragraph
            style={{
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.text.backgroundContrastText,
              padding: theme.spacing(1),
            }}
          >
            Enter the URL of an image hosted on another website, for example:{' '}
            <i>http://www.example.com/image.gif</i>. Make sure you have
            permission to use the image. When the image appears in the drawer,
            click it to add it to the board.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UrlGrid;
