import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import SyncRounded from '@material-ui/icons/SyncRounded';
import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';
import isEqual from 'lodash/isEqual';

import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import { BOARD_MACHINES } from '../../Board/constants';

const useStyles = makeStyles((theme) => controlStyles(theme));

const FrameAutoSubmitControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editControl: { durationField },
    editContext,
    editFrameIndex,
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;

  const { canAutoCapture } = activeMachineId
    ? BOARD_MACHINES.find(({ value }) => value === activeMachineId)
    : {};
  const autoCapture = canAutoCapture
    ? machinesContext[activeMachineId].boardSettings.autoCapture
    : false;
  const isAddingNewFrame = editFrameIndex === frames.length;

  const hasContextChanged =
    !isEqual(viewContext, editContext) ||
    (frames[editFrameIndex] &&
      !isEqual(durationField.value, frames[editFrameIndex].duration));

  return !hasContextChanged && isAddingNewFrame ? (
    <Help
      controlId='controllerMachineCaptureSettingControl'
      helpLabelValue={autoCapture ? 'On' : 'Off'}
      isDisabled={!canAutoCapture}
      placement='top'
    >
      <Button
        className={classes.frameSliderButton}
        color={autoCapture ? 'secondary' : 'primary'}
        disabled={!canAutoCapture}
        onClick={() =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: activeMachineId,
              property: 'boardSettings',
              values: { autoCapture: !autoCapture },
            },
          })
        }
        size='small'
        style={{ marginRight: 4 }}
        variant='contained'
      >
        {autoCapture ? (
          showControlLabels ? (
            'AUTO SAVE'
          ) : (
            <SyncRounded />
          )
        ) : showControlLabels ? (
          'AUTO SAVE'
        ) : (
          <SyncDisabledRounded />
        )}
      </Button>
    </Help>
  ) : null;
};

export default FrameAutoSubmitControl;
