import home from './home';
import howToPlayQueensGambit from './how-to-play-queens-gambit';
import pageNotFound from './page-not-found';
import sectionTutorials from './section-tutorials';

export const BOARDS_DICTIONARY = {
  home,
  'how-to-play-queens-gambit': howToPlayQueensGambit,
  'page-not-found': pageNotFound,
  'section-tutorials': sectionTutorials,
};
