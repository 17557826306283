import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import extend from 'lodash/extend';

import { IMAGE_SHARED_CONTEXT } from './constants';
import { imageStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import { globalStyles } from '../../themes/globalStyles';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...imageStyles(theme),
}));

const ImageView = ({ gridTabsSectionSize, renderBackgroundComponent }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();

  const {
    viewContext: {
      imageMachine: { imageField: machineImageField },
    },
  } = controllerState.context;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const imageField = extend(IMAGE_SHARED_CONTEXT.imageField, machineImageField);

  const {
    alt,
    backgroundObjectFit,
    backgroundObjectHorizontalPosition,
    backgroundObjectVerticalPosition,
    backgroundUrl,
    objectFit,
    objectHorizontalPosition,
    objectVerticalPosition,
    title,
    url,
  } = imageField;

  return (
    <>
      {renderBackgroundComponent ? (
        !!backgroundUrl && (
          <Box
            className={classes.backgroundImage}
            component='img'
            onError={(error) =>
              console.log(
                'ImageView renderBackgroundComponent error: ',
                error,
                {
                  backgroundUrl,
                }
              )
            }
            src={backgroundUrl}
            style={{
              objectFit: backgroundObjectFit,
              objectPosition: `${backgroundObjectHorizontalPosition}% ${
                100 - backgroundObjectVerticalPosition
              }%`,
            }}
          />
        )
      ) : !!url ? (
        <Box height='100%'>
          <Box
            key={url}
            alt={alt}
            className={classes.inactiveMachineContainer}
            component='img'
            src={url}
            sx={{
              height: '100%',
              objectFit: objectFit,
              objectPosition: `${objectHorizontalPosition}% ${
                100 - objectVerticalPosition
              }%`,
              width: '100%;',
            }}
            title={title}
          />
        </Box>
      ) : null}
    </>
  );
};

export default ImageView;
