export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const createFrame = /* GraphQL */ `
  mutation CreateFrame(
    $input: CreateFrameInput!
    $condition: ModelFrameConditionInput
  ) {
    createFrame(input: $input, condition: $condition) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;

export const updateFrame = /* GraphQL */ `
  mutation UpdateFrame(
    $input: UpdateFrameInput!
    $condition: ModelFrameConditionInput
  ) {
    updateFrame(input: $input, condition: $condition) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;

export const deleteFrame = /* GraphQL */ `
  mutation DeleteFrame(
    $input: DeleteFrameInput!
    $condition: ModelFrameConditionInput
  ) {
    deleteFrame(input: $input, condition: $condition) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;
