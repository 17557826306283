import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddToPhotosRounded from '@material-ui/icons/AddToPhotosRounded';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRounded from '@material-ui/icons/ArrowForwardIosRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import EditRounded from '@material-ui/icons/EditRounded';
import GroupAddRounded from '@material-ui/icons/GroupAddRounded';
import IconButton from '@material-ui/core/IconButton';
import SaveRounded from '@material-ui/icons/SaveRounded';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityRounded from '@material-ui/icons/VisibilityRounded';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-root': {
      border: 'none',
    },
    '& .Mui-selected td': {
      backgroundColor: theme.palette.primary[theme.palette.type],
    },
    '& .MuiTableRow-root td:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '& .MuiTableRow-root td:last-child': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
      paddingLeft: 0,
    },
  },
  tableRowData: {
    backgroundColor: theme.palette.default[theme.palette.type],
    cursor: 'pointer',
  },
}));

const BoardHistoryTable = ({
  dataItems,
  handleDeleteBoard,
  handleEditBoard,
  handleViewBoard,
}) => {
  const [rowsPerPage] = useState(5);
  const [selectedSession, setSelectedSession] = useState({});
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const numberOfPages = Math.ceil(dataItems.length / rowsPerPage);

  return !!dataItems.length ? (
    <Table
      className={classes.table}
      size='small'
      aria-label='drawer'
      style={{ tableLayout: 'fixed' }}
    >
      <TableBody>
        {dataItems
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(
            (
              {
                sessionId,
                sessionName,
                sessionMode,
                sessionRole,
                sessionUserName,
              },
              index
            ) => (
              <Fragment key={sessionId}>
                <Box
                  className={classes.tableRowData}
                  component={TableRow}
                  onClick={() =>
                    setSelectedSession({
                      collapseIn:
                        selectedSession.sessionId === sessionId
                          ? !selectedSession.collapseIn
                          : true,
                      sessionId,
                      sessionMode,
                      sessionRole,
                    })
                  }
                >
                  <TableCell padding='checkbox'>
                    {sessionMode === 'record' ? (
                      <SaveRounded color='disabled' fontSize='small' />
                    ) : (
                      <GroupAddRounded color='disabled' fontSize='small' />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <b>{sessionName || 'Unnamed board'}</b> by{' '}
                      {sessionUserName || 'you'}
                    </Typography>
                  </TableCell>
                  <TableCell padding='checkbox'>
                    {sessionMode === 'record' ? (
                      <EditRounded
                        color='disabled'
                        fontSize='small'
                        style={{ paddingLeft: 0 }}
                      />
                    ) : sessionRole === 'edit' ? (
                      <AddToPhotosRounded
                        color='disabled'
                        fontSize='small'
                        style={{ paddingLeft: 0 }}
                      />
                    ) : (
                      <VisibilityRounded
                        color='disabled'
                        fontSize='small'
                        style={{ paddingLeft: 0 }}
                      />
                    )}
                  </TableCell>
                </Box>
                <Box component={TableRow} padding='checkbox'>
                  <TableCell colSpan={3} align='center'>
                    <Collapse
                      in={
                        selectedSession.sessionId === sessionId &&
                        selectedSession.collapseIn
                      }
                      component={Box}
                    >
                      <Button
                        aria-label={`view`}
                        color='primary'
                        onClick={() =>
                          handleViewBoard({
                            sessionId: selectedSession.sessionId,
                          })
                        }
                        size='small'
                        variant='contained'
                      >
                        Play
                      </Button>
                      <Button
                        aria-label={`delete`}
                        color='primary'
                        onClick={() =>
                          handleEditBoard({
                            sessionId: selectedSession.sessionId,
                          })
                        }
                        size='small'
                        variant='contained'
                      >
                        {selectedSession.sessionMode === 'stream'
                          ? 'Stream'
                          : selectedSession.sessionRole === 'edit'
                          ? 'Record'
                          : 'Clone'}
                      </Button>
                      <Button
                        aria-label={`delete`}
                        color='secondary'
                        // disabled={sessionId === selectedSession.sessionId}
                        onClick={() =>
                          handleDeleteBoard({ ...selectedSession })
                        }
                        size='small'
                        variant='contained'
                      >
                        DELETE
                      </Button>
                    </Collapse>
                  </TableCell>
                </Box>
              </Fragment>
            )
          )}
        {numberOfPages > 1 && (
          <TableRow>
            <TableCell align='center' colSpan={3}>
              <IconButton
                color='primary'
                disabled={page === 0}
                fontSize='small'
                onClick={() => setPage((page) => page - 1)}
              >
                <ArrowBackIosRounded />
              </IconButton>
              Page {page + 1} of {numberOfPages}
              <IconButton
                color='primary'
                disabled={page === numberOfPages - 1}
                fontSize='small'
                onClick={() => setPage(page + 1)}
              >
                <ArrowForwardIosRounded />
              </IconButton>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : null;
};

export default BoardHistoryTable;
