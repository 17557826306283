import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import ViewAgendaTwoTone from '@material-ui/icons/ViewAgendaTwoTone';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const PanelsOrientationControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { containerDirection },
      },
    },
    viewContext: {
      controllerMachine: {
        boardGrid: { containerDirection: viewContainerDirection },
      },
    },
  } = controllerState.context;

  return (
    <Help
      controlId='controllerMachinePanelsOrientationControl'
      helpLabelValue={containerDirection === 'row' ? 'Inline' : 'Stacked'}
      placement='top'
    >
      <IconButton
        color={
          containerDirection === viewContainerDirection
            ? 'primary'
            : 'secondary'
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: {
                containerDirection:
                  containerDirection === 'row' ? 'column' : 'row',
              },
            },
          });
        }}
        size='small'
        style={{
          transform:
            containerDirection === 'row' ? 'rotate(90deg)' : 'rotate(0deg)',
        }}
      >
        <ViewAgendaTwoTone />
      </IconButton>
    </Help>
  );
};

export default PanelsOrientationControl;
