import moment from 'moment';
// import { CHATGPT_PROMPTS } from '../assets/prompts';
import upperCase from 'lodash/upperCase';

// export const formatCodeChatGptPrompt = ({ chatGptAsk, language, value }) => {
//   return `You are an AI trained in various programming languages. Please write the code in ${language} code. Your current task is to ${chatGptAsk}.`;
// };

// export const formatChatGptAskPrompt = ({
//   act,
//   chatGptAsk,
//   language,
//   machineId,
//   value,
// }) => {
//   const prompt = CHATGPT_PROMPTS.find(({ label }) => label === act);
//   switch (machineId) {
//     case 'sidenoteMachine':
//       return `I want you to reply in language "${language}". I want you to keep your reply neat, writing it as a sidenote and limiting the reply to 50 words. ${prompt}. For your response use the following ask, task, list of keywords, etc: "${chatGptAsk}"`;
//     case 'textMachine':
//       return `Provide an HTML-formatted response. Wrap text in bold tags, italic tags, underline tags or paragraph tags when appropriate or asked for. Do not include any css styling. Text reply is written in the language "${language}". I want you to keep your text reply neat, writing it as a blurb and limiting the text to 100 words. ${prompt}. For your response use the following ask, task, list of keywords, etc: "${chatGptAsk}"`;
//     default:
//       return `I want you to reply in language "${language}". I want you to keep your reply neat, writing it as a blurb and limiting the reply to 100 words. ${prompt}. For your response use the following ask, task, list of keywords, etc: "${chatGptAsk}"`;
//   }
// };

export const formatDate = (date) => moment(date).format('MM/DD/YYYY');

export const formatHelpLabelWithValue = ({ helpLabel, value }) =>
  `${helpLabel}: ${upperCase(value)}`;
