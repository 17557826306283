import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';

import { VIDEO_VERTICAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarVerticalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      videoMachine: { videoField },
    },
    machinesContext: {
      videoMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      videoMachine: { videoField: viewVideoField },
    },
  } = controllerState.context;

  const verticalAlignOptionIndex = VIDEO_VERTICAL_ALIGN_OPTIONS.findIndex(
    ({ value }) => value === videoField.verticalAlign
  );
  const { Icon: ImageObjectAlignIcon, label: helpLabelValue } =
    VIDEO_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex];

  return (
    <Help
      controlId='videoMachineToolbarVerticalAlignControl'
      isDisabled={!videoField.url}
      placement='top'
      helpLabelValue={helpLabelValue}
    >
      <IconButton
        color={
          viewVideoField.verticalAlign !== videoField.verticalAlign ||
          autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={!videoField.url}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'videoField',
              values: {
                verticalAlign:
                  verticalAlignOptionIndex ===
                  VIDEO_VERTICAL_ALIGN_OPTIONS.length - 1
                    ? VIDEO_VERTICAL_ALIGN_OPTIONS[0].value
                    : VIDEO_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex + 1]
                        .value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarVerticalAlign;
