import { convertHexToHexWithAlpha } from '../../../helpers';

export const drawerStyles = (theme) => ({
  drawerPaper: {
    backgroundColor: convertHexToHexWithAlpha({
      hex: theme.palette.background.default,
      alpha: 0.8,
    }),
    // borderTop: `6px solid ${theme.palette.success[theme.palette.type]}`,
    // marginBottom: 6,
  },
});
