import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import Fade from '@material-ui/core/Fade';

import { BOARD_MACHINES } from '../constants';
import { useWindowProperties } from '../../../helpers';
import { boardStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => boardStyles(theme));

const PanelBackground = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();
  const windowProperties = useWindowProperties();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const inViewMode =
    sessionState.matches('record.view') || sessionState.matches('stream.view');
  const {
    board: { frames },
    editContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;
  const { activeTabMachineId, backgroundMachineId, containerOrientation } =
    inEditMode
      ? editContext.controllerMachine.boardGrid
      : viewContext.controllerMachine.boardGrid;

  const { Component: BackgroundMachine } = backgroundMachineId
    ? BOARD_MACHINES.find((machine) => machine.value === backgroundMachineId)
    : {};

  const showDefaultBackgroundImage =
    !inViewMode &&
    !backgroundMachineId &&
    !activeMachineId &&
    frames.length <= 1;

  /**
   * When displaying the default background image, the orientation is determined
   * by the device orientation.
   */
  const orientation = showDefaultBackgroundImage
    ? windowProperties.deviceOrientation
    : containerOrientation;

  return (
    <Container
      className={classes.backgroundContainer}
      disableGutters
      maxWidth={false}
      style={{
        height: windowProperties[orientation].height,
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: windowProperties[orientation].width,
        zIndex:
          ['videoMachine'].includes(backgroundMachineId) &&
          backgroundMachineId === activeTabMachineId &&
          backgroundMachineId === activeMachineId
            ? 1225
            : 'initial',
      }}
    >
      {backgroundMachineId && (
        <BackgroundMachine
          renderBackgroundComponent={true}
          isTabSectionHidden={false}
        />
      )}
      {/* <Fade in={showDefaultBackgroundImage}>
        <Box
          component='img'
          height='100%'
          width='100%'
          style={{
            display: 'block',
            margin: 'auto',
            objectFit: orientation === 'portrait' ? 'cover' : 'contain',
          }}
          src='https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNW50bGZxaDRtMWdibXg1dHJ4bGxoZXJuaW5veWZyNW4zczhiMXhmZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/jZxeUsLnXYdO0/giphy.gif'
        />
      </Fade> */}
    </Container>
  );
};

export default PanelBackground;
