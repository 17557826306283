import React, { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Highlight from 'react-highlight';

import { globalStyles } from '../../themes/globalStyles';
import { codeStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import Help from '../Help';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...codeStyles(theme),
}));

const CodeEdit = ({ gridTabsSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    editContext: {
      codeMachine: { codeField },
      controllerMachine: {
        boardGrid: { containerDirectionReverse, containerDirection },
      },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'codeMachine';

  useEffect(() => {
    var head = document.head;
    var link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.12.0/styles/${codeField.themeHighlight}`;
    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, [codeField]);

  return !codeField.value && !isActiveMachine ? (
    <HelpPanel
      isHidden={!!activeMachineId}
      panelId='mainPanel'
      panelProperties={gridTabsSectionSize}
    />
  ) : (
    <Help
      controlId='codeMachineCodeFieldComponent'
      placement={
        containerDirection === 'row'
          ? !containerDirectionReverse
            ? 'left'
            : 'right'
          : !containerDirectionReverse
          ? 'top'
          : 'bottom'
      }
    >
      <Box
        className={
          isActiveMachine
            ? isFrameIndexOnNewFrame
              ? classes.activeMachineContainer
              : classes.activeMachineContainerEdit
            : classes.inactiveMachineContainer
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: { activeMachineId: 'codeMachine' },
            },
          });
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'codeMachine',
              property: 'toolbarDrawer',
              values: { drawerTab: 'editor', isDrawerCollapsed: true },
            },
          });
        }}
        sx={{
          height: gridTabsSectionSize.height,
        }}
      >
        <Highlight language='javascript' className={classes.highlight}>
          {codeField.value}
        </Highlight>
      </Box>
    </Help>
  );
};

export default CodeEdit;
