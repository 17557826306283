import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import { Chess } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import Box from '@material-ui/core/Box';

import { globalStyles } from '../../themes/globalStyles';
import { chessStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import Help from '../Help';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...chessStyles(theme),
}));

const ChessEdit = ({ gridTabsSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [chessGame] = useState(new Chess());
  const chessFieldRef = useRef();
  const boxRef = useRef();
  const [boardWidth, setBoardWidth] = useState(0);
  const classes = useStyles();

  const {
    editContext: {
      controllerMachine: {
        boardGrid: {
          containerDirection,
          containerDirectionReverse,
          containerOrientation,
          itemBreakpoint,
        },
      },
      chessMachine: {
        chessField: { boardOrientation, horizontalAlign, value, verticalAlign },
      },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      chessMachine: {
        boardSettings: { autoCapture },
      },
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'chessMachine';

  useLayoutEffect(() => {
    const width = Math.min(
      gridTabsSectionSize.width,
      gridTabsSectionSize.height
    );
    setBoardWidth(width);
  }, [
    containerOrientation,
    gridTabsSectionSize.height,
    gridTabsSectionSize.width,
    itemBreakpoint,
  ]);

  const handleChangeChessField = (value) => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'chessMachine',
        property: 'chessField',
        values: { value },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  const handleFocusChessField = () => {
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: { activeMachineId: 'chessMachine' },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'chessMachine',
        property: 'toolbarDrawer',
        values: { drawerTab: 'position', isDrawerCollapsed: true },
      },
    });
  };

  function onChangeChessField(sourceSquare, targetSquare) {
    var move = {
      from: sourceSquare,
      to: targetSquare,
      // promotion: 'q',
    };
    const validMove = chessGame.move(move);
    if (validMove) {
      const value = chessGame.fen();
      handleChangeChessField(value);
      // handleChangePositionField(value);
    }
    return !!validMove;
  }

  return (
    <Help
      controlId='chessMachineChessFieldComponent'
      placement={
        containerDirection === 'row'
          ? !containerDirectionReverse
            ? 'left'
            : 'right'
          : !containerDirectionReverse
          ? 'top'
          : 'bottom'
      }
    >
      <Box
        className={
          isActiveMachine
            ? isFrameIndexOnNewFrame
              ? classes.activeMachineContainer
              : classes.activeMachineContainerEdit
            : classes.inactiveMachineContainer
        }
        height={gridTabsSectionSize.height}
        ref={boxRef}
        onClick={handleFocusChessField}
        style={{
          display: 'flex',
          flexDirection:
            containerOrientation === 'landscape' ? 'row' : 'column',
          ...(containerOrientation === 'landscape'
            ? { alignItems: verticalAlign }
            : { justifyContent: verticalAlign }),
          ...(containerOrientation === 'landscape'
            ? { justifyContent: horizontalAlign }
            : { alignItems: horizontalAlign }),
        }}
      >
        <Chessboard
          boardWidth={boardWidth}
          boardOrientation={boardOrientation}
          // customBoardStyle={{ position: 'absolute' }}
          id='chessField'
          onPieceDrop={onChangeChessField}
          onSquareClick={handleFocusChessField}
          position={value}
          ref={chessFieldRef}
        />
      </Box>
    </Help>
  );
};

export default ChessEdit;
