import React, { useContext } from 'react';
import T from 'prop-types';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CancelRounded from '@material-ui/icons/CancelRounded';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import { green } from '@material-ui/core/colors';

import helpTopicsByRole from '../Topics';
import { GlobalStateContext } from '../../../globalState';
import { tooltipStyles } from './styles';

const useStyles = makeStyles((theme) => tooltipStyles(theme));

const RelatedTooltip = ({ controlId, children, isDisabled, ...restProps }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    board: { mode, role },
    helpControl: {
      helpTopicIndex,
      helpTopicId,
      openRelatedControlIds,
      minimizedControlIds,
    },
  } = controllerState.context;

  const { topicDictionary, topicSteps } =
    helpTopicsByRole[role][mode][helpTopicId] || {};
  const topicId = topicSteps[helpTopicIndex];
  const { helpLabel: relatedHelpLabel } = topicDictionary[topicId];
  const isRelatedHelpId =
    topicDictionary[topicSteps[helpTopicIndex]].relatedControlIds.includes(
      controlId
    );

  const { helpLabel, helpText } = isRelatedHelpId
    ? topicDictionary[controlId] || {}
    : topicDictionary[topicSteps[helpTopicIndex]] || {};

  /**
   * When a Tooltip's children prop contains a disabled component it needs to be
   * wrapped in an element to prevent Mui browser warnings
   * See {isDisabled ? <div>{children}</div> : children} below
   */
  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.relatedTooltip }}
      interactive
      open
      title={
        <Box m={0.5}>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <IconButton
              onClick={() => {
                minimizedControlIds.has(controlId)
                  ? minimizedControlIds.delete(controlId)
                  : minimizedControlIds.add(controlId);

                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'helpControl',
                    values: {
                      minimizedControlIds,
                    },
                  },
                });
              }}
              size='small'
            >
              {!minimizedControlIds.has(controlId) ? (
                <RemoveCircle fontSize='small' style={{ color: green[500] }} />
              ) : (
                <AddCircle fontSize='small' style={{ color: green[500] }} />
              )}
            </IconButton>
            <Typography variant='sidenote'>
              {relatedHelpLabel.toUpperCase()}
            </Typography>
            <IconButton
              color='secondary'
              onClick={() => {
                openRelatedControlIds.delete(controlId);
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'helpControl',
                    values: {
                      openRelatedControlIds,
                    },
                  },
                });
              }}
              size='small'
            >
              <CancelRounded fontSize='small' />
            </IconButton>
          </Box>
          <Typography align='center' color='inherit'>
            {helpLabel}
          </Typography>
          {!minimizedControlIds.has(controlId) &&
            helpText.map((text, index) => (
              <p key={index} color='inherit'>
                {text}
              </p>
            ))}
        </Box>
      }
      {...restProps}
    >
      {isDisabled ? <div>{children}</div> : children}
    </Tooltip>
  );
};

RelatedTooltip.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default RelatedTooltip;
