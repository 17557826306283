import { alpha } from '@material-ui/core/styles';

export const controlStyles = (theme) => ({
  appBarControl: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  audioMuteButton: {
    minWidth: 0,
    opacity: 0.7,
    zIndex: 1275,
  },
  boardSettingsButton: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    margin: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    minWidth: 0,
    zIndex: 1275,

    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.success[theme.palette.type],
    },
  },
  buttonGroup: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  controllerMachineButton: {
    margin: theme.spacing(0.5),
    minWidth: 0,
    zIndex: 1275,
  },
  creditsPaper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: theme.spacing(0.5),
  },
  panelSlider: {
    lineHeight: 0,
    zIndex: 1275,
    '& .MuiSlider-mark': {
      backgroundColor: theme.palette.text.primary,
    },
    '& .MuiSlider-thumb': {
      borderColor: theme.palette.text.primary,
      borderStyle: 'solid',
      borderWidth: 1,
    },
    '& .MuiSlider-rail': {
      height: 0,
    },
    '&.MuiSlider-root.MuiSlider-vertical': {
      overflow: 'hidden',
    },
  },
  deviceOrientationControl: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
    minWidth: 0,
    opacity: 0.8,
  },
  deviceOrientationIcon: {
    lineHeight: 1,
  },
  deviceOrientationIconAnimate: {
    animationName: '$spin',
    animationDirection: 'normal',
    animationDuration: '3s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    zIndex: 1250,
  },
  '@keyframes spin': {
    '10%': {
      color: theme.palette.secondary[theme.palette.type],
      transform: 'rotate(0deg)',
    },
    '50%': {
      color: theme.palette.primary[theme.palette.type],
      transform: 'rotate(-90deg)',
    },
    '100%': {
      color: theme.palette.primary[theme.palette.type],
      transform: 'rotate(-90deg)',
    },
  },
  frameControlCheckbox: {
    color: theme.palette.text.primary,
  },
  groupButton: {
    padding: '3px 6px',
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
  },
  groupButtonToggle: {
    padding: '3px 6px',
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
  },
  frameIndex: {
    minWidth: theme.spacing(14),
    width: theme.spacing(14),
    '& .MuiInputBase-input': {
      fontFamily: 'monospace',
      textAlign: 'center',
      width: theme.spacing(4.5),
      minWidth: theme.spacing(4.5),
    },
  },
  frameOrientation: {
    maxWidth: theme.spacing(15),
    minWidth: 0,
    '& .MuiInputBase-input': {
      fontSize: '1rem',
    },
  },
  frameSliderContainer: {
    display: 'flex',
    // height: theme.spacing(4),
    marginTop: theme.spacing(0.5),
    // Negative margin to left/right flush buttons with toolbars controls
    marginLeft: -theme.spacing(0.5),
    marginRight: -theme.spacing(0.5),
  },
  frameSliderAdd: {
    backgroundColor: theme.palette.success[theme.palette.type],
    minWidth: 0,
    padding: 6,
    '&:hover': {
      backgroundColor: theme.palette.success[theme.palette.type],
    },
  },
  framePlayheadSlider: {
    alignSelf: 'flex-end',
    flex: 1,
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    maxHeight: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2.5),
    '& .MuiSlider-valueLabel': {
      left: 'unset',
      transform: 'scale(0.7) translateY(4px)!important',
      zIndex: -1,
      '& *': {
        background: 'transparent',
        borderRadius: 4,
        height: 'auto',
        padding: 2,
        transform: 'rotate(0)',
      },
    },
    '& .MuiSlider-mark': {
      height: 3,
      width: 3,
    },
    '& .MuiSlider-markActive': {
      backgroundColor: theme.palette.text.backgroundContrastText,
    },
    '& .MuiSlider-rail': {
      height: 0,
    },
    '& .MuiSlider-track': {
      height: 0,
    },
    '& .MuiSlider-thumbColorSecondary.Mui-disabled': {
      backgroundColor: theme.palette.secondary[theme.palette.type],
      color: theme.palette.secondary[theme.palette.type],
    },
  },
  frameButton: {
    minWidth: 0,
    opacity: 0.7,
    padding: 6,
  },
  mainMenuButton: {
    height: '100%',
    marginTop: theme.spacing(0.5),
    minWidth: 0,
    opacity: 0.7,
  },
  iconButton: {
    minWidth: theme.spacing(3),
    zIndex: 1500,
  },
  playControlIconButton: {
    fontSize: 60,
  },
  sidebarMenuButton: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(0.5),
    minWidth: 0,
    zIndex: 1275,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.success[theme.palette.type],
    },
  },
  sidebarMenuButtonActive: {
    backgroundColor: theme.palette.success[theme.palette.type],
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5),
    minWidth: 0,
    zIndex: 1275,
    '&:hover': {
      backgroundColor: theme.palette.success[theme.palette.type],
      color: theme.palette.text.primary,
    },
  },
  swapButton: {
    minWidth: 0,
  },
});
