import {
  convertCompressedStringToObject,
  convertCompressedStringToString,
} from './convert';

export const getMachinesContextFromLocalStorage = ({ sessionId }) => {
  const compressedString = localStorage.getItem(`MachinesContext:${sessionId}`);
  if (compressedString) {
    return convertCompressedStringToString({
      compressedString,
    });
  }
  return '';
};

export const getRecordingFromLocalStorage = ({ sessionId }) => {
  const compressedString = localStorage.getItem(`Recording:${sessionId}`);
  if (compressedString) {
    const { board, session, user } = convertCompressedStringToObject({
      compressedString,
    });

    return { board, session, user };
  }
  return {};
};

export const getStreamFromLocalStorage = ({ sessionId }) => {
  const compressedString = localStorage.getItem(`Stream:${sessionId}`);
  if (compressedString) {
    const { session } = convertCompressedStringToObject({
      compressedString,
    });

    return { session };
  }
  return {};
};

export const getSessionIdFromStorageId = ({ storageId }) => {
  return storageId.split(':')[1];
};
