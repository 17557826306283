import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';

import { IMAGE_OBJECT_FIT_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarFitButton = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      imageMachine: { imageField },
    },
    machinesContext: {
      imageMachine: {
        boardSettings: { autoCapture },
        imageCollection: { collectionItems },
        imageField: { isBackgroundSelected },
      },
    },
    viewContext: {
      imageMachine: { imageField: viewImageField },
    },
  } = controllerState.context;

  const { Icon: ImageObjectFitIcon, label: labelForeground } =
    IMAGE_OBJECT_FIT_OPTIONS.find(
      ({ value }) => value === imageField.objectFit
    );
  const { Icon: ImageBackgroundObjectFitIcon, label: labelBackground } =
    IMAGE_OBJECT_FIT_OPTIONS.find(
      ({ value }) => value === imageField.backgroundObjectFit
    );

  return (
    <Help
      controlId='imageMachineToolbarFitControl'
      helpLabelValue={isBackgroundSelected ? labelBackground : labelForeground}
      isDisabled={!collectionItems.length}
      placement='top'
    >
      <IconButton
        color={
          isBackgroundSelected
            ? viewImageField.backgroundObjectFit !==
                imageField.backgroundObjectFit || autoCapture
              ? 'secondary'
              : 'primary'
            : viewImageField.objectFit !== imageField.objectFit || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={!collectionItems.length}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'imageMachine',
              property: 'imageField',
              values: isBackgroundSelected
                ? {
                    backgroundObjectFit:
                      imageField.backgroundObjectFit === 'cover'
                        ? 'contain'
                        : 'cover',
                  }
                : {
                    objectFit:
                      imageField.objectFit === 'cover' ? 'contain' : 'cover',
                  },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        {isBackgroundSelected ? (
          <ImageBackgroundObjectFitIcon />
        ) : (
          <ImageObjectFitIcon />
        )}
      </IconButton>
    </Help>
  );
};

export default ToolbarFitButton;
