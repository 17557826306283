import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Textfit } from 'react-textfit';
import { useActor } from '@xstate/react';
import extend from 'lodash/extend';
import { slateToHtml } from '@slate-serializers/html';

import { TEXT_SHARED_CONTEXT } from './constants';
import { GlobalStateContext } from '../../globalState';
import { convertHexToRGBWithAlpha, useWindowProperties } from '../../helpers';
import { textStyles } from './styles';

const useStyles = makeStyles((theme) => textStyles(theme));

const TextView = ({ gridTabsSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const { deviceOrientation } = useWindowProperties();
  const classes = useStyles();
  const theme = useTheme();

  const {
    viewContext: {
      textMachine: { textField: machineTextField },
    },
  } = controllerState.context;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const textField = extend(TEXT_SHARED_CONTEXT.textField, machineTextField);

  const {
    backgroundOpacity,
    editorState,
    fontFamily,
    fontSizeMax,
    fontSizeMin,
    horizontalAlign,
    verticalAlign,
  } = textField;

  const textHTML = slateToHtml(editorState);

  return textHTML !== '<p></p>' ? (
    <Textfit
      className={classes.textField}
      max={fontSizeMax}
      min={fontSizeMin}
      mode='multi'
      style={{
        display: 'flex',
        flexDirection: deviceOrientation === 'landscape' ? 'column' : 'row',
        fontFamily,
        height: gridTabsSectionSize.height,
        ...(deviceOrientation === 'landscape'
          ? { justifyContent: verticalAlign }
          : { alignItems: verticalAlign }),
        textAlign: horizontalAlign,
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: textHTML,
        }}
        style={{
          backgroundColor: convertHexToRGBWithAlpha({
            alpha: backgroundOpacity,
            hex: theme.palette.background.paper,
          }),
        }}
      ></span>
    </Textfit>
  ) : null;
};

export default TextView;
