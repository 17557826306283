// import { convertHexToRGBWithAlpha } from '../../../helpers';

export const boardStyles = (theme) => ({
  backgroundContainer: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    zIndex: -100,
  },
  boardContainer: {
    height: '100vh',
    position: 'relative',
  },
  bodyContainer: {
    height: '100%',
  },
  bodyContainerGrid: {
    display: 'flex',
    height: '100%',
  },
  gridPanelSlider: {
    lineHeight: 0,
    position: 'absolute',
    zIndex: 1250,
  },
  gridItemSidebar: {
    // alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  orientationContainer: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});
