import React, { useContext, useEffect } from 'react';
import T from 'prop-types';
import { useActor } from '@xstate/react';

import { MainTooltip, RelatedTooltip, StandardTooltip } from './ToolTips';
import { GlobalStateContext } from '../../globalState';

const Help = ({ controlId, ...restProps }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    helpControl: {
      isHelpActive,
      openControlIds,
      openRelatedControlIds,
      renderedControlIds,
    },
  } = controllerState.context;

  useEffect(() => {
    /**
     * Tracking the rendered controlId's enables us to enable/disable the
     * next step buttons to assure the control for that step is available.
     * See HelpMenu control for more information.
     */
    if (isHelpActive && !renderedControlIds.has(controlId)) {
      renderedControlIds.add(controlId);
      controllerSend({
        type: 'CONTROLLER_PROP_VALUES',
        payload: {
          property: 'helpControl',
          values: {
            renderedControlIds,
          },
        },
      });
    }
  }, [
    renderedControlIds,
    controlId,
    controllerSend,
    isHelpActive,
    openControlIds,
    openRelatedControlIds,
  ]);

  return isHelpActive && openControlIds.has(controlId) ? (
    <MainTooltip controlId={controlId} {...restProps} />
  ) : isHelpActive && openRelatedControlIds.has(controlId) ? (
    <RelatedTooltip controlId={controlId} {...restProps} />
  ) : (
    <StandardTooltip controlId={controlId} {...restProps} />
  );
};

Help.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Help;
