import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';

import { GlobalStateContext } from '../../../../globalState';
import ControlsMain from '../ControlsMain';
import ControlsTabs from '../ControlsTabs';
import MenuOptions from '../MenuOption';

const SidebarLandscapeLeft = ({ handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const { editContext, viewContext } = controllerState.context;
  const { containerDirectionReverse } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  return !containerDirectionReverse ? (
    !inEditMode ? (
      <Box
        id='sidebarLandscapeLeftTopView'
        display='flex'
        flex={1}
        flexDirection='column'
      >
        <MenuOptions isLeftSidebar />
      </Box>
    ) : (
      <>
        <Box
          id='sidebarLandscapeLeftTopEdit'
          display='flex'
          flexDirection='column'
        >
          <MenuOptions isLeftSidebar />
        </Box>
        <Box
          id='sidebarLandscapeLeftCenterEdit'
          display='flex'
          flex={1}
          flexDirection='column'
          justifyContent='center'
        >
          <ControlsMain
            handleActiveMachineChange={handleActiveMachineChange}
            isLeftSidebar
          />
        </Box>
      </>
    )
  ) : !inEditMode ? (
    <Box
      id='sidebarLandscapeLeftTopReverseView'
      display='flex'
      flex={1}
      flexDirection='column'
    >
      <MenuOptions isLeftSidebar />
    </Box>
  ) : (
    <Box
      id='sidebarLandscapeLeftTopReverseEdit'
      display='flex'
      flex={1}
      flexDirection='column'
    >
      <Box>
        <MenuOptions />
      </Box>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='center'
      >
        <ControlsTabs
          handleActiveMachineChange={handleActiveMachineChange}
          isLeftSidebar
        />
      </Box>
    </Box>
  );
};

export default SidebarLandscapeLeft;
