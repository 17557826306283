import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ZoomInOutlined from '@material-ui/icons/ZoomInOutlined';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';

import {
  MAP_ZOOM_MAX,
  MAP_ZOOM_MIN,
  MAP_ZOOM_STEP,
  MAP_POV_ZOOM_MAX,
  MAP_POV_ZOOM_MIN,
  MAP_POV_ZOOM_STEP,
} from '../../../containers/Map/constants';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarZoomControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      mapMachine: { mapField: viewMapField },
    },
  } = controllerState.context;

  const isStreetView = mapField.mapTypeId === 'streetview';

  const mapFieldPovZoomPercentage = Math.round(
    (mapField.pov.zoom / MAP_POV_ZOOM_MAX) * 100
  );
  const mapFieldZoomPercentage = Math.round(
    ((mapField.zoom - MAP_ZOOM_MIN) / (MAP_ZOOM_MAX - MAP_ZOOM_MIN)) * 100
  );

  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='mapMachineToolbarZoomControl'
          helpLabelValue={
            isStreetView ? mapFieldPovZoomPercentage : mapFieldZoomPercentage
          }
          isDisabled={!hasLocation}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='map zoom'
            color={
              viewMapField.zoom !== mapField.zoom ||
              viewMapField.pov.zoom !== mapField.pov.zoom ||
              autoCapture
                ? 'secondary'
                : 'primary'
            }
            disabled={!hasLocation}
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
          >
            <ZoomInOutlined />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='sidenoteMachineToolbarFontSizeControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          {isStreetView ? (
            <Slider
              className={classes.machineToolbarSlider}
              color={
                viewMapField.pov.zoom !== mapField.pov.zoom || autoCapture
                  ? 'secondary'
                  : 'primary'
              }
              onChange={(event, value) => {
                controllerSend({
                  type: 'UPDATE_EDIT_CONTEXT',
                  payload: {
                    autoCapture: false,
                    machineId: 'mapMachine',
                    property: 'mapField',
                    values: {
                      pov: {
                        ...mapField.pov,
                        zoom: value,
                      },
                    },
                  },
                });
                if (autoCapture) {
                  controllerSend({
                    type: 'SUBMIT_FRAME_CAPTURE',
                  });
                }
              }}
              min={MAP_POV_ZOOM_MIN}
              max={MAP_POV_ZOOM_MAX}
              orientation='vertical'
              step={MAP_POV_ZOOM_STEP}
              style={{ height: 120 }}
              value={mapField.pov.zoom}
              valueLabelDisplay='on'
              valueLabelFormat={() => `${mapFieldPovZoomPercentage}%`}
            />
          ) : (
            <Slider
              className={classes.machineToolbarSlider}
              color={
                viewMapField.zoom !== mapField.zoom || autoCapture
                  ? 'secondary'
                  : 'primary'
              }
              onChange={(event, value) => {
                controllerSend({
                  type: 'UPDATE_EDIT_CONTEXT',
                  payload: {
                    autoCapture: false,
                    machineId: 'mapMachine',
                    property: 'mapField',
                    values: {
                      zoom: value,
                    },
                  },
                });
                if (autoCapture) {
                  controllerSend({
                    type: 'SUBMIT_FRAME_CAPTURE',
                  });
                }
              }}
              min={MAP_ZOOM_MIN}
              max={MAP_ZOOM_MAX}
              orientation='vertical'
              step={MAP_ZOOM_STEP}
              style={{ height: 120 }}
              value={mapField.zoom}
              valueLabelDisplay='on'
              valueLabelFormat={() => `${mapFieldZoomPercentage}%`}
            />
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ToolbarZoomControl;
