const helpPanelDirectory = {
  codeMachine: {
    panelHeader: 'Code Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      "Click the toolbar's <i>Code Drawer</i> button to show - or hide - the code editor.",
      'Select  HTML, CSS, JavaScript or other language for syntax highlighting.',
    ],
  },
  chessMachine: {
    panelHeader: 'Chess Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      "Click the toolbar's <i>Chess Drawer</i> button to show - or hide - the chess editor.",
    ],
  },
  imageMachine: {
    panelHeader: 'Image Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      'The <i>Image Tool</i> displays images from GIPHY, Unsplash, or any web link, in the <i>Main Panel</i> and <i>Background Panel</i>.',
      "Open the bottom toolbar's <b>Image Drawer</b> to search photos and GIFs, or enter a url. Click the image to add it to a panel.",
      'Use <b>Active Panel</b> button to select which panel is controlled.',
      'Set image <b>Aspect Ratio</b> to fit or fill the panel, and align with <b>Horizontal Positions</b> and <b>Vertical Position</b>.',
      'Use the <b>Image Carousel</b> to browse and select previously added images.',
    ],
  },
  mainPanel: {
    panelHeader: 'Main Panel',
    panelStyle: 'helpPanelContainer',
    panelText: [
      'The <i>Main Panel</i> is used to display <b>Image</b>, <b>Map</b>, <b>Text</b>, <b>Video</b>, and other media content.',
      'Click a button in the sidebar to activate the corresponding tool.',
      'In the toolbar that appears, <b>Panels Layout</b> controls the inline or stacked layout along the <i>Side Panel</i>, and <b>Panels Direction</b> reverses the order.',
      'Drag the <b>Panel Slider</b> dots on side of screen to adjust the panel size.',
    ],
  },
  mapMachine: {
    panelHeader: 'Map Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      'The <i>Map Tool</i> displays Google Maps or Street View in the <i>Main Panel</i> or <i>Background Panel</i>.',
      "Open the bottom toolbar's <b>Map Drawer</b> to search a city, place, or location, and to add it to a panel.",
      'Use <b>Active Panel</b> button to select which panel is controlled.',
      'Set <b>Map Type</b> to satellite, roadmap, or streetview.',
      'Depending on the map type, control <b>Map Overlay</b>, <b>Zoom Level</b>, <b>View Direction</b>, or <b>View Tilt</b>.',
    ],
  },
  sidenotePanel: {
    panelHeader: 'Side Panel',
    panelStyle: 'helpPanelContainer',
    panelText: [
      'The <i>Side Panel</i> is used to display captions, titles, subtitles, and other text alongside the <i>Main Panel</i>. Use the Panel Slider dots in sidebar to adjust the panel size.',
      'Click the <b>Sidenote</b> button in the sidebar to activate the <i>Sidenote Tool</i>.',
      'In the toolbar that appears, <b>Panels Layout</b> controls the inline or stacked layout along the <i>Main Panel</i>, and <b>Panels Direction</b> reverses the order.',
      'Drag the <b>Panel Slider</b> dots on side of screen to adjust the panel size.',
    ],
  },
  sidenoteMachine: {
    panelHeader: 'Sidenote Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      'The <i>Sidenote Tool</i> displays titles, captions, subtitles, or any other text in the <i>Side Panel</i>.',
      "Open the bottom toolbar's <b>Sidenote Drawer</b> to enter or edit the text.",
      'Set <b>Horizontal Align</b> and <b>Vertical Align</b> to position the text within the panel.',
      'Use <b>Reverse Type</b>, <b>Font Size</b>, and <b>Font Family</b> controls to style the text.',
    ],
  },
  textMachine: {
    panelHeader: 'Text Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      'The <i>Text Tool</i> is used to display text in the <i>Main Panel</i>.',
      "Open the bottom toolbar's <b>Text Drawer</b> to enter or edit text.",
      'Set <b>Horizontal Align</b> and <b>Vertical Align</b> to position the text within the panel.',
      'Use <b>Reverse Type</b>, <b>Font Size</b>, and <b>Font Family</b> controls to style the text.',
    ],
  },
  videoMachine: {
    panelHeader: 'Video Tool',
    panelStyle: 'helpPanelMachineContainer',
    panelText: [
      'The <i>Video Tool</i> is used to play media from YouTube, SoundCloud, and other platforms, in the <i>Main Panel</i> or <i>Background Panel</i>.',
      "Open the bottom toolbar's <b>Video Drawer</b> to enter a url or file path.",
      'Use <b>Active Panel</b> button to select which panel is controlled.',
      'Set <b>Vertical Align</b> to position the media within the panel.',
      'Click the <b>Stream</b> button to start the playback.',
    ],
  },
};

export default helpPanelDirectory;
