import Code from '@material-ui/icons/Code';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import SettingsRounded from '@material-ui/icons/SettingsRounded';

import BoardThemeMenu from './BoardTheme';
import BoardOverviewMenu from './BoardOverview';
import BoardRecordMenu from './BoardRecord';
import BoardStreamMenu from './BoardStream';
import DeviceOrientationMenu from './DeviceOrientation';
import MenuBoardFrames from './BoardFrames';
import MenuMainStart from './MainStart';

export const MODAL_MENUS_DICTIONARY = {
  menuBoardRecord: {
    Component: BoardRecordMenu,
    dialogProps: { fullScreen: false },
    Icon: SettingsRounded,
    label: 'Record',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: false,
    title: 'Record Board',
  },
  menuBoardStream: {
    Component: BoardStreamMenu,
    dialogProps: { fullScreen: false },
    Icon: SettingsRounded,
    label: 'Stream',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: false,
    title: 'Stream Board',
  },
  menuBoardTheme: {
    Component: BoardThemeMenu,
    dialogProps: { fullScreen: false },
    Icon: SettingsRounded,
    label: 'Theme',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: false,
    title: 'Theme Colors',
  },
  menuBoardOverview: {
    Component: BoardOverviewMenu,
    dialogProps: { fullScreen: false, onClose: () => {} },
    Icon: SettingsRounded,
    label: 'Board Overview',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: true,
  },
  menuFrameOrientation: {
    Component: DeviceOrientationMenu,
    dialogProps: { fullScreen: false },
    Icon: SettingsRounded,
    label: 'Page Orientation',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
  },
  menuMainStart: {
    Component: MenuMainStart,
    dialogProps: { fullScreen: false },
    Icon: DashboardOutlined,
    label: 'Start',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: true,
    title: 'Start',
  },
  menuMainCreateSettings: {
    Component: MenuBoardFrames,
    dialogProps: { fullScreen: true, fullWidth: true, scroll: 'body' },
    Icon: Code,
    label: '',
    playControl: {
      pauseOnOpen: true,
      unpauseOnClose: true,
    },
    showHomeButton: true,
  },
};
