import React, { useContext, useEffect } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Highlight from 'react-highlight';
import extend from 'lodash/extend';

import { CODE_SHARED_CONTEXT } from './constants';
import { codeStyles } from './styles';
import { GlobalStateContext } from '../../globalState';

const useStyles = makeStyles((theme) => codeStyles(theme));

const CodeView = ({ gridTabsSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();

  const {
    viewContext: {
      codeMachine: { codeField: machineCodeField },
    },
  } = controllerState.context;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const codeField = extend(CODE_SHARED_CONTEXT.codeField, machineCodeField);

  useEffect(() => {
    var head = document.head;
    var link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.12.0/styles/${codeField.themeHighlight}`;
    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, [codeField]);

  return (
    <>
      <Box
        sx={{
          height: gridTabsSectionSize.height,
        }}
      >
        <Highlight language='javascript' className={classes.highlight}>
          {codeField.value}
        </Highlight>
      </Box>
    </>
  );
};

export default CodeView;
