import { createTheme } from '@material-ui/core/styles';
import { alpha, darken, lighten } from '@material-ui/core';
/**
 * Merge the selected color theme with the global overrides.
 * See:  https://github.com/mui/material-ui/issues/21757#issuecomment-778308637
 */

/**
 * Helper function to return a darker or lighter version of the given color, based
 * on the luminance of given color.
 */
const disabledColor = ({ theme, color, coefficient = 0.9 }) => {
  const contrastColor = theme.palette.getContrastText(color);
  const adjustedColor = contrastColor.toLowerCase().includes('fff')
    ? lighten(color, coefficient)
    : darken(color, coefficient);
  return alpha(adjustedColor, 0.5);
};

export const globalThemeOverrides = ({ muiTheme }) => {
  // Calculate custom contrast colors base on the selected theme colors
  const backgroundContrastText = muiTheme.palette.getContrastText(
    muiTheme.palette.background.default
  );
  const sidePanelContrast = muiTheme.palette.getContrastText(
    muiTheme.palette.text.sidePanel
  );
  const mainPanelContrast = muiTheme.palette.getContrastText(
    muiTheme.palette.text.mainPanel
  );

  muiTheme.palette.background.mainPanelContrast = mainPanelContrast;
  muiTheme.palette.background.sidePanelContrast = sidePanelContrast;
  muiTheme.palette.text.backgroundContrastText = backgroundContrastText;

  return createTheme({
    ...muiTheme,
    overrides: {
      MuiAutocomplete: {
        paper: {
          backgroundColor: muiTheme.palette.background.default,
        },
      },
      MuiButton: {
        root: {
          margin: muiTheme.spacing(0.5),
          minWidth: 0,
        },
        contained: {
          backgroundColor: muiTheme.palette.background.default,
          color: backgroundContrastText,

          '&:hover': {
            backgroundColor: muiTheme.palette.default.main,
          },
          '&.Mui-disabled': {
            backgroundColor: disabledColor({
              color: muiTheme.palette.background.default,
              theme: muiTheme,
            }),
            color: disabledColor({
              color: backgroundContrastText,
              theme: muiTheme,
            }),
          },
        },
        outlined: {
          backgroundColor: muiTheme.palette.default.main,
          color: muiTheme.palette.default.contrastText,
          '&:hover': {
            backgroundColor: muiTheme.palette.default[muiTheme.palette.type],
          },
        },
        outlinedPrimary: {
          backgroundColor: muiTheme.palette.primary.light,
          color: muiTheme.palette.primary.contrastText,
          outline: `2px dashed ${
            muiTheme.palette.primary[muiTheme.palette.type]
          } `,
          '&:hover': {
            backgroundColor: muiTheme.palette.primary.main,
          },
        },
        outlinedSecondary: {
          backgroundColor: muiTheme.palette.default[muiTheme.palette.type],
          borderColor: muiTheme.palette.divider,
          color: muiTheme.palette.primary.contrastText,
          outline: `2px dashed ${
            muiTheme.palette.secondary[muiTheme.palette.type]
          } `,
          '&:hover': {
            backgroundColor: muiTheme.palette.default.main,
            borderColor: muiTheme.palette.divider,
          },
        },
        textPrimary: {
          backgroundColor: muiTheme.palette.primary.main,
          color: muiTheme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: muiTheme.palette.primary[muiTheme.palette.type],
          },
        },
      },
      MuiButtonGroup: {
        root: {
          backgroundColor: muiTheme.palette.default.main,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        grouped: {
          margin: 0,
        },
      },
      MuiCard: {
        root: {
          backgroundColor: 'transparent',
        },
      },
      MuiCardHeader: {
        title: {
          fontWeight: 700,
        },
      },
      MuiCardContent: {
        root: {
          paddingBottom: muiTheme.spacing(1),
          paddingTop: muiTheme.spacing(1.5),
        },
      },
      MuiChip: {
        outlinedPrimary: {
          backgroundColor: muiTheme.palette.primary.light,
          color: muiTheme.palette.primary.contrastText,
        },
      },
      MuiDialog: {
        paper: {
          backgroundColor: muiTheme.palette.default[muiTheme.palette.type],
          borderRadius: muiTheme.spacing(1),
        },
      },
      MuiFormControl: {
        root: {
          borderRadius: muiTheme.spacing(0.5),
        },
      },
      MuiFormLabel: {
        root: {
          color: muiTheme.palette.text.primary,
          fontWeight: 700,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: alpha(muiTheme.palette.background.default, 0.7),
          borderRadius: muiTheme.spacing(1),
        },
      },
      MuiTooltip: {
        arrow: {
          color: muiTheme.palette.primary[muiTheme.palette.type],
        },
        tooltip: {
          color: muiTheme.palette.text.primary,
          backgroundColor: muiTheme.palette.primary[muiTheme.palette.type],
        },
      },
      MuiTypography: {
        caption: {
          alignItems: 'center',
          color: muiTheme.palette.text.primary,
          display: 'flex',
          flexWrap: 'wrap',
          fontSize: '11px',
          padding: '0 8px',
          textTransform: 'uppercase',
          [muiTheme.breakpoints.down('sm')]: {
            fontSize: '9px!important',
          },
        },
        gutterBottom: {
          marginBottom: muiTheme.spacing(2),
        },
      },
      MuiIconButton: {
        colorPrimary: {
          color: muiTheme.palette.text.primary,
        },
        root: {
          color: muiTheme.palette.text.primary,
          borderRadius: muiTheme.spacing(0.5),
        },
      },
      MuiMenu: {
        paper: {
          color: muiTheme.palette.default.contrastText,
          backgroundColor: muiTheme.palette.default[muiTheme.palette.type],
        },
      },
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: muiTheme.palette.background.default,
        },
        colorPrimary: {
          backgroundColor: backgroundContrastText,
        },
      },
      MuiListItem: {
        button: {
          color: muiTheme.palette.background.dark,
        },
      },
      MuiSlider: {
        thumb: {
          width: 12,
          height: 12,
          marginTop: -6,
          marginLeft: -6,
          '&.Mui-disabled': {
            width: 12,
            height: 12,
            marginTop: -6,
            marginLeft: -6,
          },
        },
      },
      MuiTab: {
        labelIcon: {
          minHeight: muiTheme.spacing(3),
          '& .MuiTab-wrapper > *:first-child': {
            marginBottom: 0,
            marginRight: muiTheme.spacing(1),
          },
        },
        wrapper: {
          marginBottom: 0,
          marginRight: muiTheme.spacing(1),
          flexDirection: 'row',
          justifyContent: 'center',
        },
      },
    },
  });
};

export default globalThemeOverrides;
