import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import { Chessboard } from 'react-chessboard';
import extend from 'lodash/extend';

import { CHESS_SHARED_CONTEXT } from './constants';
import { GlobalStateContext } from '../../globalState';

const ChessView = ({ gridTabsSectionSize }) => {
  const chessFieldRef = useRef();
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [boardWidth, setBoardWidth] = useState(0);
  const boxRef = useRef();

  const {
    controllerMachine: {
      boardGrid: { containerOrientation, itemBreakpoint },
    },
    chessMachine: { chessField: machineChessField },
  } = controllerState.context.viewContext;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const chessField = extend(CHESS_SHARED_CONTEXT.chessField, machineChessField);
  const { boardOrientation, horizontalAlign, value, verticalAlign } =
    chessField;

  useLayoutEffect(() => {
    const width = Math.min(
      gridTabsSectionSize.width,
      gridTabsSectionSize.height
    );
    setBoardWidth(width);
  }, [
    containerOrientation,
    gridTabsSectionSize.height,
    gridTabsSectionSize.width,
    itemBreakpoint,
  ]);

  return (
    <Box
      ref={boxRef}
      height={gridTabsSectionSize.height}
      style={{
        display: 'flex',
        flexDirection: containerOrientation === 'landscape' ? 'row' : 'column',
        ...(containerOrientation === 'landscape'
          ? { alignItems: verticalAlign }
          : { justifyContent: verticalAlign }),
        ...(containerOrientation === 'landscape'
          ? { justifyContent: horizontalAlign }
          : { alignItems: horizontalAlign }),
      }}
    >
      <Chessboard
        ref={chessFieldRef}
        boardWidth={boardWidth}
        boardOrientation={boardOrientation}
        id='chessField'
        position={value}
      />
    </Box>
  );
};

export default ChessView;
