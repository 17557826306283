import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useActor } from '@xstate/react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddToPhotosOutlined from '@material-ui/icons/AddToPhotosOutlined';
import Button from '@material-ui/core/Button';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';

import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const useStyles = makeStyles((theme) => controlStyles(theme));

const FrameSubmitControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editContext,
    editControl: {
      durationField,
      isFrameIndexOnNewFrame,
      isEditFrameModeActive,
    },
    editFrameIndex,
    modalControl: { openModalIds },
    viewContext,
  } = controllerState.context;

  const hasContextChanged =
    !isEqual(viewContext, editContext) ||
    (frames[editFrameIndex] &&
      !isEqual(durationField.value, frames[editFrameIndex].duration));

  return isFrameIndexOnNewFrame ||
    (!isFrameIndexOnNewFrame && isEditFrameModeActive && hasContextChanged) ? (
    <Help
      controlId={
        isEqual(editFrameIndex, frames.length)
          ? 'controllerMachineFrameCreateControl'
          : 'controllerMachineFrameUpdateControl'
      }
      isDisabled={!hasContextChanged || !!openModalIds.length}
      placement='top'
    >
      <Button
        className={classes.frameUndoButton}
        color='secondary'
        disabled={!hasContextChanged || !!openModalIds.length}
        onClick={() => {
          controllerSend({
            type: 'SUBMIT_FRAME_CAPTURE',
          });
        }}
        size='small'
        style={{ marginLeft: 4 }}
        variant='contained'
      >
        {sessionState.matches('stream') &&
          (showControlLabels ? 'STREAM FRAME' : <CloudUploadOutlined />)}
        {sessionState.matches('record') &&
          (isFrameIndexOnNewFrame ? (
            showControlLabels ? (
              'RECORD FRAME'
            ) : (
              <AddToPhotosOutlined />
            )
          ) : showControlLabels ? (
            'UPDATE FRAME'
          ) : (
            <EditOutlined />
          ))}
      </Button>
    </Help>
  ) : null;
};

export default FrameSubmitControl;
