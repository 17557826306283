import cloneDeep from 'lodash/cloneDeep';
import { applyDiffStringToObject, parseDomainWithoutSubdomainFromUrl } from '.';
import Link from '@material-ui/core/Link';
/**
 * Get the machine context of targeted frame by index. Will target the
 * last frame if no index is provided.
 */
export const composeFrameContext = ({
  currentContext,
  currentFrameIndex,
  frames,
  targetFrameIndex = frames.length,
  updateDiffString = '',
}) => {
  if (targetFrameIndex === currentFrameIndex) return currentContext;

  let updatedContext = cloneDeep(currentContext);
  const reverse = targetFrameIndex < currentFrameIndex;
  let frameIndex = currentFrameIndex;
  while (
    reverse ? frameIndex > targetFrameIndex : frameIndex < targetFrameIndex
  ) {
    frameIndex = reverse ? frameIndex : frameIndex + 1;
    if (typeof frames[frameIndex] !== 'undefined') {
      updatedContext = applyDiffStringToObject(
        updatedContext,
        frames[frameIndex].diffString,
        reverse,
        updateDiffString
      );
    }
    frameIndex = reverse ? frameIndex - 1 : frameIndex;
  }
  return updatedContext;
};

export const composeImageCredit = ({ credit, element = '', url }) => {
  const { imageType } = credit;
  switch (imageType) {
    case 'unsplash': {
      const { userName, userUrl } = credit;
      return (
        <div>
          {element} photo:{' '}
          {!!userName && !!userUrl && (
            <>
              <Link
                color='inherit'
                underline='always'
                href={userUrl}
                rel='noreferrer'
                target='_blank'
              >
                {userName}
              </Link>{' '}
              on{' '}
            </>
          )}
          <Link
            color='inherit'
            underline='always'
            href='https://unsplash.com'
            rel='noreferrer'
            target='_blank'
          >
            Unsplash
          </Link>
        </div>
      );
    }
    case 'giphy': {
      const { userName, userUrl } = credit;
      return (
        <div>
          {element} GIF:{' '}
          {!!userName && !!userUrl && (
            <>
              <Link
                color='inherit'
                underline='always'
                href={userUrl}
                rel='noreferrer'
                target='_blank'
              >
                {userName}
              </Link>{' '}
              on{' '}
            </>
          )}
          <Link
            color='inherit'
            href='https://giphy.com'
            rel='noreferrer'
            target='_blank'
            underline='always'
          >
            Giphy
          </Link>
        </div>
      );
    }
    default: {
      if (url) {
        // TODO: Disabled linking to the "unknown" image source for now. Do we want this?
        const domain = parseDomainWithoutSubdomainFromUrl(url);
        // const { protocol } = new URL(url);
        return (
          <div>
            {element} image:{' '}
            {/* 
            <a href={`${protocol}//${domain}`} rel='noreferrer' target='_blank'> */}
            {domain}
            {/* </a> */}
          </div>
        );
      }
    }
  }
};

export const composeMapCredit = ({ element = '', mapField }) => {
  const { center, mapTypeId, pov, zoom } = mapField;
  switch (mapTypeId) {
    case 'streetview': {
      return (
        <div>
          {element} view:{' '}
          <Link
            color='inherit'
            href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${center.lat}, ${center.lng}&heading=${pov.heading}&pitch=${pov.pitch}`}
            rel='noreferrer'
            target='_blank'
            underline='always'
          >
            Google
          </Link>
        </div>
      );
    }
    default: {
      return (
        <div>
          {element} map:{' '}
          <Link
            color='inherit'
            href={`https://www.google.com/maps/@?api=1&map_action=map&center=${center.lat}, ${center.lng}&zoom=${zoom}&basemap=${mapTypeId}`}
            rel='noreferrer'
            target='_blank'
            underline='always'
          >
            Google
          </Link>
        </div>
      );
    }
  }
};
