import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { GlobalStateContext } from '../../../globalState';
import helpTopicsByRole from '../Topics';

const TopicSelector = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorElement, setAnchorElement] = useState(null);

  const {
    board: { mode, role },
    helpControl: { helpTopicId },
  } = controllerState.context;
  const helpTopics = helpTopicsByRole[role][mode];

  const moreHelpTopics = Object.keys(helpTopics).filter(
    (topicId) => topicId !== helpTopicId
  );

  return !!moreHelpTopics.length ? (
    <FormControl fullWidth>
      <Button
        aria-haspopup='true'
        onClick={({ currentTarget }) =>
          anchorElement
            ? setAnchorElement(null)
            : setAnchorElement(currentTarget)
        }
        size='small'
        variant='outlined'
      >
        {anchorElement ? `Hide topics` : `Show topics`}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
        PaperProps={{
          style: {
            marginTop: 38,
            maxHeight: 48 * 4.5,
            width: anchorElement && anchorElement.offsetWidth,
          },
        }}
        variant='menu'
      >
        {moreHelpTopics.map((topicId) => {
          const { topicDictionary, topicSteps } = helpTopics[topicId];
          const controlId = topicSteps[0];
          const { relatedControlIds } = topicDictionary[controlId];
          return (
            <MenuItem
              key={topicId}
              dense
              onClick={() => {
                setAnchorElement(null);
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'helpControl',
                    values: {
                      helpTopicIndex: 0,
                      helpTopicId: topicId,
                      openControlIds: new Set([controlId]),
                      openRelatedControlIds: new Set(relatedControlIds),
                      minimizedControlIds: new Set([relatedControlIds]),
                      renderedControlIds: new Set(),
                    },
                  },
                });
              }}
              value={helpTopics[topicId].topicLabel}
            >
              {helpTopics[topicId].topicLabel}
            </MenuItem>
          );
        })}
      </Menu>
    </FormControl>
  ) : null;
};

export default TopicSelector;
