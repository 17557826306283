import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Textfit } from 'react-textfit';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';

import { globalStyles } from '../../themes/globalStyles';
import { sidenoteStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import { convertHexToRGBWithAlpha, useWindowProperties } from '../../helpers';
import Help from '../Help';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...sidenoteStyles(theme),
}));

const SidenoteEdit = ({ gridMainSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const { deviceOrientation } = useWindowProperties();
  const classes = useStyles();
  const theme = useTheme();

  const {
    editContext: {
      sidenoteMachine: {
        sidenoteField: {
          backgroundOpacity,
          editorState,
          fontFamily,
          fontSizeMax,
          fontSizeMin,
          horizontalAlign,
          verticalAlign,
        },
      },
      controllerMachine: {
        boardGrid: { containerDirectionReverse, containerDirection },
      },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'sidenoteMachine';
  const sidenoteHtml = slateToHtml(editorState);
  const isSidenoteHTMLEmpty = !sidenoteHtml || sidenoteHtml === '<p></p>';

  return isSidenoteHTMLEmpty && activeMachineId !== 'sidenoteMachine' ? (
    <HelpPanel
      isHidden={!!activeMachineId}
      panelId='sidenotePanel'
      panelProperties={gridMainSectionSize}
    />
  ) : (
    <Help
      controlId='sidenoteMachineSidenoteFieldComponent'
      placement={
        containerDirection === 'row'
          ? !containerDirectionReverse
            ? 'right'
            : 'left'
          : !containerDirectionReverse
          ? 'bottom'
          : 'top'
      }
    >
      <Textfit
        className={`
          ${classes.sidenoteField}
          ${
            isActiveMachine
              ? isFrameIndexOnNewFrame
                ? classes.activeMachineContainer
                : classes.activeMachineContainerEdit
              : classes.inactiveMachineContainer
          }
      `}
        max={fontSizeMax}
        min={fontSizeMin}
        mode='multi'
        onClick={() => {
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'controllerMachine',
              property: 'boardGrid',
              values: { activeMachineId: 'sidenoteMachine' },
            },
          });
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'sidenoteMachine',
              property: 'toolbarDrawer',
              values: { drawerTab: 'editor', isDrawerCollapsed: true },
            },
          });
        }}
        style={{
          display: 'flex',
          flexDirection: deviceOrientation === 'landscape' ? 'column' : 'row',
          fontFamily,
          height: gridMainSectionSize.height,
          ...(deviceOrientation === 'landscape'
            ? { justifyContent: verticalAlign }
            : { alignItems: verticalAlign }),
          textAlign: horizontalAlign,
        }}
      >
        {!isSidenoteHTMLEmpty ? (
          <span
            dangerouslySetInnerHTML={{ __html: sidenoteHtml }}
            style={{
              backgroundColor: convertHexToRGBWithAlpha({
                alpha: backgroundOpacity,
                hex: theme.palette.background.paper,
              }),
            }}
          ></span>
        ) : (
          <span
            style={{
              backgroundColor: convertHexToRGBWithAlpha({
                alpha: backgroundOpacity,
                hex: theme.palette.background.paper,
              }),
            }}
          >
            [Enter text in toolbar drawer]
          </span>
        )}
      </Textfit>
    </Help>
  );
};

export default SidenoteEdit;
