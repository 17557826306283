import { assign, createMachine } from 'xstate';
import { API } from 'aws-amplify';

import { INITIAL_MACHINE_CONTEXT } from './constants';
import {
  convertCompressedStringToObject,
  convertObjectToCompressedString,
  getSessionIdFromStorageId,
} from '../../helpers';
import {
  createBoard,
  createFrame,
  createSession,
  framesByBoardIdAndCreatedAt,
  getBoard,
  getSession,
  subscribeToNewFrame,
} from '../../api';

const sessionMachine = createMachine(
  {
    // predictableActionArguments: true,
    context: {
      ...INITIAL_MACHINE_CONTEXT,
    },
    id: 'sessionMachine',
    initial: 'init',
    states: {
      init: {
        id: 'init',
        initial: 'idle',
        states: {
          idle: {
            entry: ['loadBoardHistory', 'loadStreamsList'],
            on: {
              LOAD_RECORDING: {
                actions: ['loadRecording'],
                target: 'loadRecording',
              },
              LOAD_STREAM: {
                actions: [
                  assign({
                    session: (_, { payload: { sessionRole = 'view' } }) => ({
                      ...INITIAL_MACHINE_CONTEXT.session,
                      role: sessionRole,
                    }),
                  }),
                  'loadStream',
                ],
                target: 'getBoard',
              },
              VIEW_RECORDING: {
                actions: [
                  assign({
                    session: (_, { payload: { sessionRole = 'view' } }) => ({
                      ...INITIAL_MACHINE_CONTEXT.session,
                      role: sessionRole,
                    }),
                  }),
                  'viewRecording',
                ],
                target: 'loadRecording',
              },
              VIEW_STREAM: {
                actions: assign({
                  session: (
                    _,
                    { payload: { sessionId, sessionRole = 'view' } }
                  ) => ({
                    ...INITIAL_MACHINE_CONTEXT.session,
                    id: sessionId,
                    mode: 'stream',
                    role: sessionRole,
                  }),
                }),
                // TODO: Check if we still need this condition. Leaving it for now.
                cond: (_, { payload }) => !!payload && !!payload.sessionId,
                target: 'getSession',
              },
            },
          },
          createBoard: {
            invoke: {
              src: 'queryCreateBoard',
              onDone: {
                actions: assign({
                  /**
                   * Assign session.boardId
                   */
                  session: (
                    { session },
                    {
                      data: {
                        data: {
                          createBoard: { createdAt, id },
                        },
                      },
                    }
                  ) => ({ ...session, boardId: id, createdAt }),
                }),
                target: 'createSession',
              },
              onError: {
                // target: 'failure',
                actions: [(_, event) => console.log(event)],
              },
            },
          },
          startRecording: {
            /**
             * Containers act on controllers's 'init.startRecording' state
             * - Controller calls save event with initial board state so the empty
             *   board is available when user refreshes the page.
             */
            on: {
              SAVE_RECORDING: { actions: ['saveRecording'] },
            },
            after: {
              10: '#record',
            },
          },
          createSession: {
            invoke: {
              src: 'queryCreateSession',
              onDone: {
                // actions: assign({
                //   session: ({ session }, { data: { data } }) => {
                //     console.log('session', session);
                //     console.log('data.createSession', data.createSession);
                //     const { id } = data.createSession;
                //     return { ...session, boardId: id };
                //   },
                // }),
                actions: ['saveStreaming'],
                target: '#stream.edit',
              },
              onError: {
                // target: 'failure',
                actions: [(context, event) => console.log(context, event)],
              },
            },
          },
          getSession: {
            invoke: {
              src: 'queryGetSession',
              onDone: [
                {
                  actions: assign({
                    session: (
                      { session },
                      {
                        data: {
                          data: { getSession },
                        },
                      }
                    ) => {
                      return { ...session, ...getSession };
                    },
                  }),
                  cond: (
                    _,
                    {
                      data: {
                        data: { getSession },
                      },
                    }
                  ) => !!getSession,
                  target: 'getBoard',
                },
                {
                  target: 'getSessionFailed',
                },
              ],
              onError: {
                actions: [(_, event) => console.log(event)],
              },
            },
          },
          getSessionFailed: {
            /**
             * Lets containers hook into session's 'init.getSessionFailed' state
             */
            after: {
              100: [
                // {
                //   cond: ({ session: { role } }) => role === 'edit',
                //   target: '#record.edit',
                // },
                {
                  target: 'idle',
                },
              ],
            },
          },
          getBoard: {
            type: 'parallel',
            states: {
              loadingBoard: {
                initial: 'loadBoard',
                states: {
                  loadBoard: {
                    invoke: {
                      src: 'queryGetBoard',
                      onDone: {
                        actions: assign({
                          boardData: (
                            _,
                            {
                              data: {
                                data: { getBoard },
                              },
                            }
                          ) => ({ ...getBoard }),
                        }),
                        target: 'checkFrames',
                      },
                    },
                  },
                  checkFrames: {
                    always: [
                      {
                        cond: ({
                          boardData: {
                            frames: { nextToken },
                          },
                        }) => !!nextToken,
                        target: 'loadFrames',
                      },
                      { target: 'done' },
                    ],
                  },
                  loadFrames: {
                    invoke: {
                      src: 'queryFramesByCreatedAt',
                      onDone: {
                        actions: assign({
                          boardData: (
                            { boardData },
                            {
                              data: {
                                data: {
                                  framesByBoardIdAndCreatedAt: {
                                    items,
                                    nextToken,
                                  },
                                },
                              },
                            }
                          ) => {
                            return {
                              ...boardData,
                              frames: {
                                items: [...boardData.frames.items, ...items],
                                nextToken,
                              },
                            };
                          },
                        }),
                        target: 'checkFrames',
                      },
                      onError: {
                        actions: [(_, event) => console.log(event)],
                      },
                    },
                  },
                  done: {
                    type: 'final',
                  },
                },
              },
            },
            onDone: 'loadStream',
          },
          loadRecording: {
            /**
             * Lets containers hook into session's 'init.loadRecording' state
             */
            after: {
              100: [
                {
                  cond: ({ session: { role } }) => role === 'edit',
                  target: '#record.edit',
                },
                {
                  target: '#record.view',
                },
              ],
            },
          },
          loadStream: {
            /**
             * Lets Controller hook into session's 'init.loadStream' state
             */
            after: {
              100: [
                {
                  cond: ({ session: { role } }) => role === 'edit',
                  target: '#stream.edit',
                },
                {
                  target: '#stream.view',
                },
              ],
            },
            exit: assign({
              boardData: ({ boardData }) => ({
                ...boardData,
                frames: { ...boardData.frames, items: [] },
              }),
            }),
          },
        },
      },
      record: {
        id: 'record',
        initial: 'init',
        states: {
          init: {
            always: [
              // { cond: 'isTypeStreaming', target: 'view' },
              { target: 'edit' },
            ],
          },
          edit: {
            id: 'edit',
            initial: 'idle',
            states: {
              idle: {
                on: {
                  SAVE_RECORDING: {
                    target: 'savingRecording',
                  },
                  PUBLISH_RECORDING: {
                    actions: [
                      assign({
                        sendQueue: (
                          _,
                          {
                            payload: {
                              board: { frames },
                            },
                          }
                        ) => frames,
                        session: ({ session }) => ({
                          ...session,
                          mode: 'stream',
                          status: 'open',
                        }),
                      }),
                    ],
                    target: 'publishBoard',
                  },
                },
              },
              publishBoard: {
                invoke: {
                  src: 'queryCreateBoard',
                  onDone: {
                    actions: assign({
                      /**
                       * Assign session.boardId
                       */
                      session: (
                        { session },
                        {
                          data: {
                            data: {
                              createBoard: { createdAt, id },
                            },
                          },
                        }
                      ) => ({ ...session, boardId: id, createdAt }),
                    }),
                    target: 'publishSession',
                  },
                  onError: {
                    // target: 'failure',
                    actions: [(_, event) => console.log(event)],
                  },
                },
              },
              publishSession: {
                invoke: {
                  src: 'queryCreateSession',
                  onDone: {
                    // actions: ['saveStreaming'],
                    target: 'publishFrames',
                  },
                  onError: {
                    actions: [
                      (_, { data: { errors } }) =>
                        console.log(
                          'Error publishing Session:',
                          errors.map(({ message }) => message)
                        ),
                    ],
                  },
                },
              },
              publishFrames: {
                initial: 'idling',
                states: {
                  idling: {
                    always: [
                      {
                        cond: ({ sendQueue }) => !!sendQueue.length,
                        target: 'sending',
                      },
                      {
                        cond: ({ sendQueue }) => !sendQueue.length,
                        target: '#edit.publishedRecording',
                      },
                    ],
                  },
                  sending: {
                    invoke: {
                      src: 'queryCreateFrame',
                      onDone: {
                        actions: assign({
                          sendQueue: ({ sendQueue }) => sendQueue.slice(1),
                        }),
                        target: 'idling',
                      },
                      onError: {
                        actions: [(_, event) => console.log(event)],
                      },
                    },
                  },
                },
              },
              publishedRecording: {
                /**
                 * Lets Controller hook into session's 'edit.publishedRecording' state
                 */
                entry: [
                  ({ session, user }) => {
                    localStorage.setItem(
                      `Stream:${session.id}`,
                      convertObjectToCompressedString({ session, user })
                    );
                    localStorage.removeItem(`Recording:${session.id}`);
                  },
                ],
                after: {
                  100: [
                    // {
                    //   cond: ({ session: { role } }) => role === 'edit',
                    //   target: '#stream.edit',
                    // },
                    {
                      target: '#record',
                    },
                  ],
                },
              },
              savingRecording: {
                entry: ['saveRecording'],
                always: 'idle',
              },
            },
          },
          view: {},
        },
      },
      stream: {
        id: 'stream',
        initial: 'init',
        states: {
          init: {},
          edit: {
            type: 'parallel',
            states: {
              receive: {
                invoke: {
                  src: 'querySubscribeToNewFrame',
                  onDone: {
                    actions: (context, event) =>
                      console.log('unsubscribed from stream', context, event),
                  },
                  onError: {
                    actions: [(_, event) => console.log(event)],
                  },
                },
                // on: {
                //   REQUEST_USER_NAME: {
                //     actions: ['stagingStreamingOnRequestUserName'],
                //   },
                //   REQUEST_USER_MESSAGE: {
                //     actions: ['stagingStreamingOnRequestUserMessage'],
                //   },
                // },
              },
              send: {
                initial: 'idling',
                states: {
                  idling: {
                    always: [
                      {
                        cond: ({ sendQueue }) => !!sendQueue.length,
                        target: 'sending',
                      },
                    ],
                    on: {
                      ENQUEUE_BOARD_FRAMES: {
                        actions: assign({
                          sendQueue: (
                            { sendQueue },
                            { payload: { frames } }
                          ) => [...sendQueue, ...frames],
                        }),
                        // target: 'sending',
                      },
                    },
                  },
                  sending: {
                    invoke: {
                      src: 'queryCreateFrame',
                      onDone: {
                        actions: assign({
                          sendQueue: ({ sendQueue }) => sendQueue.slice(1),
                        }),
                        target: 'idling',
                      },
                      onError: {
                        actions: [(_, event) => console.log(event)],
                      },
                    },
                  },
                },
              },
            },
          },
          view: {
            type: 'parallel',
            states: {
              receive: {
                initial: 'idle',
                states: {
                  idle: {
                    always: [
                      {
                        cond: ({
                          boardData: {
                            frames: { nextToken },
                          },
                          session: { mode, status },
                        }) =>
                          !nextToken && mode === 'stream' && status === 'open',
                        target: 'subscription',
                      },
                    ],
                  },
                  subscription: {
                    invoke: {
                      src: 'querySubscribeToNewFrame',
                      onDone: {
                        actions: (context, event) =>
                          console.log(
                            'unsubscribed from stream',
                            context,
                            event
                          ),
                      },
                      onError: {
                        actions: [(_, event) => console.log(event)],
                      },
                    },
                    on: {
                      RECEIVE_BOARD_FRAME: {
                        actions: assign({
                          boardData: (
                            { boardData },
                            {
                              payload: { diffString, duration, machineId = '' },
                            }
                          ) => ({
                            ...boardData,
                            frames: {
                              ...boardData.frames,
                              items: [
                                ...boardData.frames.items,
                                { diffString, duration, machineId },
                              ],
                            },
                          }),
                        }),
                      },
                    },
                  },
                },
              },
              send: {
                initial: 'idling',
                states: {
                  idling: {
                    on: {
                      STREAM_BOARD_FRAME: {
                        target: 'pending',
                      },
                    },
                  },
                  pending: {
                    invoke: {
                      src: 'queryCreateFrame',
                      onDone: {
                        target: 'idling',
                      },
                      onError: {
                        actions: [(_, event) => console.log(event)],
                      },
                    },
                  },
                },
              },
            },
            on: {
              DEQUEUE_BOARD_FRAMES: {
                actions: assign({
                  boardData: ({ boardData }) => ({
                    ...boardData,
                    frames: { ...boardData.frames, items: [] },
                  }),
                }),
                cond: ({
                  boardData: {
                    frames: { items },
                  },
                }) => !!items.length,
              },
            },
          },
        },
      },
    },
    on: {
      CREATE_RECORDING: {
        actions: assign({
          session: (_, { payload: { name, sessionId, userName } }) => ({
            ...INITIAL_MACHINE_CONTEXT.session,
            createdAt: new Date().toISOString(),
            id: sessionId,
            mode: 'record',
            name,
            role: 'edit',
            status: 'draft',
            userName,
          }),
        }),
        target: '#init.startRecording',
      },
      CREATE_STREAM: {
        actions: [
          assign({
            session: (
              _,
              {
                payload: {
                  name,
                  sessionId,
                  user: { id: userId, name: userName },
                },
              }
            ) => ({
              ...INITIAL_MACHINE_CONTEXT.session,
              id: sessionId,
              mode: 'stream',
              name,
              role: 'edit',
              status: 'open',
              userId,
              userName,
            }),
          }),
        ],
        target: '#init.createBoard',
      },
      DELETE_RECORDING: {
        actions: ['deleteRecording', 'loadBoardHistory'],
      },
      DELETE_STREAM: {
        actions: ['deleteStream', 'loadStreamsList'],
      },
      VIEW_PRESET: {
        actions: ['viewPreset'],
        target: '#record.view',
      },
      SESSION_RESET: {
        actions: assign({
          ...INITIAL_MACHINE_CONTEXT,
        }),
        target: 'init',
      },
      SESSION_PROP_VALUES: {
        actions: ['setPropValues'],
      },
    },
  },
  {
    actions: {
      deleteRecording: assign({
        boardHistory: ({ boardHistory }, { payload }) => {
          const { sessionId } = payload;
          localStorage.removeItem(`Recording:${sessionId}`);
          localStorage.removeItem(`MachinesContext:${sessionId}`);
          return boardHistory.filter(
            (session) => session.sessionId !== sessionId
          );
        },
      }),
      deleteStream: assign({
        savedStreams: ({ savedStreams }, { payload: { sessionId } }) => {
          localStorage.removeItem(`Stream:${sessionId}`);
          localStorage.removeItem(`MachinesContext:${sessionId}`);
          return savedStreams.filter(
            (session) => session.sessionId !== sessionId
          );
        },
      }),
      loadRecording: assign({
        session: (_, { payload: { sessionId } }) => {
          const compressedString = localStorage.getItem(
            `Recording:${sessionId}`
          );
          const { board, session, user } = convertCompressedStringToObject({
            compressedString,
          });
          user.dateLastOpened = new Date().toISOString();
          localStorage.setItem(
            `Recording:${session.id}`,
            convertObjectToCompressedString({ board, session, user })
          );
          return session;
        },
        sessionString: (_, { payload: { sessionId } }) =>
          localStorage.getItem(`Recording:${sessionId}`) || '',
      }),
      loadBoardHistory: assign({
        boardHistory: () =>
          Object.entries(localStorage)
            .filter(([key]) => {
              return key.startsWith('Recording:') || key.startsWith('Stream:');
            })
            .map(([storageId, sessionString], index) => {
              const sessionId = getSessionIdFromStorageId({ storageId });
              const { session = {}, user = {} } =
                convertCompressedStringToObject({
                  compressedString: sessionString,
                });
              return {
                sessionCreatedAt: session.createdAt,
                sessionId,
                sessionMode: session.mode,
                sessionName: session.name,
                sessionRole: session.role,
                sessionUserName: session.userName,
                userDateLastOpened: user.dateLastOpened,
              };
            })
            .sort(
              (a, b) => new Date(b.dateLastOpened) - new Date(a.dateLastOpened)
            ),
      }),
      loadStream: assign({
        session: (_, { payload: { sessionId } }) => {
          const compressedString = localStorage.getItem(`Stream:${sessionId}`);
          const {
            board = {},
            session = {},
            user = {},
          } = convertCompressedStringToObject({
            compressedString,
          });
          user.dateLastOpened = new Date().toISOString();
          localStorage.setItem(
            `Stream:${session.id}`,
            convertObjectToCompressedString({ board, session, user })
          );
          return session;
        },
        sessionString: (_, { payload: { sessionId } }) =>
          localStorage.getItem(sessionId) || '',
      }),
      loadStreamsList: assign({
        savedStreams: () =>
          Object.entries(localStorage)
            .filter(([key]) => {
              return key.startsWith('Stream:');
            })
            .map(([storageId, sessionString]) => {
              const sessionId = getSessionIdFromStorageId({ storageId });
              const { session } = convertCompressedStringToObject({
                compressedString: sessionString,
              });
              return { sessionId, sessionName: session.name };
            }),
      }),
      saveRecording: ({ session }, { payload: { board, user } }) => {
        user.dateLastUpdated = new Date().toISOString();
        localStorage.setItem(
          `Recording:${session.id}`,
          convertObjectToCompressedString({ board, session, user })
        );
      },
      saveStreaming: ({ board = {}, session, user }) => {
        user.dateLastUpdated = new Date().toISOString();
        localStorage.setItem(
          `Stream:${session.id}`,
          convertObjectToCompressedString({ board, session, user })
        );
      },
      setPropValues: assign((context, event) => {
        const { property, values } = event.payload;
        context[property] = {
          ...context[property],
          ...values,
        };
        return context;
      }),
      viewPreset: assign({
        session: (_, { payload: { session } }) => ({ ...session }),
      }),
      /**
       * User views a locally stored recording.
       */
      viewRecording: assign({
        session: (_, { payload: { sessionId } }) => {
          const compressedString = localStorage.getItem(
            `Recording:${sessionId}`
          );
          const { board, session, user } = convertCompressedStringToObject({
            compressedString,
          });
          // Update user views count and last viewed date in local storage
          user.viewsCount += 1;
          user.dateLastOpened = new Date().toISOString();
          localStorage.setItem(
            `Recording:${session.id}`,
            convertObjectToCompressedString({ board, session, user })
          );
          // Set session role temporarily to 'view' for viewing mode
          // TODO: Should this role prop maybe be moved to the user object?
          return {
            ...session,
            role: 'view',
          };
        },
        sessionString: (_, { payload: { sessionId } }) =>
          localStorage.getItem(`Recording:${sessionId}`) || '',
      }),
    },
    services: {
      queryCreateBoard: () => {
        return API.graphql({
          query: createBoard,
          variables: {
            input: {
              // TODO Can we include frames array in createBoard query?
              // frames,
            },
          },
        });
      },
      queryCreateFrame: ({ session: { boardId }, sendQueue }) => {
        const { diffString, duration, machineId = '' } = sendQueue[0];
        return API.graphql({
          query: createFrame,
          variables: {
            input: {
              boardId,
              diffString,
              duration,
              machineId,
            },
          },
        });
      },
      queryCreateSession: ({
        session: { autoPlay, boardId, id, mode, name, status, userName },
      }) =>
        API.graphql({
          query: createSession,
          variables: {
            input: {
              autoPlay,
              id,
              boardId,
              mode,
              name,
              status,
              userName,
            },
          },
        }),
      queryGetSession: ({ session: { id } }) =>
        API.graphql({
          query: getSession,
          variables: {
            id,
          },
        }),
      queryGetBoard: ({ session: { boardId } }) =>
        API.graphql({
          query: getBoard,
          variables: {
            id: boardId,
          },
        }),
      queryFramesByCreatedAt: ({
        boardData: {
          id,
          frames: { nextToken },
        },
      }) => {
        return API.graphql({
          query: framesByBoardIdAndCreatedAt,
          variables: {
            boardId: id,
            nextToken,
          },
        });
      },
      querySubscribeToNewFrame:
        // See https://aws.amazon.com/blogs/mobile/announcing-server-side-filters-for-real-time-graphql-subscriptions-with-aws-amplify/


          ({ session }) =>
          (send) =>
            API.graphql({
              query: subscribeToNewFrame,
              variables: {
                boardId: session.boardId,
              },
            }).subscribe({
              next: ({ value: { data } }) => {
                const { diffString, duration, machineId } =
                  data.subscribeToNewFrame;
                switch (session.role) {
                  case 'edit':
                    /**
                     * Incoming stage event sent by viewer
                     */
                    // const { type } = JSON.parse(diffString);
                    // send({
                    //   type,
                    //   payload: { diffString, duration, machineId },
                    // });
                    break;
                  default:
                    send({
                      type: 'RECEIVE_BOARD_FRAME',
                      payload: { diffString, duration, machineId },
                    });
                    // assign({
                    //   boardFramesReceiveQueue: ({
                    //     boardFramesReceiveQueue,
                    //   }) => [
                    //     ...boardFramesReceiveQueue,
                    //     { diffString, duration, machineId },
                    //   ],
                    // });
                    break;
                }
              },
            }),
    },
    guards: {},
  }
);

export default sessionMachine;
