import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';

import { boardStyles } from '../styles';
import { GlobalStateContext } from '../../../../globalState';
import { useWindowProperties } from '../../../../helpers';
import DeviceOrientationControl from '../../../Controller/Controls/DeviceOrientation';
import PanelsSlider from '../../../Controller/Controls/PanelsSlider';
import ControlsTabs from '../ControlsTabs';
import MenuOptions from '../MenuOption';

const useStyles = makeStyles((theme) => boardStyles(theme));

const SidebarPortraitRight = ({ handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();
  const windowProperties = useWindowProperties();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const {
    board: { frames },
    editContext,
    editFrameIndex,
    viewContext,
  } = controllerState.context;
  const {
    containerDirection,
    containerDirectionReverse,
    containerOrientation,
  } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  const isEditFrameDisabled =
    sessionState.matches('stream.edit') && editFrameIndex <= frames.length - 1;

  return (
    <>
      {!inEditMode &&
        containerDirection === 'row' &&
        !containerDirectionReverse && (
          <Box
            id='sidebarPortraitRightTopView'
            display='flex'
            flexDirection='column'
          >
            <DeviceOrientationControl />
          </Box>
        )}
      {inEditMode && containerDirection === 'row' && (
        <Box
          id='sidebarPortraitRightTopEdit'
          className={classes.gridItemSidebar}
        >
          <>
            {containerDirectionReverse && (
              <Box display='flex' flexDirection='column'>
                <MenuOptions />
              </Box>
            )}
            <ControlsTabs
              handleActiveMachineChange={handleActiveMachineChange}
            />
          </>
        </Box>
      )}
      {containerDirection === 'column' && (
        <Box display='flex' flexDirection='column'>
          <DeviceOrientationControl />
        </Box>
      )}
      {inEditMode &&
        containerDirection === 'column' &&
        !isEditFrameDisabled && (
          <PanelsSlider
            orientation='vertical'
            style={{
              alignSelf: 'center',
              maxHeight: windowProperties[containerOrientation].height,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        )}
    </>
  );
};

export default SidebarPortraitRight;
