import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import SyncAltOutlined from '@material-ui/icons/SyncAltOutlined';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const PanelsDirectionControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      controllerMachine: {
        boardGrid: {
          containerDirectionReverse,
          containerDirection,
          itemBreakpoint,
        },
      },
    },
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const autoCapture =
    machinesContext[activeMachineId] &&
    machinesContext[activeMachineId].boardSettings.autoCapture;

  const {
    viewContext: {
      controllerMachine: {
        boardGrid: { containerDirectionReverse: viewContainerDirectionReverse },
      },
    },
  } = controllerState.context;

  const onClickToggleDirection = () => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: {
          itemBreakpoint: itemBreakpoint.map((value) => 12 - value).reverse(),
          containerDirectionReverse: !containerDirectionReverse,
        },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  return (
    <Help
      controlId={`controllerMachinePanelsDirectionControl`}
      helpLabelValue={containerDirectionReverse ? 'Reverse' : 'Default'}
      isDisabled={new Set(itemBreakpoint).size <= 2}
      placement='top'
    >
      <IconButton
        aria-label='swap content'
        color={
          containerDirectionReverse !== viewContainerDirectionReverse ||
          autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={new Set(itemBreakpoint).size <= 2}
        onClick={onClickToggleDirection}
        size='small'
        style={{
          transform:
            containerDirection === 'row' ? 'rotate(0deg)' : 'rotate(90deg)',
        }}
      >
        <SyncAltOutlined />
      </IconButton>
    </Help>
  );
};

export default PanelsDirectionControl;
