import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import FlipToBackRounded from '@material-ui/icons/FlipToBackRounded';
import FlipToFrontRounded from '@material-ui/icons/FlipToFrontRounded';

import { IMAGE_SHARED_CONTEXT } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarBackgroundButton = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [localForegroundUrl, setLocalForegroundUrl] = useState({});

  const {
    editContext: {
      imageMachine: { imageField },
    },
    machinesContext: {
      imageMachine: {
        imageCollection: { collectionItems },
        imageField: { isBackgroundSelected },
      },
    },
  } = controllerState.context;

  const handleSelectBackgroundImage = () => {
    const collectionIndex = collectionItems.findIndex(
      ({ url }) => url === imageField.backgroundUrl
    );
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex,
        },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          isBackgroundSelected: true,
        },
      },
    });
    /**
     * When switching to background image, we want to hide the foreground
     * image. In order to do so, the foreground image credit and url need
     * to be cleared. We store the current foreground image url in local
     * state so that we can restore it when switching back to foreground.
     */
    setLocalForegroundUrl({ url: imageField.url, credit: imageField.credit });
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          credit: IMAGE_SHARED_CONTEXT.imageField.credit,
          url: IMAGE_SHARED_CONTEXT.imageField.url,
        },
      },
    });
  };

  const handleSelectForegroundImage = () => {
    /**
     * When switching to background image, check if the foreground image
     * has been temporarily stored in local state when switching to background.
     */
    if (!imageField.url && !!localForegroundUrl.url) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'imageMachine',
          property: 'imageField',
          values: {
            credit: localForegroundUrl.credit,
            url: localForegroundUrl.url,
          },
        },
      });
    }
    const collectionIndex = imageField.url
      ? collectionItems.findIndex(({ url }) => url === imageField.url)
      : 0;
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex,
        },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          isBackgroundSelected: false,
        },
      },
    });
  };

  return (
    <Help
      controlId='imageMachineToolbarBackgroundControl'
      placement='top'
      helpLabelValue={isBackgroundSelected ? 'Background' : 'Main'}
    >
      <IconButton
        color='primary'
        onClick={() =>
          isBackgroundSelected
            ? handleSelectForegroundImage()
            : handleSelectBackgroundImage()
        }
        size='small'
      >
        {!isBackgroundSelected ? <FlipToBackRounded /> : <FlipToFrontRounded />}
      </IconButton>
    </Help>
  );
};

export default ToolbarBackgroundButton;
