import React from 'react';

import ToolbarVerticalAlign from './VerticalAlign';
import ToolbarHorizontalAlign from './HorizontalAlign';
import ToolbarBoardOrientation from './BoardOrientation';

const ChessToolbar = () => (
  <>
    <ToolbarVerticalAlign />
    <ToolbarHorizontalAlign />
    <ToolbarBoardOrientation />
  </>
);

export default ChessToolbar;
