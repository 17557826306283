import controlDictionary from '../Controls';

const topicSteps = [
  'controllerMachineMenuMainCreateBoardHelpControl',
  'controllerMachineMenuMainCreateBoardStreamControl',
  'controllerMachineMenuMainCreateBoardConsentControl',
  'controllerMachineMenuMainCreateBoardNameControl',
  'controllerMachineMenuMainCreateBoardUserNameControl',
  'controllerMachineMenuMainCreateBoardRecordingControl',
];

const helpTopic = {
  topicDictionary: {
    ...controlDictionary,
    controllerMachineMenuMainCreateBoardHelpControl: {
      helpLabel: 'About the Form',
      helpText: [
        'Use this form to start a new board. First enter a title or topic, and your (nick) name. Then select if you want to start recording or streaming.',
        'When in doubt, use the navigation links below to learn about the previous/next form item.',
      ],
      relatedControlIds: [],
    },
  },
  topicLabel: 'Create a Board',
  topicSteps,
  topicType: 'guide',
};

export default helpTopic;
