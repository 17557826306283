import React, { useContext } from 'react';
import T from 'prop-types';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import CancelRounded from '@material-ui/icons/CancelRounded';
import Home from '@material-ui/icons/Home';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import Error from '@material-ui/icons/Error';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import HelpRounded from '@material-ui/icons/HelpRounded';
import Collapse from '@material-ui/core/Collapse';

import { green } from '@material-ui/core/colors';

import helpTopicsByRole from '../Topics';
import TopicSelector from '../TopicSelector';
import { GlobalStateContext } from '../../../globalState';
import { tooltipStyles } from './styles';

const useStyles = makeStyles((theme) => tooltipStyles(theme));

const MainTooltip = ({
  controlId,
  children,
  helpLabel,
  helpLabelValue,
  isDisabled,
  ...restProps
}) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    board: { mode, role },
    helpControl: {
      helpTopicIndex,
      helpTopicId,
      minimizedControlIds,
      renderedControlIds,
      showTopics,
    },
  } = controllerState.context;
  const helpTopics = helpTopicsByRole[role][mode];
  const { topicDictionary, topicLabel, topicSteps, topicType } =
    helpTopics[helpTopicId] || {};

  const isRelatedHelpId =
    topicDictionary[topicSteps[helpTopicIndex]].relatedControlIds.includes(
      controlId
    );

  const { helpLabel: unformattedHelpLabel, helpText } = isRelatedHelpId
    ? topicDictionary[controlId] || {}
    : topicDictionary[topicSteps[helpTopicIndex]] || {};

  const nextStepIndex =
    helpTopicIndex === topicSteps.length - 1 ? 1 : helpTopicIndex + 1;
  const prevStepIndex =
    helpTopicIndex <= 1 ? topicSteps.length - 1 : helpTopicIndex - 1;

  const { helpLabel: nextHelpLabel } =
    topicDictionary[topicSteps[nextStepIndex]];
  const { helpLabel: prevHelpLabel } =
    topicDictionary[topicSteps[prevStepIndex]];

  const nextHelpLabelLink =
    topicType === 'tutorial' ? (!!nextStepIndex ? 'Next' : '') : nextHelpLabel;
  const isNextControlIdRendered = renderedControlIds.has(
    topicSteps[nextStepIndex]
  );

  const prevHelpLabelLink =
    topicType === 'tutorial' ? (!!helpTopicIndex ? `Back` : '') : prevHelpLabel;
  const isPrevControlIdRendered = renderedControlIds.has(
    topicSteps[prevStepIndex]
  );

  const setHelpIndex = (helpTopicIndex) => {
    const { topicDictionary, topicSteps } = helpTopics[helpTopicId];
    const controlId = topicSteps[helpTopicIndex];
    const { relatedControlIds } = topicDictionary[controlId];
    relatedControlIds.forEach(minimizedControlIds.add, minimizedControlIds);
    controllerSend({
      type: 'CONTROLLER_PROP_VALUES',
      payload: {
        property: 'helpControl',
        values: {
          helpTopicIndex,
          minimizedControlIds,
          openControlIds: new Set([controlId]),
          openRelatedControlIds: new Set(relatedControlIds),
          renderedControlIds: new Set(),
        },
      },
    });
  };

  /**
   * When a Tooltip's children prop contains a disabled component it needs to be
   * wrapped in an element to prevent Mui browser warnings
   * See {isDisabled ? <div>{children}</div> : children} below
   */
  return (
    <ClickAwayListener onClickAway={() => {}}>
      <Tooltip
        arrow
        // classes={{ tooltip: classes.mainTooltip }}
        interactive
        title={
          <>
            <Box m={0.5}>
              <Box
                alignItems='center'
                display='flex'
                justifyContent='space-between'
              >
                <IconButton
                  onClick={() => {
                    minimizedControlIds.has(controlId)
                      ? minimizedControlIds.delete(controlId)
                      : minimizedControlIds.add(controlId);
                    controllerSend({
                      type: 'CONTROLLER_PROP_VALUES',
                      payload: {
                        property: 'helpControl',
                        values: {
                          minimizedControlIds,
                        },
                      },
                    });
                  }}
                  size='small'
                >
                  {!minimizedControlIds.has(controlId) ? (
                    <RemoveCircle
                      fontSize='small'
                      style={{ color: green[500] }}
                    />
                  ) : (
                    <AddCircle fontSize='small' style={{ color: green[500] }} />
                  )}
                </IconButton>
                <Typography variant='overline'>
                  {topicType === 'tutorial' ? 'Howto' : topicType}: {topicLabel}
                </Typography>
                <IconButton
                  onClick={() =>
                    controllerSend({
                      type: 'CONTROLLER_PROP_VALUES',
                      payload: {
                        property: 'helpControl',
                        values: {
                          isHelpActive: false,
                        },
                      },
                    })
                  }
                  color='secondary'
                  size='small'
                >
                  <CancelRounded fontSize='small' />
                </IconButton>
              </Box>
              {topicType === 'tutorial' ? (
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Typography align='center'>
                    {helpTopicIndex ? `${helpTopicIndex}: ` : ''}
                    {helpLabel || unformattedHelpLabel}
                  </Typography>
                  {!!helpTopicIndex && isNextControlIdRendered && (
                    <CheckCircleOutlined
                      fontSize='small'
                      style={{ marginLeft: 4 }}
                    />
                  )}
                  {!!helpTopicIndex && !isNextControlIdRendered && (
                    <Error
                      color='error'
                      fontSize='small'
                      style={{ marginLeft: 4 }}
                    />
                  )}
                </Box>
              ) : (
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Typography align='center'>
                    {helpLabel || unformattedHelpLabel}
                  </Typography>
                </Box>
              )}
              <Collapse in={!minimizedControlIds.has(controlId)}>
                {helpText.map((text, index) => (
                  <p key={index} color='inherit'>
                    {text}
                  </p>
                ))}
              </Collapse>
              <Box display='flex' flex={1} justifyContent='space-between'>
                <Chip
                  color='primary'
                  className={classes.stepLink}
                  disabled={!isPrevControlIdRendered}
                  icon={<HelpRounded />}
                  label={prevHelpLabelLink}
                  onClick={() => setHelpIndex(prevStepIndex)}
                  size='small'
                  variant='outlined'
                />
                <IconButton
                  className={classes.stepLink}
                  disabled={!helpTopicIndex}
                  onClick={() => setHelpIndex(0)}
                  size='small'
                >
                  <Home fontSize='small' />
                </IconButton>
                <Chip
                  color='primary'
                  className={classes.stepLink}
                  disabled={!isNextControlIdRendered}
                  icon={<HelpRounded />}
                  label={nextHelpLabelLink}
                  onClick={() => setHelpIndex(nextStepIndex)}
                  size='small'
                  variant='outlined'
                />
              </Box>
            </Box>
            {showTopics &&
              !minimizedControlIds.has(controlId) &&
              !helpTopicIndex && <TopicSelector />}
          </>
        }
        open={controlId === topicSteps[helpTopicIndex]}
        {...restProps}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
};

MainTooltip.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default MainTooltip;
