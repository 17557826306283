import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import { GlobalStateContext } from '../../globalState';
import SidenoteView from './View';
import SidenoteEdit from './Edit';

const Sidenote = ({
  displayMainSection,
  gridMainSectionSize,
  isMainSectionHidden,
}) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const {
    editControl: { isEditFrameModeActive },
  } = controllerState.context;

  if (!isMainSectionHidden && displayMainSection) {
    if (
      isEditFrameModeActive &&
      (sessionState.matches('record.edit') ||
        sessionState.matches('stream.edit'))
    ) {
      return <SidenoteEdit gridMainSectionSize={gridMainSectionSize} />;
    }
    if (
      !isEditFrameModeActive ||
      sessionState.matches('record.view') ||
      sessionState.matches('stream.view')
    ) {
      return <SidenoteView gridMainSectionSize={gridMainSectionSize} />;
    }
  }
  return null;
};

export default Sidenote;
