import React, { useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { IconButton } from '@material-ui/core';

import { controllerStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import { globalStyles } from '../../themes/globalStyles';
import { MachineDrawerToggle } from '../../components';
import { MACHINES_TOOLBAR_DICTIONARY } from './constants';
import { useWindowProperties } from '../../helpers';
import BoardTitle from '../Modal/Alert/BoardTitle';
import FrameDuration from './Controls/FrameDuration';
import FrameOrientation from './Controls/FrameOrientation';
import FramesSlider from './Controls/FramesSlider';
import FrameTheme from './Controls/FrameTheme';
import Help from '../Help';
import PanelsDirection from './Controls/PanelsDirection';
import PanelsOrientation from './Controls/PanelsOrientation';
import ProgressBar from '../../components/ProgressBar';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...controllerStyles(theme),
}));

const Controller = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const windowProperties = useWindowProperties();
  const theme = useTheme();
  const classes = useStyles();
  const [helpPanelMachineId, setHelpPanelMachineId] = useState(false);

  const {
    board: { frames },
    editContext: {
      controllerMachine: {
        boardGrid: { containerOrientation },
      },
    },
    editControl: { isEditFrameModeActive },
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    modalControl: { openModalIds },
    playControl: { frameTimeElapsed },
    viewFrameIndex,
  } = controllerState.context;
  const {
    Drawer: MachineDrawer,
    DrawerTabs: MachineDrawerTabs,
    Toolbar: MachineToolbar,
  } = MACHINES_TOOLBAR_DICTIONARY[activeMachineId] || {};

  const isActiveMachineDrawerCollapsed =
    activeMachineId &&
    machinesContext[activeMachineId].toolbarDrawer.isDrawerCollapsed;

  const progressBarValue =
    frames[viewFrameIndex] && frames[viewFrameIndex].duration > 500
      ? Math.round((frameTimeElapsed / frames[viewFrameIndex].duration) * 100) -
        1
      : 100;

  return (
    <>
      <AppBar
        className={classes.appBarTop}
        color='transparent'
        position='fixed'
        // Compensate for the height of the top  progress bar
        style={controllerState.matches('view') ? {} : { marginTop: 4 }}
      >
        {controllerState.matches('view') && (
          <ProgressBar value={progressBarValue} />
        )}
      </AppBar>

      <AppBar
        className={classes.appBarBottom}
        color='transparent'
        position='fixed'
        // Compensate for the height of the bottom progress bar
        style={controllerState.matches('view') ? {} : { marginBottom: 4 }}
      >
        <Container disableGutters maxWidth={false}>
          <Grid container>
            <Grid item xs={12}>
              <Fade in={!openModalIds.length}>
                <Box
                  id='controllerContainer'
                  display='flex'
                  flexDirection='column'
                  height='100%'
                  justifyContent='flex-end'
                >
                  <Grid container>
                    {isEditFrameModeActive &&
                      activeMachineId &&
                      MachineDrawerTabs &&
                      isActiveMachineDrawerCollapsed && (
                        <Grid item xs={12}>
                          <Help
                            controlId={`${activeMachineId}ToolbarDrawerTabsControl`}
                            placement='top'
                          >
                            <Box
                              id='controllerDrawerTabsContainer'
                              display='flex'
                              flex={1}
                              ml={0.5}
                              justifyContent='center'
                            >
                              <Paper
                                elevation={2}
                                style={{
                                  backgroundColor: 'transparent',
                                }}
                              >
                                <MachineDrawerTabs />
                              </Paper>
                              <IconButton
                                aria-label='toolbar info'
                                color={
                                  helpPanelMachineId ? 'secondary' : 'primary'
                                }
                                onClick={() =>
                                  setHelpPanelMachineId((value) => !value)
                                }
                                size='small'
                              >
                                <InfoRounded />
                              </IconButton>
                            </Box>
                          </Help>
                        </Grid>
                      )}
                    {isEditFrameModeActive &&
                      activeMachineId &&
                      MachineDrawer && (
                        <>
                          <Grid item xs={12}>
                            <Paper
                              elevation={2}
                              style={{
                                backgroundColor: 'transparent',
                                // No border when drawer is closed or else it will be visible
                                border: isActiveMachineDrawerCollapsed
                                  ? `${theme.spacing(0.5)}px solid ${
                                      theme.palette.default.main
                                    }`
                                  : 0,
                                borderRadius: theme.spacing(0.5),
                                maxWidth:
                                  windowProperties[containerOrientation].width,
                                margin: 'auto',
                              }}
                            >
                              <HelpPanel
                                isHidden={
                                  !helpPanelMachineId ||
                                  !isActiveMachineDrawerCollapsed
                                }
                                panelId={activeMachineId}
                              />
                              <MachineDrawer />
                            </Paper>
                          </Grid>
                        </>
                      )}
                    {isEditFrameModeActive && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            id='controllerToolbarContainer'
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: theme.spacing(1),
                            }}
                          >
                            <Box
                              alignItems='center'
                              className={classes.toolbarPaper}
                              component={Paper}
                              display='flex'
                              elevation={0}
                              flexDirection='row'
                              id='controllerToolbarMachineContainer'
                            >
                              {activeMachineId && MachineToolbar && (
                                <>
                                  <MachineDrawerToggle />
                                  <MachineToolbar />
                                </>
                              )}
                            </Box>
                            <Box
                              className={classes.toolbarPaper}
                              component={Paper}
                              elevation={0}
                              id='controllerToolbarStandardContainer'
                              alignItems='center'
                              display='flex'
                            >
                              <FrameDuration />
                              <FrameTheme />
                              <PanelsOrientation />
                              <PanelsDirection />
                              <FrameOrientation />
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FramesSlider />
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Container>
        {controllerState.matches('view') && (
          <ProgressBar id='delayProgress' value={progressBarValue} />
        )}
      </AppBar>
      <BoardTitle />
    </>
  );
};

export default Controller;
