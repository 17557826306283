export const imageStyles = (theme) => ({
  backgroundImage: {
    height: '100%',
    width: '100%',
  },
  imageCredit: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    fontSize: 12,
    position: 'absolute',
    top: '8px',
    right: '8px',
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '& a:nth-child(2)': {
      paddingLeft: '4px',
    },
  },
});
