import React, { useContext, useEffect, useState } from 'react';
import { alpha } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { MuiColorInput } from 'mui-color-input';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { menusStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';
import {
  themeColors,
  themeDictionary,
  themeModes,
} from '../../../themes/constants';

const useStyles = makeStyles((theme) => menusStyles(theme));

const BoardThemeMenu = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [newColors, setNewColors] = useState({});
  const [contrastColors, setContrastColors] = useState({});

  const muiTheme = useTheme();
  const showInputField = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const showExtendedText = useMediaQuery(muiTheme.breakpoints.up('sm'));
  const classes = useStyles();

  const {
    editContext: {
      controllerMachine: { boardTheme },
      sidenoteMachine: {
        sidenoteField: { fontFamily: sidenoteFontFamily },
      },
      textMachine: {
        textField: { fontFamily: textFontFamily },
      },
    },
    machinesContext: {
      controllerMachine: {
        boardTheme: { type: themeMode },
      },
    },
  } = controllerState.context;

  const handleOnChangeColor = ({ color, key }) => {
    setNewColors((theme) => ({ ...theme, [key]: color }));
    var reg = /^#([0-9a-f]{3}){1,2}$/i;
    if (reg.test(color)) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardTheme',
          values: {
            [key]: color,
          },
        },
      });
    }
  };

  useEffect(() => {
    setNewColors(boardTheme);
  }, [boardTheme]);

  useEffect(() => {
    if (!isEmpty(newColors)) {
      setContrastColors(
        ({
          backgroundDefault,
          backgroundPaper,
          textSidePanel,
          textMainPanel,
        }) => ({
          backgroundDefault: newColors.backgroundDefault
            ? muiTheme.palette.getContrastText(newColors.backgroundDefault)
            : backgroundDefault,
          backgroundPaper: newColors.backgroundPaper
            ? muiTheme.palette.getContrastText(newColors.backgroundPaper)
            : backgroundPaper,
          textSidePanel: newColors.textSidePanel
            ? muiTheme.palette.getContrastText(newColors.textSidePanel)
            : textSidePanel,
          textMainPanel: newColors.textMainPanel
            ? muiTheme.palette.getContrastText(newColors.textMainPanel)
            : textMainPanel,
        })
      );
    }
  }, [muiTheme, newColors]);

  return (
    !isEmpty(newColors) &&
    !isEmpty(contrastColors) && (
      <>
        <Card>
          <CardContent>
            <FormControl fullWidth>
              <FormLabel>Select mode:</FormLabel>
            </FormControl>
            <Grid container spacing={1}>
              {themeModes.map(({ label, value }) => (
                <Grid item key={value} xs={6} sm={3}>
                  <Box
                    style={{
                      borderRadius: muiTheme.spacing(0.5),
                      paddingLeft: muiTheme.spacing(1),
                    }}
                  >
                    <FormControlLabel
                      checked={themeMode === value}
                      control={<Radio color='default' size='small' />}
                      label={label}
                      onChange={({ target: { value } }) =>
                        controllerSend({
                          type: 'UPDATE_MACHINES_CONTEXT',
                          payload: {
                            machineId: 'controllerMachine',
                            property: 'boardTheme',
                            values: {
                              type: value,
                            },
                          },
                        })
                      }
                      value={value}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardContent>
            <FormControl fullWidth>
              <FormLabel>Select theme:</FormLabel>
            </FormControl>
            <Grid container spacing={1}>
              {themeColors.map(({ label, value }) => (
                <Grid item key={value} xs={6} sm={3}>
                  <Box
                    onClick={() => {
                      // Switch theme to the selected mode
                      controllerSend({
                        type: 'UPDATE_MACHINES_CONTEXT',
                        payload: {
                          machineId: 'controllerMachine',
                          property: 'boardTheme',
                          values: { type: themeMode },
                        },
                      });
                      // Set the board and toolbar theme colors
                      controllerSend({
                        type: 'UPDATE_EDIT_CONTEXT',
                        payload: {
                          machineId: 'controllerMachine',
                          property: 'boardTheme',
                          values: themeDictionary[value][themeMode].boardTheme,
                        },
                      });
                      controllerSend({
                        type: 'UPDATE_EDIT_CONTEXT',
                        payload: {
                          machineId: 'controllerMachine',
                          property: 'toolbarTheme',
                          values:
                            themeDictionary[value][themeMode].toolbarTheme,
                        },
                      });
                    }}
                    style={{
                      color:
                        themeDictionary[value][themeMode].boardTheme
                          .textSidePanel,
                      cursor: 'pointer',
                      backgroundColor:
                        themeDictionary[value][themeMode].boardTheme
                          .backgroundDefault,
                      borderRadius: muiTheme.spacing(0.5),
                      paddingLeft: muiTheme.spacing(1),
                    }}
                  >
                    <FormControlLabel
                      checked={isEqual(
                        boardTheme,
                        themeDictionary[value][themeMode].boardTheme
                      )}
                      control={
                        <Radio
                          size='small'
                          style={{
                            color:
                              themeDictionary[value][themeMode].boardTheme
                                .textSidePanel,
                          }}
                        />
                      }
                      label={label}
                      value={value}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <CardContent>
            <FormControl fullWidth>
              <FormLabel>Change colors:</FormLabel>
            </FormControl>
            <Grid
              container
              spacing={1}
              alignItems='center'
              style={{
                backgroundColor: newColors.backgroundDefault,
                borderRadius: muiTheme.spacing(0.5),
                marginTop: muiTheme.spacing(0.5),
                padding: muiTheme.spacing(0.5),
              }}
            >
              <Grid item xs={6}>
                <MuiColorInput
                  className={classes.colorInput}
                  fallbackValue='#ffffff'
                  fullWidth
                  format='hex'
                  InputLabelProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Typography
                        style={{ color: contrastColors.backgroundDefault }}
                      >
                        {showExtendedText ? 'Background color' : 'Background'}
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                      display: showInputField ? 'block' : 'none',
                      padding: 0,
                      width: '4.5rem',
                    },
                  }}
                  isAlphaHidden
                  onChange={(_, colors) =>
                    handleOnChangeColor({
                      color: colors.hex,
                      key: 'backgroundDefault',
                    })
                  }
                  size='small'
                  style={{
                    color: contrastColors.backgroundDefault,
                    padding: muiTheme.spacing(1),
                  }}
                  value={newColors.backgroundDefault}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={6}>
                <MuiColorInput
                  className={classes.colorInput}
                  fallbackValue='#ffffff'
                  fullWidth
                  format='hex'
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Typography
                        style={{ color: contrastColors.backgroundDefault }}
                      >
                        {showExtendedText ? 'Paper color' : 'Paper'}
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                      display: showInputField ? 'block' : 'none',
                      padding: 0,
                      width: '5rem',
                    },
                  }}
                  isAlphaHidden
                  onChange={(_, colors) =>
                    handleOnChangeColor({
                      color: colors.hex,
                      key: 'backgroundPaper',
                    })
                  }
                  size='small'
                  style={{
                    // backgroundColor: contrastColors.backgroundDefault,
                    padding: muiTheme.spacing(1),
                  }}
                  value={newColors.backgroundPaper}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={6}>
                <MuiColorInput
                  className={classes.colorInput}
                  fallbackValue='#ffffff'
                  format='hex'
                  fullWidth
                  InputLabelProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Typography
                        style={{
                          color: newColors.textSidePanel,
                          fontFamily: sidenoteFontFamily,
                        }}
                      >
                        {showExtendedText ? 'Sidenote color' : 'Sidenote'}
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                      display: showInputField ? 'block' : 'none',
                      padding: 0,
                      width: '5rem',
                    },
                  }}
                  isAlphaHidden
                  onChange={(_, colors) =>
                    handleOnChangeColor({
                      color: colors.hex,
                      key: 'textSidePanel',
                    })
                  }
                  size='small'
                  style={{
                    backgroundColor: newColors.backgroundDefault,
                    padding: muiTheme.spacing(1),
                  }}
                  value={newColors.textSidePanel}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    backgroundColor: alpha(
                      newColors.backgroundPaper || boardTheme.backgroundPaper,
                      0.8
                    ),
                    borderRadius: 4,
                    color: newColors.textSidePanel,
                    fontFamily: sidenoteFontFamily,
                    padding: muiTheme.spacing(1),
                  }}
                >
                  {showExtendedText ? 'Sidenote color' : 'Sidenote'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiColorInput
                  className={classes.colorInput}
                  fallbackValue='#ffffff'
                  format='hex'
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Typography
                        style={{
                          color: newColors.textMainPanel,
                          fontFamily: textFontFamily,
                        }}
                      >
                        {showExtendedText ? 'Text color' : 'Text'}
                      </Typography>
                    ),
                  }}
                  inputProps={{
                    style: {
                      color: contrastColors.backgroundDefault,
                      display: showInputField ? 'block' : 'none',
                      padding: 0,
                      width: '5rem',
                    },
                  }}
                  isAlphaHidden
                  onChange={(_, colors) =>
                    handleOnChangeColor({
                      color: colors.hex,
                      key: 'textMainPanel',
                    })
                  }
                  size='small'
                  style={{
                    backgroundColor: newColors.backgroundDefault,
                    padding: muiTheme.spacing(1),
                  }}
                  value={newColors.textMainPanel}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    backgroundColor: alpha(
                      newColors.backgroundPaper || boardTheme.backgroundPaper,
                      0.8
                    ),
                    borderRadius: 4,
                    color: newColors.textMainPanel,
                    fontFamily: textFontFamily,
                    padding: muiTheme.spacing(1),
                  }}
                >
                  {showExtendedText ? 'Text color' : 'Text'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    )
  );
};

export default BoardThemeMenu;
