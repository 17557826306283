import { convertHexToHexWithAlpha } from '../../../helpers';

export const drawerStyles = (theme) => ({
  drawerInput: {
    marginTop: theme.spacing(0.5),
  },
  drawerPaper: {
    backgroundColor: convertHexToHexWithAlpha({
      hex: theme.palette.background.default,
      alpha: 0.5,
    }),
  },
  transparentPaper: {
    backgroundColor: 'transparent',
  },
});
