import { alpha } from '@material-ui/core';

export const globalStyles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
    },
  },
  activeMachineContainer: {
    borderRadius: theme.spacing(0.5),
    outline: `2px dashed ${theme.palette.primary[theme.palette.type]} `,
  },
  activeMachineContainerEdit: {
    borderRadius: theme.spacing(0.5),
    outline: `2px dashed ${theme.palette.secondary[theme.palette.type]} `,
  },
  activeMachineBackgroundContainer: {
    borderRadius: theme.spacing(0.5),
    border: `3px dashed ${theme.palette.primary[theme.palette.type]}`,
  },
  activeMachineBackgroundContainerEdit: {
    borderRadius: theme.spacing(0.5),
    border: `3px dashed ${theme.palette.secondary[theme.palette.type]}`,
  },
  drawerPaper: {
    backgroundColor: alpha(theme.palette.default[theme.palette.type], 0.9),
  },
  drawerTabButton: {
    backgroundColor: theme.palette.info[theme.palette.type],
    '&:hover': {
      backgroundColor: theme.palette.success[theme.palette.type],
    },
  },
  drawerTabButtonSelected: {
    backgroundColor: theme.palette.success[theme.palette.type],

    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  helpPanelContainer: {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.background.contrastText,
    padding: theme.spacing(2),
    width: '100%',
    '& div > span > p:first-child, & div > span > p:last-child': {
      margin: 0,
    },
  },
  helpPanelCloseText: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    flexWrap: 'nowrap',
    fontSize: 'inherit',
    '&:hover': {
      color: theme.palette.success.main,
    },
  },
  helpPanelMachineContainer: {
    backgroundColor: theme.palette.default.main,
    color: theme.palette.default.contrastText,
    padding: theme.spacing(1.5),
    width: '100%',
    '& div > span > p:first-child, & div > span > p:last-child': {
      margin: 0,
    },
    '& div.html': {
      padding: theme.spacing(1.5),
    },
  },
  iconPulsating: {
    animationName: '$blinker',
    animationDirection: 'alternate-reverse',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0.2 },
  },
  inactiveMachineContainer: {
    borderRadius: theme.spacing(0.5),
  },
  inactiveMachineBackgroundContainer: {},
  inputAdornmentIconButton: {
    backgroundColor: theme.palette.success[theme.palette.type],
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.success[theme.palette.type],
    },
  },

  machineToolbarPopper: {
    backgroundColor: theme.palette.default[theme.palette.type],
    borderRadius: 4,
  },
  machineToolbarPopperIconButton: {
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5),
    minWidth: 0,
    zIndex: 1275,
  },
  machineToolbarPopperIconButtonActive: {
    margin: theme.spacing(0.5),
    minWidth: 0,
    zIndex: 1275,

    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  machineToolbarPopperSlider: {
    alignContent: 'center',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.primary}`,
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(5),
    zIndex: theme.zIndex.popper,
  },
  machineToolbarSelect: {
    '& .MuiOutlinedInput-input': {
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
  },
  machineToolbarSlider: {
    backgroundColor: theme.palette.default[theme.palette.type],
    borderRadius: 4,
  },
  modalPaper: {
    backgroundColor: theme.palette.default.main,
  },
  toolbarPaper: {
    backgroundColor: theme.palette.default[theme.palette.type],
  },
  transparentPaper: {
    backgroundColor: 'transparent',
  },
});
