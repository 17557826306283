import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CancelRounded from '@material-ui/icons/CancelRounded';

import { BOARD_MACHINES } from '../constants';
import { IMAGE_SHARED_CONTEXT } from '../../Image/constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const PanelsBackgroundVisibility = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const {
    editContext,
    editControl: { isEditFrameModeActive },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;
  const { backgroundMachineId } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  const { label: helpLabelValue } = backgroundMachineId
    ? BOARD_MACHINES.find((machine) => machine.value === backgroundMachineId)
    : {};

  return inEditMode &&
    backgroundMachineId &&
    activeMachineId === backgroundMachineId &&
    isEditFrameModeActive ? (
    <Box position='absolute' top='32px' left='32px' zIndex={3000}>
      <Help
        controlId='controllerMachinePanelsBackgroundVisibilityControl'
        helpLabelValue={helpLabelValue}
        placement='right'
      >
        <IconButton
          onClick={() => {
            controllerSend({
              type: 'UPDATE_EDIT_CONTEXT',
              payload: {
                machineId: 'controllerMachine',
                property: 'boardGrid',
                values: {
                  backgroundMachineId: '',
                },
              },
            });
            if (backgroundMachineId === 'imageMachine') {
              controllerSend({
                type: 'UPDATE_MACHINES_CONTEXT',
                payload: {
                  machineId: 'imageMachine',
                  property: 'imageField',
                  values: {
                    isBackgroundSelected: false,
                  },
                },
              });
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  machineId: 'imageMachine',
                  property: 'imageField',
                  values: {
                    backgroundCredit:
                      IMAGE_SHARED_CONTEXT.imageField.backgroundCredit,
                    backgroundUrl:
                      IMAGE_SHARED_CONTEXT.imageField.backgroundUrl,
                  },
                },
              });
            }
          }}
          style={{
            color: theme.palette.text.backgroundContrastText,
          }}
        >
          <CancelRounded style={{ fontSize: 40 }} />
        </IconButton>
      </Help>
    </Box>
  ) : null;
};

export default PanelsBackgroundVisibility;
