import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { GlobalStateContext } from '../../../globalState';
import { drawerStyles } from './styles';
import { globalStyles } from '../../../themes/globalStyles';
import GiphyGrid from './GiphyGrid';
import UnsplashGrid from './UnsplashGrid';
import UrlGrid from './UrlGrid';
import DrawerInput from './Input';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...drawerStyles(theme),
}));

const ImageDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const gridColumns = largeScreen ? 6 : mediumScreen ? 5 : smallScreen ? 4 : 3;

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { activeTabMachineId, backgroundMachineId },
      },
    },
    machinesContext: {
      imageMachine: {
        boardSettings: { autoCapture },
        imageCollection: { collectionItems },
        imageField: { isBackgroundSelected },
        toolbarDrawer: {
          drawerTab,
          gridKeys,
          imageUrl,
          isDrawerCollapsed,
          isValidImageUrl,
          searchQuery,
        },
      },
    },
  } = controllerState.context;

  const handleImageAdd = (imageItem) => {
    const index = collectionItems.findIndex(
      (item) => item.url === imageItem.url
    );
    if (index < 0) {
      collectionItems.push(imageItem);
    }
    const { credit, url } = index < 0 ? imageItem : collectionItems[index];

    /**
     * Added image is set to foreground or background, depending on
     * the isBackgroundSelected flag set by control at end onf search input.
     */
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: isBackgroundSelected
          ? { backgroundCredit: credit, backgroundUrl: url }
          : { credit, url },
      },
    });
    if (isBackgroundSelected && backgroundMachineId !== 'imageMachine') {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardGrid',
          values: {
            backgroundMachineId: 'imageMachine',
          },
        },
      });
    }
    if (!isBackgroundSelected && activeTabMachineId !== 'imageMachine') {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardGrid',
          values: {
            activeTabMachineId: 'imageMachine',
          },
        },
      });
    }

    // Add image to carousel collection
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex: index < 0 ? collectionItems.length - 1 : index,
          collectionItems,
        },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values: {
          imageUrl: '',
          isValidImageUrl: false,
        },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  return (
    <Collapse in={isDrawerCollapsed} unmountOnExit>
      <Grid container>
        <Grid item xs={12} className={classes.machineDrawerContainer}>
          <Paper
            id='scrollablePaper'
            elevation={0}
            style={{
              height: '25vh',
              overflowX: 'hidden',
              overflowY: 'auto',
              textAlign: 'center',
            }}
          >
            {['gif', 'tag'].includes(drawerTab) && (
              <GiphyGrid
                gridColumns={gridColumns}
                gridKeys={gridKeys}
                handleImageAdd={handleImageAdd}
                imageType={drawerTab === 'gif' ? 'gifs' : 'stickers'}
                searchQuery={searchQuery}
              />
            )}
            {['img'].includes(drawerTab) && (
              <UnsplashGrid
                gridColumns={gridColumns}
                gridKeys={gridKeys}
                handleImageAdd={handleImageAdd}
                imageType='photos'
                searchQuery={searchQuery}
              />
            )}
            {['url'].includes(drawerTab) && (
              <UrlGrid
                handleImageAdd={handleImageAdd}
                imageUrl={imageUrl}
                isValidImageUrl={isValidImageUrl}
              />
            )}
          </Paper>
          <Paper elevation={0} className={classes.toolbarPaper}>
            <DrawerInput />
          </Paper>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default ImageDrawer;
