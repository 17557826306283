import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import InfoRounded from '@material-ui/icons/InfoRounded';
import TerrainOutlined from '@material-ui/icons/TerrainOutlined';
import TerrainRounded from '@material-ui/icons/TerrainRounded';

import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarOverlayControl = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    editContext: {
      mapMachine: { mapField },
    },
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      mapMachine: { mapField: viewMapField },
    },
  } = controllerState.context;

  const hasLocation = !!mapField.center.lat && !!mapField.center.lng;

  return (
    <>
      {['satellite', 'hybrid'].includes(mapField.mapTypeId) && (
        <Help
          controlId='mapMachineToolbarMapOverlayControl'
          helpLabelValue={
            mapField.mapTypeId === 'satellite' ? 'none' : 'labels'
          }
          isDisabled={!hasLocation}
          placement='top'
        >
          <IconButton
            color={
              mapField.mapTypeId !== viewMapField.mapTypeId || autoCapture
                ? 'secondary'
                : 'primary'
            }
            disabled={!hasLocation}
            onClick={() => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  autoCapture: false,
                  machineId: 'mapMachine',
                  property: 'mapField',
                  values: {
                    mapTypeId:
                      mapField.mapTypeId === 'hybrid' ? 'satellite' : 'hybrid',
                  },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
            size='small'
          >
            {['satellite'].includes(mapField.mapTypeId) ? (
              <InfoOutlined />
            ) : (
              <InfoRounded />
            )}
          </IconButton>
        </Help>
      )}
      {['roadmap', 'terrain'].includes(mapField.mapTypeId) && (
        <Help
          controlId='mapMachineToolbarMapOverlayControl'
          helpLabelValue={mapField.mapTypeId === 'roadmap' ? 'None' : 'Terrain'}
          placement='top'
        >
          <IconButton
            color={
              mapField.mapTypeId !== viewMapField.mapTypeId || autoCapture
                ? 'secondary'
                : 'primary'
            }
            onClick={() => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  autoCapture: false,
                  machineId: 'mapMachine',
                  property: 'mapField',
                  values: {
                    mapTypeId:
                      mapField.mapTypeId === 'terrain' ? 'roadmap' : 'terrain',
                  },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
            size='small'
          >
            {['roadmap'].includes(mapField.mapTypeId) ? (
              <TerrainOutlined />
            ) : (
              <TerrainRounded />
            )}
          </IconButton>
        </Help>
      )}
    </>
  );
};

export default ToolbarOverlayControl;
