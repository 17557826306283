import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Input from './Input';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ChessDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();
  const {
    machinesContext: {
      chessMachine: {
        toolbarDrawer: { drawerTab, isDrawerCollapsed },
      },
    },
  } = controllerState.context;

  return (
    <Collapse in={isDrawerCollapsed} unmountOnExit>
      <Grid container>
        <Grid item xs={12} className={classes.machineDrawerContainer}>
          <Paper className={classes.toolbarPaper} elevation={0}>
            {drawerTab === 'position' && <Input />}
          </Paper>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default ChessDrawer;
