import React, { useEffect, useState } from 'react';
import { useActor, useInterpret } from '@xstate/react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Amplify } from '@aws-amplify/core';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import GoogleFontLoader from 'react-google-font-loader';
import { darken, lighten } from '@material-ui/core';

import { GlobalStateContext } from './globalState';
import controllerMachine from './containers/Controller/machine';
import sessionMachine from './containers/Session/machine';
import userMachine from './containers/User/machine';
import Board from './containers/Board';
import awsConfig from './aws-exports';
import { GOOGLE_FONTS_LIST } from './themes/constants';
import globalThemeOverrides from './themes/globalTheme';

Amplify.configure(awsConfig);

function App() {
  const controllerService = useInterpret(controllerMachine);
  const sessionService = useInterpret(sessionMachine);
  const userService = useInterpret(userMachine);
  const [controllerState] = useActor(controllerService);
  const [globalTheme, setGlobalTheme] = useState(
    createTheme({
      palette: {
        default: {
          contrastText: '#000000',
          dark: 'rgb(0, 0, 0)',
          light: 'rgb(0, 0, 0)',
          main: '#000000',
        },
      },
    })
  );

  const {
    viewContext,
    editContext,
    machinesContext: {
      controllerMachine: {
        boardTheme: { type: boardThemeType },
      },
    },
  } = controllerState.context;

  const { boardTheme, toolbarTheme } = controllerState.matches('view')
    ? viewContext.controllerMachine
    : editContext.controllerMachine;

  /**
   * Preventing the Mui theme from rebuilding on every render
   */
  useEffect(() => {
    const muiTheme = createTheme({
      palette: {
        default: {
          contrastText: toolbarTheme.defaultContrastText,
          dark: darken(toolbarTheme.defaultMain, 0.25),
          light: lighten(toolbarTheme.defaultMain, 0.25),
          main: toolbarTheme.defaultMain,
        },
        background: {
          default: boardTheme.backgroundDefault,
          paper: boardTheme.backgroundPaper,
        },
        primary: {
          contrastText: toolbarTheme.primaryContrastText,
          main: toolbarTheme.primaryMain,
        },
        secondary: {
          contrastText: toolbarTheme.secondaryContrastText,
          main: toolbarTheme.secondaryMain,
        },
        text: {
          mainPanel: boardTheme.textMainPanel,
          primary: toolbarTheme.defaultContrastText,
          sidePanel: boardTheme.textSidePanel,
        },
        type: 'dark',
      },
    });
    const globalTheme = globalThemeOverrides({ muiTheme });
    setGlobalTheme(globalTheme);
  }, [boardTheme, boardThemeType, toolbarTheme]);

  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    resetErrorBoundary();
    return (
      <div role='alert'>
        <h4>Something went wrong:</h4>
        <p style={{ color: 'red' }}>{error.message}</p>
      </div>
    );
  }

  return (
    <div className='App'>
      <GoogleFontLoader fonts={GOOGLE_FONTS_LIST} />
      <ErrorBoundary fallbackRender={fallbackRender}>
        <GlobalStateContext.Provider
          value={{
            controllerService,
            sessionService,
            userService,
          }}
        >
          <ThemeProvider theme={globalTheme}>
            <Router>
              <Routes>
                <Route path='/board/:sessionId' element={<Board />} />
                <Route
                  path='/board/:sessionId/:sessionMode'
                  element={<Board />}
                />
                <Route
                  exact
                  path='/:pathBoardDirectoryId'
                  element={<Board />}
                />
                <Route exact path='/' element={<Board />} />
              </Routes>
            </Router>
            <CssBaseline />
          </ThemeProvider>
        </GlobalStateContext.Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
