import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';
import IconButton from '@material-ui/core/IconButton';

import { SIDENOTE_HORIZONTAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarHorizontalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      sidenoteMachine: {
        sidenoteField: { editorState, horizontalAlign },
      },
    },
    machinesContext: {
      sidenoteMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      sidenoteMachine: {
        sidenoteField: { horizontalAlign: viewHorizontalAlign },
      },
    },
  } = controllerState.context;

  const horizontalAlignOptionIndex =
    SIDENOTE_HORIZONTAL_ALIGN_OPTIONS.findIndex(
      ({ value }) => value === horizontalAlign
    );
  const { Icon: ImageObjectAlignIcon } =
    SIDENOTE_HORIZONTAL_ALIGN_OPTIONS[horizontalAlignOptionIndex];

  const sidenoteHtml = slateToHtml(editorState);
  const isSidenoteHTMLEmpty = !sidenoteHtml || sidenoteHtml === '<p></p>';

  return (
    <Help
      controlId='sidenoteMachineToolbarHorizontalAlignControl'
      helpLabelValue={horizontalAlign}
      isDisabled={isSidenoteHTMLEmpty}
      placement='top'
    >
      <IconButton
        color={
          viewHorizontalAlign !== horizontalAlign || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={isSidenoteHTMLEmpty}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'sidenoteMachine',
              property: 'sidenoteField',
              values: {
                horizontalAlign:
                  horizontalAlignOptionIndex ===
                  SIDENOTE_HORIZONTAL_ALIGN_OPTIONS.length - 1
                    ? SIDENOTE_HORIZONTAL_ALIGN_OPTIONS[0].value
                    : SIDENOTE_HORIZONTAL_ALIGN_OPTIONS[
                        horizontalAlignOptionIndex + 1
                      ].value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarHorizontalAlign;
