import React from 'react';

import ToolbarIndexControl from './IndexControl';
import ToolbarBackgroundButton from './BackgroundButton';
import ToolbarFitButton from './FitButton';
import HorizontalPosition from './HorizontalPosition';
import VerticalPosition from './VerticalPosition';

const ImageToolbar = () => (
  <>
    <ToolbarBackgroundButton />
    <ToolbarFitButton />
    <VerticalPosition />
    <HorizontalPosition />
    <ToolbarIndexControl />
  </>
);

export default ImageToolbar;
