export const codeMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Code Drawer',
  helpText: [
    'This button will open and close the Code Drawer. When open, the drawer shows two tabs.',
    'The EDIT Tab is used to write or edit the sidenote in a textfield, and apply font styling.',
    'The BOT Tab is used to ask artificial intelligence to write a sidenote for you in the textfield',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const codeMachineCodeFieldComponent = {
  helpLabel: 'Code Panel',
  helpText: [],
  relatedControlIds: [],
};

export const codeMachineToolbarCodingLanguageControl = {
  helpLabel: 'Coding Language',
  helpText: [],
  relatedControlIds: [],
};

export const codeMachineToolbarDrawerTableControl = {
  helpLabel: 'Code History',
  helpText: ['History of asks to bot. Click to copy and past in input field.'],
  relatedControlIds: [],
};

export const codeMachineToolbarDrawerTabsControl = {
  helpLabel: 'Code Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const codeMachineSidebarToolSelectControl = {
  helpLabel: 'Code Tool',
  helpText: [
    'Create code snippets with syntax highlighting. Write your own, or ask a bot to write it for you.',
  ],
  relatedControlIds: [],
};

export const codeMachineBoardSectionVisibilityControl = {
  helpLabel: 'Code Visibility',
  helpText: ['Hide code and close the Code Tool.'],
  relatedControlIds: [],
};
