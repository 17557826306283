export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      autoPlay
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $id: ID
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSessions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        boardId
        id
        mode
        name
        status
        userName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      frames {
        items {
          diffString
          duration
          machineId
        }
        nextToken
      }
      id
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        frames {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFrame = /* GraphQL */ `
  query GetFrame($id: ID!) {
    getFrame(id: $id) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;
export const listFrames = /* GraphQL */ `
  query ListFrames(
    $filter: ModelFrameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFrames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        boardId
        createdAt
        diffString
        duration
        machineId
        id
        updatedAt
      }
      nextToken
    }
  }
`;
export const framesByBoardIdAndCreatedAt = /* GraphQL */ `
  query FramesByBoardIdAndCreatedAt(
    $boardId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFrameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    framesByBoardIdAndCreatedAt(
      boardId: $boardId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        diffString
        duration
        machineId
      }
      nextToken
    }
  }
`;
