import { assign, createMachine } from 'xstate';

import { v4 as uuid } from 'uuid';

const userMachine = createMachine(
  {
    // predictableActionArguments: true,
    context: {
      user: {
        id: uuid(),
        name: '',
        dateLastUpdated: '',
        dateLastOpened: '',
        viewsCount: 0,
      },
      userSettings: {
        userNameMaxLength: 12,
      },
    },
    id: 'userMachine',
    initial: 'anonymous',
    states: {
      anonymous: {
        id: 'anonymous',
        initial: 'idling',
        states: {
          idling: {
            on: {
              REQUEST_USER_NAME: {
                target: 'pending',
              },
            },
          },
          pending: {
            on: {
              RECEIVE_USER_NAME: {
                target: '#userMachine.joined',
              },
            },
          },
        },
      },
      joined: {},
      authorized: {},
    },
    on: {
      LOAD_RECORDING: {
        actions: [
          assign({
            user: (_, { payload: { user } }) => ({
              ...user,
            }),
          }),
        ],
      },
      UPDATE_USER_PROPERTY: {
        actions: ['updateUserProperty'],
      },
      UPDATE_USER_PROPERTY_VALUES: {
        actions: ['updateUserPropertyValues'],
      },
    },
  },
  {
    actions: {
      updateUserProperty: assign(
        (context, { payload: { property, value } }) => {
          context[property] = value;
          return context;
        }
      ),
      updateUserPropertyValues: assign(
        (context, { payload: { property, values } }) => {
          context[property] = {
            ...context[property],
            ...values,
          };
          return context;
        }
      ),
    },
    guards: {},
  }
);

export default userMachine;
