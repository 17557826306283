export const parseHtmlFromString = (string) => {
  const parsedString = new DOMParser().parseFromString(string, 'text/html').body
    .textContent;
  return parsedString;
};

export const parseDomainWithoutSubdomainFromUrl = (url) => {
  const hostname = new URL(url).hostname;
  const parts = hostname.split('.');

  // If the hostname is an IP address, return it as is.
  if (parts.length === 4 && parts.every((part) => !isNaN(part))) {
    return hostname;
  }

  // Take last two parts of hostname to ignore subdomains (e.g. "google.com" from "www.google.com")
  return parts.slice(-2).join('.');
};
