import React, { useContext } from 'react';
import { CODE_SUPPORTED_LANGUAGES } from './constants';
import { GlobalStateContext } from '../../globalState';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { useActor } from '@xstate/react';
import Select from '@material-ui/core/Select';

import { globalStyles } from '../../themes/globalStyles';
import Help from '../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const CodeToolbar = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    editContext: {
      codeMachine: { codeField },
    },
    machinesContext: {
      codeMachine: {
        boardSettings: { autoCapture },
      },
    },
  } = controllerState.context;

  return (
    <Help controlId='codeMachineToolbarCodingLanguageControl' placement='top'>
      <Select
        className={classes.machineToolbarSelect}
        color={autoCapture ? 'secondary' : 'primary'}
        fullWidth
        id='standard-select-currency'
        onChange={({ target: { value } }) => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'codeMachine',
              property: 'codeField',
              values: { language: value },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        value={codeField.language}
        variant='outlined'
      >
        {CODE_SUPPORTED_LANGUAGES.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Help>
  );
};

export default CodeToolbar;
