import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIosOutlined from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@material-ui/icons/ArrowForwardIosOutlined';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SkipNextRounded from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRounded from '@material-ui/icons/SkipPreviousRounded';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { controlStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import FrameAddControl from './FrameAdd';
import FrameAutoSubmitControl from './FrameAutoSubmit';
import FrameDeleteControl from './FrameDelete';
import FrameEditControl from './FrameEdit';
import FrameResetControl from './FrameReset';
import FramesPlay from './FramesPlay';
import FrameSubmitControl from './FrameSubmit';
import Help from '../../Help';
import CreditsControl from './Credits';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...controlStyles(theme),
}));

const FramesSliderControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editContext,
    editFrameIndex,
    viewContext,
    viewFrameIndex,
  } = controllerState.context;
  const { session } = sessionState.context;

  const frameIndex = controllerState.matches('view')
    ? viewFrameIndex
    : editFrameIndex;
  const inEditMode = controllerState.matches('edit');
  const hasUnsavedChanges = inEditMode && !isEqual(editContext, viewContext);
  const hasMultipleFrames = frames.length > 1;
  const inViewPausedMode = controllerState.matches('view.idle');

  return inEditMode || (hasMultipleFrames && inViewPausedMode) ? (
    <Box className={classes.frameSliderContainer}>
      {inEditMode ? (
        <>
          <FrameEditControl />
          <FrameAutoSubmitControl />
          <FrameResetControl />
        </>
      ) : (
        <FramesPlay />
      )}
      <Help
        controlId='controllerMachineFramesRewindControl'
        isDisabled={!frames.length || frameIndex <= 1 || hasUnsavedChanges}
        placement='top'
      >
        <Button
          aria-label='previous frame'
          // className={classes.frameSliderButton}
          color='primary'
          disabled={
            !frames.length ||
            hasUnsavedChanges ||
            (session.mode === 'record' && frameIndex <= 1)
          }
          onClick={() => {
            controllerSend({
              type: 'CONTROLLER_PROP_VALUES',
              payload: {
                property: 'playControl',
                values: { paused: false, playing: true },
              },
            });
            controllerSend({
              type: 'CHANGE_FRAME_INDEX',
              payload: {
                targetFrameIndex: inEditMode ? 1 : 0,
              },
            });
          }}
          size='small'
          style={{ marginRight: 4 }}
          variant='contained'
        >
          {showControlLabels ? (
            inEditMode ? (
              'REWIND'
            ) : (
              'REWIND'
            )
          ) : (
            <SkipPreviousRounded />
          )}
        </Button>
      </Help>
      <Help
        controlId='controllerMachineFramesPreviousControl'
        isDisabled={!frames.length || frameIndex <= 1 || hasUnsavedChanges}
        placement='top'
      >
        <Button
          aria-label='previous frame'
          // className={classes.frameSliderButton}
          color='primary'
          disabled={!frames.length || frameIndex <= 1 || hasUnsavedChanges}
          onClick={() =>
            controllerSend({
              type: 'CHANGE_FRAME_INDEX',
              payload: {
                targetFrameIndex: frameIndex - 1,
              },
            })
          }
          size='small'
          style={{ marginRight: 4 }}
          variant='contained'
        >
          {showControlLabels ? (
            'PREVIOUS FRAME'
          ) : (
            <ArrowBackIosOutlined fontSize='small' />
          )}
        </Button>
      </Help>
      <Help
        controlId='controllerMachineFramesSliderControl'
        helpLabelValue={`Frame ${frameIndex}`}
        isDisabled={hasUnsavedChanges || frames.length <= 1}
        placement='top'
      >
        <Slider
          className={classes.framePlayheadSlider}
          color={hasUnsavedChanges ? 'secondary' : 'primary'}
          disabled={hasUnsavedChanges || frames.length <= 1}
          onChange={(event, targetFrameIndex) => {
            controllerSend({
              type: 'CHANGE_FRAME_INDEX',
              payload: {
                targetFrameIndex,
              },
            });
          }}
          marks
          min={1}
          max={frames.length - 1}
          step={1}
          value={frameIndex}
          valueLabelDisplay='on'
          valueLabelFormat={() => (
            <Typography
              variant='button'
              style={{
                backgroundColor:
                  hasUnsavedChanges && frameIndex !== frames.length
                    ? theme.palette.secondary[theme.palette.type]
                    : theme.palette.background.default,
                color: theme.palette.text.backgroundContrastText,
              }}
            >
              <code>FRAME:{frameIndex}</code>
            </Typography>
          )}
        />
      </Help>
      <Help
        controlId='controllerMachineFramesNextControl'
        isDisabled={
          !frames.length || frameIndex >= frames.length - 1 || hasUnsavedChanges
        }
        placement='top'
      >
        <Button
          aria-label='next frame'
          // className={classes.frameSliderButton}
          color='primary'
          disabled={
            !frames.length ||
            frameIndex >= frames.length - 1 ||
            hasUnsavedChanges
          }
          onClick={() =>
            controllerSend({
              type: 'CHANGE_FRAME_INDEX',
              payload: {
                targetFrameIndex: frameIndex + 1,
              },
            })
          }
          size='small'
          style={{ marginLeft: 4 }}
          variant='contained'
        >
          {showControlLabels ? (
            'NEXT FRAME'
          ) : (
            <ArrowForwardIosOutlined fontSize='small' />
          )}
        </Button>
      </Help>
      {inEditMode && (
        <>
          <FrameAddControl />
          <FrameDeleteControl />
          <FrameSubmitControl />
        </>
      )}
      {sessionState.matches('record.view') && (
        <Help
          controlId='controllerMachineFramesForwardControl'
          isDisabled={viewFrameIndex === frames.length - 1}
          placement='top'
        >
          <Button
            aria-label='last frame'
            color='primary'
            disabled={viewFrameIndex === frames.length - 1}
            id='toEnd'
            onClick={() =>
              controllerSend({
                type: 'CHANGE_FRAME_INDEX',
                payload: {
                  targetFrameIndex: frames.length - 1,
                },
              })
            }
            size='small'
            style={{ marginLeft: 4 }}
            variant='contained'
          >
            {showControlLabels ? 'FAST FORWARD' : <SkipNextRounded />}
          </Button>
        </Help>
      )}
      {sessionState.matches('stream.view') && (
        <Help
          controlId='controllerMachineFramesLiveControl'
          isDisabled={viewFrameIndex === frames.length - 1}
          placement='top'
        >
          <Button
            aria-label='next frame'
            color='primary'
            disabled={viewFrameIndex === frames.length - 1}
            id='toEnd'
            onClick={() =>
              controllerSend({
                type: 'CHANGE_FRAME_INDEX',
                payload: {
                  targetFrameIndex: frames.length - 1,
                },
              })
            }
            size='small'
            style={{ marginLeft: 4 }}
            variant='outlined'
          >
            Live
          </Button>
        </Help>
      )}
    </Box>
  ) : (
    <Box className={classes.frameSliderContainer}>
      <FramesPlay />
      <CreditsControl />
    </Box>
  );
};

export default FramesSliderControl;
