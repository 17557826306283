import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GroupAddRounded from '@material-ui/icons/GroupAddRounded';
import SaveRounded from '@material-ui/icons/SaveRounded';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const BoardSettingsControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  // eslint-disable-next-line no-unused-vars
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    modalControl: { openModalIds },
  } = controllerState.context;

  const displayFrameSettingsMenu =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');

  return displayFrameSettingsMenu ? (
    <Help
      controlId={
        sessionState.matches('record')
          ? 'controllerMachineBoardRecordSettingsControl'
          : 'controllerMachineBoardStreamSettingsControl'
      }
      helpLabelValue={
        sessionState.matches('record') ? 'Recording' : 'Streaming'
      }
      isDisabled={!!openModalIds.length}
      placement='right'
    >
      <Button
        disabled={!!openModalIds.length}
        onClick={() => {
          controllerSend({
            type: 'MODAL_OPEN',
            payload: {
              modalId: sessionState.matches('record')
                ? 'menuBoardRecord'
                : 'menuBoardStream',
            },
          });
        }}
        size='small'
        variant='contained'
      >
        {showControlLabels ? (
          'BOARD'
        ) : sessionState.matches('record.edit') ? (
          <SaveRounded />
        ) : (
          <GroupAddRounded />
        )}
      </Button>
    </Help>
  ) : null;
};

export default BoardSettingsControl;
