import LocationOnRounded from '@material-ui/icons/LocationOnRounded';

export const MAP_ICON = LocationOnRounded;

export const MAP_LIBRARIES = ['places'];

export const MAP_POV_ZOOM_MAX = 4;
export const MAP_POV_ZOOM_MIN = 0;
export const MAP_POV_ZOOM_STEP = 0.01;

export const MAP_ZOOM_MAX = 21;
export const MAP_ZOOM_MIN = 3;
export const MAP_ZOOM_STEP = 1;

export const MAP_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
    sidebarTab: true,
  },
  toolbarDrawer: {
    drawerTab: 'search',
    isDrawerCollapsed: true,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const MAP_SHARED_CONTEXT = {
  mapField: {
    center: {
      lat: null,
      lng: null,
    },
    mapTypeId: 'satellite',
    markers: [],
    selectedPlace: {
      location: {
        lat: '',
        lng: '',
      },
      placeId: '',
      title: '',
    },
    showMarkers: true,
    pov: {
      heading: 134,
      pitch: 1,
      zoom: 0,
    },
    zoom: 16,
  },
};
