export const chessMachineChessFieldComponent = {
  helpLabel: 'Chess Panel',
  helpText: [],
  relatedControlIds: [],
};

export const chessMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Chess Drawer',
  helpText: [
    'This button will open and close the Chess Drawer.',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const chessMachineToolbarDrawerTabsControl = {
  helpLabel: 'Chess Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const chessMachineToolbarBoardOrientationControl = {
  helpLabel: 'Board Orientation',
  helpText: [],
  relatedControlIds: [],
};

export const chessMachineToolbarHorizontalAlignControl = {
  helpLabel: 'Horizontal Align',
  helpText: [],
  relatedControlIds: [],
};

export const chessMachineToolbarVerticalAlignControl = {
  helpLabel: 'Vertical Align',
  helpText: [],
  relatedControlIds: [],
};

export const chessMachineToolbarDrawerTableControl = {
  helpLabel: 'Chess History',
  helpText: [
    'History of chess set ups. Click to copy and past in input field.',
  ],
  relatedControlIds: [],
};

export const chessMachineSidebarToolSelectControl = {
  helpLabel: 'Chess Tool',
  helpText: ['Show chess rules, moves, and positions using a chess board.'],
  relatedControlIds: [],
};

export const chessMachineBoardSectionVisibilityControl = {
  helpLabel: 'Chess Visibility',
  helpText: ['Hide chess board and close the Chess Tool.'],
  relatedControlIds: [],
};
