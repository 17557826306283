export const boardStyles = (theme) => ({
  backgroundContainer: {
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    zIndex: -100,
  },
  boardContainer: {
    height: '100vh',
    position: 'relative',
  },
  bodyContainer: {
    height: '100%',
  },
  bodyContainerGrid: {
    height: '100%',
  },
  gridPanelSlider: {
    lineHeight: 0,
    zIndex: 1250,
  },
  gridItemSidebar: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  loadingBackdrop: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    zIndex: 5000,
  },
});
