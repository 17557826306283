import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';

import { CHESS_HORIZONTAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarHorizontalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      chessMachine: { chessField },
    },
    machinesContext: {
      chessMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      chessMachine: { chessField: viewSidenoteField },
    },
  } = controllerState.context;

  const horizontalAlignOptionIndex = CHESS_HORIZONTAL_ALIGN_OPTIONS.findIndex(
    ({ value }) => value === chessField.horizontalAlign
  );
  const { label: helpLabelValue, Icon: ImageObjectAlignIcon } =
    CHESS_HORIZONTAL_ALIGN_OPTIONS[horizontalAlignOptionIndex];

  return (
    <Help
      controlId='chessMachineToolbarHorizontalAlignControl'
      helpLabelValue={helpLabelValue}
      placement='top'
    >
      <IconButton
        color={
          viewSidenoteField.horizontalAlign !== chessField.horizontalAlign ||
          autoCapture
            ? 'secondary'
            : 'primary'
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'chessMachine',
              property: 'chessField',
              values: {
                horizontalAlign:
                  horizontalAlignOptionIndex ===
                  CHESS_HORIZONTAL_ALIGN_OPTIONS.length - 1
                    ? CHESS_HORIZONTAL_ALIGN_OPTIONS[0].value
                    : CHESS_HORIZONTAL_ALIGN_OPTIONS[
                        horizontalAlignOptionIndex + 1
                      ].value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
        style={{ transform: 'rotate(-90deg)' }}
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarHorizontalAlign;
