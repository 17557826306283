import React, { useContext, useRef } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Editor from '@monaco-editor/react';

import { drawerStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => drawerStyles(theme));

const CodeDrawerEditor = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const codeFieldEditorRef = useRef(null);
  const classes = useStyles();

  const {
    editContext: {
      codeMachine: { codeField },
    },
    machinesContext: {
      codeMachine: {
        boardSettings: { autoCapture },
      },
    },
  } = controllerState.context;

  const onChangeCodeField = (value) => handleChangeCodeField(value);

  const onMountCodeField = (editor, monaco) => {
    codeFieldEditorRef.current = editor;
    // let placeholder = document.getElementById('monaco-placeholder');
    // placeholder.style.display = 'block';

    editor.onDidFocusEditorWidget(() => {
      handleFocusCodeField();
    });
  };

  const handleChangeCodeField = (value) => {
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'codeMachine',
        property: 'codeField',
        values: { value },
      },
    });
    if (autoCapture) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  const handleFocusCodeField = () =>
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'controllerMachine',
        property: 'boardGrid',
        values: { activeMachineId: 'codeMachine' },
      },
    });

  return (
    <Paper
      className={classes.editorPaper}
      elevation={0}
      style={{
        height: '25vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <Editor
        className={classes.editorComponent}
        id='codeField'
        language={codeField.language}
        onChange={onChangeCodeField}
        onMount={onMountCodeField}
        options={{
          minimap: { enabled: false },
          // Hide line numbers
          lineNumbers: 'off',
          glyphMargin: false,
          folding: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          // End hid line numbers
        }}
        theme='vs-light'
        value={codeField.value}
      />
    </Paper>
  );
};

export default CodeDrawerEditor;
