import isEqual from 'lodash/isEqual';
// import { toHTML } from 'react-mui-draft-wysiwyg';
import cloneDeep from 'lodash/cloneDeep';
import { parseGIF, decompressFrames } from 'gifuct-js';
import { applyDiffStringToObject } from '.';
import { FRAME_DURATION_SECONDS_PER_CHARACTER } from './constants';
// import { convertRawToEditorState } from './convert';
import { getDiffInCharactersFromStrings } from './compare';
import { parseHtmlFromString } from './parse';
import { slateToHtml } from '@slate-serializers/html';

const calculateDurationFromText = (currentText, updatedText) => {
  const diffChars = getDiffInCharactersFromStrings(currentText, updatedText);
  return diffChars * FRAME_DURATION_SECONDS_PER_CHARACTER;
};

// const calculateDurationFromRaw = (currentHtml, updatedHtml) => {
//   // const currentEditorState = convertRawToEditorState(currentRaw);
//   // const updatedEditorState = convertRawToEditorState(updatedRaw);
//   // const currentHtml = toHTML(currentEditorState.getCurrentContent());
//   // const updatedHtml = toHTML(updatedEditorState.getCurrentContent());
//   const currentText = parseHtmlFromString(currentHtml);
//   const updatedText = parseHtmlFromString(updatedHtml);
//   return calculateDurationFromText(currentText, updatedText);
// };

export const calculateFrameDuration = ({
  currentContext,
  machinesContext: {
    imageMachine: { imageCollection },
  },
  updatedContext,
}) => {
  let duration = 0;
  for (const [key, values] of Object.entries(updatedContext)) {
    if (!isEqual(currentContext[key], values)) {
      switch (key) {
        case 'sidenoteMachine':
          duration += calculateSidenoteEventDuration(
            currentContext,
            updatedContext
          );
          break;
        case 'chessMachine':
          duration += 1500;
          break;
        case 'codeMachine':
          duration += calculateCodeEventDuration(
            currentContext,
            updatedContext
          );
          break;
        case 'imageMachine':
          const {
            imageField: { backgroundUrl, url },
          } = currentContext.imageMachine;
          const { gifDuration: backgroundGifDuration = 0 } =
            imageCollection.collectionItems.find(
              (image) => image.url === backgroundUrl
            ) || {};
          const { gifDuration = 0 } =
            imageCollection.collectionItems.find(
              (image) => image.url === url
            ) || {};
          if (backgroundGifDuration || gifDuration) {
            duration = Math.max(backgroundGifDuration, duration, gifDuration);
          } else {
            duration += 3000;
          }
          break;
        case 'mapMachine':
          duration += 3000;
          break;
        case 'textMachine':
          duration += calculateTextEventDuration(
            currentContext,
            updatedContext
          );
          break;
        case 'videoMachine':
          duration += 1000;
          break;
        default:
          break;
      }
    }
  }
  return duration;
};

/**
 * We can only use the gif duration calculation for GIPHY gifs and not for
 * custom third party gif urls because of CORS issues.
 */
export const calculateGifDuration = async ({ url }) => {
  try {
    return fetch(url)
      .then((resp) => resp.arrayBuffer())
      .then((buff) => parseGIF(buff))
      .then((gif) => decompressFrames(gif, true))
      .then((frames) =>
        frames.map((frame) => frame.delay).reduce((a, b) => a + b)
      );
  } catch (error) {
    console.log({ error });
  }
};

export const calculateCodeEventDuration = (currentContext, updatedContext) => {
  const { value: currentValue } = currentContext.codeMachine.codeField;
  const { value: updatedValue } = updatedContext.codeMachine.codeField;
  return calculateDurationFromText(currentValue, updatedValue);
};

export const calculateBoardGridProps = ({
  containerDirectionReverse,
  containerDirection,
  itemBreakpoint,
}) => {
  return containerDirection === 'row'
    ? {
        containerFlexDirection: `row${
          containerDirectionReverse ? '-reverse' : ''
        }`,
        itemCenterSpacerSx: { height: '100%' },
        itemCenterSpacerXs: itemBreakpoint[2] - itemBreakpoint[1],
        itemLeftSpacerSx: { height: '100%' },
        itemLeftSpacerXs: !containerDirectionReverse
          ? itemBreakpoint[0]
          : 12 - itemBreakpoint[3],
        itemMainSectionSx: { height: '100%', zIndex: 1 },
        itemMainSectionXs: !containerDirectionReverse
          ? itemBreakpoint[1] - itemBreakpoint[0]
          : itemBreakpoint[3] - itemBreakpoint[2],
        itemMainSidebarSx: { height: '100%' },
        itemRightSpacerSx: { height: '100%' },
        itemRightSpacerXs: !containerDirectionReverse
          ? 12 - itemBreakpoint[3]
          : itemBreakpoint[0],
        itemTabSectionSx: { height: '100%', zIndex: 1 },
        itemTabSectionXs: !containerDirectionReverse
          ? itemBreakpoint[3] - itemBreakpoint[2]
          : itemBreakpoint[1] - itemBreakpoint[0],
      }
    : {
        containerFlexDirection: `column${
          containerDirectionReverse ? '-reverse' : ''
        }`,
        itemCenterSpacerSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? itemBreakpoint[2] - itemBreakpoint[1]
              : itemBreakpoint[1] - itemBreakpoint[2],
            12
          )}%`,
        },
        itemCenterSpacerXs: 12,
        itemLeftSpacerSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? itemBreakpoint[0]
              : 12 - itemBreakpoint[3],
            12
          )}%`,
        },
        itemLeftSpacerXs: 12,
        itemMainSectionSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? itemBreakpoint[1] - itemBreakpoint[0]
              : itemBreakpoint[3] - itemBreakpoint[2],
            12
          )}%`,
          zIndex: 0,
        },
        itemMainSidebarSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? itemBreakpoint[1] - itemBreakpoint[0]
              : itemBreakpoint[3] - itemBreakpoint[2],
            12
          )}%`,
        },
        itemMainSectionXs: 12,
        itemRightSpacerSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? 12 - itemBreakpoint[3]
              : itemBreakpoint[0],
            12
          )}%`,
        },
        itemRightSpacerXs: 12,
        itemTabSectionSx: {
          maxHeight: `${calculatePercentage(
            !containerDirectionReverse
              ? itemBreakpoint[3] - itemBreakpoint[2]
              : itemBreakpoint[1] - itemBreakpoint[0],
            12
          )}%`,
          zIndex: 0,
        },
        itemTabSectionXs: 12,
      };
};

export const calculateSidenoteEventDuration = (
  currentContext,
  updatedContext
) => {
  const { editorState: currentState } =
    currentContext.sidenoteMachine.sidenoteField;
  const { editorState: updateState } =
    updatedContext.sidenoteMachine.sidenoteField;
  const currentHtml = slateToHtml(currentState);
  const updatedHtml = slateToHtml(updateState);
  const currentText = parseHtmlFromString(currentHtml);
  const updatedText = parseHtmlFromString(updatedHtml);
  return calculateDurationFromText(currentText, updatedText);
};

export const calculateTextEventDuration = (currentContext, updatedContext) => {
  const { editorState: currentState } = currentContext.textMachine.textField;
  const { editorState: updateState } = updatedContext.textMachine.textField;
  const currentHtml = slateToHtml(currentState);
  const updatedHtml = slateToHtml(updateState);
  const currentText = parseHtmlFromString(currentHtml);
  const updatedText = parseHtmlFromString(updatedHtml);
  return calculateDurationFromText(currentText, updatedText);
};

export const calculateEventsListTotalDuration = (eventsList) => {
  const totalDuration = eventsList.reduce((total, { duration, machineId }) => {
    switch (machineId) {
      case 'videoMachine':
        // !TODO Figure out how to include the video playing time
        break;
      default:
        total = duration ? total + duration : total;
        break;
    }
    return total;
  }, 0);
  return totalDuration;
};

export const calculatePercentage = (partial, total) => {
  /**
   * TODO: We use Math.floor() here because we never want the total
   * percentage to be greater than 100. We should probably find a better
   * way to handle this. But for now, this works.
   * Mats.abs makes sure that the percentage is always positive.
   */
  return Math.floor(((100 * Math.abs(partial)) / total) * 100) / 100;
};

export const calculateVideoPlayerTarget = ({
  currentContext,
  currentFrameIndex,
  frames,
}) => {
  const { played } = currentContext.videoMachine.videoField;
  let updatedContext = cloneDeep(currentContext);
  let frameIndex = currentFrameIndex;
  let target = played;

  while (frameIndex < frames.length && target <= played) {
    frameIndex = frameIndex + 1;
    if (typeof frames[frameIndex] !== 'undefined') {
      updatedContext = applyDiffStringToObject(
        updatedContext,
        frames[frameIndex].diffString,
        false
      );
      target = updatedContext.videoMachine.videoField.played;
    }
  }
  return target;
};

export const calculateTargetFrameIndex = ({
  queryFrameIndex,
  framesLength,
  sessionMode,
}) => {
  /**
   * If target frame index is provided in the query string, make use it is
   * not less than 1 and not greater than the total number of frames.
   * When url is set to view mode and target frame is provided in the query,
   * subtract 1 from the target frame to display correct initial frame.
   */
  const targetFrameIndex = !!queryFrameIndex
    ? Math.min(Math.max(parseInt(queryFrameIndex), 1), framesLength)
    : framesLength;
  return sessionMode === 'view'
    ? !queryFrameIndex
      ? 0
      : targetFrameIndex - 1
    : targetFrameIndex;
};
