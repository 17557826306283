import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Editor } from 'slate';
import { Slate, Editable } from 'slate-react';
import isHotkey from 'is-hotkey';

import { editorStyles } from './styles';

const useStyles = makeStyles((theme) => editorStyles(theme));

const SlateEditor = ({
  editor,
  editorState,
  editableProps,
  initialValue,
  onChange,
  onFocus,
  setEditor,
  ...restProps
}) => {
  const classes = useStyles();
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  /**
   * When the user clicks 'undo changes', the editor needs to be reset to the previous state.
   * This is done somewhat hacky by setting the editor.children to the (previous) editorState.
   * See: https://github.com/ianstormtaylor/slate/pull/4540#issuecomment-951380551
   */
  editor.children = editorState;

  return (
    <Paper
      // className={classes.editorPaper}
      elevation={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: '25vh',
      }}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={onChange}
        onFocus={onFocus}
        {...restProps}
      >
        <Editable
          className={classes.textEditor}
          placeholder='Enter text here...'
          renderLeaf={renderLeaf}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          {...editableProps}
        />
      </Slate>
    </Paper>
  );
};

export default SlateEditor;
