export const menusStyles = (theme) => ({
  autocompleteOptionIcon: {
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.success[theme.palette.type],
    },
  },
  autoPlaySwitch: {
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  boardOverviewButtonFab: {
    height: 88,
    width: 88,
  },
  boardOverviewButtonWrapper: {
    position: 'relative',
    width: 88,
  },
  colorInput: {
    '& .MuiColorInput-Button': {
      border: `1px solid ${theme.palette.text.backgroundContrastText}`,
    },
  },
  cookieConsent: {
    ...theme.typography.sidenote,
  },
});
