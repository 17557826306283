import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const FrameDeleteControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editContext,
    editControl: { durationField, isEditFrameModeActive },
    editFrameIndex,
    viewContext,
  } = controllerState.context;

  const hasContextChanged =
    !isEqual(viewContext, editContext) ||
    (frames[editFrameIndex] &&
      !isEqual(durationField.value, frames[editFrameIndex].duration));
  const isAddingNewFrame = editFrameIndex === frames.length;

  return (
    isEditFrameModeActive &&
    !hasContextChanged &&
    !isAddingNewFrame && (
      <Help controlId='controllerMachineFrameDeleteControl' placement='top'>
        <Button
          color='secondary'
          disabled={sessionState.matches('stream')}
          onClick={() => {
            controllerSend({
              type: 'DELETE_FRAME_CAPTURE',
              payload: { targetFrameIndex: editFrameIndex || 1 },
            });
          }}
          size='small'
          style={{ marginLeft: 4 }}
          variant='contained'
        >
          {showControlLabels ? 'DELETE FRAME' : <DeleteForeverOutlined />}
        </Button>
      </Help>
    )
  );
};

export default FrameDeleteControl;
