import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { useTheme } from '@material-ui/core';
import extend from 'lodash/extend';

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StreetViewPanorama,
} from '@react-google-maps/api';

import { MAP_LIBRARIES, MAP_SHARED_CONTEXT } from './constants';
import { GlobalStateContext } from '../../globalState';

const MapView = ({ renderBackgroundComponent }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const theme = useTheme();

  const { isLoaded } = useJsApiLoader({
    // See https://github.com/JustFly1984/react-google-maps-api/issues/3334#issuecomment-2002696176
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`,
    libraries: MAP_LIBRARIES,
  });

  const {
    viewContext: {
      mapMachine: { mapField: machineMapField },
    },
  } = controllerState.context;

  if (!isLoaded) {
    return <h1>Loading</h1>;
  }

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects. Google Map Streetview did not work when using lodash
   * merge method.
   */
  const mapField = extend(MAP_SHARED_CONTEXT.mapField, machineMapField);

  return (
    <GoogleMap
      center={mapField.center}
      mapContainerStyle={{
        height: '100%',
        width: '100%',
        zIndex: 0,
      }}
      mapTypeId={mapField.mapTypeId}
      onLoad={(map) => {
        map.setZoom(mapField.zoom);
        map.setMapTypeId(mapField.mapTypeId);
      }}
      options={{
        backgroundColor: theme.palette.background.default,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        streetViewControl: false,
        zoomControl: false,
      }}
      zoom={mapField.zoom}
    >
      <StreetViewPanorama
        id='street-view'
        position={mapField.center}
        pov={mapField.pov}
        motionTracking={false}
        motionTrackingControl={false}
        options={{
          addressControl: false,
          enableCloseButton: false,
          fullscreenControl: false,
          linksControl: false,
          panControl: false,
          showRoadLabels: false,
          visible: mapField.mapTypeId === 'streetview',
          zoomControl: false,
        }}
        style={{
          // TODO: T
          backgroundColor: theme.palette.background.default,
          zIndex: 0,
        }}
        zoom={mapField.pov.zoom}
      />
      {/* Child components, such as markers, info windows, etc. */}
      <>
        {mapField.showMarkers &&
          mapField.markers.map(({ location, markerLabel, placeId, value }) => {
            return (
              <Marker
                key={placeId}
                label={markerLabel}
                position={location}
                title={value}
              />
            );
          })}
      </>
    </GoogleMap>
  );
};

export default MapView;
