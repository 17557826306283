import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';

import Editor from './Editor';
// import Bot from './Bot';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const TextDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();
  const {
    machinesContext: {
      textMachine: {
        toolbarDrawer: { drawerTab, isDrawerCollapsed },
      },
    },
  } = controllerState.context;

  return (
    <Collapse in={isDrawerCollapsed} unmountOnExit>
      <Grid container>
        <Grid item xs={12} className={classes.machineDrawerContainer}>
          {drawerTab === 'editor' && <Editor />}
          {/* {drawerTab === 'bot' && <Bot />} */}
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default TextDrawer;
