export const INITIAL_MACHINE_CONTEXT = {
  boardData: {},
  boardEventsCount: 0,
  eventsReceive: [],
  eventsSend: [],
  framesData: {
    items: [],
    nextToken: null,
  },
  session: {
    autoPlay: true,
    boardId: '',
    createdAt: '',
    id: '',
    mode: '', // record / stream
    name: '',
    role: '', // edit / view
    slug: '', // nanoID
    status: '', // open, close, delete
    userName: '',
  },
  sendQueue: [],
  sessionString: '',
  boardHistory: [],
  savedStreams: [],
};
