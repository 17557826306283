import { customAlphabet } from 'nanoid';

export const generateSessionId = () => {
  const nanoid = customAlphabet(
    // No 0, O, o
    '123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrst',
    12
  );
  return nanoid();
};
