import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';

import { GlobalStateContext } from '../../../../globalState';
import ControlsMain from '../ControlsMain';
import ControlsTabs from '../ControlsTabs';
import MenuOptions from '../MenuOption';

const SidebarPortraitLeft = ({ handleActiveMachineChange }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');
  const { editContext, viewContext } = controllerState.context;
  const { containerDirectionReverse } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  return !inEditMode ? (
    <Box
      id='sidebarPortraitLeftTopView'
      display='flex'
      flex={1}
      flexDirection='column'
    >
      <MenuOptions isLeftSidebar />
    </Box>
  ) : (
    <Box
      id='sidebarPortraitLeftEdit'
      display='flex'
      flex={1}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box
        id='sidebarPortraitLeftTopEdit'
        display='flex'
        flexDirection='column'
      >
        <MenuOptions isLeftSidebar />
      </Box>
      <Box
        id='sidebarPortraitLeftCenterEdit'
        display='flex'
        flex={1}
        justifyContent='center'
        style={{
          flexDirection: containerDirectionReverse
            ? 'column-reverse'
            : 'column',
        }}
      >
        <ControlsMain
          handleActiveMachineChange={handleActiveMachineChange}
          isLeftSidebar
        />
        <ControlsTabs
          handleActiveMachineChange={handleActiveMachineChange}
          isLeftSidebar
        />
      </Box>
    </Box>
  );
};

export default SidebarPortraitLeft;
