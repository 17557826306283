import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormatSizeRounded from '@material-ui/icons/FormatSizeRounded';
import IconButton from '@material-ui/core/IconButton';

import { globalStyles } from '../../../themes/globalStyles';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const ToolbarFontSize = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      sidenoteMachine: {
        sidenoteField: { editorState, fontSizeMax, fontSizeMin },
      },
    },
    machinesContext: {
      sidenoteMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      sidenoteMachine: { sidenoteField: viewSidenoteField },
    },
  } = controllerState.context;

  const sidenoteHtml = slateToHtml(editorState);
  const isSidenoteHTMLEmpty = !sidenoteHtml || sidenoteHtml === '<p></p>';

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='sidenoteMachineToolbarFontSizeControl'
          helpLabelValue={fontSizeMax}
          isDisabled={isSidenoteHTMLEmpty}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='swap content'
            color={
              viewSidenoteField.fontSizeMax !== fontSizeMax || autoCapture
                ? 'secondary'
                : 'primary'
            }
            disabled={isSidenoteHTMLEmpty}
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
          >
            <FormatSizeRounded />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='sidenoteMachineToolbarFontSizeControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Slider
            aria-labelledby='font size slider'
            className={classes.machineToolbarSlider}
            color={
              viewSidenoteField.fontSizeMax !== fontSizeMax || autoCapture
                ? 'secondary'
                : 'primary'
            }
            min={fontSizeMin}
            max={150}
            orientation='vertical'
            step={1}
            style={{ height: 120 }}
            value={fontSizeMax}
            valueLabelDisplay='on'
            onChange={(event, newValue) => {
              event.preventDefault();
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  machineId: 'sidenoteMachine',
                  property: 'sidenoteField',
                  values: {
                    fontSizeMax: newValue,
                  },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ToolbarFontSize;
