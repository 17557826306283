import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player';

import { GlobalStateContext } from '../../../globalState';
import ToolbarBackgroundButton from './BackgroundButton';
import ToolbarMuteButton from './MuteButton';
import ToolbarVerticalAlign from './VerticalAlign';
import Help from '../../Help';

const VideoToolbar = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    playControl: { videoPlayerProgress },
    editContext: {
      videoMachine: { videoField },
    },
    viewContext: {
      videoMachine: { videoField: viewVideoField },
    },
  } = controllerState.context;

  return (
    <>
      <ToolbarBackgroundButton />
      <ToolbarMuteButton />
      <ToolbarVerticalAlign />
      <Help
        controlId='videoMachineToolbarVideoStreamControl'
        isDisabled={!ReactPlayer.canPlay(videoField.url)}
        placement='top'
      >
        <Button
          color={viewVideoField.playing ? 'secondary' : 'inherit'}
          disabled={!ReactPlayer.canPlay(videoField.url)}
          id='video-stage-button-submit'
          onClick={() => {
            controllerSend({
              type: 'UPDATE_EDIT_CONTEXT',
              payload: {
                machineId: 'videoMachine',
                property: 'videoField',
                values: {
                  playing: !videoField.playing,
                  played: videoPlayerProgress,
                },
              },
            });
            controllerSend({ type: 'SUBMIT_FRAME_CAPTURE' });
          }}
          style={{ padding: 0 }}
          variant='text'
        >
          {viewVideoField.playing ? 'stop' : 'stream'}
        </Button>
      </Help>
    </>
  );
};

export default VideoToolbar;
