export const toolbarStyles = (theme) => ({
  textToolbarFontSizeSlider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    maxWidth: 75,
    padding: '0 0 16px',
    '& .MuiChip-root': {
      height: 0,
    },
    '& .MuiSlider-thumb': {
      boxShadow: 'none',
      height: theme.spacing(3),
      marginLeft: -12,
      marginTop: -13,
      width: theme.spacing(3),
      '& .bar': {
        height: 9,
        width: 1,
        marginLeft: 1,
        marginRight: 1,
      },
    },
    '& .MuiSlider-thumb:after': {
      bottom: 0,
    },
  },
});
