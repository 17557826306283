import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { globalStyles } from '../../themes/globalStyles';
import { imageStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import Help from '../Help';
import HelpPanel from '../Help/Panels';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...imageStyles(theme),
}));

const ImageEdit = ({ gridTabsSectionSize, renderBackgroundComponent }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { containerDirectionReverse, containerDirection },
      },
      imageMachine: {
        imageField: {
          alt,
          backgroundObjectFit,
          backgroundObjectHorizontalPosition,
          backgroundObjectVerticalPosition,
          backgroundUrl,
          objectFit,
          objectHorizontalPosition,
          objectVerticalPosition,
          url,
        },
      },
    },
    editControl: { isFrameIndexOnNewFrame },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
      imageMachine: {
        imageCollection: { collectionIndex, collectionItems },
        imageField: { isBackgroundSelected },
      },
    },
  } = controllerState.context;

  const isActiveMachine = activeMachineId === 'imageMachine';
  const isActiveBackgroundMachine =
    isActiveMachine &&
    collectionItems[collectionIndex] &&
    backgroundUrl === collectionItems[collectionIndex].url;

  return (
    <>
      {renderBackgroundComponent && !!backgroundUrl && (
        <Box
          className={`${classes.backgroundImage} ${
            isActiveBackgroundMachine && isBackgroundSelected
              ? isFrameIndexOnNewFrame
                ? classes.activeMachineBackgroundContainer
                : classes.activeMachineBackgroundContainerEdit
              : classes.inactiveMachineBackgroundContainer
          }`}
          component='img'
          src={backgroundUrl}
          sx={{
            objectFit: backgroundObjectFit,
            objectPosition: `${backgroundObjectHorizontalPosition}% ${
              100 - backgroundObjectVerticalPosition
            }%`,
          }}
        />
      )}
      {!renderBackgroundComponent && !url && !isActiveMachine && (
        <HelpPanel
          isHidden={!!activeMachineId}
          panelId='mainPanel'
          panelProperties={gridTabsSectionSize}
        />
      )}
      {!renderBackgroundComponent && (isActiveMachine || !!url) && (
        <Box
          className={
            isActiveMachine && !isBackgroundSelected
              ? isFrameIndexOnNewFrame
                ? classes.activeMachineContainer
                : classes.activeMachineContainerEdit
              : classes.inactiveMachineContainer
          }
          height='100%'
          // Fix for showing vertical scroll bar in portrait mode
          style={{ overflow: 'hidden' }}
        >
          {!!url ? (
            <Help
              controlId='imageMachineImageFieldComponent'
              placement={
                containerDirection === 'row'
                  ? !containerDirectionReverse
                    ? 'left'
                    : 'right'
                  : !containerDirectionReverse
                  ? 'top'
                  : 'bottom'
              }
            >
              <Box
                key={url}
                alt={alt}
                component='img'
                onClick={() => {
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'controllerMachine',
                      property: 'boardGrid',
                      values: { activeMachineId: 'imageMachine' },
                    },
                  });
                }}
                src={url}
                style={{
                  objectFit,
                  objectPosition: `${objectHorizontalPosition}% ${
                    100 - objectVerticalPosition
                  }%`,
                }}
                sx={{
                  height: '100%',
                  width: '100%;',
                }}
              />
            </Help>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default ImageEdit;
