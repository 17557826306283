import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Container from '@material-ui/core/Container';
import { useActor } from '@xstate/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddRounded from '@material-ui/icons/AddRounded';
import EditRounded from '@material-ui/icons/EditRounded';

import Controller from '../Controller';
import Grid from './Grid';
import Modal from '../Modal';
import { BOARDS_DICTIONARY } from '../../boards/boardsDictionary';
import { GlobalStateContext } from '../../globalState';
import {
  calculateTargetFrameIndex,
  convertCompressedStringToObject,
  getRecordingFromLocalStorage,
  getStreamFromLocalStorage,
  useWindowProperties,
} from '../../helpers';
import { boardStyles } from './styles';

const useStyles = makeStyles((theme) => boardStyles(theme));

const Board = () => {
  const { controllerService, sessionService, userService } =
    useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState, sessionSend] = useActor(sessionService);
  // eslint-disable-next-line no-unused-vars
  const [userState, userSend] = useActor(userService);
  const [searchParams] = useSearchParams();
  const { sessionId, sessionMode } = useParams();
  const classes = useStyles();
  const { deviceOrientation, inAutoOrientationMode } = useWindowProperties();

  const {
    board,
    boardFramesSendQueue,
    editControl: { isAwaitingApiRequest, isCapturingBoardFrame },
    editFrameIndex,
    machinesContext: {
      controllerMachine: { boards, boardsIndex },
    },
    playControl: { isLoadingAssets },
  } = controllerState.context;
  const { boardData, sessionString, session } = sessionState.context;
  const { user } = userState.context;

  const queryFrameIndex = searchParams.get('frame');
  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');

  useEffect(() => {
    if (
      isMobile &&
      inAutoOrientationMode &&
      // inEditMode &&
      editFrameIndex === board.frames.length
    ) {
      /**
       * On mobile device - when in edit mode and adding a new frame - automatically
       * set containerOrientation to the device orientation. We are not displaying
       * the FrameOrientationControl component like we do on desktop, but expect
       * the user to rotate the device to the desired orientation,
       */
      controllerSend({
        type: 'CHANGE_CONTAINER_ORIENTATION',
        payload: {
          containerOrientation: deviceOrientation,
        },
      });
    }
  }, [
    board.frames.length,
    controllerSend,
    deviceOrientation,
    editFrameIndex,
    inAutoOrientationMode,
    inEditMode,
  ]);

  useEffect(() => {
    /**
     * When loading a session from api has failed, load the 404 board.
     */
    if (sessionState.matches('init.getSessionFailed')) {
      const { board, session } = BOARDS_DICTIONARY['page-not-found'];
      sessionSend({
        type: 'VIEW_PRESET',
        payload: { session },
      });
      controllerSend({
        type: 'VIEW_PRESET',
        payload: { board, session },
      });
    }
  }, [controllerSend, sessionSend, sessionState, userSend]);

  useEffect(() => {
    /**
     * When Session state is idle and a sessionId is provided in url, check in
     * local storage if user has edit role for that session. If so and no view
     * mode requested in url, load session load to edit. Else load to view.
     */
    if (sessionId && sessionState.matches('init.idle')) {
      const targetFrameIndex = calculateTargetFrameIndex({
        framesLength: board.frames.length,
        queryFrameIndex,
        sessionMode,
      });
      const { session: recordingSession } = getRecordingFromLocalStorage({
        sessionId,
      });
      if (
        recordingSession &&
        recordingSession.role === 'edit' &&
        recordingSession.status === 'draft'
      ) {
        /**
         * If edit role for recording, check if view mode is requested in url.
         */
        userSend({
          type: 'UPDATE_USER_PROPERTY_VALUES',
          payload: {
            property: 'user',
            values: { role: 'edit' },
          },
        });
        if (sessionMode === 'view') {
          sessionSend({
            type: 'VIEW_RECORDING',
            payload: {
              sessionRole: 'view',
              sessionId,
              targetFrameIndex,
            },
          });
        } else {
          sessionSend({
            type: 'LOAD_RECORDING',
            payload: {
              sessionRole: 'edit',
              sessionId,
              targetFrameIndex,
            },
          });
        }
      } else {
        const { session: streamSession } = getStreamFromLocalStorage({
          sessionId,
        });
        /**
         * If edit role for recording, check if view mode is requested in url.
         */
        if (streamSession && streamSession.role === 'edit') {
          userSend({
            type: 'UPDATE_USER_PROPERTY_VALUES',
            payload: {
              property: 'user',
              values: { role: 'edit' },
            },
          });
          if (sessionMode === 'view') {
            sessionSend({
              type: 'VIEW_STREAM',
              payload: {
                sessionRole: 'view',
                sessionId,
                targetFrameIndex,
              },
            });
          } else {
            sessionSend({
              type: 'LOAD_STREAM',
              payload: {
                sessionRole: 'edit',
                sessionId,
                targetFrameIndex,
              },
            });
          }
        } else {
          sessionSend({
            type: 'VIEW_STREAM',
            payload: {
              sessionRole: 'view',
              sessionId,
              targetFrameIndex,
            },
          });
        }
      }
    }
  }, [
    board,
    queryFrameIndex,
    sessionId,
    sessionMode,
    sessionSend,
    sessionState,
    userSend,
  ]);

  // useEffect(() => {
  //   /**
  //    * Handle the display of preset boards when no session id is provided.
  //    * If board id is provided, get data from dictionary or else set to 404.
  //    * If no board id provided, fall back to board for home page.
  //    */
  //   if (
  //     !sessionId &&
  //     sessionState.matches('init') &&
  //     controllerState.matches('init')
  //   ) {
  //     const presetBoard = boards[boardsIndex];
  //     const { session, board } = pathBoardDirectoryId
  //       ? BOARDS_DICTIONARY[pathBoardDirectoryId]
  //         ? BOARDS_DICTIONARY[pathBoardDirectoryId]
  //         : BOARDS_DICTIONARY['page-not-found']
  //       : BOARDS_DICTIONARY[presetBoard];
  //     sessionSend({
  //       type: 'VIEW_PRESET',
  //       payload: { session },
  //     });
  //     controllerSend({
  //       type: 'VIEW_PRESET',
  //       payload: { board, session },
  //     });
  //   }
  // }, [
  //   sessionState,
  //   pathBoardDirectoryId,
  //   controllerSend,
  //   sessionString,
  //   controllerState,
  //   sessionId,
  //   sessionSend,
  //   boards,
  //   boardsIndex,
  // ]);

  useEffect(() => {
    if (
      /**
       * When viewing a recording, and the last frame is reached, open the
       * board overview modal and load the next board in the queue.
       */
      !sessionId &&
      board.mode === 'record' &&
      !controllerState.can('STOP_PLAY_FRAMES') &&
      controllerState.matches('view.playFrames.compose')
    ) {
      const nextBoardSlug = boards[boardsIndex];
      const { board, session } = BOARDS_DICTIONARY[nextBoardSlug];
      sessionSend({
        type: 'VIEW_PRESET',
        payload: { session },
      });
      controllerSend({
        type: 'VIEW_PRESET',
        payload: { board, session },
      });
    }
  }, [
    board.mode,
    boards,
    boardsIndex,
    controllerSend,
    controllerState,
    sessionId,
    sessionSend,
  ]);

  /**
   * The Session machine and Controller machine are not connected. Through
   * useEffects we handle the exchange of data and interaction between the two.
   */
  useEffect(() => {
    /**
     * The Session machine has loaded a recorded board from local storage, and
     * passes the necessary data to the controller when in init.loadRecording
     * state.
     */
    if (sessionState.matches('init.loadRecording')) {
      const { board, user, session } = convertCompressedStringToObject({
        compressedString: sessionString,
      });
      const targetFrameIndex = calculateTargetFrameIndex({
        framesLength: board.frames.length,
        queryFrameIndex,
        sessionMode,
      });
      userSend({
        type: 'LOAD_RECORDING',
        payload: { user: { ...user, role: 'edit' } },
      });
      if (sessionMode === 'view') {
        controllerSend({
          type: 'VIEW_RECORDING',
          payload: {
            board: { ...board, role: 'view' },
            session,
            targetFrameIndex,
          },
        });
      } else {
        controllerSend({
          type: 'EDIT_RECORDING',
          payload: { board, sessionId: session.id, targetFrameIndex },
        });
      }
    }
  }, [
    sessionState,
    controllerSend,
    sessionString,
    userSend,
    sessionMode,
    sessionId,
    queryFrameIndex,
  ]);

  useEffect(() => {
    /**
     * The Controller machine has created, updated, or deleted a frame, and
     * passes the necessary data to the session machine  when in edit.createFrame
     * edit.updateFrame, or edit.deleteFrame state.
     */
    if (
      controllerState.matches('edit.createFrame') ||
      controllerState.matches('edit.deleteFrame') ||
      controllerState.matches('edit.updateFrame')
    ) {
      if (board.mode === 'record' && board.role === 'edit') {
        sessionSend({
          type: 'SAVE_RECORDING',
          payload: { board, user },
        });
      }
    }
  }, [board, controllerState, sessionSend, user]);

  useEffect(() => {
    /**
     * Save the newly started recording to local storage.
     * Note: Moved this into a separate useEffect as including sessionState
     * in the dependency array caused an infinite loop in the above useEffect.
     */
    if (sessionState.matches('init.startRecording')) {
      sessionSend({
        type: 'SAVE_RECORDING',
        payload: { board, user },
      });
    }
  }, [board, sessionSend, sessionState, user]);

  useEffect(() => {
    /**
     * The Session machine has loaded a streamed board from api, and passes
     * the necessary data to the controller when in init.loadStream state.
     */
    if (sessionState.matches('init.loadStream')) {
      const {
        boardData: {
          id,
          frames: { items },
        },
        session: { id: sessionId, role },
      } = sessionState.context;
      const targetFrameIndex = calculateTargetFrameIndex({
        framesLength: items.length,
        queryFrameIndex,
        sessionMode,
      });
      controllerSend({
        type: role === 'edit' ? 'EDIT_STREAM' : 'VIEW_STREAM',
        payload: {
          board: {
            id,
            frames: items,
          },
          session,
          sessionId,
          targetFrameIndex,
        },
      });
    }
  }, [
    sessionState,
    controllerSend,
    sessionString,
    queryFrameIndex,
    sessionMode,
    session,
  ]);

  useEffect(() => {
    /**
     * The Controller machine stores captured frames in a temporary queue. When
     * both machines are in the right state, the captured frames are queued in
     * the Session machine in order to be streamed, and removed from Controller
     * queue.
     */
    if (
      controllerState.can('DEQUEUE_BOARD_FRAMES') &&
      sessionState.can('ENQUEUE_BOARD_FRAMES')
    ) {
      sessionSend({
        type: 'ENQUEUE_BOARD_FRAMES',
        payload: { frames: boardFramesSendQueue },
      });
      controllerSend({
        type: 'DEQUEUE_BOARD_FRAMES',
      });
    }

    /**
     * The Session machines stores received frames in a temporary queue. When
     * both machines are in the right state, the received frames are queue in
     * the Controller machine to be rendered on screen, and removed from Session
     * queue.
     */
    if (
      controllerState.can('ENQUEUE_BOARD_FRAMES') &&
      sessionState.can('DEQUEUE_BOARD_FRAMES')
    ) {
      controllerSend({
        type: 'ENQUEUE_BOARD_FRAMES',
        payload: { frames: boardData.frames.items },
      });
      sessionSend({
        type: 'DEQUEUE_BOARD_FRAMES',
      });
    }
  }, [
    boardData,
    boardFramesSendQueue,
    controllerSend,
    controllerState,
    sessionSend,
    sessionState,
  ]);

  return (
    <Container disableGutters maxWidth={false}>
      <Container
        className={classes.boardContainer}
        component={Box}
        disableGutters
        maxWidth={false}
      >
        <Grid />
        <Controller />
        <Modal />
        <Backdrop
          className={classes.loadingBackdrop}
          open={
            isLoadingAssets || isAwaitingApiRequest || isCapturingBoardFrame
          }
          transitionDuration={{ enter: 0, exit: 250 }}
        >
          {(isAwaitingApiRequest || isLoadingAssets) && (
            <CircularProgress color='inherit' size={200} thickness={18} />
          )}
          {isCapturingBoardFrame && editFrameIndex < board.frames.length && (
            <EditRounded color='inherit' style={{ fontSize: 200 }} />
          )}
          {isCapturingBoardFrame && editFrameIndex >= board.frames.length && (
            <AddRounded color='inherit' style={{ fontSize: 200 }} />
          )}
        </Backdrop>
      </Container>
    </Container>
  );
};

export default Board;
