import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';
import {
  SIDENOTE_ICON_COLUMN,
  SIDENOTE_ICON_ROW,
} from '../../Sidenote/constants';

const ControlsMain = ({ handleActiveMachineChange, isLeftSidebar }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    editContext: {
      controllerMachine: {
        boardGrid: { containerDirection, containerDirectionReverse },
      },
    },
    editControl: { isEditFrameModeActive, isFrameIndexOnNewFrame },
    modalControl: { openModalIds },
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const SidenoteIcon =
    containerDirection === 'row' ? SIDENOTE_ICON_ROW : SIDENOTE_ICON_COLUMN;

  return isEditFrameModeActive ? (
    <Help
      controlId='sidenoteMachineSidebarToolSelectControl'
      isDisabled={!!openModalIds.length}
      placement={isLeftSidebar ? 'right' : 'left'}
    >
      <Button
        color={
          activeMachineId === 'sidenoteMachine'
            ? isFrameIndexOnNewFrame
              ? 'primary'
              : 'secondary'
            : 'default'
        }
        disabled={!!openModalIds.length}
        onClick={() =>
          handleActiveMachineChange({
            editValues: { displayMainSection: true },
            machineValues: {
              activeMachineId:
                'sidenoteMachine' === activeMachineId ? '' : 'sidenoteMachine',
            },
          })
        }
        size='small'
        variant='outlined'
      >
        {showControlLabels ? (
          'Sidenote'
        ) : (
          <SidenoteIcon
            color={!!openModalIds.length ? 'secondary' : 'inherit'}
            style={
              containerDirectionReverse ? { transform: 'rotate(-180deg)' } : {}
            }
          />
        )}
      </Button>
    </Help>
  ) : null;
};

export default ControlsMain;
