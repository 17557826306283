import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { GlobalStateContext } from '../../../globalState';
import { menusStyles } from './styles';
import FrameToolsControl from '../../Controller/Controls/FrameTools';
import Help from '../../Help';

const useStyles = makeStyles((theme) => menusStyles(theme));

const BoardRecordMenu = () => {
  const { controllerService, sessionService, userService } =
    useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState, sessionSend] = useActor(sessionService);
  const [userState] = useActor(userService);
  const navigate = useNavigate();

  const { board } = controllerState.context;
  const {
    session: { id: sessionId, name: sessionName, userName: sessionUserName },
  } = sessionState.context;
  const { user } = userState.context;

  useEffect(() => {
    if (sessionState.matches('record.edit.publishedRecording')) {
      /**
       * The Session machine has published the recording, and updated the
       * session data in local storage. Now we can navigate to the session
       * route to fetch it from backend and show in view mode.
       */
      controllerSend({
        type: 'CONTROLLER_PROP_VALUES',
        payload: {
          property: 'editControl',
          values: {
            isAwaitingApiRequest: false,
          },
        },
      });
      const sessionUrl = `${window.location.protocol}//${
        window.location.hostname
      }${
        window.location.port ? `:${window.location.port}` : ''
      }/board/${sessionId}`;

      window.open(sessionUrl, '_self', 'rel=noopener noreferrer');
    }
  }, [sessionState, controllerSend, sessionSend, sessionId, user]);

  const handleOnChangeTextField = (values) => {
    sessionSend({
      type: 'SESSION_PROP_VALUES',
      payload: {
        property: 'session',
        values,
      },
    });
    sessionSend({
      type: 'SAVE_RECORDING',
      payload: { board, user },
    });
  };

  return (
    <Card>
      <CardContent>
        <FrameToolsControl />
      </CardContent>
      <CardContent>
        <FormControl fullWidth style={{ marginBottom: 16 }}>
          <FormLabel>Board Details</FormLabel>
        </FormControl>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={7}>
            <Help
              controlId='controllerMachineMenuMainCreateBoardNameControl'
              placement='top'
            >
              <TextField
                id='stageNameOrId'
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 60,
                }}
                fullWidth
                label='Title'
                onChange={({ target: { value } }) =>
                  handleOnChangeTextField({ name: value })
                }
                required
                size='small'
                value={sessionName}
                variant='outlined'
              />
            </Help>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Help
              controlId='controllerMachineMenuMainCreateBoardUserNameControl'
              placement='top'
            >
              <TextField
                id='userName'
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontWeight: 'normal',
                  },
                }}
                inputProps={{
                  maxLength: 15,
                }}
                fullWidth
                label='Created by'
                onChange={({ target: { value } }) =>
                  handleOnChangeTextField({ userName: value })
                }
                required
                size='small'
                value={sessionUserName}
                variant='outlined'
              />
            </Help>
          </Grid>
          <Collapse in={!sessionName || !sessionUserName}>
            <Grid item xs={12}>
              <Typography
                variant='body2'
                style={{ paddingLeft: 8, paddingRight: 8 }}
              >
                Enter a board title and creator name to save and stop recording
                for now, or if you want to stream the board and invite others
                who can view added frames in real-time.
              </Typography>
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              aria-label='Close recording'
              color={!sessionName || !sessionUserName ? 'default' : 'primary'}
              disabled={!sessionName || !sessionUserName}
              fullWidth
              onClick={() => {
                sessionSend({
                  type: 'SESSION_RESET',
                });
                controllerSend({
                  type: 'CONTROLLER_RESET',
                });
                navigate(`/`);
              }}
              size='small'
              variant='contained'
            >
              Save and Stop
            </Button>
          </Grid>
          <Collapse in={!!sessionName && !!sessionUserName}>
            <Grid item xs={12}>
              <Typography
                style={{ paddingLeft: 8, paddingRight: 8 }}
                variant='body2'
              >
                To continue recording later, open the <i>Start</i> menu on the
                home page, and on the <i>Continue</i> tab select the saved board
                by title or topic.
              </Typography>
            </Grid>
          </Collapse>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Button
              aria-label='Share Board'
              color={!sessionName || !sessionUserName ? 'default' : 'primary'}
              disabled={!sessionName || !sessionUserName}
              fullWidth
              onClick={() => {
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'editControl',
                    values: {
                      isAwaitingApiRequest: true,
                    },
                  },
                });
                sessionSend({
                  type: 'PUBLISH_RECORDING',
                  payload: { board, sessionId, user },
                });
              }}
              size='small'
              variant='contained'
            >
              Stream Board
            </Button>
          </Grid>
          <Collapse in={!!sessionName && !!sessionUserName}>
            <Grid item xs={12}>
              <Typography
                variant='body2'
                style={{ paddingLeft: 8, paddingRight: 8 }}
              >
                Stream board to share with others. The board title, creator, and
                recorded frames can no longer be edited. However, you can still
                add new frames to the board, which can be viewed in real-time.
              </Typography>
            </Grid>
          </Collapse>
        </Grid>

        {/* <MenuControl modalId='menuMainCreateSettings' /> */}
      </CardContent>
    </Card>
  );
};

export default BoardRecordMenu;
