import React from 'react';
import { useCookies } from 'react-cookie';
import { useTheme } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import Help from '../../Help';
import { Typography } from '@material-ui/core';

const MenuMainStart = () => {
  const themeMui = useTheme();
  const [cookies, setCookie] = useCookies(['cookieConsent']);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Help
            controlId='controllerMachineMenuMainCreateBoardConsentControl'
            placement='top-start'
          >
            <Checkbox
              checked={cookies.cookieConsent}
              style={{
                color: cookies.cookieConsent
                  ? themeMui.palette.text.disabled
                  : themeMui.palette.secondary[themeMui.palette.type],
              }}
              onChange={({ target: { checked } }) =>
                setCookie('cookieConsent', checked)
              }
              name='cookieConsent'
            />
          </Help>
        }
        label={
          <Typography
            style={{
              color: cookies.cookieConsent
                ? themeMui.palette.text.main
                : themeMui.palette.secondary.main,
              padding: '2px 8px',
            }}
          >
            I allow this web app to use cookies for an enhanced user experience.
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default MenuMainStart;
