import { convertHexToRGBWithAlpha } from '../../helpers';

export const controllerStyles = (theme) => ({
  appBarBottom: {
    bottom: 0,
    boxShadow: 'none',
    left: '50%',
    top: 'auto',
    transform: 'translateX(-50%)',
    width: '83.333333%',
    zIndex: 1250,
  },
  appBarTop: {
    boxShadow: 'none',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '83.333333%',
    zIndex: 1000,
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  breakpointSlider: {
    '& .MuiSlider-mark': {
      height: 12,
      width: 2,
      marginTop: -5,
    },
    '& .MuiSlider-markActive': {
      opacity: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
    '& .MuiSlider-rail': {
      height: 2,
      opacity: 1,
    },
    '& .MuiSlider-thumb': {
      borderRadius: 0,
      width: 5,
      height: 18,
      marginTop: -8,
      marginLeft: 0,
    },
  },
  collapseToolbar: {},
  directionToggle: {
    padding: theme.spacing(1),
  },
  // durationField: {},
  iconButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  controllerTab: {
    minWidth: 0,
  },
  controllerTabs: {
    display: 'flex',
    marginRight: theme.spacing(2),
    '& .MuiButtonBase-root': {
      borderRadius: '4px',
      marginRight: theme.spacing(2),
    },
    '& .MuiTabs-flexContainer': {
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'flex-start',
      width: '100%',
    },
    overflow: 'visible',
  },
  gridItemSidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: 1,
    paddingRight: 1,
  },
  /**
   * The Mui elevation prop does not allow changing the shadow color.
   * Below the elevation={3} style using the primary text color.
   */
  machineDrawerPaper: {
    boxShadow: `0px 3px 3px -2px ${convertHexToRGBWithAlpha({
      hex: theme.palette.text.primary,
      alpha: 0.3,
    })}, 0px 3px 4px 0px ${convertHexToRGBWithAlpha({
      hex: theme.palette.text.primary,
      alpha: 0.24,
    })}, 0px 1px 8px 0px ${convertHexToRGBWithAlpha({
      hex: theme.palette.text.primary,
      alpha: 0.22,
    })}`,
  },
  machineToolbarControl: {
    backgroundColor: convertHexToRGBWithAlpha({
      alpha: 0.8,
      hex: theme.palette.success[theme.palette.type],
    }),
  },
});
