import { green } from '@material-ui/core/colors';

export const tooltipStyles = (theme) => ({
  mainTooltip: {
    width: 300,
  },
  relatedTooltip: {
    width: 250,
  },
  standardTooltipIcon: {
    color: green[500],
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
  },
  stepLink: {
    '&[disabled]': {
      opacity: '50%',
      // color: theme.palette.action.active,
      pointerEvents: 'none',
    },
  },
});
