import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import { MachineDrawerTable } from '../../../components/MachineDrawer';
import { GlobalStateContext } from '../../../globalState';

const VideoDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      videoMachine: {
        toolbarDrawer: { rowDataItems, page, rowsPerPage },
      },
    },
  } = controllerState.context;

  return (
    <MachineDrawerTable
      controlId='videoMachineToolbarDrawerTableControl'
      dataItems={rowDataItems}
      tableRowProps={{
        handleOnClickDataLabel: ({ value }) => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'videoField',
              values: { url: value },
            },
          });
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'toolbarDrawer',
              values: {
                isDrawerCollapsed: false,
              },
            },
          });
        },
      }}
      tablePaginationProps={{
        handleOnChangePage: ({ value }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'toolbarDrawer',
              values: {
                page: value,
              },
            },
          }),
        handleOnChangeRowsPerPage: ({ value }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'toolbarDrawer',
              values: {
                rowsPerPage: value,
              },
            },
          }),
        page,
        rowsPerPage,
      }}
    />
  );
};

export default VideoDrawer;
