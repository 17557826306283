export const textStyles = (theme) => ({
  textField: {
    '& div': {
      width: '100%',
    },
    '& span': {
      borderRadius: theme.spacing(0.5),
      color: theme.palette.text.mainPanel,
      display: 'inline-block',
      lineHeight: 1.125,
      padding: theme.spacing(1.5),
    },
    '& div > span > p': {
      margin: 0,
      // Sets height to empty paragraphs
      minHeight: '1.125em',
    },
  },
  toolbarButtonGroup: {},
});
