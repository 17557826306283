import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';
import IconButton from '@material-ui/core/IconButton';

import { TEXT_VERTICAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarVerticalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      textMachine: {
        textField: { editorState, verticalAlign },
      },
    },
    machinesContext: {
      textMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      textMachine: {
        textField: { verticalAlign: viewVerticalAlign },
      },
    },
  } = controllerState.context;

  const verticalAlignOptionIndex = TEXT_VERTICAL_ALIGN_OPTIONS.findIndex(
    ({ value }) => value === verticalAlign
  );
  const { Icon: ImageObjectAlignIcon, label: helpLabelValue } =
    TEXT_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex];

  const textHTML = slateToHtml(editorState);
  const isTextHTMLEmpty = !textHTML || textHTML === '<p></p>';

  return (
    <Help
      controlId='textMachineToolbarVerticalAlignControl'
      helpLabelValue={helpLabelValue}
      isDisabled={isTextHTMLEmpty}
      placement='top'
    >
      <IconButton
        color={
          viewVerticalAlign !== verticalAlign || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={isTextHTMLEmpty}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'textMachine',
              property: 'textField',
              values: {
                verticalAlign:
                  verticalAlignOptionIndex ===
                  TEXT_VERTICAL_ALIGN_OPTIONS.length - 1
                    ? TEXT_VERTICAL_ALIGN_OPTIONS[0].value
                    : TEXT_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex + 1]
                        .value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarVerticalAlign;
