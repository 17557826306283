import React, { useContext, useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';

import { GlobalStateContext } from '../../../globalState';
// import { controllerStyles } from '../../Menu/styles';

// const useStyles = makeStyles((theme) => controllerStyles(theme));

const Controller = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const [displayBoardTitle, setDisplayBoardTitle] = useState('init');

  // const classes = useStyles();

  const {
    modalControl: { openModalIds },
    playControl: { paused, playing },
  } = controllerState.context;
  const {
    session: { name, userName },
  } = sessionState.context;

  const inViewMode =
    sessionState.matches('record.view') || sessionState.matches('stream.view');

  useEffect(() => {
    /**
     * Handles display of board title when playback is paused in view mode
     */
    if (inViewMode && !openModalIds.length) {
      if (displayBoardTitle === 'init' && playing && paused) {
        setDisplayBoardTitle('open');
      }
      if (playing && !paused) {
        setDisplayBoardTitle('init');
      }
    }
  }, [
    displayBoardTitle,
    inViewMode,
    openModalIds,
    paused,
    playing,
    setDisplayBoardTitle,
  ]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={4000}
      open={displayBoardTitle === 'open'}
      onClose={(event, reason) => {
        /**
         * Clicking the play/pause button triggers clickaway onClose event
         * */
        if (reason !== 'clickaway') {
          setDisplayBoardTitle('closed');
        }
      }}
      style={{ textAlign: 'center', marginBottom: 80 }}
    >
      <Alert
        icon={false}
        onClose={() => setDisplayBoardTitle('closed')}
        variant='filled'
      >
        <AlertTitle style={{ fontSize: 10 }}>VIEWING SMURFBOARD</AlertTitle>
        <AlertTitle
          style={{ fontSize: `1.4rem`, marginBottom: 8, marginTop: 4 }}
        >
          <strong>{name}</strong>
          {!!userName && (
            <>
              {` `}by <strong>{userName}</strong>
            </>
          )}
        </AlertTitle>
        <AlertTitle style={{ fontSize: 10 }}>PRESS PLAY TO CONTINUE</AlertTitle>
      </Alert>
    </Snackbar>
  );
};

export default Controller;
