import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import LocationOffOutlined from '@material-ui/icons/LocationOffOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { MachineDrawerTable } from '../../../components/MachineDrawer';
import { GlobalStateContext } from '../../../globalState';

const DrawerInput = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      mapMachine: {
        boardSettings: { autoCapture },
        toolbarDrawer: { rowDataItems, page, rowsPerPage },
      },
    },
    editContext: {
      mapMachine: { mapField },
    },
  } = controllerState.context;

  const handleOnClickMarker = ({ lat, lng, markerLabel, placeId, value }) => {
    let updatedMarkers;
    if (mapField.markers.some((marker) => marker.placeId === placeId)) {
      updatedMarkers = mapField.markers.filter(
        (marker) => marker.placeId !== placeId
      );
    } else {
      updatedMarkers = mapField.markers;
      updatedMarkers.push({
        location: { lat, lng },
        markerLabel,
        placeId,
        value,
      });
    }
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'mapMachine',
        property: 'mapField',
        values: { markers: updatedMarkers },
      },
    });
  };

  const updatedRowDataItems = rowDataItems.map(
    ({ placeId, ...restRowDataItem }, index) => ({
      ...restRowDataItem,
      markerLabel: `${rowDataItems.length - index}`,
      placeId,
      actions: [
        {
          key: placeId,
          Component: (
            <Button
              size='small'
              startIcon={
                mapField.markers.some(
                  (marker) => marker.placeId === placeId
                ) ? (
                  <Tooltip
                    arrow
                    placement='left'
                    title='Remove marker from map'
                  >
                    <LocationOnOutlined color='secondary' fontSize='small' />
                  </Tooltip>
                ) : (
                  <Tooltip arrow placement='left' title='Add marker to map'>
                    <LocationOffOutlined color='primary' fontSize='small' />
                  </Tooltip>
                )
              }
            >
              {`${rowDataItems.length - index}`}
            </Button>
          ),
          onClick: handleOnClickMarker,
        },
      ],
    })
  );

  return (
    <MachineDrawerTable
      controlId='mapMachineToolbarDrawerTableControl'
      dataItems={updatedRowDataItems}
      tableRowProps={{
        handleOnClickDataLabel: ({ lat, lng }) => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'mapMachine',
              property: 'mapField',
              values: {
                center: { lat, lng },
              },
            },
          });
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'mapMachine',
              property: 'toolbarDrawer',
              values: {
                isDrawerCollapsed: false,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        },
      }}
      tablePaginationProps={{
        handleOnChangePage: ({ value }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'mapMachine',
              property: 'toolbarDrawer',
              values: {
                page: value,
              },
            },
          }),
        handleOnChangeRowsPerPage: ({ value }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'mapMachine',
              property: 'toolbarDrawer',
              values: {
                rowsPerPage: value,
              },
            },
          }),
        page,
        rowsPerPage,
      }}
    />
  );
};

export default DrawerInput;
