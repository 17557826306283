export const drawerStyles = (theme) => ({
  editorComponent: {
    '& .monaco-editor': {
      padding: theme.spacing(2),
    },
    /**
     * TODO Monaco Editor has option to set visibility of
     * scrollbar but could not get it to work. Will revisit.
     */
    '& .decorationsOverviewRuler': {
      display: 'none!important',
    },
  },
});
