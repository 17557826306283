import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Textfit } from 'react-textfit';
import { useActor } from '@xstate/react';
import Collapse from '@material-ui/core/Collapse';

import { globalStyles } from '../../../themes/globalStyles';
import { GlobalStateContext } from '../../../globalState';
import { useWindowProperties } from '../../../helpers';
import helpPanelDirectory from './constants';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
}));

const HelpPanel = ({ isHidden, panelId, panelProperties }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const { deviceOrientation } = useWindowProperties();
  const classes = useStyles();

  const {
    machinesContext: {
      controllerMachine: {
        helpControl: { helpPanelsClosed },
      },
    },
  } = controllerState.context;

  const { panelHeader, panelStyle, panelText } =
    helpPanelDirectory[panelId] || {};

  return !helpPanelsClosed.includes(panelId) ? (
    <Collapse
      in={!isHidden}
      timeout={panelStyle === 'helpPanelContainer' ? 0 : 'auto'}
    >
      <Textfit
        className={classes[panelStyle]}
        max={18}
        min={8}
        mode='multi'
        style={{
          display: 'flex',
          flexDirection: deviceOrientation === 'landscape' ? 'column' : 'row',
          fontFamily: 'Roboto Condensed',
          height: panelProperties ? panelProperties.height : 'auto',
          ...(deviceOrientation === 'landscape'
            ? { justifyContent: 'flex-start' }
            : { alignItems: 'flex-start' }),
          textAlign: 'justify',
        }}
      >
        <div className='html'>
          {panelStyle === 'helpPanelContainer' && (
            <div
              style={{
                alignItems: 'start',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <h2
                style={{ marginBottom: 0 }}
                dangerouslySetInnerHTML={{
                  __html: panelHeader,
                }}
              />
              <span
                className={classes.helpPanelCloseText}
                title={`Click to hide ${panelHeader}`}
                onClick={() => {
                  helpPanelsClosed.push(panelId);
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'controllerMachine',
                      property: 'helpControl',
                      values: {
                        helpPanelsClosed,
                      },
                    },
                  });
                }}
              >
                Got it, hide this
              </span>
            </div>
          )}
          <span
            dangerouslySetInnerHTML={{
              __html: panelText.join('&nbsp;&nbsp;&#8226;&nbsp;&nbsp;'),
            }}
            style={{}}
          />
        </div>
      </Textfit>
    </Collapse>
  ) : null;
};

export default HelpPanel;
