import AspectRatioOutlined from '@material-ui/icons/AspectRatioOutlined';
import ImageOutlined from '@material-ui/icons/ImageOutlined';
import SettingsOverscanOutlined from '@material-ui/icons/SettingsOverscanOutlined';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';
// import ArrowRightAltOutlined from '@material-ui/icons/ArrowRightAltOutlined';

export const IMAGE_ICON = ImageOutlined

export const IMAGE_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
    sidebarTab: true,
  },
  imageCollection: {
    collectionIndex: 0,
    collectionItems: [],
  },
  imageField: {
    isBackgroundSelected: false,
  },
  urlField: {
    value: '',
  },
  toolbarDrawer: {
    addToBackground: false,
    gridKeys: { imageType: 'gifs', searchQuery: '' },
    drawerTab: 'gif',
    imageUrl: '',
    isDrawerCollapsed: false,
    isValidImageUrl: false,
    rowDataItems: [],
    searchQuery: '',
  }
};

export const IMAGE_SHARED_CONTEXT = {
  imageField: {
    alt: '',
    backgroundCredit: {
      imageType: '',
      userName: '',
      userUrl: '',
    },
    backgroundObjectFit: 'cover',
    backgroundObjectHorizontalPosition: 50,
    backgroundObjectVerticalPosition: 50,
    backgroundUrl: '',
    credit: {
      imageType: '',
      userName: '',
      userUrl: '',
    },
    objectFit: 'contain',
    objectHorizontalPosition: 50,
    objectVerticalPosition: 50,
    title: '',
    url: '',
  },
}

export const IMAGE_OBJECT_FIT_OPTIONS = [
  {label: 'fill', value: 'cover', Icon: SettingsOverscanOutlined },
  {label: 'fit', value: 'contain', Icon: AspectRatioOutlined },
]

export const IMAGE_OBJECT_HORIZONTAL_POSITION_OPTIONS = [
  {label: 'left', value: 'left', Icon: VerticalAlignTopOutlined },
  {label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  {label: 'right', value: 'right', Icon: VerticalAlignBottomOutlined },
]

export const IMAGE_OBJECT_VERTICAL_POSITION_OPTIONS = [
  {label: 'top', value: 'top', Icon: VerticalAlignTopOutlined },
  {label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  {label: 'bottom', value: 'bottom', Icon: VerticalAlignBottomOutlined },
]
