export const textMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Text Drawer',
  helpText: [
    'This button will open and close the Text Drawer. When open, the drawer shows two tabs.',
    'The EDIT Tab is used to write or edit the sidenote in a textfield, and apply font styling.',
    'The BOT Tab is used to ask artificial intelligence to write a sidenote for you in the textfield',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const textMachineSidenoteFieldComponent = {
  helpLabel: 'Text Panel',
  helpText: [],
  relatedControlIds: [],
};

export const textMachineToolbarDrawerBotInputControl = {
  helpLabel: 'Bot Input',
  helpText: ['Ask the bot to write text for you. Pick a prompt.'],
  relatedControlIds: [],
};

export const textMachineToolbarDrawerTableControl = {
  helpLabel: 'Bot History',
  helpText: [
    'History of what you have asked the bot. Click to copy and past in input field.',
  ],
  relatedControlIds: [],
};

export const textMachineToolbarDrawerTabsBotControl = {
  helpLabel: 'Bot Tab',
  helpText: [],
  relatedControlIds: [],
};

export const textMachineToolbarDrawerTabsEditControl = {
  helpLabel: 'Text Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const textMachineToolbarFontFamilyControl = {
  helpLabel: 'Font',
  helpText: [],
  relatedControlIds: [],
};

export const textMachineToolbarFontSizeControl = {
  helpLabel: 'Font Size',
  helpText: ['Slide to change the sidenote text font size.'],
  relatedControlIds: [],
};

export const textMachineToolbarHorizontalAlignControl = {
  helpLabel: 'Horizontal Align',
  helpText: [
    'Click to align text left, center, right, or justify in the Text Box.',
  ],
  relatedControlIds: [],
};

export const textMachineToolbarReversedTypeControl = {
  helpLabel: 'Reversed Type',
  helpText: ['Click to show or hide background color for text. '],
  relatedControlIds: [],
};

export const textMachineToolbarSettingsModalControl = {
  helpLabel: 'Text Settings',
  helpText: ['Click to open the Text Settings.'],
  relatedControlIds: [],
};

export const textMachineToolbarVerticalAlignControl = {
  helpLabel: 'Vertical Align',
  helpText: [
    'Click to align text top, center, or bottom in the sidenote section.',
  ],
  relatedControlIds: [],
};

export const textMachineSidebarToolSelectControl = {
  helpLabel: 'Text Tool',
  helpText: [
    'Write stories, articles, messages, etc., or ask the bot to write it for you.',
  ],
  relatedControlIds: ['textMachineToolbarDrawerCollapseControl'],
};

export const textMachineBoardSectionVisibilityControl = {
  helpLabel: 'Text Visibility',
  helpText: ['Hide text and close the Text Tool.'],
  relatedControlIds: [],
};
