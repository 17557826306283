import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import { BOARD_MACHINES } from '../../containers/Board/constants';
import { machineIconStyles } from './styles';
import { GlobalStateContext } from '../../globalState';

import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => machineIconStyles(theme));

const MachineIcon = ({ Icon, label, ...restProps }) => {
  const { controllerService } = useContext(GlobalStateContext);
  // eslint-disable-next-line no-unused-vars
  const [_, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  return (
    <Button
      className={classes.machineButton}
      color='primary'
      onClick={() =>
        controllerSend({
          type: 'UPDATE_MACHINES_CONTEXT',
          payload: {
            machineId: 'controllerMachine',
            property: 'boardGrid',
            values: {
              activeMachineId: '',
            },
          },
        })
      }
      variant='contained'
      {...restProps}
    >
      <Tooltip placement='top' title={label}>
        <Icon />
      </Tooltip>
    </Button>
  );
};

export default MachineIcon;
