import controlDictionary from '../Controls';

/**
 * Guide: View Menus
 */

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'controllerMachineFramesPlayControl',
  'controllerMachineFramesRewindControl',
  'controllerMachineFramesPreviousControl',
  'controllerMachineFramesSliderControl',
  'controllerMachineFramesNextControl',
  'controllerMachineFramesForwardControl',
  'controllerMachineMenuMainCreate',
];

const helpTopic = {
  topicDictionary: {
    ...controlDictionary,
    controllerMachineHelpMenuControl: {
      helpLabel: 'Help',
      helpText: [
        'Step through these tooltips to get help on how to view a board.',
        'It explains how to pause and play the board, how to browse through the frames.',
      ],
      relatedControlIds: [],
    },
    controllerMachineMenuMainCreate: {
      ...controlDictionary.controllerMachineMenuMainCreate,
      helpLabel: 'Menu',
    },
  },
  topicLabel: 'VIEW A BOARD',
  topicSteps,
  topicType: 'how to',
};

export default helpTopic;
