export const mapMachineMapFieldComponent = {
  helpLabel: 'Map Panel',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Map Drawer',
  helpText: [
    'This button will open and close the Map Drawer. When open, the drawer shows two tabs.',
    'The EDIT Tab is used to write or edit the sidenote in a textfield, and apply font styling.',
    'The BOT Tab is used to ask artificial intelligence to write a sidenote for you in the textfield',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const mapMachineToolbarBackgroundControl = {
  helpLabel: 'Active Panel',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarMapDirectionControl = {
  helpLabel: 'View Direction',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarDrawerTabsControl = {
  helpLabel: 'Map Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarMapOverlayControl = {
  helpLabel: 'Map Overlay',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarMapTiltControl = {
  helpLabel: 'View Tilt',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarMapTypeControl = {
  helpLabel: 'Map Type',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineToolbarZoomControl = {
  helpLabel: 'Zoom Level',
  helpText: [],
  relatedControlIds: [],
};

export const mapMachineSidebarToolSelectControl = {
  helpLabel: 'Map Tool',
  helpText: [
    'Real-time roadmaps and satellite images to add places, locations, routes, etc.',
  ],
  relatedControlIds: [],
};

export const mapMachineBoardSectionVisibilityControl = {
  helpLabel: 'Map Visibility',
  helpText: ['Hide map and close the Map Tool.'],
  relatedControlIds: [],
};
