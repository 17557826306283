import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import { menusStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => menusStyles(theme));

const BoardOverviewMenu = () => {
  // const [assetsLoaded, setAssetsLoaded] = useState(false);
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState, sessionSend] = useActor(sessionService);
  const navigate = useNavigate();

  const classes = useStyles();

  const {
    playControl: { isLoadingAssets },
  } = controllerState.context;
  const {
    session: { name, userName },
  } = sessionState.context;

  return (
    <Card className={classes.card} id='loadCard'>
      <CardContent component={Box} mx={2} my={2} textAlign='center'>
        {!!name && (
          <Typography gutterBottom variant='h4' component='h2'>
            {name}
          </Typography>
        )}
        {!!userName && (
          <>
            <Typography gutterBottom variant='caption'>
              by
            </Typography>
            <Typography gutterBottom variant='h6' component='h3'>
              {userName}
            </Typography>
          </>
        )}
        <Box display='flex' justifyContent='space-around'>
          <div className={classes.boardOverviewButtonWrapper}>
            <Fab
              aria-label={isLoadingAssets ? 'loading' : 'play'}
              className={classes.boardOverviewButtonFab}
              disabled={!controllerState.can('START_PLAY_FRAMES')}
              color={isLoadingAssets ? 'secondary' : 'primary'}
              onClick={() => {
                controllerSend({
                  type: 'CONTROLLER_PROP_VALUES',
                  payload: {
                    property: 'playControl',
                    values: { paused: false, playing: true },
                  },
                });
                controllerSend({
                  type: 'MODAL_CLOSE',
                  payload: { modalId: 'menuBoardOverview' },
                });
              }}
            >
              {isLoadingAssets ? 'Loading' : 'Play'}
            </Fab>
            {isLoadingAssets && (
              <CircularProgress
                color='secondary'
                style={{
                  height: 102,
                  left: -8,
                  position: 'absolute',
                  top: -8,
                  width: 102,
                  zIndex: 1,
                }}
              />
            )}
          </div>
          <Fab
            aria-label='save'
            className={classes.boardOverviewButtonFab}
            color={isLoadingAssets ? 'primary' : 'secondary'}
            onClick={() => {
              controllerSend({
                type: 'MODAL_CLOSE',
                payload: { modalId: 'menuBoardOverview' },
              });
              sessionSend({
                type: 'SESSION_RESET',
              });
              controllerSend({
                type: 'CONTROLLER_RESET',
              });
              navigate(`/`);
            }}
          >
            {isLoadingAssets ? 'Cancel' : 'Quit'}
          </Fab>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BoardOverviewMenu;
