import React from 'react';

import ToolbarBackground from './Background';
import ToolbarType from './MapType';
import ToolbarOverlay from './MapOverlay';
import ToolbarZoom from './Zoom';
import ToolbarTilt from './MapTilt';
import ToolbarDirection from './MapDirection';

const MapToolbar = () => (
  <>
    <ToolbarBackground />
    <ToolbarType />
    <ToolbarOverlay />
    <ToolbarZoom />
    <ToolbarDirection />
    <ToolbarTilt />
  </>
);

export default MapToolbar;
