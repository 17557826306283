import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TouchAppRounded from '@material-ui/icons/TouchAppRounded';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const MainMenuControl = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    modalControl: { openModalIds },
  } = controllerState.context;

  const inViewMode =
    sessionState.matches('record.view') || sessionState.matches('stream.view');

  return (
    <Help
      controlId={
        !inViewMode
          ? 'controllerMachineMenuBoardOverview'
          : 'controllerMachineMenuMainCreate'
      }
      isDisabled={!!openModalIds.length}
      placement='right'
    >
      <Button
        color='primary'
        disabled={!!openModalIds.length}
        onClick={() => {
          controllerSend({
            type: 'MODAL_OPEN',
            payload: {
              modalId: 'menuMainStart',
            },
          });
        }}
        size='small'
        style={{ zIndex: 2000 }}
        variant='contained'
      >
        {showControlLabels ? 'START' : <TouchAppRounded />}
      </Button>
    </Help>
  );
};

export default MainMenuControl;
