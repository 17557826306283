import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Textfit } from 'react-textfit';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import extend from 'lodash/extend';
import { slateToHtml } from '@slate-serializers/html';

import { SIDENOTE_SHARED_CONTEXT } from './constants';
import { sidenoteStyles } from './styles';
import { GlobalStateContext } from '../../globalState';
import { convertHexToRGBWithAlpha, useWindowProperties } from '../../helpers';

const useStyles = makeStyles((theme) => sidenoteStyles(theme));

const SidenoteView = ({ gridMainSectionSize }) => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const { deviceOrientation } = useWindowProperties();
  const classes = useStyles();
  const theme = useTheme();

  const {
    viewContext: {
      sidenoteMachine: { sidenoteField: machineSidenoteField },
    },
  } = controllerState.context;

  /**
   * Merge frame composed context with the machine's initial context so any
   * future changes to the field object are gracefully set to the default value
   * when viewing an "older" board. Lodash's extend method is used to deeply
   * merge the two objects.
   */
  const sidenoteField = extend(
    SIDENOTE_SHARED_CONTEXT.sidenoteField,
    machineSidenoteField
  );
  const {
    backgroundOpacity,
    editorState,
    fontFamily,
    fontSizeMax,
    fontSizeMin,
    horizontalAlign,
    verticalAlign,
  } = sidenoteField;

  const sidenoteHtml = slateToHtml(editorState);

  return sidenoteHtml !== '<p></p>' ? (
    <Box>
      <Textfit
        className={classes.sidenoteField}
        max={fontSizeMax}
        min={fontSizeMin}
        mode='multi'
        style={{
          display: 'flex',
          flexDirection: deviceOrientation === 'landscape' ? 'column' : 'row',
          fontFamily: fontFamily,
          height: gridMainSectionSize.height,
          ...(deviceOrientation === 'landscape'
            ? { justifyContent: verticalAlign }
            : { alignItems: verticalAlign }),
          textAlign: horizontalAlign,
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: sidenoteHtml,
          }}
          style={{
            backgroundColor: convertHexToRGBWithAlpha({
              alpha: backgroundOpacity,
              hex: theme.palette.background.paper,
            }),
          }}
        ></span>
      </Textfit>
    </Box>
  ) : null;
};

export default SidenoteView;
