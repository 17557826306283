import controlDictionary from '../Controls';

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'sidenoteMachineSidebarToolSelectControl',
  'chessMachineSidebarToolSelectControl',
  'codeMachineSidebarToolSelectControl',
  'imageMachineSidebarToolSelectControl',
  'mapMachineSidebarToolSelectControl',
  'textMachineSidebarToolSelectControl',
  'videoMachineSidebarToolSelectControl',
];

const helpTopic = {
  topicDictionary: {
    ...controlDictionary,
    controllerMachineHelpMenuControl: {
      helpLabel: 'Help Tool',
      helpText: [
        'Guide about the Sidenote, Chess, Code, Image, Map, Text, and Video Tools shown in the sidebar.',
        'Click the text below to step through the guide, or the button to select a different help topic.',
      ],
      relatedControlIds: [],
    },
    sidenoteMachineSidebarToolSelectControl: {
      helpLabel: 'Sidenote Tool',
      helpText: [
        'Select the Sidenote Tool to enhance images, videos, maps, and other content by adding engaging text sidenotes with ease.',
        'When selected, the Sidenote Toolbar and Sidenote Drawer appear at the bottom of screen.',
      ],
      relatedControlIds: ['sidenoteMachineToolbarDrawerCollapseControl'],
    },
    chessMachineSidebarToolSelectControl: {
      helpLabel: 'Chess Tool',
      helpText: [
        'Demonstrate and explain chess rules, moves, positions, and more using a chess board.',
        'To activate the sidebar Chess Tool, open the stream or recording menu.',
      ],
      relatedControlIds: ['chessMachineToolbarDrawerCollapseControl'],
    },
    codeMachineSidebarToolSelectControl: {
      helpLabel: 'Code Tool',
      helpText: [
        'Display code snippets with syntax highlighting. Write your own, or have a bot write them.',
      ],
      relatedControlIds: ['codeMachineToolbarDrawerCollapseControl'],
    },
    imageMachineSidebarToolSelectControl: {
      helpLabel: 'Image Tool',
      helpText: [
        'Add gifs and images from our selection, or from the web. Set size, placement, and more.',
      ],
      relatedControlIds: ['imageMachineToolbarDrawerCollapseControl'],
    },
  },
  topicLabel: 'Sidebar Tools',
  topicSteps,
  topicType: 'guide',
};

export default helpTopic;
