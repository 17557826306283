import controlDictionary from '../Controls';

/**
 * Guide: View Menus
 */

const topicSteps = [
  'controllerMachineHelpMenuControl',
  'controllerMachineMenuMainCreate',
  'controllerMachineMenuMainCreateBoardNameControl',
  'controllerMachineMenuMainCreateBoardUserNameControl',
  'controllerMachineMenuMainCreateStartStreamControl',
];

const helpTopic = {
  topicDictionary: {
    ...controlDictionary,
    controllerMachineHelpMenuControl: {
      helpLabel: 'Intro',
      helpText: [
        'This tutorial will guide you through the steps of starting a live stream Smurfboard.',
        'Once you have started a live stream, you can invite others by sharing a link.',
      ],
      relatedControlIds: [],
    },
  },
  topicLabel: 'Start Live Stream',
  topicSteps,
  topicType: 'tutorial',
};

export default helpTopic;
