import React, { useContext, useRef } from 'react';
import { useActor } from '@xstate/react';
import { debounce } from 'lodash';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import { validateImageUrl } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';

const DrawerInput = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    machinesContext: {
      imageMachine: {
        imageCollection: { collectionItems },
        toolbarDrawer: { drawerTab, imageUrl, isValidImageUrl, searchQuery },
      },
    },
  } = controllerState.context;

  /**
   * Use debounce to add a delay before validating the image url. Without
   * it the validation will be called on every keystroke.
   */
  const handleValidateImageUrl = useRef(
    debounce(async (value) => {
      const isValidImageUrl = await validateImageUrl(value);
      controllerSend({
        type: 'UPDATE_MACHINES_CONTEXT',
        payload: {
          machineId: 'imageMachine',
          property: 'toolbarDrawer',
          values: { isValidImageUrl },
        },
      });
    }, 300)
  ).current;

  const handleImageAdd = ({ url }) => {
    if (collectionItems.some((item) => item.url === url)) return;
    const alt = '';
    const credit = {
      imageType: 'url',
      userName: '',
      userUrl: '',
    };
    const title = '';
    collectionItems.push({ alt, credit, title, url });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageCollection',
        values: {
          collectionIndex: collectionItems.length - 1,
          collectionItems,
        },
      },
    });
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'imageField',
        values: {
          credit,
          title,
          url,
        },
      },
    });
  };

  const handleOnChangeText = async ({ target: { value } }) => {
    const values =
      drawerTab === 'url' ? { imageUrl: value } : { searchQuery: value };
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values,
      },
    });
    handleValidateImageUrl(value);
  };

  const handleOnClickSearch = () => {
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'imageMachine',
        property: 'toolbarDrawer',
        values: {
          gridKeys: { drawerTab, searchQuery },
          isDrawerCollapsed: true,
        },
      },
    });
  };

  return (
    <TextField
      id='image-text-field'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {drawerTab === 'url' ? (
              <IconButton
                color='primary'
                disabled={!isValidImageUrl}
                onClick={() => {
                  handleImageAdd({ url: imageUrl });
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'imageMachine',
                      property: 'toolbarDrawer',
                      values: { imageUrl: '', isValidImageUrl: false },
                    },
                  });
                }}
                size='small'
              >
                <AddCircleOutline />
              </IconButton>
            ) : (
              <IconButton
                color='primary'
                disabled={!searchQuery}
                onClick={handleOnClickSearch}
                size='small'
              >
                <Search />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      fullWidth
      onChange={handleOnChangeText}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          handleOnClickSearch();
        }
      }}
      placeholder={drawerTab === 'url' ? 'Image url' : 'Search'}
      size='small'
      value={drawerTab === 'url' ? imageUrl : searchQuery}
      variant='outlined'
    />
  );
};

export default DrawerInput;
