export const subscribeToNewFrame = /* GraphQL */ `
  subscription SubscribeToNewFrame($boardId: ID!) {
    subscribeToNewFrame(boardId: $boardId) {
      diffString
      duration
      machineId
    }
  }
`;

export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession($filter: ModelSubscriptionSessionFilterInput) {
    onCreateSession(filter: $filter) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession($filter: ModelSubscriptionSessionFilterInput) {
    onUpdateSession(filter: $filter) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession($filter: ModelSubscriptionSessionFilterInput) {
    onDeleteSession(filter: $filter) {
      boardId
      id
      mode
      name
      status
      userName
      createdAt
      updatedAt
    }
  }
`;

export const onCreateBoard = /* GraphQL */ `
  subscription OnCreateBoard($filter: ModelSubscriptionBoardFilterInput) {
    onCreateBoard(filter: $filter) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateBoard = /* GraphQL */ `
  subscription OnUpdateBoard($filter: ModelSubscriptionBoardFilterInput) {
    onUpdateBoard(filter: $filter) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteBoard = /* GraphQL */ `
  subscription OnDeleteBoard($filter: ModelSubscriptionBoardFilterInput) {
    onDeleteBoard(filter: $filter) {
      frames {
        items {
          boardId
          createdAt
          diffString
          duration
          machineId
          id
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const onCreateFrame = /* GraphQL */ `
  subscription OnCreateFrame($filter: ModelSubscriptionFrameFilterInput) {
    onCreateFrame(filter: $filter) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;

export const onUpdateFrame = /* GraphQL */ `
  subscription OnUpdateFrame($filter: ModelSubscriptionFrameFilterInput) {
    onUpdateFrame(filter: $filter) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;

export const onDeleteFrame = /* GraphQL */ `
  subscription OnDeleteFrame($filter: ModelSubscriptionFrameFilterInput) {
    onDeleteFrame(filter: $filter) {
      boardId
      createdAt
      diffString
      duration
      machineId
      id
      updatedAt
    }
  }
`;
