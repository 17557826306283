import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';

import { boardStyles } from './styles';
import { calculateBoardGridProps, useWindowProperties } from '../../../helpers';
import { GlobalStateContext } from '../../../globalState';
import AudioMute from '../../Controller/Controls/AudioMute';
import PanelBackground from './PanelBackground';
import PanelMain from './PanelMain';
import PanelSlider from '../../Controller/Controls/PanelsSlider';
import PanelTabs from './PanelTabs';
import SidebarLandscapeLeft from './Sidebars/LandscapeLeft';
import SidebarLandscapeRight from './Sidebars/LandscapeRight';
import SidebarPortraitLeft from './Sidebars/PortraitLeft';
import SidebarPortraitRight from './Sidebars/PortraitRight';
import PanelsBackgroundVisibility from './PanelsBackgroundVisibility';

const useStyles = makeStyles((theme) => boardStyles(theme));

const BoardGridContainer = () => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const [gridMainSectionSize, setGridMainSectionSize] = useState({});
  const [gridTabsSectionSize, setGridTabsSectionSize] = useState({});
  const classes = useStyles();
  const gridMainSectionRef = useRef(null);
  const gridTabsSectionRef = useRef(null);
  const windowProperties = useWindowProperties();

  const {
    editContext,
    editControl: { isEditFrameModeActive },
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
    viewContext,
  } = controllerState.context;

  const inEditMode =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit');

  const {
    containerDirection,
    containerDirectionReverse,
    containerOrientation,
    itemBreakpoint,
  } = inEditMode
    ? editContext.controllerMachine.boardGrid
    : viewContext.controllerMachine.boardGrid;

  const {
    containerFlexDirection,
    itemCenterSpacerSx,
    itemCenterSpacerXs,
    itemLeftSpacerSx,
    itemLeftSpacerXs,
    itemMainSectionSx,
    itemMainSectionXs,
    itemRightSpacerSx,
    itemRightSpacerXs,
    itemTabSectionSx,
    itemTabSectionXs,
  } = calculateBoardGridProps({
    containerOrientation,
    containerDirection,
    containerDirectionReverse,
    itemBreakpoint,
  });

  useEffect(() => {
    // See: https://codefrontend.com/resize-observer-react/
    const mainSectionElement = gridMainSectionRef?.current;
    const tabsSectionElement = gridTabsSectionRef?.current;
    if (!mainSectionElement || !tabsSectionElement) return;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { id, clientHeight, clientWidth } = entry.target;
        if (id === 'gridMainSection') {
          setGridMainSectionSize({
            height: clientHeight,
            width: clientWidth,
          });
        }
        if (id === 'gridTabsSection') {
          setGridTabsSectionSize({
            height: clientHeight,
            width: clientWidth,
          });
        }
      }
    });
    observer.observe(mainSectionElement);
    observer.observe(tabsSectionElement);

    return () => {
      observer.disconnect();
    };
  }, [containerDirectionReverse, containerOrientation]);

  const handleActiveMachineChange = ({ editValues, machineValues }) => {
    if (!isEmpty(editValues)) {
      controllerSend({
        type: 'UPDATE_EDIT_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardGrid',
          values: { ...editValues },
        },
      });
    }
    if (!isEmpty(machineValues)) {
      controllerSend({
        type: 'UPDATE_MACHINES_CONTEXT',
        payload: {
          machineId: 'controllerMachine',
          property: 'boardGrid',
          values: { ...machineValues },
        },
      });
      if (!isEmpty(machineValues.activeMachineId)) {
        controllerSend({
          type: 'UPDATE_MACHINES_CONTEXT',
          payload: {
            machineId: machineValues.activeMachineId,
            property: 'toolbarDrawer',
            values: {
              isDrawerCollapsed: true,
            },
          },
        });
      }
    }
    const { autoCapture } =
      machinesContext[machineValues.activeMachineId || activeMachineId]
        .boardSettings;
    if (autoCapture && (!isEmpty(editValues) || !isEmpty(machineValues))) {
      controllerSend({
        type: 'SUBMIT_FRAME_CAPTURE',
      });
    }
  };

  return (
    <Container
      className={classes.orientationContainer}
      disableGutters
      maxWidth={false}
    >
      <PanelBackground />
      <Container
        className={classes.mainContainer}
        p={[1, 2, 3, 4, 5]}
        disableGutters
        maxWidth={false}
      >
        <Container
          className={classes.bodyContainer}
          disableGutters
          maxWidth={false}
        >
          <Grid
            className={classes.bodyContainerGrid}
            container
            component={Box}
            style={{
              height: '100vh',
              width: '100vw',
            }}
            wrap='nowrap'
          >
            {/**
             * The left container
             */}
            <Grid
              id='boardGridContainerLeft'
              component={Box}
              display='flex'
              flexDirection='column'
              item
              // Set to progress bar height to align buttons with main content
              paddingBottom={0.5}
              paddingTop={0.5}
              // Compensate the height of the fixed sidebarLandscapeLeftBottom below
              style={{ marginBottom: 48 }}
              xs={1}
            >
              {containerDirection === 'column' ? (
                <SidebarPortraitLeft
                  handleActiveMachineChange={handleActiveMachineChange}
                />
              ) : (
                <SidebarLandscapeLeft
                  handleActiveMachineChange={handleActiveMachineChange}
                />
              )}
              <Box
                id='sidebarLandscapeLeftBottom'
                display='flex'
                flexDirection='column'
                style={{
                  top: 'auto',
                  bottom: 4,
                  position: 'fixed',
                  width: '8.33333333%',
                }}
              ></Box>
            </Grid>
            {/**
             * The center container
             */}
            <Grid
              id='boardGridContainerCenter'
              component={Box}
              item
              xs={10}
              style={{
                alignSelf: 'center',
                flexDirection: containerDirection,
                height: windowProperties[containerOrientation].height,
                maxHeight: windowProperties[containerOrientation].height,
                maxWidth: windowProperties[containerOrientation].width,
                margin: 'auto',
                position: 'relative',
                width: windowProperties[containerOrientation].width,
              }}
            >
              {inEditMode &&
                containerDirection === 'row' &&
                isEditFrameModeActive && (
                  <Grid className={classes.gridPanelSlider} container>
                    <Grid item xs={12}>
                      <PanelSlider />
                    </Grid>
                  </Grid>
                )}
              <PanelsBackgroundVisibility />
              <Grid
                className={classes.bodyContainerGrid}
                component={Box}
                container
                display='flex'
                style={{
                  flexDirection: containerFlexDirection,
                }}
              >
                <Grid
                  component={Box}
                  item
                  style={itemLeftSpacerSx}
                  xs={itemLeftSpacerXs || null}
                />
                <Grid
                  component={Box}
                  id='gridMainSection'
                  item
                  ref={gridMainSectionRef}
                  style={itemMainSectionSx}
                  xs={itemMainSectionXs || null}
                >
                  <PanelMain
                    gridMainSectionSize={gridMainSectionSize}
                    handleActiveMachineChange={handleActiveMachineChange}
                  />
                </Grid>
                <Grid
                  component={Box}
                  item
                  style={itemCenterSpacerSx}
                  xs={itemCenterSpacerXs || null}
                />
                <Grid
                  component={Box}
                  id='gridTabsSection'
                  item
                  ref={gridTabsSectionRef}
                  style={itemTabSectionSx}
                  xs={itemTabSectionXs || null}
                >
                  <PanelTabs
                    gridTabsSectionSize={gridTabsSectionSize}
                    handleActiveMachineChange={handleActiveMachineChange}
                  />
                </Grid>
                <Grid
                  component={Box}
                  item
                  style={itemRightSpacerSx}
                  xs={itemRightSpacerXs || null}
                />
              </Grid>
            </Grid>
            {/**
             * The right container
             */}
            <Grid
              id='boardGridContainerRight'
              component={Box}
              display='flex'
              flexDirection='column'
              item
              // Set to progress bar height to align buttons with main content
              paddingBottom={0.5}
              paddingTop={0.5}
              xs={1}
            >
              {containerDirection === 'column' ? (
                <SidebarPortraitRight
                  handleActiveMachineChange={handleActiveMachineChange}
                />
              ) : (
                <SidebarLandscapeRight
                  handleActiveMachineChange={handleActiveMachineChange}
                />
              )}
              {controllerState.matches('view') && <AudioMute />}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Container>
  );
};

export default BoardGridContainer;
