import React, { useState } from 'react';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import ResizeObserver from 'react-resize-observer';

import { calculateGifDuration } from '../../../helpers';

const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_API_KEY);

const GiphyGrid = ({
  gridColumns,
  gridKeys,
  handleImageAdd,
  searchQuery,
  imageType,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  const fetchGifs = (offset) =>
    !searchQuery
      ? giphyFetch.trending({ offset, limit: 10, type: imageType })
      : giphyFetch.search(searchQuery, { offset, limit: 10, type: imageType });

  const handleOnGifClick = async (gif, event) => {
    if (event.cancelable) event.preventDefault();
    const {
      images: {
        original: { url },
      },
      title,
      user,
      username,
    } = gif;
    const credit = {
      imageType: 'giphy',
      userName: username,
      // Not all gifs provide a user object
      userUrl: user ? user.profile_url : '',
    };
    const gifDuration = await calculateGifDuration({ url });
    handleImageAdd({ alt: title, credit, gifDuration, title, url });
  };

  return (
    <>
      <Grid
        backgroundColor='transparent'
        key={`${gridKeys.imageType}-${gridKeys.searchQuery}`}
        columns={gridColumns}
        fetchGifs={fetchGifs}
        gutter={6}
        hideAttribution
        noLink
        onGifClick={handleOnGifClick}
        width={width}
      />
      <ResizeObserver
        onResize={({ width }) => {
          setWidth(width);
        }}
      />
    </>
  );
};

export default GiphyGrid;
