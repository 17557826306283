import CodeOutlined from '@material-ui/icons/CodeOutlined';

export const CODE_ICON = CodeOutlined;

export const CODE_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
    sidebarTab: false,
  },
  codeField: {
    themeMonaco: 'TomorrowNight',
  },
  toolbarDrawer: {
    chatGptAsk: '',
    drawerTab: 'editor',
    isDrawerCollapsed: true,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const CODE_SHARED_CONTEXT = {
  codeField: {
    language: 'javascript',
    themeHighlight: 'vs2015.min.css',
    value: '',
  },
};

export const CODE_SUPPORTED_LANGUAGES = [
  { label: 'Apex', value: 'apex' },
  { label: 'Azcli', value: 'azcli' },
  { label: 'Bat', value: 'bat' },
  { label: 'C', value: 'c' },
  { label: 'Clojure', value: 'clojure' },
  { label: 'Coffeescript', value: 'coffeescript' },
  { label: 'CPP', value: 'cpp' },
  { label: 'C Sharp', value: 'csharp' },
  { label: 'CSP', value: 'csp' },
  { label: 'CSS', value: 'css' },
  { label: 'Dockerfile', value: 'dockerfile' },
  { label: 'F Sharp', value: 'fsharp' },
  { label: 'Go', value: 'go' },
  { label: 'GraphQL', value: 'graphql' },
  { label: 'Handlebars', value: 'handlebars' },
  { label: 'HTML', value: 'html' },
  { label: 'Ini', value: 'ini' },
  { label: 'Java', value: 'java' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'JSON', value: 'json' },
  { label: 'Kotlin', value: 'kotlin' },
  { label: 'LESS', value: 'less' },
  { label: 'Lua', value: 'lua' },
  { label: 'Markdown', value: 'markdown' },
  { label: 'MS Dax', value: 'msdax' },
  { label: 'MySQL', value: 'mysql' },
  { label: 'Objective-C', value: 'objective-c' },
  { label: 'Pascal', value: 'pascal' },
  { label: 'Perl', value: 'perl' },
  { label: 'PostgresSQL', value: 'pgsql' },
  { label: 'PHP', value: 'php' },
  { label: 'Plaintext', value: 'plaintext' },
  { label: 'Postiats', value: 'postiats' },
  { label: 'Power Query', value: 'powerquery' },
  { label: 'PowerShell', value: 'powershell' },
  { label: 'Pug', value: 'pug' },
  { label: 'Python', value: 'python' },
  { label: 'R', value: 'r' },
  { label: 'Razor', value: 'razor' },
  { label: 'Redis', value: 'redis' },
  { label: 'Redshift', value: 'redshift' },
  { label: 'Ruby', value: 'ruby' },
  { label: 'Rust', value: 'rust' },
  { label: 'SB', value: 'sb' },
  { label: 'Scheme', value: 'scheme' },
  { label: 'SCSS', value: 'scss' },
  { label: 'Shell', value: 'shell' },
  { label: 'SOL', value: 'sol' },
  { label: 'SQL', value: 'sql' },
  { label: 'ST', value: 'st' },
  { label: 'Swift', value: 'swift' },
  { label: 'TCL', value: 'tcl' },
  { label: 'TypeScript', value: 'typescript' },
  { label: 'Visual Basic', value: 'vb' },
  { label: 'XML', value: 'xml' },
  { label: 'YAML', value: 'yaml' },
];
