import SubjectOutlined from '@material-ui/icons/SubjectOutlined';
import FormatAlignCenterRounded from '@material-ui/icons/FormatAlignCenterRounded';
import FormatAlignJustifyRounded from '@material-ui/icons/FormatAlignJustifyRounded';
import FormatAlignLeftRounded from '@material-ui/icons/FormatAlignLeftRounded';
import FormatAlignRightRounded from '@material-ui/icons/FormatAlignRightRounded';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';

export const TEXT_ICON = SubjectOutlined;

export const TEXT_MACHINE_CONTEXT = {
  boardSettings: {
    autoCapture: false,
    sidebarTab: true,
  },
  toolbarDrawer: {
    chatGptAsk: '',
    drawerTab: 'editor',
    isDrawerCollapsed: true,
    page: 0,
    rowDataItems: [],
    rowsPerPage: 5,
  },
};

export const TEXT_SHARED_CONTEXT = {
  textField: {
    act: 'Storyteller',
    backgroundOpacity: 0.75,
    editorState: [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
    fontFamily: 'Roboto Condensed',
    fontSizeMax: 20,
    fontSizeMin: 10,
    horizontalAlign: 'center',
    language: 'English',
    verticalAlign: 'center',
  },
};

export const TEXT_HORIZONTAL_ALIGN_OPTIONS = [
  { value: 'left', Icon: FormatAlignLeftRounded },
  { value: 'center', Icon: FormatAlignCenterRounded },
  { value: 'justify', Icon: FormatAlignJustifyRounded },
  { value: 'right', Icon: FormatAlignRightRounded },
];

export const TEXT_VERTICAL_ALIGN_OPTIONS = [
  { label: 'top', value: 'flex-start', Icon: VerticalAlignTopOutlined },
  { label: 'center', value: 'center', Icon: VerticalAlignCenterOutlined },
  { label: 'bottom', value: 'flex-end', Icon: VerticalAlignBottomOutlined },
];
