const session = {
  autoPlay: true,
  mode: 'record',
  role: 'view',
  name: "How to play the Queen's Gambit",
  status: 'closed',
  userName: 'Marco',
};

export default session;
