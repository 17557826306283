import { SIDENOTE_ICON_ROW } from '../Sidenote/constants';
import { CHESS_ICON } from '../Chess/constants';
import { CODE_ICON } from '../Code/constants';
import { IMAGE_ICON } from '../Image/constants';
import { MAP_ICON } from '../Map/constants';
import { TEXT_ICON } from '../Text/constants';
import { VIDEO_ICON } from '../Video/constants';

import Sidenote from '../Sidenote';
import Chess from '../Chess';
import Code from '../Code';
import Image from '../Image';
import Map from '../Map';
import Text from '../Text';
import Video from '../Video';

export const BOARD_MACHINES = [
  {
    ariaLabel: 'sidenote',
    canAutoCapture: true,
    Component: Sidenote,
    Icon: SIDENOTE_ICON_ROW,
    isTabbed: false,
    label: 'Sidenote',
    value: 'sidenoteMachine',
  },
  {
    ariaLabel: 'chess',
    canAutoCapture: true,
    Component: Chess,
    Icon: CHESS_ICON,
    isDisabled: false,
    isTabbed: true,
    label: 'Chess',
    value: 'chessMachine',
  },
  {
    ariaLabel: 'code',
    canAutoCapture: true,
    Component: Code,
    Icon: CODE_ICON,
    isDisabled: false,
    isTabbed: true,
    label: 'Code',
    value: 'codeMachine',
  },
  {
    ariaLabel: 'image',
    canAutoCapture: true,
    Component: Image,
    Icon: IMAGE_ICON,
    isDisabled: false,
    isTabbed: true,
    label: 'Image',
    value: 'imageMachine',
  },
  {
    ariaLabel: 'map',
    canAutoCapture: true,
    Component: Map,
    Icon: MAP_ICON,
    isDisabled: false,
    isTabbed: true,
    label: 'Map',
    value: 'mapMachine',
  },
  {
    ariaLabel: 'text',
    canAutoCapture: true,
    Component: Text,
    Icon: TEXT_ICON,
    isDisabled: false,
    isTabbed: true,
    label: 'Text',
    value: 'textMachine',
  },
  {
    ariaLabel: 'video',
    canAutoCapture: true,
    Component: Video,
    Icon: VIDEO_ICON,
    isDisabled: true,
    isTabbed: true,
    label: 'Video',
    value: 'videoMachine',
  },
];
