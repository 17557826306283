import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';

import { GlobalStateContext } from '../../../globalState';
import BoardSettings from '../../Controller/Controls/BoardSettings';
import MainMenu from '../../Controller/Controls/MainMenu';
import HelpMenu from '../../Controller/Controls/HelpMenu';
import BoardLink from '../../Controller/Controls/BoardLink';
import BoardView from '../../Controller/Controls/BoardView';

const MenuOptions = ({ isLeftSidebar }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const inEditMode =
    controllerState.matches('edit') &&
    (sessionState.matches('record.edit') ||
      sessionState.matches('stream.edit'));

  return (
    <>
      {isLeftSidebar && !inEditMode && (
        <Box display='flex' flexDirection='column'>
          <MainMenu />
          <HelpMenu />
          <BoardView />
          <BoardLink />
        </Box>
      )}
      {inEditMode && (
        <Box display='flex' flexDirection='column'>
          <BoardSettings />
          <HelpMenu />
          <BoardView />
          <BoardLink />
        </Box>
      )}
    </>
  );
};

export default MenuOptions;
