import React from 'react';
import T from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { machineToolbarStyles } from './styles';

const useStyles = makeStyles((theme) => machineToolbarStyles(theme));

const MachineWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.machineToolbarPaper}
      component={Box}
      display='flex'
      elevation={6}
    >
      {children}
    </Paper>
  );
};

MachineWrapper.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default MachineWrapper;
