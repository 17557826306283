import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import { GlobalStateContext } from '../../globalState';
import VideoView from './View';
import VideoEdit from './Edit';

const Video = ({ gridTabsSectionSize, renderBackgroundComponent }) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);

  const {
    editControl: { isEditFrameModeActive },
  } = controllerState.context;

  const {
    controllerMachine: {
      boardGrid: { backgroundMachineId, activeTabMachineId },
    },
  } =
    sessionState.matches('record.edit') || sessionState.matches('stream.edit')
      ? controllerState.context.editContext
      : controllerState.context.viewContext;

  if (
    (activeTabMachineId === 'videoMachine' &&
      backgroundMachineId !== 'videoMachine') ||
    (backgroundMachineId === 'videoMachine' && renderBackgroundComponent)
  ) {
    if (
      isEditFrameModeActive &&
      (sessionState.matches('record.edit') ||
        sessionState.matches('stream.edit'))
    ) {
      return (
        <VideoEdit
          gridTabsSectionSize={gridTabsSectionSize}
          renderBackgroundComponent={renderBackgroundComponent}
        />
      );
    }
    if (
      !isEditFrameModeActive ||
      sessionState.matches('record.view') ||
      sessionState.matches('stream.view')
    ) {
      return (
        <VideoView
          gridTabsSectionSize={gridTabsSectionSize}
          renderBackgroundComponent={renderBackgroundComponent}
        />
      );
    }
  }
  return null;
};

export default Video;
