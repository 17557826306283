/**
 * See: https://www.npmjs.com/package/diff-match-patch
 */

import DiffMatchPatch from 'diff-match-patch';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';

export const applyDiffStringToObject = (
  oldObject,
  diffString,
  reverse = false,
  updateDiffString = ''
) => {
  const oldText = JSON.stringify(oldObject);
  const newText = applyDiffStringToText(
    oldText,
    diffString,
    reverse,
    updateDiffString
  );
  return JSON.parse(newText);
};

export const applyDiffStringToText = (oldText, diffString, reverse = false) => {
  const dmp = new DiffMatchPatch();
  const patch = dmp.patch_fromText(diffString);
  const usePatch = reverse ? reverseDiffPatch(patch) : patch;
  const [newText, patchResults] = dmp.patch_apply(usePatch, oldText);
  if (patchResults.some((success) => !success)) {
    const oldObject = JSON.parse(oldText);
    const newObject = JSON.parse(newText);
    console.log(
      'Warning: patch failed! Diffing the old and new objects could be helpful debugging the issue.',
      {
        oldObject,
        newObject,
        patchResults,
        patch,
      }
    );
    // TODO: Proper error handling when patch fails. Issues mostly occurs when
    // moving playhead in reverse, or continue play after having reversed playhead.
    // Consider resetting and rebuilding the object until the current index, and display
    // a message to board was rebuild due to error (probably caused by reverse play).
    return oldText;
  }
  return newText;
};

export const createDiffStringFromObjects = (oldObject, newObject) => {
  const oldText = JSON.stringify(oldObject);
  const newText = JSON.stringify(newObject);
  return createDiffStringFromText(oldText, newText);
};

export const createDiffStringFromText = (oldText, newText) => {
  const dmp = new DiffMatchPatch();
  const patch = dmp.patch_make(oldText, newText);
  return dmp.patch_toText(patch);
};

/**
 * See https://stackoverflow.com/questions/67985183/lodash-difference-between-two-objects
 */
export const createDiffObjectFromObjects = (oldObject, newObject) => {
  const changes = differenceWith(
    toPairs(oldObject),
    toPairs(newObject),
    isEqual
  );
  return fromPairs(changes);
};

export const getDiffInCharactersFromStrings = (oldText, newText) => {
  const dmp = new DiffMatchPatch();
  const diffChanges = dmp.diff_main(oldText, newText);
  const diffChars = dmp.diff_levenshtein(diffChanges);
  return diffChars;
};

/**
 * See: https://github.com/google/diff-match-patch/issues/29#issuecomment-647627182
 */
export const reverseDiffPatch = (patch) => {
  return patch.map((patchObj) => ({
    diffs: patchObj.diffs.map(([op, val]) => [
      op * -1, // The money maker
      val,
    ]),
    start1: patchObj.start2,
    start2: patchObj.start1,
    length1: patchObj.length2,
    length2: patchObj.length1,
  }));
};
