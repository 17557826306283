export const themeColors = [
  {
    label: 'Blue',
    value: 'blue',
  },
  {
    label: 'Green',
    value: 'green',
  },
  {
    label: 'Indigo',
    value: 'indigo',
  },
  {
    label: 'Orange',
    value: 'orange',
  },
  {
    label: 'Red',
    value: 'red',
  },
  {
    label: 'Yellow',
    value: 'yellow',
  },
  {
    label: 'Violet',
    value: 'violet',
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
];

export const themeModes = [
  {
    label: 'Dark',
    value: 'dark',
  },
  {
    label: 'Light',
    value: 'light',
  },
];

export const themeDictionary = {
  red: {
    dark: {
      boardTheme: {
        backgroundDefault: '#2C0B0E', // Dark Red
        backgroundPaper: '#4A0E12', // Deeper Red
        textMainPanel: '#FFC1C3', // Light Pink
        textSidePanel: '#FF8A8D', // Softer Pink
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#C62828', // Crimson Red
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#FF5252', // Bright Red
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF8A80', // Light Coral
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#FFEBEE', // Light Pink
        backgroundPaper: '#FFCDD2', // Soft Pink
        textMainPanel: '#B71C1C', // Dark Red
        textSidePanel: '#D32F2F', // Medium Dark Red
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#FF8A80', // Light Coral
        primaryContrastText: '#000000', // Black
        primaryMain: '#FF5252', // Bright Red
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#C62828', // Crimson Red
      },
    },
  },
  orange: {
    dark: {
      boardTheme: {
        backgroundDefault: '#40220C', // Dark Orange
        backgroundPaper: '#5C3314', // Deeper Orange
        textMainPanel: '#FFD8B2', // Soft Peach
        textSidePanel: '#FFAB91', // Light Apricot
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#EF6C00', // Deep Orange
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#FF8F00', // Vivid Orange
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#FFF3E0', // Light Peach
        backgroundPaper: '#FFE0B2', // Soft Peach
        textMainPanel: '#E65100', // Dark Orange
        textSidePanel: '#F57C00', // Medium Dark Orange
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#FFCC80', // Light Salmon
        primaryContrastText: '#000000', // Black
        primaryMain: '#FF8F00', // Vivid Orange
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  yellow: {
    dark: {
      boardTheme: {
        backgroundDefault: '#413F0B', // Dark Yellow
        backgroundPaper: '#615E11', // Deeper Yellow
        textMainPanel: '#FFF9C4', // Pale Yellow
        textSidePanel: '#FFEE58', // Soft Yellow
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#F9A825', // Bright Yellow
        primaryContrastText: '#000000', // Black
        primaryMain: '#FDD835', // Sunflower Yellow
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#FFFDE7', // Light Lemon
        backgroundPaper: '#FFF9C4', // Soft Yellow
        textMainPanel: '#F57F17', // Dark Yellow
        textSidePanel: '#F9A825', // Medium Dark Yellow
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#FFEB3B', // Lemon Yellow
        primaryContrastText: '#000000', // Black
        primaryMain: '#FDD835', // Sunflower Yellow
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  green: {
    dark: {
      boardTheme: {
        backgroundDefault: '#0C2C14', // Dark Green
        backgroundPaper: '#144022', // Deeper Green
        textMainPanel: '#C8E6C9', // Pale Mint
        textSidePanel: '#A5D6A7', // Soft Mint
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#388E3C', // Forest Green
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#4CAF50', // Bright Green
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#E8F5E9', // Light Mint
        backgroundPaper: '#C8E6C9', // Soft Mint
        textMainPanel: '#2E7D32', // Dark Green
        textSidePanel: '#388E3C', // Medium Dark Green
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#A5D6A7', // Light Mint
        primaryContrastText: '#000000', // Black
        primaryMain: '#4CAF50', // Bright Green
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  blue: {
    dark: {
      boardTheme: {
        backgroundDefault: '#0B1E34', // Dark Blue
        backgroundPaper: '#0E2A50', // Deeper Blue
        textMainPanel: '#BBDEFB', // Soft Sky Blue
        textSidePanel: '#90CAF9', // Light Sky Blue
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#1565C0', // Navy Blue
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#1976D2', // Bright Blue
        secondaryContrastText: '#000000', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#E3F2FD', // Light Blue
        backgroundPaper: '#BBDEFB', // Soft Blue
        textMainPanel: '#1565C0', // Dark Blue
        textSidePanel: '#1565C0', // Medium Dark Blue
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#90CAF9', // Light Blue
        primaryContrastText: '#000000', // Black
        primaryMain: '#1976D2', // Bright Blue
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  indigo: {
    dark: {
      boardTheme: {
        backgroundDefault: '#14133A', // Dark Indigo
        backgroundPaper: '#1B1A52', // Deeper Indigo
        textMainPanel: '#C5CAE9', // Soft Lavender
        textSidePanel: '#9FA8DA', // Light Lavender
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#283593', // Dark Indigo
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#3F51B5', // Bright Indigo
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#E8EAF6', // Light Lavender
        backgroundPaper: '#C5CAE9', // Soft Lavender
        textMainPanel: '#1A237E', // Dark Indigo
        textSidePanel: '#283593', // Medium Dark Indigo
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#9FA8DA', // Light Lavender
        primaryContrastText: '#000000', // Black
        primaryMain: '#3F51B5', // Bright Indigo
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  violet: {
    dark: {
      boardTheme: {
        backgroundDefault: '#31134A', // Dark Violet
        backgroundPaper: '#422A5C', // Deeper Violet
        textMainPanel: '#E1BEE7', // Soft Lilac
        textSidePanel: '#CE93D8', // Light Lilac
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#8E24AA', // Plum
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#9C27B0', // Bright Violet
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#F3E5F5', // Light Lilac
        backgroundPaper: '#E1BEE7', // Soft Lilac
        textMainPanel: '#6A1B9A', // Dark Violet
        textSidePanel: '#8E24AA', // Medium Dark Violet
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#CE93D8', // Light Lilac
        primaryContrastText: '#000000', // Black
        primaryMain: '#9C27B0', // Bright Violet
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
  neutral: {
    dark: {
      boardTheme: {
        backgroundDefault: '#000000', // Black
        backgroundPaper: '#1F1F1F', // Darker Grey
        textMainPanel: '#E0E0E0', // Light Grey
        textSidePanel: '#BDBDBD', // Softer Grey
      },
      toolbarTheme: {
        defaultContrastText: '#FFFFFF', // White
        defaultMain: '#424242', // Medium Grey
        primaryContrastText: '#FFFFFF', // White
        primaryMain: '#616161', // Grey
        secondaryContrastText: '#000000', // Black
        secondaryMain: '#FF5252', // Bright Red
      },
    },
    light: {
      boardTheme: {
        backgroundDefault: '#FFFFFF', // White
        backgroundPaper: '#E0E0E0', // Soft Grey
        textMainPanel: '#212121', // Dark Grey
        textSidePanel: '#424242', // Medium Dark Grey
      },
      toolbarTheme: {
        defaultContrastText: '#000000', // Black
        defaultMain: '#BDBDBD', // Light Grey
        primaryContrastText: '#000000', // Black
        primaryMain: '#9E9E9E', // Grey
        secondaryContrastText: '#FFFFFF', // White
        secondaryMain: '#FF5252', // Bright Red
      },
    },
  },
};

/**
 * Make sure to only include Fonts hosted by Google, or else loading the
 * fonts will fail (403 error) when deployed to AWS Amplify.
 * TODO: Should we host the fonts ourselves?
 */
export const GOOGLE_FONTS_LIST = [
  { font: 'Archivo Black', weights: [400, 700] },
  {
    font: 'Cousine',
    weights: [400, 700],
  },
  { font: 'Dancing Script', weights: [400, 700] },
  {
    font: 'EB Garamond',
    weights: [400, 700],
  },
  {
    font: 'Fredericka the Great',
    weights: [400, 700],
  },
  {
    font: 'Lato',
    weights: [400, 700],
  },
  { font: 'Matemasie', weights: [400, 700] },
  {
    font: 'Montserrat',
    weights: [400, 700],
  },
  {
    font: 'Nanum Gothic Coding',
    weights: [400, 700],
  },
  {
    font: 'Opens Sans',
    weights: [400, 700],
  },
  {
    font: 'Oswald',
    weights: [400, 700],
  },
  {
    font: 'Patua One',
    weights: [400, 700],
  },
  {
    font: 'Poppins',
    weights: [400, 700],
  },
  {
    font: 'Raleway',
    weights: [400, 700],
  },
  { font: 'Roboto', weights: [400, 700] },
  { font: 'Roboto Condensed', weights: [400, 700] },
  {
    font: 'Roboto Slab',
    weights: [400, 700],
  },
  { font: 'Rowdies', weights: [400, 700] },
  {
    font: 'Rubik',
    weights: [400, 700],
  },
  {
    font: 'Saira',
  },
];
