export const sidenoteMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Sidenote Drawer',
  helpText: [
    'Open the Sidenote Drawer to access to Sidenote Tool settings, enter or edit the sidenote text, or ask our Bot to write a sidenote for you',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineSidenoteFieldComponent = {
  helpLabel: 'Side Panel',
  helpText: [
    'Area where the sidenote text is displayed within. Enter the sidenote text in the bottom toolbar.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarDrawerBotInputControl = {
  helpLabel: 'Bot Input',
  helpText: [
    'Ask the bot whatever you want it write a sidenote about. Select the Edit Sidenote tab to modify the sidenote.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarDrawerTableControl = {
  helpLabel: 'Prompt History',
  helpText: [
    'List of prompts you have previously submitted to the Bot. When clicked, the prompt will be copied into the input field and can be edited before submitting.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarDrawerEditInputControl = {
  helpLabel: 'Sidenote Input',
  helpText: [
    'Text field to enter and edit the sidenote text, and apply font styles.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarDrawerTabsBotControl = {
  helpLabel: 'Sidenote Bot',
  helpText: ['Select to access the Sidenote Bot.'],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarDrawerTabsEditControl = {
  helpLabel: 'Sidenote Drawer',
  helpText: ['Select to access the Sidenote Text input field.'],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarFontFamilyControl = {
  helpLabel: 'Font Family',
  helpText: [],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarFontSizeControl = {
  helpLabel: 'Font Size',
  helpText: [
    'Slide to change the sidenote text maximum font size. Large font sizes will automatically be adjusted on smaller screens.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarHorizontalAlignControl = {
  helpLabel: 'Horizontal Align',
  helpText: [
    'Click to align the sidenote text left, center, right, or justify in the Side Panel.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarReversedTypeControl = {
  helpLabel: 'Reversed Type',
  helpText: [
    'Click to set or unset the background color for the sidenote text. It can emphasize the sidenote text when displayed over a background image.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarSettingsModalControl = {
  helpLabel: 'Sidenote Settings',
  helpText: [
    'Opens the Sidenote Settings modal to change the Bot response and sidenote text font family.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineToolbarVerticalAlignControl = {
  helpLabel: 'Vertical Align',
  helpText: [
    'Click to vertically align sidenote text top, center, or bottom withing the Side Panel.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineSidebarToolSelectControl = {
  helpLabel: 'Sidenote Tool',
  helpText: [
    'Enhance images, videos, maps, and other content by adding a sidenote.',
  ],
  relatedControlIds: [],
};

export const sidenoteMachineBoardSectionVisibilityControl = {
  helpLabel: 'Sidenote Visibility',
  helpText: ['Hide sidenote and close the Sidenote Tool.'],
  relatedControlIds: [],
};
