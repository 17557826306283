export const videoMachineToolbarDrawerCollapseControl = {
  helpLabel: 'Video Drawer',
  helpText: [
    'This button will open and close the Video Drawer. When open, the drawer shows two tabs.',
    'The EDIT Tab is used to write or edit the sidenote in a textfield, and apply font styling.',
    'The BOT Tab is used to ask artificial intelligence to write a sidenote for you in the textfield',
    'The bottom toolbar contains various controls to position and display the sidenote. ',
  ],
  relatedControlIds: [],
};

export const videoMachineVideoFieldComponent = {
  helpLabel: 'Video Panel',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineToolbarAudioMuteControl = {
  helpLabel: 'Audio Mute',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineToolbarBackgroundControl = {
  helpLabel: 'Active Panel',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineToolbarDrawerTableControl = {
  helpLabel: 'URL History',
  helpText: [
    'History of what you have asked the bot. Click to copy and past in input field.',
  ],
  relatedControlIds: [],
};

export const videoMachineToolbarDrawerTabsControl = {
  helpLabel: 'Video Drawer',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineToolbarSwapContentControl = {
  helpLabel: 'Swap Panels',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineToolbarVerticalAlignControl = {
  helpLabel: 'Vertical Align',
  helpText: [
    'History of what you have asked the bot. Click to copy and past in input field.',
  ],
  relatedControlIds: [],
};

export const videoMachineToolbarVideoStreamControl = {
  helpLabel: 'Stream Video',
  helpText: [],
  relatedControlIds: [],
};

export const videoMachineSidebarToolSelectControl = {
  helpLabel: 'Video Tool',
  helpText: [
    ' Play content from YouTube, Facebook, Twitch, SoundCloud, Vimeo, and many other platforms.',
  ],
  relatedControlIds: ['videoMachineToolbarDrawerCollapseControl'],
};

export const videoMachineBoardSectionVisibilityControl = {
  helpLabel: 'Video Visibility',
  helpText: ['Hide video and close the Video Tool.'],
  relatedControlIds: [],
};
