import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { GlobalStateContext } from '../../../globalState';

const MenuBoardFrames = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);

  const { board } = controllerState.context;

  return (
    <Card id='loadCard'>
      <CardContent component={Box} ml={2} mr={2}>
        <pre>{JSON.stringify(board, undefined, 2)}</pre>
      </CardContent>
    </Card>
  );
};

export default MenuBoardFrames;
