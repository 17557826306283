import React, { useContext, useState } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined';
import VerticalAlignTopOutlined from '@material-ui/icons/VerticalAlignTopOutlined';
import VerticalAlignBottomOutlined from '@material-ui/icons/VerticalAlignBottomOutlined';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Slider from '@material-ui/core/Slider';

import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => globalStyles(theme));

const VerticalPositionSlider = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const {
    editContext: {
      imageMachine: { imageField },
    },
    machinesContext: {
      imageMachine: {
        boardSettings: { autoCapture },
        imageCollection: { collectionItems },
        imageField: { isBackgroundSelected },
      },
    },
    viewContext: {
      imageMachine: { imageField: viewImageField },
    },
  } = controllerState.context;

  const objectVerticalPosition = isBackgroundSelected
    ? imageField.backgroundObjectVerticalPosition
    : imageField.objectVerticalPosition;
  const viewObjectVerticalPosition = isBackgroundSelected
    ? viewImageField.backgroundObjectVerticalPosition
    : viewImageField.objectVerticalPosition;

  const valueLabelIconDictionary = {
    0: { Icon: VerticalAlignBottomOutlined, label: 'Bottom' },
    100: { Icon: VerticalAlignTopOutlined, label: 'Top' },
  };

  const ToolbarIcon = valueLabelIconDictionary[objectVerticalPosition]
    ? valueLabelIconDictionary[objectVerticalPosition].Icon
    : VerticalAlignCenterOutlined;
  const helpLabelValue = valueLabelIconDictionary[objectVerticalPosition]
    ? valueLabelIconDictionary[objectVerticalPosition].label
    : `${50 - objectVerticalPosition}%`;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Help
          controlId='imageMachineToolbarVerticalPositionsControl'
          helpLabelValue={helpLabelValue}
          isDisabled={!collectionItems.length}
          isHidden={Boolean(anchorEl)}
          placement='top'
        >
          <IconButton
            aria-label='vertical position'
            disabled={!collectionItems.length}
            color={
              objectVerticalPosition !== viewObjectVerticalPosition ||
              autoCapture
                ? 'secondary'
                : 'primary'
            }
            onClick={(event) =>
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget)
            }
            size='small'
          >
            <ToolbarIcon />
          </IconButton>
        </Help>
        <Popper
          anchorEl={anchorEl}
          id='imageMachineToolbarVerticalPositionsControl'
          open={Boolean(anchorEl)}
          placement='top'
          style={{ zIndex: 2000 }}
        >
          <Slider
            className={classes.machineToolbarSlider}
            color={
              objectVerticalPosition !== viewObjectVerticalPosition ||
              autoCapture
                ? 'secondary'
                : 'primary'
            }
            onChange={(event, value) => {
              controllerSend({
                type: 'UPDATE_EDIT_CONTEXT',
                payload: {
                  autoCapture: false,
                  machineId: 'imageMachine',
                  property: 'imageField',
                  values: isBackgroundSelected
                    ? {
                        backgroundObjectVerticalPosition: value,
                      }
                    : {
                        objectVerticalPosition: value,
                      },
                },
              });
              if (autoCapture) {
                controllerSend({
                  type: 'SUBMIT_FRAME_CAPTURE',
                });
              }
            }}
            min={0}
            max={100}
            orientation='vertical'
            step={1}
            style={{ height: 120 }}
            track={false}
            value={objectVerticalPosition}
            valueLabelDisplay='on'
            valueLabelFormat={() => {
              const { Icon } =
                valueLabelIconDictionary[objectVerticalPosition] || {};
              return Icon ? <Icon /> : `${50 - objectVerticalPosition}%`;
            }}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default VerticalPositionSlider;
