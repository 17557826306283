import guideBoardCreateForm from './guideBoardCreateForm';
import guideSidebarTools from './guideSidebarTools';
import guideEditMenus from './guideEditMenus';
import guideFrameToolbar from './guideFrameToolbar';
import guideViewMenus from './guideViewMenus';
import guideSidenoteToolbar from './guideSidenoteToolbar';
import tutorialImageBackgroundSet from './tutorialImageBackgroundSet';
import tutorialImageBackgroundChange from './tutorialImageBackgroundChange';
import tutorialMapBackgroundSet from './tutorialMapBackgroundSet';
import tutorialStreamStart from './tutorialStreamStart';
import tutorialRecordStart from './tutorialRecordStart';
/**
 * Help topics are based on the user's role for a board ('edit', 'view').
 * A role has a `default` help topic that will be the topic displayed when
 * the Help Tool is first opened.
 */
const editHelpTopics = {
  record: {
    default: guideSidebarTools,
    guideSidenoteToolbar,
    guideEditMenus,
    guideFrameToolbar,
    tutorialImageBackgroundSet,
    tutorialImageBackgroundChange,
    tutorialMapBackgroundSet,
  },
  stream: {
    default: guideSidebarTools,
    guideSidenoteToolbar,
    guideEditMenus,
    guideFrameToolbar,
    tutorialImageBackgroundSet,
    tutorialImageBackgroundChange,
    tutorialMapBackgroundSet,
  },
};

const viewHelpTopics = {
  record: {
    default: guideViewMenus,
    tutorialRecordStart,
    tutorialStreamStart,
    guideBoardCreateForm,
  },
  stream: {
    default: guideViewMenus,
    tutorialRecordStart,
    tutorialStreamStart,
    guideBoardCreateForm,
  },
};

const helpTopicsByRole = {
  edit: editHelpTopics,
  view: viewHelpTopics,
};

export default helpTopicsByRole;
