import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import IconButton from '@material-ui/core/IconButton';
import CachedOutlined from '@material-ui/icons/CachedOutlined';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarBoardOrientation = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      chessMachine: {
        chessField: { boardOrientation },
      },
    },
    machinesContext: {
      chessMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      chessMachine: {
        chessField: { boardOrientation: viewBoardOrientation },
      },
    },
  } = controllerState.context;

  return (
    <Help
      controlId='chessMachineToolbarBoardOrientationControl'
      placement='top'
    >
      <IconButton
        color={
          viewBoardOrientation !== boardOrientation || autoCapture
            ? 'secondary'
            : 'primary'
        }
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'chessMachine',
              property: 'chessField',
              values: {
                boardOrientation:
                  boardOrientation === 'white' ? 'black' : 'white',
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <CachedOutlined />
      </IconButton>
    </Help>
  );
};

export default ToolbarBoardOrientation;
