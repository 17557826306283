import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import Editor from './Editor';
// import Bot from './Bot';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const CodeDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();
  const {
    machinesContext: {
      codeMachine: {
        toolbarDrawer: { drawerTab, isDrawerCollapsed },
      },
    },
  } = controllerState.context;

  return (
    <Collapse
      className={classes.machineDrawerContainer}
      in={isDrawerCollapsed}
      unmountOnExit
    >
      {drawerTab === 'editor' && <Editor />}
      {/* {drawerTab === 'bot' && <Bot />} */}
    </Collapse>
  );
};

export default CodeDrawer;
