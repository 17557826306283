import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessOutlined from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import IconButton from '@material-ui/core/IconButton';

import { MACHINES_TOOLBAR_DICTIONARY } from '../../containers/Controller/constants';
import { GlobalStateContext } from '../../globalState';
import Help from '../../containers/Help';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary[theme.palette.type],
    },
  },
}));

const MachineDrawerToggle = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    machinesContext,
    machinesContext: {
      controllerMachine: {
        boardGrid: { activeMachineId },
      },
    },
  } = controllerState.context;

  const {
    toolbarDrawer: { isDrawerCollapsed },
  } = machinesContext[activeMachineId];
  const { label: machineLabel } =
    MACHINES_TOOLBAR_DICTIONARY[activeMachineId] || {};
  const controlId = `${activeMachineId}ToolbarDrawerCollapseControl`;

  return (
    <Help
      controlId={controlId}
      helpLabelValue={isDrawerCollapsed ? 'Open' : 'Closed '}
      isDisabled={!machinesContext[activeMachineId].toolbarDrawer}
      placement='bottom-start'
    >
      <IconButton
        className={classes.root}
        color='primary'
        disabled={!machinesContext[activeMachineId].toolbarDrawer}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: activeMachineId,
              property: 'toolbarDrawer',
              values: {
                isDrawerCollapsed: !isDrawerCollapsed,
              },
            },
          });
          controllerSend({
            type: 'CONTROLLER_PROP_VALUES',
            payload: {
              property: 'helpControl',
              values: {
                renderedControlIds: new Set(),
              },
            },
          });
        }}
        size='small'
      >
        {isDrawerCollapsed ? (
          <ExpandLessOutlined label={machineLabel} />
        ) : (
          <ExpandMoreOutlined label={machineLabel} />
        )}
      </IconButton>
    </Help>
  );
};

export default MachineDrawerToggle;
