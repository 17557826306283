import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { slateToHtml } from '@slate-serializers/html';
import IconButton from '@material-ui/core/IconButton';

import { SIDENOTE_VERTICAL_ALIGN_OPTIONS } from '../constants';
import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarVerticalAlign = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      sidenoteMachine: {
        sidenoteField: { editorState, verticalAlign },
      },
    },
    machinesContext: {
      sidenoteMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      sidenoteMachine: {
        sidenoteField: { verticalAlign: viewVerticalAlign },
      },
    },
  } = controllerState.context;

  const verticalAlignOptionIndex = SIDENOTE_VERTICAL_ALIGN_OPTIONS.findIndex(
    ({ value }) => value === verticalAlign
  );
  const { Icon: ImageObjectAlignIcon, label: helpLabelValue } =
    SIDENOTE_VERTICAL_ALIGN_OPTIONS[verticalAlignOptionIndex];

  const sidenoteHtml = slateToHtml(editorState);
  const isSidenoteHTMLEmpty = !sidenoteHtml || sidenoteHtml === '<p></p>';

  return (
    <Help
      controlId='sidenoteMachineToolbarVerticalAlignControl'
      helpLabelValue={helpLabelValue}
      isDisabled={isSidenoteHTMLEmpty}
      placement='top'
    >
      <IconButton
        color={
          viewVerticalAlign !== verticalAlign || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={isSidenoteHTMLEmpty}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'sidenoteMachine',
              property: 'sidenoteField',
              values: {
                verticalAlign:
                  verticalAlignOptionIndex ===
                  SIDENOTE_VERTICAL_ALIGN_OPTIONS.length - 1
                    ? SIDENOTE_VERTICAL_ALIGN_OPTIONS[0].value
                    : SIDENOTE_VERTICAL_ALIGN_OPTIONS[
                        verticalAlignOptionIndex + 1
                      ].value,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        <ImageObjectAlignIcon />
      </IconButton>
    </Help>
  );
};

export default ToolbarVerticalAlign;
