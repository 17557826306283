import React, { useContext } from 'react';
import { useActor } from '@xstate/react';

import VolumeOffOutlined from '@material-ui/icons/VolumeOffOutlined';
import VolumeUpOutlined from '@material-ui/icons/VolumeUpOutlined';
import IconButton from '@material-ui/core/IconButton';

import { GlobalStateContext } from '../../../globalState';
import Help from '../../Help';

const ToolbarMuteButton = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);

  const {
    editContext: {
      videoMachine: { videoField },
    },
    machinesContext: {
      videoMachine: {
        boardSettings: { autoCapture },
      },
    },
    viewContext: {
      videoMachine: { videoField: viewVideoField },
    },
  } = controllerState.context;

  return (
    <Help
      controlId='videoMachineToolbarAudioMuteControl'
      isDisabled={!videoField.url}
      placement='top'
      helpLabelValue={videoField.muted ? 'On' : 'Off'}
    >
      <IconButton
        color={
          videoField.muted !== viewVideoField.muted || autoCapture
            ? 'secondary'
            : 'primary'
        }
        disabled={!videoField.url}
        onClick={() => {
          controllerSend({
            type: 'UPDATE_EDIT_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'videoField',
              values: {
                muted: !videoField.muted,
              },
            },
          });
          if (autoCapture) {
            controllerSend({
              type: 'SUBMIT_FRAME_CAPTURE',
            });
          }
        }}
        size='small'
      >
        {videoField.muted ? <VolumeOffOutlined /> : <VolumeUpOutlined />}
      </IconButton>
    </Help>
  );
};

export default ToolbarMuteButton;
