import React, { useContext } from 'react';
import isEqual from 'lodash/isEqual';
import { useActor } from '@xstate/react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import AddRounded from '@material-ui/icons/AddRounded';

import { globalStyles } from '../../../themes/globalStyles';
import { GlobalStateContext } from '../../../globalState';
import { controlStyles } from './styles';
import Help from '../../Help';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  ...controlStyles(theme),
}));

const FramesSliderControl = (props) => {
  const { controllerService, sessionService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [sessionState] = useActor(sessionService);
  const classes = useStyles();
  const theme = useTheme();
  const showControlLabels = useMediaQuery(theme.breakpoints.up('md'));

  const {
    board: { frames },
    editContext,
    editControl: { isEditFrameModeActive },
    editFrameIndex,
    viewContext,
    viewFrameIndex,
  } = controllerState.context;

  const frameIndex = controllerState.matches('view')
    ? viewFrameIndex
    : editFrameIndex;
  const inEditMode = controllerState.matches('edit');
  const hasUnsavedChanges = inEditMode && !isEqual(editContext, viewContext);

  const isEditFrameDisabled =
    sessionState.matches('stream.edit') && editFrameIndex <= frames.length - 1;

  return inEditMode &&
    !isEditFrameModeActive &&
    !hasUnsavedChanges &&
    frameIndex !== frames.length ? (
    <Help
      controlId='controllerMachineFramesAddControl'
      isDisabled={frameIndex === frames.length}
      placement='top'
    >
      <Button
        aria-label='new frame'
        color='primary'
        disabled={frameIndex === frames.length}
        onClick={() => {
          controllerSend({
            type: 'CHANGE_FRAME_INDEX',
            payload: {
              targetFrameIndex: frames.length,
            },
          });
          controllerSend({
            type: 'CONTROLLER_PROP_VALUES',
            payload: {
              property: 'editControl',
              values: { isEditFrameModeActive: true },
            },
          });
        }}
        size='small'
        style={{ marginLeft: 4 }}
        variant='contained'
      >
        {showControlLabels ? (
          `ADD FRAME: ${frames.length}`
        ) : (
          <AddRounded
            className={isEditFrameDisabled ? classes.iconPulsating : ''}
            color={isEditFrameDisabled ? 'secondary' : 'inherit'}
          />
        )}
      </Button>
    </Help>
  ) : null;
};

export default FramesSliderControl;
