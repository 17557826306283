import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Input from './Input';
import { GlobalStateContext } from '../../../globalState';
import { globalStyles } from '../../../themes/globalStyles';

const useStyles = makeStyles((theme) => globalStyles(theme));

const VideoDrawer = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState] = useActor(controllerService);
  const classes = useStyles();
  const {
    machinesContext: {
      videoMachine: {
        toolbarDrawer: { drawerTab, isDrawerCollapsed },
      },
    },
  } = controllerState.context;

  return (
    <Paper className={classes.drawerPaper} elevation={0}>
      <Collapse in={isDrawerCollapsed} unmountOnExit>
        <Grid container>
          <Grid item xs={12} className={classes.machineDrawerContainer}>
            <Paper elevation={0} className={classes.toolbarPaper}>
              {drawerTab === 'url' && <Input />}
            </Paper>
          </Grid>
        </Grid>
      </Collapse>
    </Paper>
  );
};

export default VideoDrawer;
