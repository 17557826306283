import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { progressBar } from './styles';

const useStyles = makeStyles((theme) => progressBar(theme));

const ProgressBar = ({ id, value, ...restProps }) => {
  const classes = useStyles();

  return (
    <LinearProgress
      aria-label='progress bar'
      className={classes.progressBar}
      id={id}
      value={value}
      variant='determinate'
      {...restProps}
    />
  );
};

export default ProgressBar;
