// Tooltips : DONE
export const controllerMachineBoardLinkControl = {
  helpLabel: 'Copy Invite Link',
  helpText: [
    "Copies the board's invite link to the clipboard. Paste it anywhere to share with others.",
  ],
  relatedControlIds: [],
};

export const controllerMachineBoardRecordSettingsControl = {
  helpLabel: 'Board Settings',
  helpText: [
    'Change title/topic, user name, auto-play, sidebar tools. Choose to stop recording now and continue later, or to share the recording with others.',
  ],
  relatedControlIds: [],
};

export const controllerMachineBoardStreamSettingsControl = {
  helpLabel: 'Stream Menu',
  helpText: ['Change sidebar tools, select actions, etc.'],
  relatedControlIds: [],
};

export const controllerMachineBoardViewControl = {
  helpLabel: 'Board Mode',
  helpText: [
    'Click to toggle between recording/streaming and viewing the board.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreate = {
  helpLabel: 'Start Menu',
  helpText: [
    'Start a live stream or recording, or continue with a board you viewed or worked on earlier.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardConsentControl = {
  helpLabel: 'Cookies Consent',
  helpText: [
    'Check the box to accept cookies. Required to create or view a board.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardHelpControl = {
  helpLabel: 'Help',
  helpText: ['Get on screen help with creating a board.'],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardNameControl = {
  helpLabel: 'Title, Topic, etc.',
  helpText: [
    'Name or description for the board.',
    "Required and max. 60 characters. Cannot be changed after you've started streaming or recording!",
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardRecordingControl = {
  helpLabel: 'Start Recording',
  helpText: [
    'Create and edit your board first. Share it with others when ready.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardStreamControl = {
  helpLabel: 'Start Streaming',
  helpText: [
    'When streaming, changes you save to your board are live shared (streamed) with viewers invited to the board.',
    'To invite viewers, you share the invite link that is becomes available you started streaming.',
    'Unlike when recording, you cannot edit any changes you have saved to the board as they have been shared.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateBoardUserNameControl = {
  helpLabel: 'Nickname',
  helpText: [
    'Name, nickname, or alias for yourself.',
    "Required and max. 15 characters. Cannot be changed after you've started streaming or recording!",
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainContinueBoardSelectControl = {
  helpLabel: 'Select Board',
  helpText: [
    'Pick a board you created or viewed previously. Enter text to search by board label, or use the dropdown.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainContinueBoardDeleteControl = {
  helpLabel: 'Delete Board',
  helpText: [
    'Delete this board from the history. This action cannot be undone!',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainContinueBoardRecordControl = {
  helpLabel: 'Edit Board',
  helpText: [
    'Continue editing this recording. Share the board with others when ready.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainContinueBoardStreamControl = {
  helpLabel: 'Stream Board',
  helpText: [
    'Continue live sharing the selected board with others while creating.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainContinueBoardViewControl = {
  helpLabel: 'View Board',
  helpText: [
    'Continue playing the selected board. Pause, rewind, and resume at any time.',
  ],
  relatedControlIds: [],
};

export const controllerMachineMenuMainViewBoardIdControl = {
  helpLabel: 'Board ID',
  helpText: ["Valid identifier number of a board that's been shared with you."],
  relatedControlIds: [],
};

export const controllerMachineMenuMainViewBoardStartControl = {
  helpLabel: 'View Board',
  helpText: ['Start playing the board. Pause, rewind, and resume at any time.'],
  relatedControlIds: [],
};

export const controllerMachineModalCloseControl = {
  helpLabel: 'Close Modal',
  helpText: ['Click to close modal, or hit Escape key on your keyboard.'],
};

export const controllerMachineModalHomeControl = {
  helpLabel: 'Home',
  helpText: ['Return to home screen.'],
};

export const controllerMachineHelpMenuControl = {
  helpLabel: 'Help Tooltips',
  helpText: [
    'If you ever need help, this is where to go for guidance and tutorials.',
  ],
  relatedControlIds: [],
};

export const controllerMachineCaptureSettingControl = {
  helpLabel: 'Auto Mode',
  helpText: [
    'In capture mode Manual (default mode) a button click is required to capture board changes for a new frame. When in Automatic mode, for every change to the board a new frame is automatically captured.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFramesPlayControl = {
  helpLabel: 'Play/Pause',
  helpText: [
    'Click to play or pause the automated playback of frames. When playback is paused, the Frame Toolbar will become visible.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFramesForwardControl = {
  helpLabel: 'Last Frame',
  helpText: [],
  relatedControlIds: [],
};

export const controllerMachineFramesLiveControl = {
  helpLabel: 'Live Stream',
  helpText: [],
  relatedControlIds: [],
};

export const controllerMachineFramesNextControl = {
  helpLabel: 'Next Frame',
  helpText: [],
  relatedControlIds: [],
};

export const controllerMachineFramesPreviousControl = {
  helpLabel: 'Previous Frame',
  helpText: [
    'Click to move the playhead one frame back.',
    'This button is disabled when the playhead is on the first frame.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFramesRewindControl = {
  helpLabel: 'First Frame',
  helpText: [
    'Click to jump to the first frame. This button is disabled when the playhead is on the first frame.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFramesSliderControl = {
  helpLabel: 'Frame Slider',
  helpText: [
    'Use the toolbar buttons to step forward or backward through the frames. Drag the slider to quickly navigate to a specific frame index.',
    'This toolbar is only visible when the playback is paused.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFramesAddControl = {
  helpLabel: 'New Frame',
  helpText: ['Skip to end, and start new frame.'],
  relatedControlIds: [],
};

export const controllerMachineFrameResetControl = {
  helpLabel: 'Undo Changes',
  helpText: ['Reset board to last captured frame.'],
  relatedControlIds: [],
};

export const controllerMachineDimensionSliderControl = {
  helpLabel: 'Section Slider',
  helpText: ['This slider allows you to configure your board dimensions.'],
  relatedControlIds: [],
};

export const controllerMachineFrameCreateControl = {
  helpLabel: 'Add New Frame',
  helpText: [
    'Capture the frame and add frame with changes since previous frame.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFrameDeleteControl = {
  helpLabel: 'Delete Frame',
  helpText: [
    'Delete the current frame from your board. This action cannot be undone.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFrameUpdateControl = {
  helpLabel: 'Update Frame',
  helpText: [
    'Update the current frame with the changes. This action cannot be undone.',
    'To reset the changes to this frame, use the Undo button in the toolbar.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFrameDurationControl = {
  helpLabel: 'Frame Timer',
  helpText: [
    'This button allows to set a custom delay time for the frame.',
    'If no custom delay time is set, the delay time will be automatically determined based on the changes compared to the previous frame.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFrameOrientationControl = {
  helpLabel: 'Board Orientation',
  helpText: [
    'This button lets you change the board orientation.',
    'When portrait, the panels are stacked; one above the other.',
    'When landscape, the panels are in line; one next to the other.',
  ],
  relatedControlIds: [],
};

export const controllerMachineFrameThemeControl = {
  helpLabel: 'Frame Theme',
  helpText: ['This button lets you pick a color theme for the frame.'],
  relatedControlIds: [],
};

export const controllerMachineMenuBoardOverview = {
  helpLabel: 'Menu Modal',
  helpText: ['This button allows you to capture your frame.'],
  relatedControlIds: [],
};

export const controllerMachineMenuMainCreateTab = {
  helpLabel: 'Main Menu Tabs',
  helpText: ['This button allows you to capture your frame.'],
  relatedControlIds: [],
};

export const controllerMachineMenuBoardStreamCancelControl = {
  helpLabel: 'Stop Streaming',
  helpText: ['Click to stop streaming. Streaming can be continued later.'],
  relatedControlIds: [],
};

export const controllerMachinePanelsBackgroundVisibilityControl = {
  helpLabel: 'Hide Background:',
  helpText: [],
  relatedControlIds: [],
};

export const controllerMachinePanelsDirectionControl = {
  helpLabel: 'Panels Direction',
  helpText: ['Swaps the direction of the panels.'],
  relatedControlIds: [],
};

export const controllerMachinePanelsOrientationControl = {
  helpLabel: 'Panels Layout',
  helpText: [],
  relatedControlIds: [],
};

export const controllerMachinePanelsSliderControl = {
  helpLabel: 'Panel Slider',
  helpText: ['Slide the markers to change the panel size.'],
  relatedControlIds: [],
};
