import React, { useContext, useState } from 'react';
import { Chess } from 'chess.js';
import { makeStyles } from '@material-ui/core/styles';
import { useActor } from '@xstate/react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import Table from './Table';
import { drawerStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => drawerStyles(theme));

const ChessToolbar = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const [chessGame] = useState(new Chess());
  const classes = useStyles();

  const {
    machinesContext: { chessMachine },
    editContext,
  } = controllerState.context;
  const {
    positionField,
    toolbarDrawer: { rowDataItems },
  } = chessMachine;

  const onClickPositionSubmit = () => {
    rowDataItems.unshift({
      value: positionField.value,
    });
    controllerSend({
      type: 'UPDATE_EDIT_CONTEXT',
      payload: {
        machineId: 'chessMachine',
        property: 'chessField',
        values: { value: positionField.value },
      },
    });
    controllerSend({
      type: 'UPDATE_MACHINES_CONTEXT',
      payload: {
        machineId: 'chessMachine',
        property: 'toolbarDrawer',
        values: { rowDataItems },
      },
    });
    chessGame.load(positionField.value);
  };

  return (
    <>
      <Table />
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                className={classes.toolbarButton}
                color='primary'
                id='positionSubmit'
                disabled={
                  !positionField.value ||
                  positionField.value ===
                    editContext.chessMachine.chessField.value
                }
                onClick={onClickPositionSubmit}
                size='small'
                variant='contained'
              >
                Set pieces
              </Button>
            </InputAdornment>
          ),
        }}
        id='positionField'
        fullWidth
        onChange={({ target: { value } }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'chessMachine',
              property: 'positionField',
              values: { value },
            },
          })
        }
        placeholder='Enter chess position in FEN notation'
        size='small'
        value={positionField.value}
        variant='outlined'
      />
    </>
  );
};

export default ChessToolbar;
