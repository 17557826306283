export const videoStyles = (theme) => ({
  reactPlayer: {},
  reactPlayerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  // TODO: Allow video to cover or contain background
  // https://stackoverflow.com/a/60192837
  // reactPlayerWrapperFull: {
  //   position: 'relative',
  //   overflow: 'hidden',
  //   width: '100vw',
  //   height: '100vh',

  //   '& iframe': {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     width: '100%',
  //     height: '100%',
  //     transform: 'translate(-50%, -50%)',

  //     '@media (min-aspect-ratio: 16/9)': {
  //       height: '56.25%',
  //     },

  //     '@media (max-aspect-ratio: 16/9)': {
  //       width: '177.78%',
  //     },
  //   },
  // },
  toolbarInput: {
    paddingLeft: theme.spacing(1),
  },
  toolbarButton: {
    marginRight: theme.spacing(1),
  },
});
