import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactPlayer from 'react-player';
import TextField from '@material-ui/core/TextField';

import Table from './Table';
import { drawerStyles } from './styles';
import { GlobalStateContext } from '../../../globalState';

const useStyles = makeStyles((theme) => drawerStyles(theme));

const VideoDrawerInput = () => {
  const { controllerService } = useContext(GlobalStateContext);
  const [controllerState, controllerSend] = useActor(controllerService);
  const classes = useStyles();

  const {
    machinesContext: {
      videoMachine: {
        toolbarDrawer: { rowDataItems },
        urlField,
      },
    },
    editContext: {
      videoMachine: { videoField },
    },
  } = controllerState.context;

  return (
    <>
      <Table />
      <TextField
        id='video-url-field'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                color='primary'
                disabled={
                  !ReactPlayer.canPlay(urlField.value) ||
                  urlField.value === videoField.url
                }
                id='video-url-button-submit'
                onClick={() => {
                  if (
                    !rowDataItems.find(({ value }) => value === urlField.value)
                  ) {
                    rowDataItems.unshift({
                      value: urlField.value,
                    });
                  }
                  controllerSend({
                    type: 'UPDATE_EDIT_CONTEXT',
                    payload: {
                      machineId: 'videoMachine',
                      property: 'videoField',
                      values: { url: urlField.value },
                    },
                  });
                  controllerSend({
                    type: 'UPDATE_MACHINES_CONTEXT',
                    payload: {
                      machineId: 'videoMachine',
                      property: 'toolbarDrawer',
                      values: {
                        isDrawerCollapsed: false,
                        rowDataItems,
                      },
                    },
                  });
                }}
                size='small'
                variant='contained'
              >
                load
              </Button>
            </InputAdornment>
          ),
        }}
        fullWidth
        onChange={({ target: { value } }) =>
          controllerSend({
            type: 'UPDATE_MACHINES_CONTEXT',
            payload: {
              machineId: 'videoMachine',
              property: 'urlField',
              values: { value },
            },
          })
        }
        placeholder='Video url'
        size='small'
        type='url'
        value={urlField.value}
        variant='outlined'
      />
    </>
  );
};

export default VideoDrawerInput;
