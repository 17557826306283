import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActor } from '@xstate/react';
import { useCookies } from 'react-cookie';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { GlobalStateContext } from '../../../globalState';
import { generateSessionId } from '../../../helpers';
import CookieConsent from '../../Controller/Controls/CookieConsent';
import { BoardHistoryTable } from '../../../components/BoardHistory';
import Help from '../../Help';

const MenuMainStart = () => {
  const { controllerService, sessionService, userService } =
    useContext(GlobalStateContext);
  // eslint-disable-next-line no-unused-vars
  const [_, controllerSend] = useActor(controllerService);
  const [sessionState, sessionSend] = useActor(sessionService);
  // eslint-disable-next-line no-unused-vars
  const [__, userSend] = useActor(userService);
  const [userName] = useState('');
  const [name] = useState('');
  const navigate = useNavigate();
  const [cookies] = useCookies(['cookieConsent']);

  const { boardHistory, session } = sessionState.context;
  const sessionId = generateSessionId();

  const handleDeleteBoard = ({ sessionId, sessionMode }) => {
    sessionSend({
      type: sessionMode === 'record' ? 'DELETE_RECORDING' : 'DELETE_STREAM',
      payload: { sessionId },
    });
  };

  const handleEditBoard = ({ sessionId }) => {
    sessionSend({
      type: 'SESSION_RESET',
    });
    controllerSend({
      type: 'CONTROLLER_RESET',
    });
    userSend({
      type: 'USER_RESET',
    });
    navigate(`/board/${sessionId}`);
  };

  const handleViewBoard = ({ sessionId }) => {
    sessionSend({
      type: 'SESSION_RESET',
    });
    controllerSend({
      type: 'CONTROLLER_RESET',
    });
    userSend({
      type: 'USER_RESET',
    });
    navigate(`/board/${sessionId}/view`);
  };

  useEffect(() => {
    /**
     * Wait for session to be created before closing the modal
     * and navigating to session route.
     */
    if (
      !!session.id &&
      (sessionState.matches('init.startRecording') ||
        sessionState.matches('init.createBoard'))
    ) {
      controllerSend({
        type: 'MODAL_CLOSE',
        payload: { modalId: 'menuMainStart' },
      });
      navigate(`/board/${session.id}`);
    }
  }, [controllerSend, navigate, session, sessionState]);

  return (
    <Card style={{ overflow: 'auto' }}>
      <CardContent>
        <CookieConsent />
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={12}>
            {sessionState.can('CREATE_RECORDING') && (
              <Help
                controlId='controllerMachineMenuMainCreateBoardRecordingControl'
                isDisabled={!cookies.cookieConsent}
                placement='bottom'
              >
                <Button
                  color={!cookies.cookieConsent ? 'default' : 'primary'}
                  disabled={!cookies.cookieConsent}
                  fullWidth
                  id='stageStart'
                  onClick={() => {
                    // TODO: replace with CREATE_RECORDING event like othe machines
                    userSend({
                      type: 'UPDATE_USER_PROPERTY_VALUES',
                      payload: {
                        property: 'user',
                        values: {
                          dateLastOpened: new Date().toISOString(),
                          name: userName,
                          sessionId,
                        },
                      },
                    });
                    sessionSend({
                      type: 'CREATE_RECORDING',
                      payload: { name, sessionId, userName },
                    });
                    controllerSend({
                      type: 'CREATE_RECORDING',
                      payload: { sessionId },
                    });
                  }}
                  style={{ margin: 0 }}
                  variant='contained'
                >
                  Start New Board
                </Button>
              </Help>
            )}
          </Grid>
          {!!boardHistory.length && (
            <Grid item xs={12}>
              <FormControl fullWidth style={{ marginBottom: 16 }}>
                <FormLabel>Board History</FormLabel>
              </FormControl>
              <BoardHistoryTable
                dataItems={boardHistory}
                handleDeleteBoard={handleDeleteBoard}
                handleEditBoard={handleEditBoard}
                handleViewBoard={handleViewBoard}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MenuMainStart;
